import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService) { }

  async createDeliverySetting(payload){
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.createDeliverySetting(payload).
    subscribe(val => {
      this.dataService.hideSpinner()
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }
  async updateDeliverySetting(id, payload){
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.updateDeliverySetting(id, payload).
    subscribe(val => {
      this.dataService.hideSpinner()
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }

  async getSingleSetting(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleDeliverySetting(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val.shipping)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async deleteSetting(id) {
  
    let promise = new Promise<boolean>((resolve, reject) => {
      this.dataService.deleteDeliverySetting(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Delivery Setting has been deleted successfully!')
          resolve(true)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('Failed!')
          reject(false)
        });
    })
    
    let result = await promise;
    return result;
  }

  
}
