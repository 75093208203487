import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LiveChatService {
  public message;

  
  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router) { }

  async getConversations(payload, msgReceived) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getConversations(payload)
        .subscribe(val => {
          if(!msgReceived){
            this.dataService.hideSpinner()
          }
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getConversationMessages(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getConversationMessages(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async sendMessage(payload) {
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.sendMessage(payload)
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

    getMessage(){
        return this.message;
    }

    setMessage(msg: any) {
        this.message = msg;
    }
}
