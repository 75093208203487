import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { ConfirmDialogService } from 'app/confirm-dialog/confirm-dialog.service';
// import { ConfirmationDialogueService } from 'app/confirmation-dialogue.service';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { DataServiceService } from 'app/utilities/data-service.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-store-categories-list',
  templateUrl: './store-categories-list.component.html',
  styleUrls: ['./store-categories-list.component.css']
})
export class StoreCategoriesListComponent implements OnInit {
  categories;
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
  }
  urlID;
  constructor(private router: Router, private route: ActivatedRoute,private ngModalService: NgbModal, private translate: TranslateService, private dataService: DataServiceService, private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id
    if(this.urlID != 'all'){
      this.payload.filters = {storeId: this.urlID}
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    this.dataService.getStoreCategories(this.payload)
      .subscribe(val => {
        this.dataService.hideSpinner()
        this.totalRecords = val.totalRecord
        this.categories = val.storeCategory
        this.createPagination()
      }, err => {
        this.dataService.hideSpinner()
      })
  }

  updateCategory(id: string) {
    this.router.navigate(['add-store-category/' + id]);
  }

  async delete(id: String, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if (result == 'yes') {

        that.dataService.showSpinner()
        that.dataService.deleteStoreCategories(id).subscribe(val => {
          that.dataService.hideSpinner()
          that.ngOnInit();
          that.toastr.success('Store Category has been deleted successfully!')
        }, err => {
          that.dataService.hideSpinner()
          that.toastr.error('Failed!')
        });
      }
    });
  }


  reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getStoreCategories(this.payload)
      .subscribe(val => {
        this.categories = val.categories
        this.totalRecords = val.totalRecord
        this.createPagination()
      }, err => {
      }

      )
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getStoreCategories(this.payload)
      .subscribe(val => {
        this.categories = val.storeCategory
      }, err => {
      }

      )

  }
  addCategory() {
    this.router.navigate(['add-store-category/add'])
  }
  startPage() {
    return this.pageStart

  }
  counter(i) {
    return new Array(i)
  }


  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }


}
