import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { BrandService } from '../brand.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.css']
})
export class AddBrandComponent implements OnInit {
  constructor(public router: Router, private brandService: BrandService, private route: ActivatedRoute,private sanitizer: DomSanitizer,private translate: TranslateService) { }
  public uploader: FileUploader;
  public validationMessages = {
    name: [
      {
        type: "required",
        message: "Name is required",
      },
    ]
  };
  brandForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    detail: new UntypedFormControl(''),
    picture: new UntypedFormControl(''),
  })

  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  urlID;
  orgs;
  selectedOrg;
  previewImg:any;
  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id;
    this.orgs = JSON.parse(localStorage.getItem('organizations'))
      this.selectedOrg = this.orgs.find(o=>o.selected)
      if(this.urlID != 'add'){
        this.patchBrand()
      }
      const uploaderOptions: FileUploaderOptions = {
        url: environment.cloudinaryURL,

      };

      this.uploader = new FileUploader(uploaderOptions);
      this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
        form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
        form.append('file', fileItem);
        fileItem.withCredentials = false;
        return { fileItem, form };
      };

      this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
        let res: any = JSON.parse(response);
        this.brandForm.patchValue({
          picture: res.url
        })
        return { item, response, status, headers };
      };

      this.uploader.onAfterAddingFile = async (fileItem) => {
        this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
        this.brandForm.patchValue({
          picture: ''
        })
      }
    }


  gotoList(){
    this.router.navigate(['brands-list/'+this.selectedOrg.storeId])
  }

  submit(){
    let payload = this.brandForm.getRawValue();
    payload.storeId = this.selectedOrg.storeId
    this.brandService.createBrand(payload);
  }

  update(){
    let payload = this.brandForm.getRawValue();
    payload.storeId = this.selectedOrg.storeId
    this.brandService.updateBrand(this.urlID, payload);
  }

  async patchBrand(){
    let response = await this.brandService.getSingleBrand(this.urlID)
    if(response){
      this.brandForm.patchValue(response.brand)
    }
  }

  removeImg() {
    this.uploader.clearQueue();
    this.brandForm.patchValue({
      picture: ''
    })
  }

}


