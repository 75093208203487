import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/utilities/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) { }
  token
  verified
  async ngOnInit(): Promise<void> {
    this.token = this.route.snapshot.params.token
    let resp = await this.authService.verifyEmail({token: this.token})
    if(resp && resp.accessToken){
      this.verified = true;
    }
  }

  login(){
    this.router.navigate(['login'])
  }

}
