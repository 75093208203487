import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'app/utilities/auth.service';
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router, private auth: AuthService) { }
  public store = new BehaviorSubject(null)
  public updateStoreInLayout: BehaviorSubject<string> = new BehaviorSubject('');


  async getStores(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreList(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
  async getCountries() {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getCountries()
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
  async getCountriesData(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getCountriesData(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getStoreCategories(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreCategories(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async deleteStore(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.deleteStore(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Store has been deleted successfully!')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('Internal server error!')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async blockStore(id, payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.blockStore(id, payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Store modified!')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('Internal server error!')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getSingleStore(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleStore(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getDeliveryLocations(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getDeliveryLocations(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  // async getFoodCuisines(payload) {
  //   this.dataService.showSpinner()
  //   let promise = new Promise<any>((resolve, reject) => {
  //     this.dataService.getFoodCusinies(payload)
  //       .subscribe(val => {
  //         this.dataService.hideSpinner()
  //         resolve(val)
  //       }, err => {
  //         this.dataService.hideSpinner()
  //         reject(err)
  //       });
  //   })
  //   let result = await promise;
  //   return result;
  // }

  async createStore(payload) {
    this.dataService.showSpinner()
    this.dataService.createStore(payload)
      .subscribe(val => {
        this.router.navigate(['store-list']);
        this.toastr.success('Store has been registered successfully!')
        this.dataService.hideSpinner()
      }, err => {
        this.toastr.error('Registration failed!')
        this.dataService.hideSpinner()
      });
  }

  async updateStore(id, payload) {
    this.dataService.showSpinner()
    this.dataService.updateStore(id, payload)
      .subscribe(val => {
        // this.router.navigate(['store-list']);
        this.toastr.success('Store has been updated successfully!')
        let orgs =  JSON.parse(localStorage.getItem('organizations'))
        orgs.find(o=>o.selected).storeName = payload.name;
        orgs.find(o=>o.selected).storePic = payload.profilePic;
        localStorage.setItem('organizations', JSON.stringify(orgs))
        this.auth.setOrganizations();

        this.dataService.hideSpinner()
      }, err => {
        this.toastr.error('Update failed!')
        this.dataService.hideSpinner()
      });
  }

  async syncFbData() {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.syncFbData()
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async resetBotMenus() {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.resetBotMenus()
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async resetBotStores() {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.resetBotStore()
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getServing(payload) {
    this.dataService.showSpinner()

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getServing(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async updateOnBoarded(payload) {
    this.dataService.showSpinner()

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateServing(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getClosed(payload) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getClosed(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }
  async getCustomMessage(id) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getCustomMessage(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }
  async getStoreShippingPolicy(id) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreShippingPolicy(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }
  async getStoreRefundExchangeMessage(id) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreRefundExchange(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }
  async getTermsAndConditions(id) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getTermsAndConditions(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }

  async updateClosed(payload) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateClosed(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }
  async updateCustomMessage(payload, id) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateCustomMessage(payload, id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Custom Message has been updated')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }
  async updateStoreShippingPolicy(payload, id) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateStoreShippingPolicy(payload, id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Store Shipping Policy has been updated')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }
  async updateTermsAndConditions(payload, id) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateTermsAndConditions(payload, id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Terms & Conditions have been updated')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }
  async updateStoreExchangePolicy(payload, id) {
    this.dataService.showSpinner();

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateStoreExchangePolicy(payload, id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Store Refund & Exchange Policy has been updated')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })

    let result = await promise;
    return result;
  }

  async getStatus(payload) {
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreStatus(payload)
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async deliveryLocationsCity(payload) {
    return await new Promise<any>((resolve, reject) => {
      this.dataService.getDeliveryLocationsForCity(payload)
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        });
    });
  }

  async getDeliveryLocationsCity(payload) {
    return await new Promise<any>((resolve, reject) => {
      this.dataService.getDeliveryLocationsCity(payload)
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        });
    });
  }

  async checkDomainAvailability(domain) {
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.checkDomainAvailability(domain)
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async addWebsitePlatform(payload) {
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.addWebsitePlatform(payload)
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  getStore(): Observable<any> {
    return this.store.asObservable();
  }

  setStore(val: any) {
    this.store.next(val);
  }



}
