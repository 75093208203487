import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-google-analytics-plugin',
  templateUrl: './google-analytics-plugin.component.html',
  styleUrls: ['./google-analytics-plugin.component.scss']
})
export class GoogleAnalyticsPluginComponent implements OnInit {



  constructor(private dataService: DataServiceService, private route: ActivatedRoute, private toastr: ToastrService, private router: Router) { }
  org;
  plugin;
  loader:any = true;
  googlePayload = {
    googleAnalyticsKey: "",
    isGoogleAnalytics: false
  }
  googleError = '';
  storeWebsiteConfigId
  ngOnInit(): void {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.getPlugin()
  }

  getPlugin(){
    this.dataService.showSpinner();
    this.dataService.getSinglePlugin(this.org.storeId, this.route.snapshot.routeConfig.path).subscribe(res=>{
      this.plugin = res.currentAppPlugin;
      if(this.plugin.isConnected){
        this.getStoreWebsiteSetting()
      }
      this.loader = false;
      this.dataService.hideSpinner();
    },err=>{
      this.dataService.hideSpinner();
      this.toastr.error('Error loading plugin')
      this.loader = 'failed';
    })
  }

  goToPlugins(){
    this.router.navigate(['plugins-list'])
  }

  updateGoogleId(){
    let payload = {...this.googlePayload, storeId: this.org.storeId}
    this.dataService.updateGoogleAnalytics(payload, this.storeWebsiteConfigId).subscribe(res=>{
      if(res?.storeWebsiteUpdate?.nModified == 1){
        this.toastr.success('Store configuration updated')
      }
    },err=>{
      console.log({err})
      this.toastr.error('Store configuration update failed')
    })
  }

  enteringGoogleId(e){
    let value = e.target.value;
    if(/(UA|YT|MO)-\d+-\d+/i.test(value) || !value){
      this.googleError = '';
    }
    else{
      this.googleError = 'Invalid ID'
    }
  }

  toggleGoogle(){
    this.googlePayload.isGoogleAnalytics = !this.googlePayload.isGoogleAnalytics
  }


  getStoreWebsiteSetting(){

    this.dataService.getBannerSettings(this.org.storeId).subscribe(res=>{
      if(res.storeWebsite){
        this.googlePayload.googleAnalyticsKey = res.storeWebsite.googleAnalyticsKey;
        this.googlePayload.isGoogleAnalytics = res.storeWebsite.isGoogleAnalytics;
        this.storeWebsiteConfigId = res.storeWebsite._id;
      }
     },err=>{
     })
  }

}
