import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-marketing-campaign',
  templateUrl: './marketing-campaign.component.html',
  styleUrls: ['./marketing-campaign.component.css']
})
export class MarketingCampaignComponent implements OnInit {

  constructor(private router: Router) { }

  campaigns = [{
    name: "Campaign 1",
    noOfCustomers: 5,
    status: "created",
    createdAt: new Date()
  },
  {
    name: "Campaign 1",
    noOfCustomers: 5,
    status: "created",
    createdAt: new Date()
  },
  {
    name: "Campaign 1",
    noOfCustomers: 5,
    status: "created",
    createdAt: new Date()
  },{
    name: "Campaign 1",
    noOfCustomers: 5,
    status: "created",
    createdAt: new Date()
  },
  {
    name: "Campaign 1",
    noOfCustomers: 5,
    status: "created",
    createdAt: new Date()
  },
  {
    name: "Campaign 1",
    noOfCustomers: 5,
    status: "created",
    createdAt: new Date()
  }]
  ngOnInit(): void {
  }

  addCampaign(){
    this.router.navigate(['add-marketing-campaign'])
  }

}
