import { Injectable } from '@angular/core';
import { DataServiceService } from '../../utilities/data-service.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private dataService: DataServiceService) { }

  async getOrders(payload){
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getOrder(payload)
        .subscribe(val =>{
          this.dataService.hideSpinner()
          resolve(val)
        },err=>{
          this.dataService.hideSpinner()
          reject(err)
        });
      })
        let result = await promise;
        return result;
    }

    async getStores(payload){
      let promise = new Promise<any>((resolve, reject) => {
        this.dataService.getStoreList(payload)
          .subscribe(val =>{
            resolve(val.stores)
          },err=>{
            reject(err)
          });
        })
          let result = await promise;
          return result;
      }

      async getSingleOrder(id){
        let promise = new Promise<any>((resolve, reject) => {
          this.dataService.getSingleOrder(id)
            .subscribe(val =>{
              resolve(val.order)
            },err=>{
              reject(err)
            });
          })
            let result = await promise;
            return result;
        }

        async changeOrderStatus(id, payload){
          this.dataService.showSpinner()
          let promise = new Promise<any>((resolve, reject) => {
            this.dataService.changeOrderStatus(id, payload)
              .subscribe(val =>{
                this.dataService.hideSpinner()
                resolve(val)
              },err=>{
                this.dataService.hideSpinner()
                reject(err)
              });
            })
              let result = await promise;
              return result;
          }

}
