import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css']
})
export class InfoModalComponent implements OnInit {
  @Input() public response:any;
  @Input() public modal:any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  close(){
    this.activeModal.close()
  }
  

}
