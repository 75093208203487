import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.css']
})
export class AddSubscriptionComponent implements OnInit {
  urlID;
  status;
  public validationMessages = {
    name: [{
      type: 'required', message: 'Email is required'
    }],
    description: [{
      type: 'required', message: 'Description is required'
    }],
    price: [{
      type: 'required', message: 'Price is required'
    }],
    discount: [{
      type: 'required', message: 'Discount is required'
    }],
    extraOrderCharge: [{
      type: 'required', message: 'Extra Order Charges are required'
    }],
    noOfOrders: [{
      type: 'required', message: 'Number of orders is required'
    }],
    onlinePayment: [{
      type: 'required', message: 'Online payment is required'
    }],
    noOfProduct: [{
      type: 'required', message: 'No. of products is required'},
      {type: 'pattern', message: 'Value should be greater than 0',
    }],
    noOfUsers: [{
      type: 'required', message: 'No. of users is required'},
      {type: 'pattern', message: 'Value should be greater than 0',
    }]
  }
  allowedFeatures = [
    {key:'Bulk Import/Export Products',value:'import-export-product'},
    {key:'Facebook & Instagram Messenger Store',value:'chatbot-store'},
    {key:'Message Centre',value:'inbox'},
  ]
  subscriptionForm = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    noOfOrders: ['', Validators.required],
    price: ['', Validators.required],
    discount: ['', Validators.required],
    isDiscount: [false, Validators.required],
    extraOrderCharge: ['', Validators.required],
    onlinePayment: [false, Validators.required],
    isCustomDomain: [false, Validators.required],
    allowedFeatures: new UntypedFormArray([]),
    noOfProduct: [null, [Validators.required, Validators.pattern(/[^0]+/)]],
    noOfUsers: [null, [Validators.required, Validators.pattern(/[^0]+/)]]

  })
  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder, private subService: SubscriptionService) { }

  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id;
    if(this.urlID != 'add'){
      this.getSingleSub()
    }
  }
  gotoList(){
    this.router.navigate(['subscription-list'])
  }

  submit(){
    let payload = this.subscriptionForm.getRawValue();
    payload.noOfUsers = parseInt(payload.noOfUsers);
    payload.price = parseInt(payload.price)
    payload.noOfOrders = parseInt(payload.noOfOrders)
    payload.discount = parseInt(payload.discount)
    payload.extraOrderCharge = parseInt(payload.extraOrderCharge)
    this.subService.createSubscription(payload);
  }
  update(){
    let payload = this.subscriptionForm.getRawValue();
    payload.noOfUsers = parseInt(payload.noOfUsers);
    payload.price = parseInt(payload.price)
    payload.status = this.status;
    payload.noOfOrders = parseInt(payload.noOfOrders)
    payload.discount = parseInt(payload.discount)
    payload.extraOrderCharge = parseInt(payload.extraOrderCharge)
    this.subService.updateSubscription(this.urlID, payload);
  }

  async getSingleSub(){
    let resp = await this.subService.getSingleSubscription(this.urlID)
    let allowedFeatures = resp.subscription.allowedFeatures;
    this.status = resp.subscription.status;
    let formValues = JSON.parse(JSON.stringify(resp.subscription))
    delete formValues.allowedFeatures;
    this.subscriptionForm.patchValue(formValues)
    allowedFeatures.forEach(feature=>{
      const control = new UntypedFormControl(feature);
      (this.subscriptionForm.controls.allowedFeatures as UntypedFormArray).push(control)
      this.allowedFeatures.find(a=>a.value == feature)['checked'] = true;
    })
  }

  getIndex(feature){
    return this.subscriptionForm.getRawValue().allowedFeatures.findIndex(a=>a==feature);
  }

  featureSelected(e,feature){
    if(e.target.checked){
      const control = new UntypedFormControl(feature);
      (this.subscriptionForm.controls.allowedFeatures as UntypedFormArray).push(control)
    }
    else{
      const index = this.getIndex(feature);
      (this.subscriptionForm.controls.allowedFeatures as UntypedFormArray).removeAt(index)
    }
  }

}
