import { Component, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import Chart from 'chart.js';
import { DataServiceService } from 'app/utilities/data-service.service';
import { AuthService } from 'app/utilities/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { SidebarService } from 'app/sidebar.service';
import { DashboardService } from 'app/dashboard.service';
import { StoreService } from '../settings/store/store.service';
import { MessagingService } from 'app/messaging.service';
import { NgxCaptureService } from 'ngx-capture';


@Component({
  selector: 'app-analytic',
  templateUrl: './analytic.component.html',
  styleUrls: ['./analytic.component.css']
})
export class AnalyticComponent implements OnInit {
  @ViewChild('screen', { static: true }) screen: any;
  @Output() userInfo = new EventEmitter();
  public canvas: any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;
  public engagementCanvas: any;
  public engagementCtx;
  public engagementChartColor;
  public engagementChartEmail;
  public engagementChartHours;
  payload = {
    filters: {
      "month": new Date().getMonth(),
      "year": new Date().getFullYear(),
    }
  }
  message: string = "Hola Mundo!"
  dashboardInfo = {
    sixMonths: [],
    totalBilledOrders: null,
    totalCancelledOrders: null,
    totalOrderCount: null,
    totalRejectedOrders: null,
    totalSalesBilled: null
  };
  labels = [];
  sixMonths = [];
  stores = [];
  storePayload = {
    "filters": {},
    "pagination": { start: 0, limit: 50 },
    "sort": {},
    "fields": "name"
  }
  storeId;
  isStoreOwner = false;
  isOboarded = false;
  isSuperAdmin = false;
  options = [{ _id: "all", name: "All" }];
  @Output() messageEvent = new EventEmitter<string>();

  constructor(
    private dataService: DataServiceService, private messagingService: MessagingService, private captureService: NgxCaptureService, private storeService: StoreService, private sidebar: SidebarService, private dashboardService: DashboardService, private authenticationService: AuthService, private permissionService: NgxPermissionsService
  ) { }

  async ngOnInit() {
    if (!this.authenticationService.getUser()) {
      await this.dataService.getUser().subscribe(async response => {
        this.permissionService.addPermission(response.user.features);
        this.permissionService.loadPermissions(response.user.features);
        this.authenticationService.setUser(response.user)
        this.sidebar.setProfileObs(response.user);
      })
    }

    if (!this.authenticationService.isAuthenticated) {
      this.authenticationService.logout();
    } else {
      let response = await this.dashboardService.getDashboardInfo(this.payload)
      this.dashboardInfo = await response.data;
      this.sixMonths = await response.data.sixMonths;

      let user = await this.authenticationService.getUser();

      if (await user.role == "admin") {
        this.isStoreOwner = true;
        this.payload.filters["storeId"] = user.storeId;

        let isServingPayload = {
          filters: {}
        }

        let isServing = this.storeService.getServing(isServingPayload).then((res) => {
          this.storeId = res.isServing._id;
          this.isOboarded = res.isServing.isServing;
        });
      }


      if (await user.role == "superAdmin") {
        this.isSuperAdmin = true;

        let res = await this.storeService.getStores(this.storePayload);

        this.stores = await res.stores;

        if (this.options.length > 0) {
          this.options.splice(0, this.options.length);

          this.options.push({ _id: "all", name: "All" });
        }

        this.stores.forEach((x) => this.options.push(x));
      }

      this.chartColor = "#FFFFFF";

      this.getTheChart();

      /* this.messagingService.requestPermission()
      this.messagingService.receiveMessage() */
    }
  }

  refresh() {
    this.ngOnInit()
  }

  async getTheChart() {
    if (this.dashboardInfo.sixMonths) {
      if (document.getElementById("chartHours")) {
        this.canvas = document.getElementById("chartHours")
        this.ctx = this.canvas.getContext("2d");
      }

      this.dashboardInfo.sixMonths.map(month => {
        if (this.labels.length >= 6) {
          this.labels.splice(0, this.labels.length);
        }

        this.labels.push(Object.keys(month)[0])
      })

      let chartData = {
        totalBilledOrders: [],
        totalRejectedOrders: [],
        totalCancelledOrders: []
      }

      this.dashboardInfo.sixMonths.map((month, i) => {
        chartData.totalBilledOrders.push(month[this.labels[i]].totalBilledOrders)
        chartData.totalRejectedOrders.push(month[this.labels[i]].totalRejectedOrders)
        chartData.totalCancelledOrders.push(month[this.labels[i]].totalCancelledOrders)
      })

      if (this.ctx) {
        this.chartHours = new Chart(this.ctx, {
          type: 'line',
          toolTip: {
            enabled: true,
          },
          data: {
            labels: this.labels,
            datasets: [{
              label: 'Total billed orders',
              borderColor: "#6bd098",
              backgroundColor: "#6bd098",
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 3,
              data: chartData.totalBilledOrders
            },
            {
              label: 'Total rejected orders',
              borderColor: "#f17e5d",
              backgroundColor: "#f17e5d",
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 3,
              data: chartData.totalRejectedOrders
            },
            {
              label: 'Total cancelled orders',
              borderColor: "#fcc468",
              backgroundColor: "#fcc468",
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 3,
              data: chartData.totalCancelledOrders
            }
            ]
          },
          options: {
            tooltips: {
              enabled: true,
              mode: 'label'
            },
            legend: {
              display: true
            },
            scales: {
              yAxes: [{

                ticks: {
                  fontColor: "#9f9f9f",
                  beginAtZero: false,
                  maxTicksLimit: 5,
                  //padding: 20
                },
                gridLines: {
                  drawBorder: false,
                  zeroLineColor: "#ccc",
                  color: 'rgba(255,255,255,0.05)'
                }

              }],

              xAxes: [{
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(255,255,255,0.1)',
                  zeroLineColor: "transparent",
                  display: false,
                },
                ticks: {
                  padding: 20,
                  fontColor: "#9f9f9f"
                }
              }]
            },
          }
        });
      }
    }
  }

  async updateServing() {
    let updateServingPayload = {
      filters: {},
      isServing: this.isOboarded ? false : true
    }

    let updateServing = await this.storeService.updateOnBoarded(updateServingPayload);

    await this.ngOnInit();
  }

  async getByStore(name) {
    let selected;

    if (name !== "All") {
      selected = this.options.filter((x) => x.name == name);

      this.payload.filters["storeId"] = selected[0]._id;

      let response = await this.dashboardService.getDashboardInfo(this.payload)
      this.dashboardInfo = await response.data;
      this.sixMonths = await response.data.sixMonths;

      this.getTheChart();
    } else {
      this.payload = {
        filters: {
          "month": new Date().getMonth(),
          "year": new Date().getFullYear(),
        }
      }

      let response = await this.dashboardService.getDashboardInfo(this.payload)
      this.dashboardInfo = await response.data;
      this.sixMonths = await response.data.sixMonths;

      this.getTheChart();
    }
  }

  downloadPDF() {
    this.captureService.getImage(this.screen.nativeElement, true).then(img => {
      const popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
      popupWinindow.document.open();
      popupWinindow.document.write(`
    <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width">
        
      </head>
      <body onload="window.print()" class="pad-15">

        <img src="${img}"/>
      </body>
      </html>`);
      popupWinindow.document.close();
    });
  }
}

