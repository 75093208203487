import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { DataServiceService } from 'app/utilities/data-service.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IntegrationsService } from 'app/pages/integrations/integrations.service';

@Component({
  selector: 'app-plugins-list',
  templateUrl: './plugins-list.component.html',
  styleUrls: ['./plugins-list.component.scss']
})
export class PluginsListComponent implements OnInit {
 integrations = []
  loader = false;
  org;
  constructor(private router: Router, private route: ActivatedRoute, private integrationService: IntegrationsService, private ngModalService: NgbModal, private translate: TranslateService, private permissionService: NgxPermissionsService, private dataService: DataServiceService, private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.getIntegrations()

  }

  getIntegrations(){
    this.loader = true;
    this.dataService.showSpinner()
    this.dataService.getPlugins(this.org.storeId)
      .subscribe(val => {
        console.log({val})
        this.loader = false;
        this.dataService.hideSpinner()
        this.integrations = val.appsPlugins;
        console.log(this.integrations)
      }, err => {
        this.loader = false;
        this.dataService.hideSpinner()
        this.toastr.error('Error loading plugins')
      })
  }

  routeTo(route){
    this.router.navigate([route]);
  }

}

