import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CategoryService } from 'app/pages/categories/category.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { DndDropEvent } from 'ngx-drag-drop';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProductService } from '../product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { VariableProductComponent } from './variable-product/variable-product.component';
import { environment } from 'environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})

export class AddProductComponent implements OnInit {
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.productGallery, event.previousIndex, event.currentIndex);
  }
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: 'lg'
  }
  productType = ''
  singleProduct: any;
  generalPayload: any;
  brands: any = [];
  lang = localStorage.getItem('lang')
  isPublished = false;
  uploading = false;

  @ViewChild('variableProductComp') variableProductComp: VariableProductComponent;
  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  addProduct = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    detail: new UntypedFormControl('', Validators.required),
    brandId: new UntypedFormControl(''),
    categoryId: new UntypedFormControl('', Validators.required),
    shortDetail: new UntypedFormControl(''),
    availableStock: new UntypedFormControl(''),
    isManageStock: new UntypedFormControl(false),
    subCategoryId: new UntypedFormControl(''),
    tags: new UntypedFormControl(''),
    // productTagsId: new FormControl(''),
    displayPicture: new UntypedFormControl('', Validators.required),
    productType: new UntypedFormControl(''),
    regularPrice: new UntypedFormControl(null, Validators.required),
    salePrice: new UntypedFormControl(null),
    stockStatus: new UntypedFormControl(null),
    sku: new UntypedFormControl(null)
  })
  addedAttributes = [];
  payload = {
    filters: {},
    pagination: { start: 0, limit: 999 },
    sort: {},
  }
  urlId;
  storeId;
  gallery = [];
  imgIndex;
  salePriceDate = {
    from: '',
    to: ''
  }
  public uploaderTemp: FileUploader;
  categories = [];
  public dpUploader: FileUploader;
  productGallery = [];
  previewImg;
  subCategories = [];
  tags = [];
  galleryImages: any = [];
  picturegallery = [];
  dropdownSettings = {}
  uploaderOptions: FileUploaderOptions = {
    url: environment.cloudinaryURL,
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize', 'insertImage',
        'insertVideo', 'toggleEditorMode'],

    ]
  };
  type = 'update'
  org;
  constructor(public router: Router, private ngModalService: NgbModal, private translate: TranslateService, private toastr: ToastrService, private route: ActivatedRoute, private productService: ProductService, private dataService: DataServiceService, private sanitizer: DomSanitizer) {

   }
  ngOnInit(): void {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o => o.selected)
    let paramId = JSON.parse(JSON.stringify(this.route.snapshot.params.id));
    this.urlId = paramId == 'clone' ? this.productService.getProductId() : paramId;
    this.type = (paramId != 'new' && paramId != 'clone') ? 'update' : paramId;
    if (!this.urlId) {
      this.router.navigate(['products-list/' + this.org.storeId])
      return;
    }
    // if(this.route.snapshot.params.id != 'new'){
    //   this.urlId = this.productService.getProductId() || 'new';
    // // }
    // if(this.route.snapshot.params.id!='new'){
    //   this.type = this.route.snapshot.params.id;
    // }
    this.storeId = this.route.snapshot.params.storeId;
    this.payload.filters = { storeId: this.storeId }
    this.getCategories()
    this.getBrands()
    this.getTags();
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    const uploaderOptions: FileUploaderOptions = {
      url: environment.cloudinaryURL,

    };

    this.dpUploader = new FileUploader(uploaderOptions);
    this.dpUploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      // console.log('hereeeeeeeeee2',fileItem)
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.dpUploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      // console.log('hereeeeeeeeeeee',response)
      let res: any = JSON.parse(response);
      this.addProduct.patchValue({
        displayPicture: res.url
      })
      return { item, response, status, headers };
    };

    this.dpUploader.onAfterAddingFile = async (fileItem) => {
      this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
      this.addProduct.patchValue({
        displayPicture: ''
      })
    }
    this.imageUploadConfig();
    if (this.urlId != 'new') {
      setTimeout(() => {
        this.getSingleProduct(this.urlId)
      }, 2000);
    }
  }

  removeGalleryImg(i) {
    // this.uploaderTemp.removeFromQueue(this.productGallery[i].file)
    this.productGallery.splice(i, 1)
    this.picturegallery.splice(i,1)
    if(!this.productGallery.length){
      this.uploaderTemp.clearQueue()
    }
  }

  imageUploadConfig() {
    this.uploaderTemp = new FileUploader(this.uploaderOptions);

    this.uploaderTemp.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.uploaderTemp.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);
      this.picturegallery.push(res.url)
      this.productGallery.forEach(gal => {
        gal.uploaded = true;
      })
      // this.galleryImages = JSON.parse(JSON.stringify(this.picturegallery))
    };
  }


  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      if (event.target.files.length > 5 || ((this.productGallery.length + event.target.files.length) > 5)) {
        this.toastr.error('You can upload only 5 images')
        return;
      }
      const file = event.target.files
      for (let i = 0; i < file.length; i++) {
        this.productGallery.push({
          file: file[i],
          temp: this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file[i]))),
          url: ''
        })
      }
    }
   // console.log(this.productGallery)
  }

  checkUpload(){
    return this.productGallery.filter(p=>!p.uploaded).length ? false : true;
  }

  uploadImages() {
    this.productGallery.forEach((image, i) => {
      // setTimeout(() => {
      if (!image.uploaded) {
        let file = image.file
        this.imgIndex = i;
        this.uploaderTemp.addToQueue([file], { autoUpload: true });
        this.uploaderTemp.uploadAll();
      }
      // }, 3000);
    })
  }

  async dpUpload() {
    this.uploading = true;
    let uploaded: any = await this.dpUploader.uploadAll()
    // setTimeout(() => {
    // }, 1800);
    if (uploaded) {
      this.uploading = false;
    }
  }


  addFile() {
    document.getElementById('add-img').click();
  }

  removeImg() {
    this.dpUploader.clearQueue();
    this.addProduct.patchValue({
      displayPicture: ''
    })
  }

  getCategories() {
    this.dataService.getCategories(this.payload)
      .subscribe(val => {
        this.categories = val.category;
      })
  }
  getBrands() {
    this.dataService.getBrands(this.payload)
      .subscribe(val => {
        this.brands = val.brand;
      })
  }

  getSubCategories(catId) {
    let payload = JSON.parse(JSON.stringify(this.payload))
    payload.filters = { 'categoryId': catId, storeId: this.storeId }
    this.dataService.getSubCategories(payload).subscribe(res => {
      this.subCategories = res.subCategory;
      if (this.urlId != 'new') {
        this.addProduct.get('subCategoryId').setValue(this.singleProduct.subCategoryId)
      }
    })
  }

  catSelected(id) {
    const val = id.target.value;
    this.getSubCategories(val)
  }

  getTags() {
    this.dataService.getTags(this.payload).subscribe(res => {
      this.tags = res.tag
    })
  }

  submit() {
    let variableProductForm = this.variableProductComp?.getFormStatus()


    if (this.addProduct.valid && (this.addProduct.get('productType').value == 'variable' ? variableProductForm?.valid : true)) {

      this.generalPayload = this.addProduct.getRawValue();
      this.generalPayload = {...this.generalPayload,isPublished: this.isPublished}
      if (this.salePriceDate.to && !this.salePriceDate.from) {
        this.toastr.error('Please select schedule dates')
        return;
      }
      this.generalPayload.productTagsId = []
      if (this.generalPayload.tags.length) {
        this.generalPayload.tags.forEach(p => {
          this.generalPayload.productTagsId.push(p._id)
        })
      }
      delete this.generalPayload.tags;
      this.generalPayload.picturegallery = this.picturegallery;
      this.productGallery.forEach(p => {
        if (p.url) {
          this.picturegallery.push(p.url)
        }
      })
      if (this.urlId != 'new' && typeof this.previewImg == 'string') {
        this.generalPayload.displayPicture = this.previewImg;
      }
      this.generalPayload.storeId = this.storeId;
      this.generalPayload.variations = [];
      this.generalPayload.salePriceDate = this.salePriceDate;
      this.generalPayload.isOnSale = this.salePriceDate.from ? true : false;
      if (this.addProduct.get('stockStatus').value == 'true') {
        this.generalPayload.stockStatus = this.addProduct.get('stockStatus').value === this.addProduct.get('stockStatus').value;
      }
      if (this.addProduct.get('stockStatus').value == 'false') {
        this.generalPayload.stockStatus = this.addProduct.get('stockStatus').value !== this.addProduct.get('stockStatus').value;
      }
      let payloadAtt: any = []
      this.addedAttributes.forEach(att => {
        let options = [];
        att.options.filter(o => o.checked).forEach(opt => {
          options.push({ optionName: opt.name, optionId: opt._id, optionImg: opt.imgURL })
        })
        payloadAtt.push({ attributeName: att.name, attributeId: att._id, attributeValue: options, usedForVariation: att.usedForVariation, isVisiblePage: att.isVisiblePage })
      })
      this.generalPayload.attributes = payloadAtt;

      let resp: any;

      if (this.urlId == 'new' || this.type == 'clone') {
        if (this.addProduct.get('productType').value == 'variable') {
          let variations = variableProductForm.getRawValue()
          this.generalPayload['variations'] = variations.variants;
        }
        resp = this.productService.addProduct(this.generalPayload);
      }
      else {
        if (this.addProduct.get('productType').value == 'variable') {
          let variations = variableProductForm.getRawValue();

          // if(Array.isArray(variations.variants)) {
          //   for(let i = 0; i < variations.variants.length; i++) {
          //     variations.variants[i].isOnSale = variations.variants[i].salePrice ? true : false;
          //   }
          // }
          this.generalPayload['variations'] = variations.variants;
        }
        //console.log(this.generalPayload);
        resp = this.productService.updateProduct(this.urlId, this.generalPayload);
      }
    }
    else {
      this.toastr.error(('Invalid Parameters'));
    }
  }

  productTypeSelected(e) {
    this.addedAttributes = [];
    if (this.addProduct.get('productType').value) {
      // e.preventDefault()
      if (e.target) {
        e.stopImmediatePropagation()
        let that = this;
        const modalRef = this.ngModalService.open(DeleteModalComponent, this.modalOptions);
        modalRef.componentInstance.message = this.lang == 'en' ? 'All your changes will be lost. Are you sure you want to change product type?' : 'سيتم فقدان جميع التغييرات الخاصة بك. هل أنت متأكد أنك تريد تغيير نوع المنتج?';
        modalRef.result.then(async (result) => {
          if (result == 'yes') {
            this.addProduct.controls.productType.setValue(e.target ? e.target.value : e)
            let productType = this.addProduct.get('productType').value
            if (productType == 'variable') {
              this.addProduct.controls["regularPrice"].clearValidators();
              this.addProduct.controls["regularPrice"].updateValueAndValidity();
            } else if (productType == 'simple') {
              this.addProduct.controls["regularPrice"].setValidators(Validators.required);
              this.addProduct.controls["regularPrice"].updateValueAndValidity();
            }
            that.salePriceDate = {
              from: '',
              to: ''
            }
            that.addProduct.controls.regularPrice.reset()
            that.addProduct.controls.salePrice.reset()
            that.addProduct.controls.isManageStock.reset()
            that.addProduct.controls.availableStock.reset()
            that.addProduct.controls.stockStatus.reset()
            that.addProduct.controls.sku.reset()
            that.variableProductComp?.getFormStatus().reset();

          }
          else {
            document.getElementById('product-type')['value'] = this.addProduct.get('productType').value
          }
        });
      }
    }
    else{
      this.addProduct.controls.productType.setValue(e.target ? e.target.value : e)
      let productType = this.addProduct.get('productType').value

      if (productType == 'variable') {
        this.addProduct.controls["regularPrice"].clearValidators();
        this.addProduct.controls["regularPrice"].updateValueAndValidity();
      } else if (productType == 'simple') {
        this.addProduct.controls["regularPrice"].setValidators(Validators.required);
        this.addProduct.controls["regularPrice"].updateValueAndValidity();
      }
    }
  }

  allowDropFunction() {
    let type = 4;
    return (dragData: any) => dragData.width == type || dragData.estAdaptable == true;
  }

  dropped(e) {
    //console.log(e)
  }
  async getSingleProduct(id) {
    let response = await this.productService.getSingleProduct(id)
    this.singleProduct = response.product;
    // this.addProduct.patchValue(this.singleProduct);
    this.addProduct.get('name').setValue(this.singleProduct.name)
    this.addProduct.get('categoryId').setValue(this.singleProduct.categoryId)
    this.addProduct.get('brandId').setValue(this.singleProduct.brandId)
    this.addProduct.get('productType').setValue(this.singleProduct.productType)
    this.addProduct.get('shortDetail').setValue(this.singleProduct.shortDetail)
    this.addProduct.get('availableStock').setValue(this.singleProduct.availableStock)
    this.addProduct.get('isManageStock').setValue(this.singleProduct.isManageStock)
    this.addProduct.get('detail').setValue(this.singleProduct.detail)
    this.addProduct.get('displayPicture').setValue(this.singleProduct.displayPicture)
    this.isPublished = this.singleProduct.isPublished;
    this.getSubCategories(this.singleProduct.categoryId)
    let tags = [];
    this.tags.forEach(tag => {
      if (this.singleProduct.productTagsId.find(s => s == tag._id)) {
        tags.push({ name: tag.name, _id: tag._id })
      }
    })
    this.previewImg = this.singleProduct.displayPicture;
    this.addProduct.get('tags').setValue(tags)
    this.singleProduct.picturegallery.forEach(pic => {
      this.productGallery.push({ temp: pic, file: pic, url: pic })
    })
    this.productTypeSelected(this.addProduct.get('productType').value);
    if(this.addProduct.get('productType').value == 'variable'){
      this.addProduct.controls["regularPrice"].clearValidators();
              this.addProduct.controls["regularPrice"].updateValueAndValidity();
    }
    document.body.click();
  }

  checkUploadDisable() {
    let result: any = [];
    this.productGallery.forEach(gal => {
      if (this.singleProduct.picturegallery.find(p => p == gal.url)) {
        result.push(true)
      }
      else {
        result.push(false)
      }
    })
    if (!result.includes(false)) {
      return true
    }
    else {
      return false
    }
  }

  removeSubcategory() {
    this.subCategories = [];
  }

  checkDisable(){
    return !this.variableProductComp?.getFormStatus().getRawValue().variants.length ? true : !this.variableProductComp?.getFormStatus().valid
  }

  cancel(){
    this.router.navigate(['products-list/' + this.org.storeId])
  }

  changePublish(){
    this.isPublished = document.getElementById('publish-toggle')['checked'];
  }

}
