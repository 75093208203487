export const environment = {
  production: false,
    firebase : {
      apiKey: "AIzaSyDTSYF-Mky3VILBVy1u4yKcbR_Gi8XZBjY",
      authDomain: "botnide-ecommerce-live.firebaseapp.com",
      projectId: "botnide-ecommerce-live",
      storageBucket: "botnide-ecommerce-live.appspot.com",
      messagingSenderId: "897214854992",
      appId: "1:897214854992:web:ae3b66cba07001f6225e55",
      measurementId: "G-4VXVV4ZHFW"
     },
    fbId: "3122643081379686",
    baseURL :'https://qa-server1.sellker.live',
    botURL : 'https://qa-server2.sellker.live',
    paymentURL : 'https://qa-pay.sellker.live',
    cloudinaryURL:'https://api.cloudinary.com/v1_1/doiqskci6/upload',
    cloudinaryUploadPreset:'lsaqlh7b',
    isTempWork:true,
    facebookScope:'pages_messaging,email,pages_show_list, pages_manage_metadata, pages_messaging_subscriptions, instagram_basic, instagram_manage_messages',
    payMobIframeCard:80540,
    payMobIframeNift:83023,
    ga:''
};
