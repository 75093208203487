import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { IsLoggedGuard } from './guards/isLogged.guard';
import { AuthGuard } from './guards/auth.guard';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { TosComponent } from './tos/tos.component';
import { PrivacyComponent } from './privacy/privacy.component';

export const AppRoutes: Routes = [
  { path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' }, {
    path: '',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
  }]},

  // {
  //   path: '**',
  //   redirectTo: 'dashboard'
  // },
  {
    path: 'login',
    canActivate: [IsLoggedGuard],
    component: LoginComponent,
    data:{title: 'Login'}
  },
  {
    path: 'register',
    canActivate: [IsLoggedGuard],
    component: RegisterComponent,
    data:{title: 'Register'}
  },
  {
    path: 'verifyEmail/:token',
    canActivate: [IsLoggedGuard],
    component: VerifyEmailComponent
  },
  {
    path: 'forgot-password',
    canActivate: [IsLoggedGuard],
    component: ForgotPasswordComponent,
    data:{title: 'Forget Password'}
  },
  {
    path: 'reset-password/:token',
    canActivate: [IsLoggedGuard],
    component: ResetPasswordComponent,
    data:{title: 'Reset Password'}
  },
  {
    path: 'tos',
    component: TosComponent,
    data:{title: 'TOS'}
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data:{title: 'Privacy'}
  }

]
