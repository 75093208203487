
import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router) { }



  async getSinglePromoCode(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSinglePromoCodes(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async createPromoCode(payload) {
    this.dataService.showSpinner()
    this.dataService.createPromoCodes(payload)
      .subscribe(val => {
        this.router.navigate(['promoCodes-list/'+payload.storeId]);
        this.toastr.success('Promotion Code has been added successfully!')
        this.dataService.hideSpinner()
    this.router.navigate(['promotion-code-list'])
      }, err => {
        this.toastr.error(err?.error?.error || 'Failed!')
        this.dataService.hideSpinner()
      });
  }

  async updatePromoCode(id, payload) {
    this.dataService.showSpinner()
    this.dataService.updatePromoCodes(id, payload)
      .subscribe(val => {
        // this.router.navigate(['store-list']);
        this.router.navigate(['promoCodes-list/'+payload.storeId]);
        this.toastr.success('Promotion Code has been updated successfully!')
        this.dataService.hideSpinner()
    this.router.navigate(['promotion-code-list'])
      }, err => {
        this.toastr.error('Update failed!')
        this.dataService.hideSpinner()
      });
  }


  
}
