import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from 'app/utilities/auth.service';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(public auth: AuthService) {}
 
  canActivate(): boolean {
    const isLoggedIn = this.auth.isAuthenticated();

    if(!isLoggedIn) {
      //Muddassir
      //localStorage.clear();
    }

    return isLoggedIn;
  }
}