import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'app/pages/customer/customer.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogueService } from 'app/confirmation-dialogue.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bot-customer-profile',
  templateUrl: './bot-customer-profile.component.html',
  styleUrls: ['./bot-customer-profile.component.css']
})
export class BotCustomerProfileComponent implements OnInit {

  constructor(private route: ActivatedRoute,private confirmationService: ConfirmationDialogueService,private permissionService: NgxPermissionsService, private translate: TranslateService,  private router: Router ,private customerService: CustomerService, private dataService: DataServiceService) {

   }
  urlID;
  customer;
  async ngOnInit(): Promise<void> {
    this.urlID = this.route.snapshot.params.id;
    this.customer = await this.customerService.getCustomerProfile(this.urlID)
    this.modifyDate()
  }

  editCustomer(){
    this.router.navigate(['edit-bot-customer/'+this.urlID])
  }

  async deleteCustomer(){
  let res = await this.confirmationService.confirmation('Are you sure you want to delete this customer?')
  if(res){
    await this.customerService.deleteCustomer(this.urlID)
  }

  }

  modifyDate(){
    this.customer.createdAt = this.dataService.formatDate(new Date(this.customer.createdAt))
    this.customer.DOB = this.formatDate(this.customer.DOB)
  }

  goToOrders(id){
    this.router.navigate(['bot-orders-list/customer/'+id])
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  close(){
    this.router.navigate(['bot-customers-list'])
  }

}

