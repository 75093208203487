
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { AuthService } from 'app/utilities/auth.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { OnboardingService } from 'app/utilities/onboarding.service';
import { environment } from 'environments/environment';

import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';



@Component({
  selector: 'app-fb-login',
  templateUrl: './fb-login.component.html',
  styleUrls: ['./fb-login.component.css']
})





export class FbLoginComponent implements OnInit, OnDestroy {
  @Input('callFrom') callFrom;
  user: SocialUser;
  userInfo;
  loggedIn: boolean;
  fbLoginOptions = {
    scope: environment.facebookScope
  }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

  mySubscription: Subscription;

  constructor(private authService: SocialAuthService, private onboardingService: OnboardingService, private ngWizardService: NgWizardService, private dataService: DataServiceService, private auth: AuthService) { }

  ngOnInit(): void {
    this.userInfo = localStorage.getItem('user_info');
    this.userInfo = JSON.parse(this.userInfo);
    this.mySubscription = this.authService.authState.pipe(take(1)).subscribe(async (user) => {
      let fbSource = this.dataService.getFbSource()
      if(fbSource == 'onboarding' && user.id){
        this.dataService.showSpinner()
        let connectUser = await this.onboardingService.connectSocialAccountToUser({
          fbId : user.id,
          token : user.authToken,
          userId:this.userInfo._id
        })
        if(connectUser.userUpdate.nModified == 1){
          await this.dataService.getUser().subscribe(async response => {
            this.dataService.hideSpinner()
            // this.permissionService.addPermission(response.user.features);
            // this.permissionService.loadPermissions(response.user.features);
            this.auth.setUser(response.user)

            this.user = user;
            this.loggedIn = (user != null);
            this.dataService.setFbSource('')
            this.ngWizardService.next()
            //this.sidebar.setProfileObs(response.user);
          })

          // const processChange = this.dataService.debounce(() => {
          //   this.ngWizardService.next()
          // });


        }else{
          this.dataService.hideSpinner()
        }

      }else if(fbSource == 'manageChannel' && user.id){
        this.dataService.showSpinner()
        let connectUser = await this.onboardingService.connectSocialAccountToUser({
          fbId : user.id,
          token : user.authToken,
          userId:this.userInfo._id
        })
        if(connectUser.userUpdate.nModified == 1){
          await this.dataService.getUser().subscribe(async response => {
            this.dataService.hideSpinner()
            // this.permissionService.addPermission(response.user.features);
            // this.permissionService.loadPermissions(response.user.features);
            this.auth.setUser(response.user)

            this.user = user;
            this.loggedIn = (user != null);
            this.dataService.setFbSource('')
            this.ngWizardService.next()
            //this.sidebar.setProfileObs(response.user);
          })

          // const processChange = this.dataService.debounce(() => {
          //   this.ngWizardService.next()
          // });


        }else{
          this.dataService.hideSpinner()
        }

      }

    });
  }

  signInWithFB(): void {
    this.dataService.setFbSource(this.callFrom);
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions);
  }

  signOut(): void {
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.mySubscription.unsubscribe();
 }

}
