import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-attribute-modal',
  templateUrl: './attribute-modal.component.html',
  styleUrls: ['./attribute-modal.component.css']
})
export class AttributeModalComponent implements OnInit {
  @Input() public selectedAttribute:any;
  @Input() public editing:any;
  @Input() public type:any;

  constructor(public activeModal: NgbActiveModal) { 
  }
  ngOnInit(): void {
  }

  optionChanged(e, i){
    const checked = e.target.checked;
    this.selectedAttribute.options[i].checked = checked;
  }

  close(type){
    this.activeModal.close(type)
  }

  usedChanged(e){
    const checked = e.target.checked;
    this.selectedAttribute.usedForVariation = checked
  }

}
