import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';

@Component({
  selector: 'app-blinq-transactions',
  templateUrl: './blinq-transactions.component.html',
  styleUrls: ['./blinq-transactions.component.css']
})
export class BlinqTransactionsComponent implements OnInit {
  transactions;
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  status
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {"createdAt":1},
  }
  urlID;
  constructor(private router: Router, private route: ActivatedRoute, private dataService: DataServiceService) {
  }


  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id
    this.payload.filters = {storeId: this.urlID}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    this.dataService.getSubscriptions(this.payload)
      .subscribe(val => {
        this.dataService.hideSpinner()
        this.totalRecords = val.totalRecord
        this.transactions = val.transactions
        this.createPagination()
      }, err => {
        this.dataService.hideSpinner()
      })
  }



  viewBlinqTransaction(id: string) {
  }


  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getSubscriptions(this.payload)
      .subscribe(val => {
        this.transactions = val.transaction
      }, err => {
      }

      )

  }
  startPage() {
    return this.pageStart

  }
  counter(i) {
    return new Array(i)
  }


  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }


}
