import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { OnboardingService } from 'app/utilities/onboarding.service';
import { FacebookLoginProvider } from "angularx-social-login";
import * as _ from 'lodash';
import { DataServiceService } from 'app/utilities/data-service.service';
import { AuthService } from 'app/utilities/auth.service';
import { NgWizardService } from 'ng-wizard';
import { first, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fb-pages',
  templateUrl: './fb-pages.component.html',
  styleUrls: ['./fb-pages.component.css']
})
export class FbPagesComponent implements OnInit, OnDestroy {
  user;
  userFbPage=[];
  userInstaPage=[];
  owner;
  fbLoginOptions = {
    scope: environment.facebookScope
  }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11
  @Input('selectedPlatform') selectedPlatform: any;
  @Input('callFrom') callFrom: any;
  @Input('storeId') storeId: any;
  @Output() platformConnected: EventEmitter<any> = new EventEmitter();
  mySubscription: Subscription;

  constructor(private onboardingService: OnboardingService, private authService: SocialAuthService, private dataService: DataServiceService, private auth: AuthService, private ngWizardService: NgWizardService, private toastr: ToastrService) { }

  async ngOnInit(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem("user_info"));
    this.mySubscription = this.authService.authState.pipe(take(1)).subscribe(async (user) => {
      this.dataService.showSpinner()
      let fbSource = this.dataService.getFbSource()
      if(fbSource == 'fb-pages' && user.id){
        let connectUser = await this.onboardingService.connectSocialAccountToUser({
          fbId : user.id,
          token : user.authToken,
          userId:this.user._id
        })
        await this.dataService.getUser().subscribe(async response => {
          this.auth.setUser(response.user)
          this.dataService.setFbSource('')
          this.getIntialData();
        })
      }
    })
    this.getIntialData()
  }

  async connectPage(page){
    if(this.callFrom == 'add-channel'){
      let response = await this.onboardingService.addSocialPlatform({
        userId:this.user._id,
        token:page.access_token,
        fbId:page.id,
        name: page.name,
        category: page.category,
        profilePic: 'http://graph.facebook.com/'+page.id+'/picture?width=300',
        instaId: page.instagram_business_account ? page.instagram_business_account.id : undefined,
        platform: this.selectedPlatform,
        storeId: this.storeId
      })
      if(response.restUpdate.nModified == 1){
        this.toastr.success('Your page have been connected to store successfully');
        this.platformConnected.emit(this.selectedPlatform)
      }
    }else{
      let store = await this.onboardingService.addUserPage({
        token:page.access_token,
        fbId:page.id,
        name: page.name,
        category: page.category,
        profilePic: 'http://graph.facebook.com/'+page.id+'/picture?width=300',
        instaId: page.instagram_business_account ? page.instagram_business_account.id : undefined,
        platform: this.selectedPlatform
      },this.user._id)
      if(store){
        this.onboardingService.setStore(store);
        this.ngWizardService.next();
      }
    }

  }

  settlePageFb(){
    let self = this;
    if(this.owner.activePages.length > 0){
      for (let i = 0; i < this.userFbPage.length; i++) {
        const fbPage = this.userFbPage[i];
        let currentPageIndex = _.findIndex(this.owner.activePages,function(p){
          return p.fbId == fbPage.id;
        })
        if(currentPageIndex > -1){
          let ownerPage = this.owner.activePages[currentPageIndex];
          fbPage.isActive = ownerPage.isActive;
          fbPage.isApproved = ownerPage.isApproved;

          if(ownerPage.isOnboarded){
            fbPage.isAdded = 1;
          }else{
            fbPage.isAdded = 2;
          }
        }else{
          fbPage.isAdded = 3;
        }

      }
    }else{
      for (let i = 0; i < this.userFbPage.length; i++) {
        let fbPage = this.userFbPage[i];
        fbPage.isAdded = 3
      }

    }

  }

  settlePageInsta(){
    let self = this;
    if(this.owner.activePages.length > 0){
      for (let i = 0; i < this.userFbPage.length; i++) {
        const fbPage = this.userFbPage[i];
        let currentPageIndex = _.findIndex(this.owner.activePages,function(p){
          return p.fbId == fbPage.id;
        })
        if(currentPageIndex > -1){
          let ownerPage = this.owner.activePages[currentPageIndex];
          fbPage.isActive = ownerPage.isActive;
          fbPage.isApproved = ownerPage.isApproved;

          if(ownerPage.isOnboarded){
            fbPage.isAdded = 1;
          }else{
            fbPage.isAdded = 2;
          }
        }else{
          fbPage.isAdded = 3;
        }

        if(i == this.userFbPage.length-1){
          this.userInstaPage = []
          for (let j = 0; j < this.userFbPage.length; j++) {
            if(this.userFbPage[j].instagram_business_account){
              this.userInstaPage.push(this.userFbPage[j])
            }


            if(j == this.userFbPage.length-1){
            }
          }
        }
      }
    }else{
      for (let i = 0; i < this.userFbPage.length; i++) {
        let fbPage = this.userFbPage[i];
        fbPage.isAdded = 3

        if(i == this.userFbPage.length-1){
          this.userInstaPage = []
          for (let j = 0; j < this.userFbPage.length; j++) {
            if(this.userFbPage[j].instagram_business_account){
              this.userInstaPage.push(this.userFbPage[j])
            }

            if(j == this.userFbPage.length-1){
            }
          }
        }
      }

    }

  }

  getPage(){
    this.getIntialData()
  }

  async getIntialData(){
    let response = await this.onboardingService.getUserFbPages(this.user._id)
    this.owner = await this.onboardingService.getStoreOwner(this.user._id)
    this.userFbPage = response['fbPage'].data;
    this.dataService.hideSpinner()
    if(this.selectedPlatform == 'facebook'){
      this.settlePageFb();
    }else{
      this.settlePageInsta();
    }


  }

  signInWithFB(): void {
    this.dataService.setFbSource('fb-pages');
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions);
  }

  async continuePage(page){
    if(this.callFrom == 'add-channel'){

    }else{
      let store = await this.onboardingService.getByFbId({fbId:page.id})
      this.onboardingService.setStore(store);
      this.ngWizardService.next();
    }
  }

  ngOnDestroy() {
    this.mySubscription.unsubscribe();
  }
}
