import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService) { }

  async createPromotion(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
    this.dataService.createPromotion(payload)
      .subscribe(val => {
        this.toastr.success('Promotion has been created successfully!')
        this.dataService.hideSpinner();
        resolve(val)
      }, err => {
        this.toastr.error('Create Promotion failed!')
        this.dataService.hideSpinner();
        reject(err)
      });
    })
    let result = await promise;
    return result;
  }

  async getPromotions(payload){
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getPromotions(payload)
        .subscribe(val =>{ 
          this.dataService.hideSpinner()
          resolve(val)
        },err=>{      
          this.dataService.hideSpinner()
          reject(err)
        });
      })
        let result = await promise;
        return result;
    }

    async getSinglePromotion(id) {
      this.dataService.showSpinner()
      let promise = new Promise<any>((resolve, reject) => {
        this.dataService.getSinglePromotion(id)
          .subscribe(val => {
            this.dataService.hideSpinner()
            resolve(val)
          }, err => {
            this.dataService.hideSpinner()
            reject(err)
          });
      })
      let result = await promise;
      return result;
    }

    async changeStatus(payload, id) {
      this.dataService.showSpinner()
      let promise = new Promise<any>((resolve, reject) => {
        this.dataService.changePromoStatus(payload, id)
          .subscribe(val => {
            this.dataService.hideSpinner()
            this.toastr.success('Promotion modified!')
            resolve(val)
          }, err => {
            this.dataService.hideSpinner()
            this.toastr.error('Internal server error!')
            reject(err)
          });
      })
      let result = await promise;
      return result;
    }

    async updatePromotion(payload, id) {
      this.dataService.showSpinner()
      let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updatePromotion(payload ,id)
        .subscribe(val => {
          this.toastr.success('Promotion has been updated successfully!')
          this.dataService.hideSpinner();
          resolve(val)
        }, err => {
          this.toastr.error('Update Promotion failed!')
          this.dataService.hideSpinner();
          reject(err)
        });
      })
      let result = await promise;
      return result;
    }

}
