import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { CategoryService } from '../category.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  constructor(public router: Router, private catService: CategoryService, private route: ActivatedRoute,private sanitizer: DomSanitizer, private translate: TranslateService) {

   }
  public uploader: FileUploader;
  public validationMessages = {
    name: [
      {
        type: "required",
        message: "Name is required",
      },
    ],
    detail: [
      {
        type: "required",
        message: "Details is required",
      },
    ],picture: [
      {
        type: "required",
        message: "Picture is required",
      },
    ],
  };
  categoryForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    detail: new UntypedFormControl('', Validators.required),
    picture: new UntypedFormControl('', Validators.required),
    isActive: new UntypedFormControl(true, Validators.required),
  })

  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  urlID;
  orgs = [];
  selectedOrg;
  previewImg:any;
  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id;
    this.orgs = JSON.parse(localStorage.getItem('organizations')) || []
      this.selectedOrg = this.orgs.length ? this.orgs.find(o=>o.selected) : null
      if(this.urlID != 'add'){
        this.patchCategory()
      }
      const uploaderOptions: FileUploaderOptions = {
        url: environment.cloudinaryURL,

      };

      this.uploader = new FileUploader(uploaderOptions);
      this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
        form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
        form.append('file', fileItem);
        fileItem.withCredentials = false;
        return { fileItem, form };
      };

      this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
        let res: any = JSON.parse(response);
        this.categoryForm.patchValue({
          picture: res.url
        })
        return { item, response, status, headers };
      };

      this.uploader.onAfterAddingFile = async (fileItem) => {
        this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
        this.categoryForm.patchValue({
          picture: ''
        })
      }
    }


  gotoList(){
    if(this.selectedOrg){
      this.router.navigate(['categories-list/'+this.selectedOrg.storeId])
    }
    else{
      this.router.navigate(['categories-list/all'])
    }
  }

  submit(){
    let payload = this.categoryForm.getRawValue();
    if(this.selectedOrg){
      payload.storeId = this.selectedOrg.storeId
    }
    payload.isActive = payload.isActive.toString();
    this.catService.createCategory(payload);
  }

  update(){
    let payload = this.categoryForm.getRawValue();
    if(this.selectedOrg){
      payload.storeId = this.selectedOrg.storeId
    }
    payload.isActive = payload.isActive.toString();
    this.catService.updateCategory(this.urlID, payload);
  }

  async patchCategory(){
    let response = await this.catService.getSingleCategory(this.urlID)
    if(response){
      this.categoryForm.patchValue(response.category)
    }
  }

  removeImg() {
    this.uploader.clearQueue();
    this.categoryForm.patchValue({
      picture: ''
    })
  }

}

