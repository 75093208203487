import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from '../store/store.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-manage-channels',
  templateUrl: './manage-channels.component.html',
  styleUrls: ['./manage-channels.component.css']
})
export class ManageChannelsComponent implements OnInit {
  tab = 'website';
  getStartedForm: any;
  store;
  storeId;
  user;
  isCallBack = false;
  showWebsiteCreateForm = false;
  showFacebookConnectWizard = false;
  showInstaConnectWizard = false;
  isDomainAvailable = false;
  showDomainSign = false;
  isTempWork = environment.isTempWork

  // tempUrls:any = [{url:'',image:''},{url:'',image:''},{url:'',image:''}];
  validationMessages = {
    domain: [{
      type: 'required', message: 'Store Domain name is required',
    },{
      type: 'pattern', message: "Store Domain name should only include alphanumeric character & only '-' is allowed from special character. Upper-Case is also not allowed"
    }]
  };
  storeWebsiteConfigId;

  constructor(private formBuilder: UntypedFormBuilder,private sanitizer: DomSanitizer, private translate: TranslateService, private storeService: StoreService, private dataService: DataServiceService, private toastr: ToastrService) {
    this.getStartedForm = this.formBuilder.group({
      domain:['',[Validators.required, Validators.pattern('^[a-z0-9][a-z0-9-]+[a-z0-9]$')]]
    });

   }

  async ngOnInit(): Promise<void> {
    this.storeId = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected).storeId;
    this.user = JSON.parse(localStorage.getItem("user_info"));
    let storeResponse = await this.storeService.getSingleStore(this.storeId)

    this.store = storeResponse.store;
    //this.store.platforms = ['instagram'] - Open this to test hardcoded platforms
  }

  platformExists(platform){
    return this.store?.platforms.includes(platform) ? true : false;
  }

  toggleStatus(id, platform){
    let key = platform == 'facebook' ? 'isLive' :  platform == 'website' ? 'isLiveWebsite' : 'isInstaLive';
    let functionName = platform == 'facebook' ? 'setStoreStatus'  :  platform == 'website' ? 'setStoreStatusWebsite'  : 'setStoreStatusInsta';
    this.dataService.showSpinner()
    this.dataService[functionName]({storeId:this.store._id, status:!this.store[key]})
      .subscribe(val => {
        if(val.code == 200){
          let msg = platform == 'facebook' ?
          'Facebook is live Successfully, It will take some time to reflect changes on your FB Page. Please refresh your Facebook to see changes' :
          platform == 'website'? 'Website is live Successfully, It will take some time to reflect changes on your domain.' :
          'Instagram is live Successfully, It will take some time to reflect changes on your Instagram Page. Please refresh your Instagram to see changes'
          this.toastr.success(!this.store[key] ? msg : val.message);
          this.store[key] = !this.store[key]
        }else if(val.code == 202 || val.code == 203){
          document.getElementById(id)['checked'] = !document.getElementById(id)['checked'];
          this.toastr.error(val.message);
        }
        this.dataService.hideSpinner()
      }, err => {
        console.log(err)
        document.getElementById(id)['checked'] = !document.getElementById(id)['checked'];
        this.dataService.hideSpinner()
        this.toastr.error('Failed to update store');
      });
  }

  async addPlatform(platform){
    this.isCallBack = true;
    this.dataService.showSpinner()
    if(this.store.fbId && this.store.fbPageToken){
      this.dataService.addPlatformStore(this.store._id,{platform,userId:this.user._id})
      .subscribe(async (val) => {
        this.isCallBack = false;
        if(val.code == 200){
          let msg = platform == 'facebook' ?  'Facebook is added succesfully' : 'Instagram is added successfully.'
          this.toastr.success(msg);
          let storeResponse = await this.storeService.getSingleStore(this.storeId)
          this.store = storeResponse.store;
        }else if(val.code == 201){
          this.dataService.hideSpinner()
          this.toastr.error(val.message);
        }
        this.dataService.hideSpinner()
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Failed to update connect the channel');
      });
    }else{
      this.isCallBack = false;
      this.dataService.hideSpinner()
      //Show user wizard with login with Fb, select page acc to platform selection
      this.showSocailConnectWizard(platform)

    }

  }

  async showCreatWebsiteForm(){
    this.showWebsiteCreateForm = true
    this.showFacebookConnectWizard = false;
    this.showInstaConnectWizard = false;
  }

  async showSocailConnectWizard(key){
    this.showWebsiteCreateForm = false;
    if(key == 'facebook'){
      this.showFacebookConnectWizard = true;
      this.showInstaConnectWizard = false;
    }else if(key == 'instagram'){
      this.showFacebookConnectWizard = false;
      this.showInstaConnectWizard = true;
    }

  }

  async checkDomainAvailability(){
    this.isCallBack = true;
    let domain = this.getStartedForm.value.domain.toLowerCase();
    let domainControl= this.getStartedForm.get('domain');
    if(domainControl.valid){
      let domainAvailable = await this.storeService.checkDomainAvailability(domain);
      this.isCallBack = false;
      this.showDomainSign = true
      this.isDomainAvailable = domainAvailable.isDomainAvailable
    }else{
      this.isCallBack = false;
    }
  }

  async addWebsitePlatform(){

    let payload = {
      ownerId: this.user._id,
      domain : this.getStartedForm.value.domain,
      storeId: this.storeId
    }
    let update = await this.storeService.addWebsitePlatform(payload);
    if(update.code == 200){
      if(update.restUpdate.nModified == 1){
        this.showWebsiteCreateForm = false;
        this.toastr.success('E-commerce Website is added to your Store');
        let storeResponse = await this.storeService.getSingleStore(this.storeId)
        this.store = storeResponse.store;
        this.getStoreWebsiteSetting();
      }
    }
  }


  changeTab(key){
    this.tab = key;
    this.showWebsiteCreateForm = false
    this.showFacebookConnectWizard = false;
    this.showInstaConnectWizard = false;
  }

  async platformConnected(key){
    let storeResponse = await this.storeService.getSingleStore(this.storeId)
    this.store = storeResponse.store;
    this.showWebsiteCreateForm = false
    this.showFacebookConnectWizard = false;
    this.showInstaConnectWizard = false;
  }


  getStoreWebsiteSetting(){
    this.dataService.getBannerSettings(this.storeId).subscribe(res=>{
      if(res.storeWebsite){
        this.storeWebsiteConfigId = res.storeWebsite._id;
      }
     },err=>{
     })
  }
}
