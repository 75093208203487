import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PromoCodeService } from '../promo-code.service';

@Component({
  selector: 'app-add-promotion-code',
  templateUrl: './add-promotion-code.component.html',
  styleUrls: ['./add-promotion-code.component.css']
})
export class AddPromotionCodeComponent implements OnInit {
  urlID
  public validationMessages = {
    name: [{
      type: 'required', message: 'Name is required'
    }],
    code: [{
      type: 'required', message: 'Code is required'
    }],
    discount: [
      {
      type: 'required', message: 'Discount is required'
    },
      {
      type: 'max', message: 'Discount must be within 100'
    },
  
  ],
  }
  promoCodeForm = this.formBuilder.group({
    name: ['', Validators.required],
    code: ['', Validators.required],
    discount: ['', [Validators.required, Validators.max(100)]]
  })
  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder, private subService: PromoCodeService) { }

  ngOnInit(): void {    
    this.urlID = this.route.snapshot.params.id;
    if(this.urlID != 'add'){
      this.getSingleSub()
    }
  }
  gotoList(){
    this.router.navigate(['promotion-code-list'])
  }

  submit(){
    let payload = this.promoCodeForm.getRawValue();
    payload.discount = parseInt(payload.discount)
    this.subService.createPromoCode(payload);
  }
  update(){
    let payload = this.promoCodeForm.getRawValue();
    this.subService.updatePromoCode(this.urlID, payload);
  }

  async getSingleSub(){
    let resp = await this.subService.getSinglePromoCode(this.urlID)
    this.promoCodeForm.patchValue(resp.promoCode)
  }

}
