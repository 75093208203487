import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { InvoiceService } from 'app/pages/invoice/invoice.service';
import * as _ from 'lodash';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'app/_modal';
import { OrdersService } from 'app/pages/order/orders.service';
import { AuthService } from 'app/utilities/auth.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { InvoicePaymentModalComponent } from '../invoice-payment-modal/invoice-payment-modal.component';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment'
import { environment } from 'environments/environment';
import { OnboardingService } from 'app/utilities/onboarding.service';
import { StoreService } from 'app/pages/settings/store/store.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  isTempWork = environment.isTempWork
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  subscriptions:any = [];
  loader = false;
  NoOfPages = 0;
  currentInvoice ={};
  // currentDate = new Date();
  // currentMonth = this.currentDate.getMonth()+1
  // currentYear = this.currentDate.getFullYear();
  payload = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
    "fields": "storeName year month noOfOrders totalOrderAmount dueDate invoiceRefNo commission createdAt"
  }
  storePayload = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: 1000 },
    "sort": {},
    "fields": "name"
  }
  invoices: any;
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  years;
  orders = []
  invoice
  margins = {
    top: 80,
    bottom: 60,
    left: 40,
    width: 522
  };
  stores;
  selectedRefNo: boolean;
  selectedInvoiceId = null
  hasSearched = false;
  user;
  org:any;
  currentInvoiceIndex
  subscriptionPayload = {
    filters: {},
    pagination: { start: this.pageStart, limit: 1000 },
    sort: {"createdAt":1},
  }
  invoiceId;
  outstandingInfo;
  constructor(private invoiceService: InvoiceService, private storeService: StoreService, private translate: TranslateService,private dataService: DataServiceService, private formBuilder: UntypedFormBuilder, private modalService: ModalService, private ordersService: OrdersService, private toast: ToastrService, private authenticationService: AuthService, private ngModalService: NgbModal, private router: Router, private activatedRoute: ActivatedRoute, private onboardingService: OnboardingService, private toastr: ToastrService) {

  }

  async ngOnInit(): Promise<void> {
    this.loader = true;
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.payload.filters = {
      storeId: this.org.storeId
    }
    let response = await this.invoiceService.getInvoiceList(this.org.storeId)
    this.loader = false;
    this.invoices = response.invoice;
    this.formatDates()
    this.getSubscriptions()
    this.getCurrentInvoice()
    this.getUserOutstandingAndCurrentInvoice()
    this.invoices.map((x) => {
      let date = new Date(x.dueDate);

      // x.dueDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      x.invoicedDate = this.dataService.formatDate(new Date(this.invoices.invoicedDate))
    })

    /* this.invoices.dueDate = this.dataService.formatDate(new Date(this.invoices.dueDate)) */
    this.totalRecords = response.totalRecord;

    this.user = await this.authenticationService.getUser();
    //this.stores = await this.storeService.getStores(this.storePayload)

    if(await this.user.role == "admin") {
      this.stores = this.stores.filter((x) => this.user.storeId == x._id);
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.invoiceId = params['orderId']
      if(this.invoiceId){
        this.getInvoiceDetail(this.invoiceId)
      }
    })

    this.createPagination()
  }

  getCurrentInvoice(){
    let that =this;
     this.currentInvoiceIndex = _.findIndex(this.invoices, function(ci){
      let currentDate = new Date()
      let dueDate= new Date(ci.dueDateTemp)
      let createdAt= new Date(ci.createdAtTemp)

       return ((createdAt <= currentDate) && (currentDate <= dueDate))
      //return (ci.month == that.currentMonth) && (ci.year == that.currentYear);
    });

    if(this.currentInvoiceIndex > -1){
      this.currentInvoice = this.invoices[this.currentInvoiceIndex];
      this.invoices.splice(this.currentInvoiceIndex, 1)
    }
  }

  async sort(SortName: String) {
    this.loader = true;
    if (SortName == 'storeName') {
      this.payload.sort = { "storeName": 1 }
    }
    if (SortName == 'month') {
      this.payload.sort = { "month": 1 }
    }
    if (SortName == 'year') {
      this.payload.sort = { "year": 1 }
    }
    if (SortName == 'noOfOrders') {
      this.payload.sort = { "noOfOrders": 1 }
    }
    if (SortName == 'totalOrderAmount') {
      this.payload.sort = { "totalOrderAmount": 1 }
    }
    if (SortName == 'commission') {
      this.payload.sort = { "commission": 1 }
    }
    if (SortName == 'isPaid') {
      this.payload.sort = { "isPaid": 1 }
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    let response = await this.invoiceService.getInvoiceList(this.org.storeId)
    this.loader = false;
    this.invoices = response.invoice;
    this.formatDates()
    // this.invoices.dueDate = this.dataService.formatDate(new Date(this.invoices.dueDate))
    this.totalRecords = response.totalRecord;
    //this.stores = await this.invoiceService.getStores(this.storePayload)
    this.createPagination()
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  async goToPage(Index) {
    this.loader = true;
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    let response = await this.invoiceService.getInvoiceList(this.org.storeId)
    this.loader = false;
    this.invoices = response.invoice;
    this.formatDates()
    // this.invoices.dueDate = this.dataService.formatDate(new Date(this.invoices.dueDate))
    this.totalRecords = response.totalRecord;
    //this.stores = this.invoiceService.getStores(this.storePayload)
    this.createPagination()
  }



  getByRefNo() {
  }

  closeModal() {
    this.modalService.close('orders-list')
  }

  downloadPDF() {
    let element = document.getElementById('pdfTable');
    var innerContents = document.getElementById('pdfTable').innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width">
      <style>
        .heading{
          text-align: center
        }
        .table, .invoiceDate, .invoiceDueDate{
          margin-top: 10px
        }
        .fl-right{
          float: right;
          margin-right: 30px
        }
        .container{
          border: 1px solid grey;
          padding: 20px
        }
        .store{
          display: inline-block
        }
        .table-responsive{
          margin-left: 20px
        }
        .right{
          margin-left: 20px
        }
        .mt-10{
          margin-top: 10px
        }
        .content{
          min-height: 970px
        }
        .foot{
          border-top: 1px solid black
        }
        .inline{
          display: inline-block
        }
        .ml-40{
          margin-left: 60px
        }
        .ml-5{
          margin-left: 5px
        }
        .mr-161{
          margin-right: 161px
        }
        .mr-130{
          margin-right: 130px
        }
        .mr-100{
          margin-right: 100px
        }
        .mr-191{
          margin-right: 191px
        }
        .mr-30{
          margin-right: 30px
        }
        .info{
          font-weight: bold;
          font-size: 16px;
      }
      .mr-10{
        margin-right: 10px
      }
      .ml-5{
        margin-top: 10px;
        margin-left: 10px;
      }
      .ml-20{
        margin-left: 20px;
      }
      .ml-60{
        margin-left: 60px;
      }
      .ml-40{
        margin-left: 40px;
      }
      .left{
        margin-left: 5px;
      }
      .ml-33{
        margin-left: 33px
      }
      .ml-50{
        margin-left: 50px;
      }
      .ml-160{
        margin-left: 160px
      }
      .ml-90{
        margin-left: 90px
      }
      .mr-30{
        margin-right: 30px
      }
      .mr-64{
        margin-right: 64px
      }
      .mr-63{
        margin-right: 63px
      }
      .mr-157{
        margin-right: 157px
      }
      .mr-40{
        margin-right: 40px
      }
      .ml-80{
        margin-left: 80px
      }
      .mr-50{
        margin-right: 50px
      }
      .mr-128{
        margin-right: 128px
      }
      </style>
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="content">${innerContents}</div>
            <div class="foot">
              <div class="inline ml-5">
                0398923112
              </div>
              <div class="inline ml-160">
                shehrozeali81@gmail.com
              </div>
              <div class="inline ml-20">
                abcdefghiojksola jsjbabsnnsad jnjasdnasd
              </div>
            </div>
          </div>
</body>
</html>`);
    popupWinindow.document.close();
  }


  counter(i) {
    return new Array(i)
  }

  downloadInvoiceListPDF() {
    var innerContents = document.getElementById('invoiceListPdf').innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <style>
      #actionsHead{
        display: none
      }
      #actionsValue{
        display: none
      }
      .sort, .fa{
        display: none !important
      }
      .paginationBtn{
        display: none
      }
    </style>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="content">${innerContents}</div>
</body>
</html>`);
    popupWinindow.document.close();
  }
  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }

  async pay(invoice){
    const modalRef = this.ngModalService.open(InvoicePaymentModalComponent,this.modalOptions);
    modalRef.componentInstance.invoice = invoice;
    modalRef.result.then((result) => {
      if (result) {
      }
    });
    //let masterInvoice = await this.invoiceService.getInvoiceList(this.org.storeId)
  }

  async payOutstanding(outstandingInfo){
    const modalRef = this.ngModalService.open(InvoicePaymentModalComponent,this.modalOptions);
    modalRef.componentInstance.outstandingInfo = outstandingInfo;
    modalRef.componentInstance.storeId = this.org.storeId;

    modalRef.result.then((result) => {
      if (result) {
      }
    });
    //let masterInvoice = await this.invoiceService.getInvoiceList(this.org.storeId)
  }

  getSubscriptions(){
    this.dataService.getSubscriptions(this.subscriptionPayload)
      .subscribe(val => {
        this.subscriptions = val.subscriptions
      })
  }

  getSubscriptionName(id){
    return this.subscriptions.find(sub=>sub._id == id)?.name;
  }

  getSubscription(id){
    return this.subscriptions.find(sub=>sub._id == id);
  }

  changeSubscription(){
    this.router.navigate(['change-subscription'])
  }

  formatDates(){
    this.invoices.forEach(inv=>{
      inv['createdAtTemp'] = inv.createdAt
      inv['dueDateTemp'] = inv.dueDate
      inv.createdAt = moment(new Date(inv.createdAt)).format('DD-MM-YYYY');
      inv.dueDate = moment(new Date(inv.dueDate)).format('DD-MM-YYYY');
    })
  }

  async getInvoiceDetail(invoiceId){
    this.invoice = await this.onboardingService.getInvoiceDetail(invoiceId)
    if(this.invoice){
      if(this.invoice.status=='paid'){
        //this.ngWizardService.show(4);
        this.toastr.success('Your invoice is paid. Thanks for your payment 🤩')
      }else if(this.invoice.status == 'unpaid'){
        this.toastr.error('We unable to process your invoice payment, Kindly retry! 🙂');
      }
    }
  }

  getDate(value){
    //console.log(value, new Date(value))
    return new Date(value)
  }

  async getUserOutstandingAndCurrentInvoice(){
   let currentAPIResponse= await this.invoiceService.getUserOutstandingAndCurrentInvoice({storeId:this.org.storeId})
    if(currentAPIResponse){
      this.outstandingInfo = currentAPIResponse.outstandingInfo
    }
  }
}
