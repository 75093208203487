import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/utilities/auth.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.css']
})
export class OnlinePaymentComponent implements OnInit {
  @Input('invoice') invoice;
  newUser = false
  constructor(private dataService: DataServiceService, private toastr: ToastrService, private authenticationService: AuthService, private router: Router) {
   }

  ngOnInit(): void {
    if(!JSON.parse(localStorage.getItem('organizations'))?.length && !localStorage.getItem('superadmin')){
      this.newUser = true;
    }
    else{
      this.newUser = false;
    }

    if(this.invoice && this.invoice.status == 'paid'){
      this.toastr.success('Thanks for your Payment');
      this.getUserOrganzation();

    }else{
      this.getUserOrganzation();
    }
  }

  getUserOrganzation(){
    let userId = JSON.parse(localStorage.getItem('user_info'))._id
    this.dataService.getUserOrganzation(userId)
    .subscribe((arg) => {
      arg.organizations[0]['selected'] = true;
      localStorage.setItem('organizations', JSON.stringify(arg.organizations))
      this.authenticationService.setOrganizations();
      if(this.newUser){
        this.dataService.setUserObs()

      }
      this.dataService.setCheckForStoreTrail()
    });


  }
  goToSellKerUniversity(){
    this.router.navigate(['sellKer-university'])
  }

  setCheckForStoreTrail
}
