import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'app/pages/customer/customer.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.css']
})
export class CustomerProfileComponent implements OnInit {

  constructor(private route: ActivatedRoute, private toastr: ToastrService, private ngModalService: NgbModal, private permissionService: NgxPermissionsService, private translate: TranslateService,  private router: Router ,private customerService: CustomerService, private dataService: DataServiceService) {

   }
  urlID;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  org;
  block = false;
  customer = {
    picture:"",
    DOB: "",
    email: "",
    block: null,
    createdAt: "",
    customerName: "",
    fbId: [],
    phoneNumber: null,
    updatedAt: "",
    __v: null,
    _id: ""
  };
  note;
  payload = {
    "filters": {},
    "pagination": { start: 0, limit: 1000 },
    "sort": {},
    "fields": "name"
  }
  async ngOnInit(): Promise<void> {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    try {
      this.urlID = this.route.snapshot.params.id;
      this.customer = await this.customerService.getCustomerProfile(this.urlID)

      this.modifyDate()
    } catch(err) {
      if(err) {
        this.toastr.error("Invalid Customer");

        this.router.navigateByUrl('/customers-list');
      }
    }
  }

  editCustomer(){
    this.router.navigate(['edit-customer/'+this.urlID])
  }

//   async deleteCustomer(){
//   let res = await this.confirmationService.confirmation('Are you sure you want to delete this customer?')
//   if(res){
//     await this.customerService.deleteCustomer(this.urlID)
//   }
// }
  deleteCustomer(message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if(result=='yes'){
        that.dataService.showSpinner()
        let response = await that.customerService.deleteCustomer(this.urlID)

      }
    })
  }


  modifyDate(){
    this.customer.createdAt = this.dataService.formatDate(new Date(this.customer.createdAt))
    this.customer.DOB = this.customerService.formatDate(this.customer.DOB)
  }

  goToOrders(id){
    this.router.navigate(['orders/customer/'+id])
  }

  close(){
    this.router.navigate(['customers-list'])
  }

  async update(){
    let payload = {
      note: this.note
    }
    let resp =await this.customerService.updateCustomer(this.urlID, payload)
    if(resp){
      this.router.navigate(['customers-list'])
    }
  }

  toggleBlock(id){
    this.block = !this.customer.block
    let payloadBlock = {
      block: this.block
    }
    this.customerService.blockCustomer(id, payloadBlock)
  }


}

