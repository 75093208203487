import { Component, OnInit } from '@angular/core';
import { NgWizardConfig, THEME, StepChangedArgs, NgWizardService } from 'ng-wizard';
import { DealsService } from '../../deal/deals.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-marketing-campaign',
  templateUrl: './add-marketing-campaign.component.html',
  styleUrls: ['./add-marketing-campaign.component.css']
})
export class AddMarketingCampaignComponent implements OnInit {
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ]}
  };
  stores = []
  storePayload = {
    "filters":{},
    "pagination":{start:0,limit:50},
    "sort":{},
    "fields": "name"
  }
  storeId;
  customers = [];
  pageLimit = 30;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
    fields: "firstName lastName email phoneNumber role"
  }
  selectedCustomers = [];
  customerChosen = true;
  templateChosen;
  selectedAll = false;
  constructor(private ngWizardService: NgWizardService, private dealsService: DealsService) { }

  async ngOnInit(): Promise<void> {
    this.stores = await this.dealsService.getStores(this.storePayload)
    this.totalRecords = 60;
    this.createPagination()
  }
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {

  }

  storeSelected(){
    for(let i = 0; i < 30; i++){
      this.customers.push({name: 'Ali', id: i})
    }
  }
  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  async goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.stores = await this.dealsService.getStores(this.storePayload)
    this.totalRecords = 60;
    this.createPagination()
  }

  counter(i) {
    return new Array(i)
  }

  selectCustomer(name, id, i){
    if(this.selectedCustomers[i]){
      this.customerChosen = false;
    }
    if(this.customerChosen){
      this.selectedCustomers.push({name: name, id: id})
    }
    else{
      let index = _.find(this.customers, {id: id})
      this.selectedCustomers.splice(index,1)
    }
  }

  selectAll(){
    this.selectedAll = !this.selectedAll
    if(this.selectedAll){
      this.selectedCustomers = this.customers
    }
    else{
      this.selectedCustomers = []
    }
  }

  templateSelected(e){
    
  }
}
