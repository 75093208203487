import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { SocialAuthService } from 'angularx-social-login';
import { MessagingService } from 'app/messaging.service';
import { AuthService } from 'app/utilities/auth.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { FacebookLoginProvider } from "angularx-social-login";
import { first, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  lang = localStorage.getItem('lang') == 'en' ? 'English' : 'عربي'
  public validationMessages = {
    email: [{
      type: 'required', message: 'Email is required'
    },
    {
      type: 'pattern', message: 'Enter valid email address'
    }],
    password: [{
      type: 'required', message: 'Password is required'
    }]
  }

  loginForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]],
    password: ['', Validators.required]
  })

  fbLoginOptions = {
    scope: environment.facebookScope
  };
  mySubscription: Subscription;
  constructor(private elementRef: ElementRef, private formBuilder: UntypedFormBuilder, private messaging: MessagingService, private translate: TranslateService,private dataService: DataServiceService,
    private router: Router, private authService: AuthService, private socialAuthService: SocialAuthService, private activatedRoute: ActivatedRoute) {

  }
  referCode;
  isInAppBrowser = false;
  ngOnInit(): void {
    // localStorage.setItem('lang','en')
    this.getBrowserInfo()
    this.activatedRoute.queryParams.subscribe(params => {
      if(!localStorage.getItem('rc')){
        this.referCode = params['rc']
        if(this.referCode){
          localStorage.setItem('rc',this.referCode)
        }
      }

    })


    this.mySubscription = this.socialAuthService.authState.pipe(take(1)).subscribe(async (user) => {
      let fbSource = this.dataService.getFbSource()

      if(fbSource == 'login' && user.id){
        this.dataService.showSpinner();
        let refByCode = localStorage.getItem('rc')
        let paylaod = {
          fbId : user.id,
          token : user.authToken,
          email:user.email,
          name:user.name,
          pic: user.photoUrl,
          refByCode
        }
        this.dataService.handleSocialLogin(paylaod).subscribe(async response => {
          this.authService.setToken(response.accessToken)
          this.dataService.hideSpinner()
          localStorage.removeItem('rc')
          if(response.userInfo){
            this.messaging.requestPermission();
            let orgs = response.userInfo?.organizations || [];
            if(orgs.length){
              orgs[0].selected = true;
            }
            localStorage.setItem('organizations', JSON.stringify(orgs))
          }
          else{
            let userInfo = {
              role: response.role,
              name: response.userFullName
            }
            localStorage.setItem('superadmin', JSON.stringify(userInfo))
          }
          this.dataService.setFbSource('')
          this.router.navigate(['dashboard'])
       })
      }
    });
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F8FAFC';
  }

  // async login() {
  //   let response = await this.authService.login(this.loginForm.value)
  //   this.authService.setToken(response.accessToken)
  //   if(response.userInfo){

  //     let orgs = response.userInfo.organizations;
  //     orgs[0].selected = true;
  //     localStorage.setItem('organizations', JSON.stringify(orgs))
  //   }
  //   else{
  //     let userInfo = {
  //       role: response.role,
  //       name: response.userFullName
  //     }
  //     localStorage.setItem('superadmin', JSON.stringify(userInfo))
  //   }
  //   this.router.navigate(['dashboard'])
  // }

  async login() {
    let response = await this.authService.login(this.loginForm.value)
    this.authService.setToken(response.accessToken)
    if(response.userInfo){
      this.messaging.requestPermission();
      let orgs = response.userInfo.organizations;
      if(orgs.length){
        orgs[0].selected = true;
      }
      localStorage.setItem('organizations', JSON.stringify(orgs))
    }
    else{
      let userInfo = {
        role: response.role,
        name: response.userFullName
      }
      localStorage.setItem('superadmin', JSON.stringify(userInfo))
    }
    this.router.navigate(['dashboard'])
  }

  signInWithFB(): void {
    this.dataService.setFbSource('login')
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions);
  }

  getBrowserInfo(){
    let userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    //Instagram , FB_IAB , FBAV , FB4A
    //let str = navigator.userAgent;
    //alert(userAgent)
    if(this.isInAppBrowserCheck(userAgent)){
      this.isInAppBrowser = true;
    }
  }


  isInAppBrowserCheck(ua) {
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf("FB_IAB") > -1) || (ua.indexOf("FB4A") > -1) || (ua.indexOf("Instagram") > -1);
  }

  ngOnDestroy() {
    this.mySubscription.unsubscribe();
  }

  setLanguage(lang){
    this.lang = lang == 'ar' ? 'عربي' : 'English';
    this.authService.setLanguage(lang)
  }
}
