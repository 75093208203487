import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SidebarModule } from "./sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { FixedPluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { FileUploadModule } from "ng2-file-upload";
import { ModalModule } from './_modal';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './http-interceptor.service';
import { HttpErrorInterceptorService } from './http-error-interceptor.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BrowserModule } from '@angular/platform-browser'



import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { StoreRegisterComponent } from './pages/settings/store/store-register/store-register.component';

import { StoreListComponent } from './pages/settings/store/store-list/store-list.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe, CommonModule } from '../../node_modules/@angular/common';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  FacebookLoginProvider,
} from 'angularx-social-login';

import { AdminRegisterComponent } from './pages/settings/admin/admin-register/admin-register.component';
import { AdminListComponent } from './pages/settings/admin/admin-list/admin-list.component';
import { OrdersComponent } from './pages/order/orders/orders.component';
import { AddDealsComponent } from './pages/deal/add-deals/add-deals.component';
import { DealsComponent } from './pages/deal/deals/deals.component';
import { OrderDetailComponent } from './pages/order/order-detail/order-detail.component';
import { CustomersListComponent } from './pages/customer/customers-list/customers-list.component';
import { EditCustomerComponent } from './pages/customer/edit-customer/edit-customer.component';
import { InvoiceListComponent } from './pages/invoice/invoice-list/invoice-list.component';
import { StoreProfileComponent } from './pages/settings/store/store-profile/store-profile.component'
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPermissionsModule } from 'ngx-permissions';
// import { TooltipModule } from 'ng2-tooltip-directive';

import { CustomerProfileComponent } from './pages/customer/customer-profile/customer-profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { MarketingCampaignComponent } from './pages/marketingCampaign/marketing-campaign/marketing-campaign.component';
import { AddMarketingCampaignComponent } from './pages/marketingCampaign/add-marketing-campaign/add-marketing-campaign.component';
import { BotCustomersListComponent } from './pages/bot-customers/bot-customers-list/bot-customers-list.component';
import { BotCustomerProfileComponent } from './pages/bot-customers/bot-customer-profile/bot-customer-profile.component';
import { EditBotCustomerComponent } from './pages/bot-customers/edit-bot-customer/edit-bot-customer.component';
import { BotOrderDetailsComponent } from './pages/bot-orders/bot-order-details/bot-order-details.component'
import { BotOrdersListComponent } from './pages/bot-orders/bot-orders-list/bot-orders-list.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { DragulaModule } from 'ng2-dragula';
import { AnalyticComponent } from './pages/analytic/analytic.component';
import { NgxCaptureModule } from 'ngx-capture';
import { DeliveryLocationsCitiesListComponent } from './pages/delivery-location-cities/delivery-locations-cities-list/delivery-locations-cities-list.component';
import { DeliveryLocationCitiesRegisterComponent } from './pages/delivery-location-cities/delivery-location-cities-register/delivery-location-cities-register.component';
import { PageRequestComponent } from './pages/page-request/page-request-list/page-request.component';
import { DealCategoriesComponent } from './pages/deal/deal-categories/deal-categories.component';
// import { AddCategoryComponent } from './pages/deal/add-category/add-category.component';
import { AddCategoryComponent } from './pages/categories/add-category/add-category.component';
import { CreatePromotionComponent } from "./pages/promotion/create-promotion/create-promotion.component";
import { PromotionsListComponent } from "./pages/promotion/promotions-list/promotions-list.component";
import { CategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { SubCategoriesListComponent } from './pages/sub-categories/sub-categories-list/sub-categories-list.component';
import { AddSubCategoryComponent } from './pages/sub-categories/add-sub-category/add-sub-category.component';
import { TagsListComponent } from './pages/tags/tags-list/tags-list.component';
import { AddTagComponent } from './pages/tags/add-tag/add-tag.component';
import { AttributesListComponent } from './pages/attributes/attributes-list/attributes-list.component';
import { DeliverySettingsComponent } from './pages/settings/delivery-settings/delivery-settings/delivery-settings.component';
import { AddDeliverySettingComponent } from './pages/settings/delivery-settings/add-delivery-setting/add-delivery-setting.component';
import { AddProductComponent } from './pages/products/add-product/add-product.component';
// import { QuillModule } from 'ngx-quill'
import { DndModule } from 'ng2-dnd';
import { SimpleProductComponent } from "./pages/products/add-product/simple-product/simple-product.component";
import { ProductsListComponent } from "./pages/products/products-list/products-list.component";
import { BrandsListComponent } from './pages/brand/brands-list/brands-list.component';
import { AddBrandComponent } from './pages/brand/add-brand/add-brand.component';
import { VariableProductComponent } from "./pages/products/add-product/variable-product/variable-product.component";
import { SingleVariantDetailsComponent } from "./pages/products/add-product/variable-product/single-variant-details/single-variant-details.component";
import { CustomMessageComponent } from './pages/settings/plugins/custom-message/custom-message.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FbLoginComponent } from './addStore/fb-login/fb-login.component';
import { FbPagesComponent } from './addStore/fb-pages/fb-pages.component';
import { SelectSubscriptionComponent } from './addStore/select-subscription/select-subscription.component';
import { LandingComponent } from './addStore/landing/landing.component';
import { OnlinePaymentComponent } from './addStore/online-payment/online-payment.component';
import { StoreInfoComponent } from './addStore/store-info/store-info.component';
import { RegisterComponent } from './pages/register/register.component';
import { AddSubscriptionComponent } from './pages/subscription/add-subscription/add-subscription.component';
import { SubscriptionListComponent } from "./pages/subscription/subscription-list/subscription-list.component";
import { TutorialVideosListComponent } from './pages/tutorial-videos/tutorial-videos-list/tutorial-videos-list.component';
import { AddTutorialVideoComponent } from './pages/tutorial-videos/add-tutorial-video/add-tutorial-video.component';
import { BotnideUniversityComponent } from './pages/botnide-university/botnide-university.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PromotionCodeListComponent } from './pages/promotion-code/promotion-code-list/promotion-code-list.component';
import { AddPromotionCodeComponent } from './pages/promotion-code/add-promotion-code/add-promotion-code.component';
import {AlertModule } from "./shared/_alert";
import { BlinqTransactionsComponent } from './pages/blinq-transactions/blinq-transactions.component';
import { StoreCategoriesListComponent } from './pages/store-categories/store-categories-list/store-categories-list.component';
import { AddStoreCategoryComponent } from './pages/store-categories/add-store-category/add-store-category.component';
import { ChangeSubscriptionComponent } from './pages/change-subscription/change-subscription.component';
import { InvoicePaymentModalComponent } from './pages/invoice/invoice-payment-modal/invoice-payment-modal.component';
import { PoliciesComponent } from './pages/settings/policies/policies.component';
import { QuickEditModalComponent } from './pages/products/quick-edit-modal/quick-edit-modal.component';
import { AddAttributeModalComponent } from './pages/attributes/add-attribute-modal/add-attribute-modal.component';
import { AttributeModalComponent } from './pages/products/attribute-modal/attribute-modal.component';
import { MethodModalComponent } from './pages/settings/delivery-settings/method-modal/method-modal.component';
import { ImportProductsComponent } from './pages/products/import-products/import-products.component';
import { InfoModalComponent } from './pages/products/info-modal/info-modal.component';
import { LiveChatComponent } from './pages/live-chat/live-chat.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TosComponent } from './tos/tos.component';
import { SelectPlatformComponent } from './addStore/select-platform/select-platform.component';
import { ManageChannelsComponent } from './pages/settings/manage-channels/manage-channels.component';
import { AvatarModule } from "ngx-avatar";
import { TimeagoModule } from 'ngx-timeago';
import { FlexLayoutModule } from "@angular/flex-layout";
import { ClipboardModule } from "ngx-clipboard";
import { AddSocailChannelWizardComponent } from './pages/settings/add-socail-channel-wizard/add-socail-channel-wizard.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreWebsiteConfigComponent } from './pages/settings/store-website-config/store-website-config.component';
import { ProductsArchivedComponent } from './pages/products/products-archived/products-archived.component';
import { AlertComponent } from "./shared/_alert/alert.component";
import { TrailSubscriptionNotificationComponent } from './shared/trail-subscription-notification/trail-subscription-notification.component';
import { BanksListComponent } from './pages/banks/banks-list/banks-list.component';
import { AddBankComponent } from './pages/banks/add-bank/add-bank.component';
import { PaymentIntegrationsComponent } from './pages/payment-integrations/payment-integrations.component';
import { IntegrationsListComponent } from './pages/integrations/integrations-list/integrations-list.component';
import { AddIntegrationComponent } from './pages/integrations/add-integration/add-integration.component';
import { PluginsListComponent } from './pages/settings/plugins/plugins-list/plugins-list.component';
import { FacebookMessengerPluginComponent } from './pages/settings/plugins/facebook/facebook-messenger-plugin/facebook-messenger-plugin.component';
import { InstagramMessengerPluginComponent } from './pages/settings/plugins/instagram/instagram-messenger-plugin/instagram-messenger-plugin.component';
import { PluginSingleCardComponent } from './pages/settings/plugins/plugin-single-card/plugin-single-card.component';
import { GoogleAnalyticsPluginComponent } from "./pages/settings/plugins/google/google-analytics-plugin/google-analytics-plugin.component";
import { FacebookPixelPluginComponent } from "./pages/settings/plugins/facebook/facebook-pixel-plugin/facebook-pixel-plugin.component";
import { OnlinePaymentsPluginComponent } from "./pages/settings/plugins/payments/online-payments-plugin/online-payments-plugin.component";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { AttachPropertyComponent } from './pages/settings/store-website-config/attach-property/attach-property.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";


const ngWizardConfig: NgWizardConfig = {
  theme: THEME.arrows,
  keyNavigation: false,
  selected: 0,
  toolbarSettings: {
    showNextButton: false,
    showPreviousButton: false
  },
  anchorSettings:{
    anchorClickable: false,
    markAllPreviousStepsAsDone: true
  }
  //   keyNavigation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    StoreRegisterComponent,
    LiveChatComponent,
    StoreListComponent,
    CreatePromotionComponent,
    PromotionsListComponent,
    AdminRegisterComponent,
    AdminListComponent,
    OrdersComponent,
    AddDealsComponent,
    DealsComponent,
    OrderDetailComponent,
    CustomersListComponent,
    EditCustomerComponent,
    CustomerProfileComponent,
    InvoiceListComponent,
    StoreProfileComponent,
    ChangePasswordComponent,
    MarketingCampaignComponent,
    SingleVariantDetailsComponent,
    AddMarketingCampaignComponent,
    BotCustomersListComponent,
    BotCustomerProfileComponent,
    EditBotCustomerComponent,
    BotOrdersListComponent,
    BotOrderDetailsComponent,
    DeleteModalComponent,
    VariableProductComponent,
    AnalyticComponent,
    DeliveryLocationsCitiesListComponent,
    DeliveryLocationCitiesRegisterComponent,
    PageRequestComponent,
    ProductsListComponent,
    DealCategoriesComponent,
    AddCategoryComponent,
    CategoriesListComponent,
    SubCategoriesListComponent,
    AddSubCategoryComponent,
    TagsListComponent,
    AddTagComponent,
    AttributesListComponent,
    DeliverySettingsComponent,
    AddDeliverySettingComponent,
    AddProductComponent,
    SimpleProductComponent,
    BrandsListComponent,
    AddBrandComponent,
    CustomMessageComponent,
    FbLoginComponent,
    FbPagesComponent,
    SelectSubscriptionComponent,
    LandingComponent,
    OnlinePaymentComponent,
    StoreInfoComponent,
    RegisterComponent,
    AddSubscriptionComponent,
    SubscriptionListComponent,
    TutorialVideosListComponent,
    AddTutorialVideoComponent,
    BotnideUniversityComponent,
    VerifyEmailComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PromotionCodeListComponent,
    AddPromotionCodeComponent,
    BlinqTransactionsComponent,
    StoreCategoriesListComponent,
    AddStoreCategoryComponent,
    ChangeSubscriptionComponent,
    InvoicePaymentModalComponent,
    PoliciesComponent,
    QuickEditModalComponent,
    AddAttributeModalComponent,
    AttributeModalComponent,
    MethodModalComponent,
    ImportProductsComponent,
    InfoModalComponent,
    PrivacyComponent,
    TosComponent,
    SelectPlatformComponent,
    ManageChannelsComponent,
    AddSocailChannelWizardComponent,
    StoreWebsiteConfigComponent,
    ProductsArchivedComponent,
    TrailSubscriptionNotificationComponent,
    BanksListComponent,
    AddBankComponent,
    PaymentIntegrationsComponent,
    IntegrationsListComponent,
    AddIntegrationComponent,
    PluginsListComponent,
    FacebookMessengerPluginComponent,
    InstagramMessengerPluginComponent,
    FacebookPixelPluginComponent,
    GoogleAnalyticsPluginComponent,
    OnlinePaymentsPluginComponent,
    PluginSingleCardComponent,
    AttachPropertyComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { useHash: true, relativeLinkResolution: 'legacy' }),
    SidebarModule,
    // QuillModule.forRoot(),
    NavbarModule,
    BrowserModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    ModalModule,
    CommonModule,
    FlexLayoutModule,
    DndModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    AngularEditorModule,
    NgxCaptureModule,
    ToastrModule.forRoot(),
    DragulaModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    AmazingTimePickerModule,
    FileUploadModule,
    HttpClientModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgxPermissionsModule.forRoot(),
    NgWizardModule.forRoot(ngWizardConfig),

    // TooltipModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SocialLoginModule,
    AlertModule,
    AvatarModule,
    TimeagoModule.forRoot(),
    ClipboardModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgbModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    //NgxGoogleAnalyticsRouterModule,
    PasswordStrengthMeterModule.forRoot(),
    InfiniteScrollModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true },
    MessagingService,
    AsyncPipe,
    AlertComponent,
    NgbDropdown,
    DeleteModalComponent,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [

          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.fbId),
          }
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);

}
