import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TagsService } from '../tags.service';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.css']
})
export class AddTagComponent implements OnInit {
  urlID;
  orgs = []
  selectedOrg;
  public validationMessages = {
    name: [
      {
        type: "required",
        message: "Name is required",
      },
    ],
    detail: [
      {
        type: "required",
        message: "Details is required",
      },
    ]
  };
  constructor(private route: ActivatedRoute, private translate: TranslateService, private tagService: TagsService, public router: Router) { 
    
  }
  tagForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    detail: new UntypedFormControl(''),
  })
  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id;
    this.orgs = JSON.parse(localStorage.getItem('organizations'))
      this.selectedOrg = this.orgs.find(o=>o.selected)
      if(this.urlID != 'add'){
        this.patchTags()
      }
  }

  submit(){
    let payload = this.tagForm.getRawValue();
    payload.storeId = this.selectedOrg.storeId;
    this.tagService.createTag(payload);
  }

  update(){
    let payload = this.tagForm.getRawValue();
    payload.storeId = this.selectedOrg.storeId;
    this.tagService.updateTag(this.urlID, payload);
  }

  gotoList(){
    this.router.navigate(['tags-list/'+this.selectedOrg.storeId])
  }


  async patchTags(){
    let response = await this.tagService.getSingleTag(this.urlID)
    if(response){
      this.tagForm.patchValue(response.tag)
    }
  }


}
