import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  productId:any=''
  // private submit:any = new BehaviorSubject({})
  // reqBody:any = {};
  // payload:any = new BehaviorSubject({})

  constructor(private toastr: ToastrService, private router: Router, private dataService: DataServiceService) {
    // this.payload.subscribe(res=>{
    //   this.reqBody = {...this.reqBody, ...res}
    //   console.log(this.reqBody)
    //   if(this.reqBody.attributes && this.reqBody.regularPrice && this.reqBody.attributes.length){
    //     if(!this.reqBody.updating){
    //       this.createProduct(this.reqBody)
    //     }
    //     else{
    //       this.updateProduct(this.reqBody.updating, this.reqBody)
    //     }
    //   }
    // })
   }

  async addProduct(payload){  
    // this.reqBody = {...this.reqBody, ...payload}
    //   this.submit.next(this.reqBody)
    delete payload.updating;
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.createProduct(payload).
    subscribe(val => {
      this.dataService.hideSpinner()
      if(val?.code && val.code == 204){
        this.toastr.error(val.message)
        resolve(val)
      }
      else{
        this.toastr.success('Product has been created')
        this.router.navigate(['products-list/'+payload.storeId])
          resolve(val)
      }
      },err=>{      
        this.dataService.hideSpinner()
        console.log(err)
        this.toastr.error(err?.error?.error || 'Product create failed')
        // this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }
  async importProducts(payload){  
    delete payload.updating;
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.importProducts(payload).
    subscribe(val => {
      this.dataService.hideSpinner()
      // this.toastr.success('Products have been uploaded')
      // this.router.navigate(['products-list/'+payload.storeId])
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        reject(err)
        this.toastr.error('Products upload failed!')
      });
    })
    let result:any;
      await promise.then(res=>{
        result = res;
      },err=>{
        result = err
      })
      return result
  }
  async updateImportProducts(payload){  
    delete payload.updating;
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.updateImportProducts(payload).
    subscribe(val => {
      this.dataService.hideSpinner()
      this.toastr.success('Products have been updated')
      this.router.navigate(['products-list/'+payload.storeId])
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        console.log(err)
        this.toastr.error('Products update failed!')
        reject(err)
      });
    })
    let result:any;
    await promise.then(res=>{
      result = res;
    },err=>{
      result = err
    })
    return result
  }
  async exportProducts(storeId){  
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.exportProducts(storeId).
    subscribe(val => {
      this.dataService.hideSpinner()
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        console.log(err)
        this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }

 
  async updateProduct(id, payload){
    delete payload.updating;
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.updateProduct(id, payload).
    subscribe(val => {
      this.dataService.hideSpinner()
      this.toastr.success('Product has been updated')
      this.router.navigate(['products-list/'+payload.storeId])
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        console.log(err)
        this.toastr.error(err?.error?.error || 'Product update failed')
        // this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }

  async updateProductFeatured(id, payload){
    delete payload.updating;
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.updateProductFeatured(id, payload).
    subscribe(val => {
      this.dataService.hideSpinner()
      this.toastr.success('Product has been updated')
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        console.log(err)
        // this.toastr.success('Product create failed')
        this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }


  async quickEditProduct(id, payload){
    delete payload.updating;
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.quickEditProduct(id, payload).
    subscribe(val => {
      this.dataService.hideSpinner()
      this.toastr.success('Product has been updated')
      this.router.navigate(['products-list/'+payload.storeId])
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        console.log(err)
        // this.toastr.success('Product create failed')
        this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }

  async getSingleProduct(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleProduct(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async deleteProduct(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.deleteProduct(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Product has been deleted successfully!')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('Internal server error!')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  setProductId(id){
    this.productId = id;
  }
  getProductId(){
    return this.productId;
  }

  async changePublish(payload, id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.changePublish(payload, id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Product modified!')
          resolve(val)
        }, err => {
          console.log({err})
          this.dataService.hideSpinner()
          this.toastr.error(err?.error?.error || 'Internal server error!')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async restoreProduct(payload, id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.restoreProduct(payload, id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err?.error?.error || 'Internal server error!')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
}
