import { Component, OnInit } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import * as _ from 'lodash';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { OrdersService } from 'app/pages/order/orders.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bot-orders-list',
  templateUrl: './bot-orders-list.component.html',
  styleUrls: ['./bot-orders-list.component.css']
})
export class BotOrdersListComponent implements OnInit {
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  orders;
  stores = [];
  status =[
    'pending',
    'confirm',
    'rejected',
    'preparing',
    'served/dispatched',
    'billed',
    'all'
  ];
  ordersForm: any;
  selectedStore: any;
  selectedStatus: any;
  selectedRefNo: any;
  constructor(private dataService: DataServiceService,private permssionService: NgxPermissionsService, private translate: TranslateService, private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private ordersService: OrdersService) {

    this.ordersForm = this.formBuilder.group({
      storeId: ['', Validators.required],
      status: ['', Validators.required],
      refNo: ['', Validators.required]
    })
  }
  urlID;
  urlKEY;
  response: any;
  loader = false;
  payload = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
    "fields": "storeName orderType status orderRefNo totalPrice createdAt"
  }
  storePayload = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: 50 },
    "sort": {},
    "fields": "name"
  }
  allOrderTypes = [
    { id: 0, name: 'Delivery' },
    { id: 1, name: 'Take away' },
    { id: 2, name: 'Reservation' },
    { id: 3, name: 'Digital menu' }
  ]

  async ngOnInit(): Promise<void> {
    this.loader = true;
    this.dataService.showSpinner()
    this.urlID = this.route.snapshot.params.id;
    this.urlKEY = this.route.snapshot.params.key;
    if(this.urlKEY == 'all'){
      this.response = await this.ordersService.getOrders(this.payload)
  }
  if(this.urlKEY == 'customer'){
    this.payload.filters = {
      "customerId": this.urlID
    }
    this.response = await this.ordersService.getOrders(this.payload)

  }
  if(this.urlKEY == 'store'){
    this.payload.filters = {
      "storeId": this.urlID
    }
    this.response = await this.ordersService.getOrders(this.payload)
  }
  this.orders = await this.response.orders;
  this.loader = false;
      this.totalRecords = this.response.totalRecord
      this.modifyOrdersData()
      this.createPagination()
      this.dataService.hideSpinner()
  }

  async showStores(){
    this.dataService.showSpinner()
    this.selectedRefNo = false;
    if(this.stores.length == 0){
      this.stores = await this.ordersService.getStores(this.storePayload);
      this.dataService.hideSpinner()
    }
    this.dataService.hideSpinner()
    this.selectedStore = true;
  }

  showStatus(){
    this.selectedRefNo = false;
    this.selectedStatus = true;
  }

  async sort(SortName: String) {
    if (SortName == 'storeName') {
      this.payload.sort = { "storeName": 1 }
    }
    if (SortName == 'orderType') {
      this.payload.sort = { "orderType": 1 }
    }
    if (SortName == 'status') {
      this.payload.sort = { "status": 1 }
    }
    let response = await this.ordersService.getOrders(this.payload)
      this.orders = response.orders;
      this.totalRecords = response.totalRecord
      this.modifyOrdersData()
      this.createPagination()

  }

  async goToPage(Index) {
    this.dataService.showSpinner()
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    let response = await this.ordersService.getOrders(this.payload)
      this.orders = await response.orders;
      this.dataService.hideSpinner()
      this.totalRecords = response.totalRecord
      this.modifyOrdersData()
      this.createPagination()
    }

  async reset() {
    this.payload.sort = {}
    let response = await this.ordersService.getOrders(this.payload)
      this.orders = await response.orders;
      this.totalRecords = response.totalRecord
      this.modifyOrdersData()
      this.createPagination()
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  getByRefNo(){
    this.selectedRefNo = true
    this.selectedStore = false;
    this.selectedStatus = false;
  }

  async enteredRefNo(){
    this.dataService.showSpinner()
    this.ordersForm.value.refNo = this.ordersForm.value.refNo.toString()
    if(this.ordersForm.value.refNo.length >= 9){
      document.getElementById('err-msg').classList.remove('show')
      this.payload.filters = {
        "orderRefNo": this.ordersForm.value.refNo
      }
      let response = await this.ordersService.getOrders(this.payload)
      this.orders = await response.orders;
      this.totalRecords = response.totalRecord
      this.modifyOrdersData()
      this.createPagination()
      this.dataService.hideSpinner()
    }
    else{
      document.getElementById('err-msg').classList.add('show')
    }
  }

  async storeSelected(event){
    this.dataService.showSpinner()
    this.selectedStore = event;
    if(this.selectedStatus){
      this.payload.filters["storeId"] = event
    }
    else{
      this.payload.filters = {
        "storeId" : event
      }
    }
    let response = await this.ordersService.getOrders(this.payload)
      this.orders = await response.orders;
      this.totalRecords = response.totalRecord
      this.modifyOrdersData()
      this.createPagination()
      this.dataService.hideSpinner()
  }

  async statusSelected(event){
    this.dataService.showSpinner()
    this.selectedStatus = event;
    if(this.selectedStore){
      this.payload.filters = {
        "storeId": this.selectedStore,
        "status": event
      }
      if(event == 'all'){
        this.payload.filters = {
          "storeId": this.selectedStore,
        }
      }
    }
    else{
      this.payload.filters = {
        "status": event
      }
    }
    if(event == 'all' && !this.selectedStore){
      this.payload.filters = {}
    }

    let response = await this.ordersService.getOrders(this.payload)
      this.orders = await response.orders;
      this.totalRecords = response.totalRecord
      this.modifyOrdersData()
      this.createPagination()
      this.dataService.hideSpinner()
  }

  async getAllOrders(){
    this.selectedStore = null;
    this.selectedStatus = '';
    this.payload.filters = {}
    let response = await this.ordersService.getOrders(this.payload)
      this.orders = await response.orders;
      this.totalRecords = response.totalRecord
      this.modifyOrdersData()
      this.createPagination()
  }

  modifyOrdersData(){
    this.orders.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
      let index = _.findIndex(this.allOrderTypes, {id: parseInt(single.orderType)});
      if(index>=0){
        single.orderType = this.allOrderTypes[index].name;
      }
    })
  }


  viewOrder(id){
    this.router.navigate(['bot-order-details/'+id])
  }

  counter(i) {
    return new Array(i)
  }

}
