import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'app/pages/customer/customer.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FileUploaderOptions, FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css']
})
export class EditCustomerComponent implements OnInit {
  editCustomerForm: any;
  public uploader: FileUploader;
  previewImg: any;
  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private translate: TranslateService,
    private dataService: DataServiceService,
    private toastr: ToastrService
  ) {

    this.editCustomerForm = formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]],
      picture: [''],
      DOB: [''],
      phone: ['', Validators.required],
    })

  }
  urlID;
  customer;
  public validationMessages = {
    name: [{
      type: 'required', message: 'First name is required'
    }],
    email: [{
      type: 'required', message: 'Email is required'
    },
    {
      type: 'pattern', message: 'Email is invalid'
    }],
    phone: [{
      type: 'required', message: 'Phone number is required'
    }]
  }
  async ngOnInit(): Promise<void> {
    try {
      this.urlID = this.route.snapshot.params.id
      this.customer = await this.customerService.getCustomerProfile(this.urlID)
      if (this.customer.DOB) {
        this.customer.DOB = this.customerService.formatDate(this.customer.DOB)
      }
      this.patchCustomerInfo()
      this.imageUploadConfig()
    } catch(err) {
      if(err) {
        this.toastr.error("Invalid Customer");

        this.router.navigateByUrl('customers-list');
      }
    }
  }

  imageUploadConfig() {
    const uploaderOptions: FileUploaderOptions = {
      url: environment.cloudinaryURL,

    };
    this.uploader = new FileUploader(uploaderOptions);
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);
      this.editCustomerForm.patchValue({
        picture: res.url
      })
      return { item, response, status, headers };
    };

    this.uploader.onAfterAddingFile = (fileItem) => {
      this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
      this.editCustomerForm.patchValue({
        picture: ''
      })
    }
  }
  submit() {
    this.editCustomerForm.value.DOB = new Date(this.editCustomerForm.value.DOB).valueOf()
    let payload = {
      name: this.editCustomerForm.value.name,
      picture: this.editCustomerForm.value.picture ? this.editCustomerForm.value.picture : this.customer.picture,
      phoneNumber: this.editCustomerForm.value.phone,
      email: this.editCustomerForm.value.email,
      DOB: this.editCustomerForm.value.DOB ? this.editCustomerForm.value.DOB : ''
    }
    let response = this.customerService.updateCustomer(this.urlID, payload)
  }

  patchCustomerInfo() {
    this.editCustomerForm.patchValue({
      name: this.customer.customerName,
      phone: this.customer.phoneNumber,
      email: this.customer.email,
      picture: this.customer.picture,
      password: this.customer.password,
      DOB: this.customer.DOB
    })
  }

  close() {
    this.router.navigate(['customer-profile/' + this.urlID])
  }

  removeImg() {
    this.uploader.clearQueue();
    this.editCustomerForm.patchValue({
      picture: ''
    })
  }

}
