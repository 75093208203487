import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';

@Component({
  selector: 'app-add-socail-channel-wizard',
  templateUrl: './add-socail-channel-wizard.component.html',
  styleUrls: ['./add-socail-channel-wizard.component.scss']
})
export class AddSocailChannelWizardComponent implements OnInit {
  @Input('selectedPlatform')selectedPlatform;
  @Input('storeId')storeId;
  @Output() platformConnected: EventEmitter<any> = new EventEmitter();

  currentStep = 0;
  isValidTypeBoolean: boolean = true;
  hideFbLoginStep = false;
  user
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  constructor(private ngWizardService: NgWizardService, private activatedRoute: ActivatedRoute, private dataService: DataServiceService) { }

  ngOnInit(): void {
    this.user = localStorage.getItem('user_info')
    this.user = JSON.parse(this.user)
    this.activatedRoute.queryParams.subscribe(params => {
      if(this.user.fbId){
        this.hideFbLoginStep = true;
        let that = this;
        setTimeout(function(){
          that.dataService.setFbSource('manageChannel');
          that.ngWizardService.show(1);
        },0)

      }
    })
  }

  stepChanged(args: StepChangedArgs) {
    //console.log(args.step);
    this.currentStep = args.step.index
  }

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  platformConnectedLocal(key){
    this.platformConnected.emit(key)
  }
}
