import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { ConfirmDialogService } from 'app/confirm-dialog/confirm-dialog.service';
// import { ConfirmationDialogueService } from 'app/confirmation-dialogue.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../subscription.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.css']
})
export class SubscriptionListComponent implements OnInit {
  subscriptions;
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  status
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
  }
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  urlID;
  constructor(private router: Router, private route: ActivatedRoute,private ngModalService: NgbModal, private subService: SubscriptionService, private translate: TranslateService, private dataService: DataServiceService, private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id
    this.payload.filters = {storeId: this.urlID}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    this.dataService.getSubscriptions(this.payload)
      .subscribe(val => {
        this.dataService.hideSpinner()
        this.totalRecords = val.totalRecord
        this.subscriptions = val.subscriptions
        this.createPagination()
      }, err => {
        this.dataService.hideSpinner()
      })
  }



  updateSubscription(id: string) {
    this.router.navigate(['subscription/' + id]);
  }

  async delete(id: String, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if (result == 'yes') {
        that.dataService.showSpinner()
        that.dataService.deleteSubscriptions(id).subscribe(val => {
          that.dataService.hideSpinner()
          that.ngOnInit();
          that.toastr.success('Subscription has been deleted successfully!')
        }, err => {
          that.dataService.hideSpinner()
          that.toastr.error('Failed!')
        });
      }
    });
    // this.confirmDialogService.confirmThis(message, async function () {
    //   that.dataService.showSpinner()
    //   that.dataService.deleteSubscriptions(id).subscribe(val => {
    //     that.dataService.hideSpinner()
    //     that.ngOnInit();
    //     that.toastr.success('Subscription has been deleted successfully!')
    //   }, err => {
    //     that.dataService.hideSpinner()
    //     that.toastr.error('Failed!')
    //   });
    // }, function () {
    // })
  }


  reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getSubscriptions(this.payload)
      .subscribe(val => {
        this.subscriptions = val.subscriptions
        this.totalRecords = val.totalRecord
        this.createPagination()
      }, err => {
      }

      )
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getSubscriptions(this.payload)
      .subscribe(val => {
        this.subscriptions = val.subscription
      }, err => {
      }

      )

  }
  addSubscription() {
    this.router.navigate(['subscription/add'])
  }
  startPage() {
    return this.pageStart

  }
  counter(i) {
    return new Array(i)
  }


  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }

  async toggleActive(id) {
    let index = this.subscriptions.findIndex(t=>t._id == id)
    let sub = this.subscriptions.find(s=>s._id == id)
    this.status = !this.subscriptions[index].status
    let payloadBlock = {
      ...sub,
      status: this.status
    }
    let response:any = await this.subService.updateSubscription(id, payloadBlock)
    if (response) {
      this.subscriptions[index].status = !this.subscriptions[index].status
    }
  }


}
