import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ModalService } from 'app/_modal';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../settings.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MethodModalComponent } from '../method-modal/method-modal.component';
import { StoreService } from 'app/pages/settings/store/store.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-delivery-setting',
  templateUrl: './add-delivery-setting.component.html',
  styleUrls: ['./add-delivery-setting.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddDeliverySettingComponent implements OnInit {

  constructor(private route: ActivatedRoute, public router: Router, private storeService: StoreService, private ngModalService: NgbModal, private toastr: ToastrService, private settingService: SettingsService, private modalService: ModalService, private dataService: DataServiceService) { }
  name;
  urlID;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  tempUserSelectedRegions
  countries = [];
  patchedRegions = [];
  dropdownSettings;
  deliveryRegionsForDropDown = [];
  regions = [];
  addClosed = false;
  payload = {
    filters: {},
    pagination: { start: 0, limit: 999 },
    sort: {},
  }
  finalUserSelectedRegions = [];
  countryIds = [];
  addedMethods:any = [];
  orgs = [];
  selectedOrg;
  isActive = true;
  loader = false;
  ngOnInit(): void {
    this.loader = true;
    this.orgs = JSON.parse(localStorage.getItem('organizations'))
      this.selectedOrg = this.orgs.find(o=>o.selected)
      this.urlID = this.route.snapshot.params.id;
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.getCountryInfo();
  }

  async delete(id: String, message) {
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if(result=='yes'){
        this.addedMethods.splice(this.addedMethods.findIndex(s=>s.methodId == id), 1)
      }
    })
  }

  openModal(id){
    const modalRef = this.ngModalService.open(MethodModalComponent,this.modalOptions);
    modalRef.componentInstance.addedMethods = this.addedMethods;
    if(id){
      modalRef.componentInstance.edit = this.addedMethods.find(a=>a.methodId==id);
    }
    modalRef.result.then(async (result) => {
      if (result != 'cancel') {
        if(!id){
          this.addedMethods.push(result)
        }
        else{
          this.addedMethods[this.addedMethods.findIndex(a=>a.methodId == id)] = result;
        }
      }
    });
  }




  async submit(type){
    this.finalUserSelectedRegions = [];
    for (let i = 0; i < this.tempUserSelectedRegions.length; i++) {
      if (typeof this.tempUserSelectedRegions[i] == 'string') {
        let that = this;
        const countryIndex = _.findIndex(
          this.countries,
          function (o) {
            return o.countryName == that.tempUserSelectedRegions[i];
          }
        );

        this.finalUserSelectedRegions.push({
          countryId: this.countries[countryIndex].countryId,
          countryName: this.countries[countryIndex].countryName,
        });
      } else {
        this.finalUserSelectedRegions.push(this.tempUserSelectedRegions[i]);
      }
      if (i == this.tempUserSelectedRegions.length - 1) {
       // console.log(this.finalUserSelectedRegions);
      }
    }
    let payload = {
      name:this.name,
      regions: this.finalUserSelectedRegions,
      methods: this.addedMethods,
      isActive: this.isActive,
      storeId: this.selectedOrg.storeId
    }
    payload.regions.forEach(r=>{
      delete r.group;
    })
    payload.methods.forEach(m=>{
      if(m.methodId == 2){
        m.minimumOrderAmount = parseInt(m.minimumOrderAmount);
      }
    })
    if(type == 'add'){

      let response = await this.settingService.createDeliverySetting(payload)
      if(response){
        this.toastr.success('Delivery Setting has been added')
        this.router.navigate(['delivery-settings/'+this.selectedOrg.storeId])
      }
    }
    else{
      let response = await this.settingService.updateDeliverySetting(this.urlID, payload)
      if(response){
        this.toastr.success('Delivery Setting has been updated')
        this.router.navigate(['delivery-settings/'+this.selectedOrg.storeId])
      }
    }
  }

  toggleActive(id){
    this.addedMethods.find(a=>a.methodId == id).isActive = !this.addedMethods.find(a=>a.methodId == id).isActive;
  }

  gotoList(){
    this.router.navigate(['delivery-settings/'+this.selectedOrg.storeId])
  }

  async patchSetting(){
    let response = await this.settingService.getSingleSetting(this.urlID)
    this.loader = false;
    if(response.regions){
      response.regions.forEach((r,i)=>{
        response.regions[i]['group'] = r.countryName;
        response.regions[i]['show_name'] = r.stateId ? (r.stateName + ', ' + r.countryName) :r.countryName;
        response.regions[i]['name'] = r.stateId ? (r.stateName) :r.countryName;

        if(r.stateId){
          let regionIndex = _.findIndex(this.deliveryRegionsForDropDown,function (o) {
            return o.show_name == response.regions[i]['show_name'];
          });
          if(regionIndex > -1){
            response.regions[i]['id'] = this.deliveryRegionsForDropDown[regionIndex].id;
          }
          response.regions[i]['value'] ={countryName:r.countryName, countryId:r.countryId, group: r.countryName, stateName: r.stateName, stateId:r.stateId}
          delete response.regions[i]['countryName']
          delete response.regions[i]['countryId']
          delete response.regions[i]['stateName']
          delete response.regions[i]['stateId']
        }else{
          response.regions[i]['value'] ={countryName:r.countryName, countryId:r.countryId, group: r.countryName}
          delete response.regions[i]['countryName']
          delete response.regions[i]['countryId']
        }
      })
      this.tempUserSelectedRegions = response.regions
    }

    this.isActive = response.isActive;
    this.name = response.name;
    this.addedMethods = response.methods;
    // if(response.regions){
    //   this.patchedRegions = JSON.parse(JSON.stringify(response.regions));
    //   response.regions.forEach((r,i)=>{
    //     response.regions[i]._id = r.regionId;
    //     delete response.regions[i].regionId;
    //   })
    // }

    // this.addedMethods.forEach(added=>{
    //   this.availableMethods.splice(this.availableMethods.findIndex(a=>a.methodId == added.methodId), 1)
    //   this.selectedMethods.push(added.methodId)
    // })
  }

  async getCountryInfo(){
    let storeResponse = await this.storeService.getSingleStore(this.selectedOrg.storeId)
    this.countryIds = storeResponse?.store?.countriesDeliver;
    if(this.countryIds.length){
      let countryResponse = await this.storeService.getCountriesData(this.countryIds.map(c=>c.id))
      this.countries = countryResponse.countries;
      this.createDeliveryRegionsData()
    }
    if(this.urlID != 'add'){
      this.patchSetting()
    }
    else{
      this.loader = false;
    }
  }

  createDeliveryRegionsData() {
    this.deliveryRegionsForDropDown = [];
    for (let i = 0; i < this.countries.length; i++) {
      let currentCountry = this.countries[i]; //setting current country

      if (currentCountry.states && currentCountry.states.length > 0) {
        for (let s = 0; s < currentCountry.states.length; s++) {
          let currentState = currentCountry.states[s]; //setting current country
          //console.log(i, s, currentState.id, currentState.name);
          this.deliveryRegionsForDropDown.push({
            id: i + 1+ ''+ s,
            name: currentState.name,
            show_name: currentState.name + ', ' + currentCountry.countryName,
            group: currentCountry.countryName,
            value: {
              countryId: currentCountry.countryId,
              countryName: currentCountry.countryName,
              stateId: currentState.id,
              stateName: currentState.name,
              group: currentCountry.countryName,
            },
          }); //option Created for State
        }
      } else {
        this.deliveryRegionsForDropDown.push({
          id: i,
          name: currentCountry.countryName,
          show_name: currentCountry.countryName,
          value: {
            countryId: currentCountry.countryId,
            countryName: currentCountry.countryName,
          },
        }); //option Created for country
      }
    }
  }

  unselectAll() {
    this.tempUserSelectedRegions = [];
  }

  compareFn(a, b) {
    //return a.id === b.id || a.group === b.group || a.show_name == b.show_name;
    return a.show_name == b.show_name || a.group === b.show_name
  }



  // response.regions.forEach((r,i)=>{
  //   response.regions[i]['group'] = r.countryName;
  //   response.regions[i]['show_name'] = r.stateId ? (r.stateName + ', ' + r.countryName) :r.countryName;
  //   response.regions[i]['name'] = r.stateId ? (r.stateName) :r.countryName;
  //   let regionIndex = _.findIndex(
  //     this.deliveryRegionsForDropDown,
  //     function (o) {
  //       return o.show_name == response.regions[i]['show_name'];
  //     }
  //   );
  //   if(regionIndex > -1){
  //     response.regions[i]['id'] = this.deliveryRegionsForDropDown[regionIndex].id;
  //   }
  //   if(r.stateId){
  //     response.regions[i]['value'] ={countryName:r.countryName, countryId:r.countryId, group: r.countryName, stateName: r.stateName, stateId:r.stateId}
  //     delete response.regions[i]['countryName']
  //     delete response.regions[i]['countryId']
  //     delete response.regions[i]['stateName']
  //     delete response.regions[i]['stateId']
  //   }else{
  //     response.regions[i]['value'] ={countryName:r.countryName, countryId:r.countryId, group: r.countryName}
  //     delete response.regions[i]['countryName']
  //     delete response.regions[i]['countryId']
  //   }
  // })

}
