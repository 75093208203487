import { Component, OnInit } from '@angular/core';
import { PageRequestService } from '../page-request.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { findIndex } from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-page-request',
  templateUrl: './page-request.component.html',
  styleUrls: ['./page-request.component.css']
})
export class PageRequestComponent implements OnInit {

  constructor(
    private pageRequestService: PageRequestService,
    private dataService: DataServiceService,
    private toastr: ToastrService
  ) { }

  requests: any;

  async ngOnInit(): Promise<void>  {
    try {
      this.dataService.showSpinner();

      this.requests = await this.pageRequestService.allPageRequests();
    } catch (err) {
      throw new Error(err);
    } finally {
      this.dataService.hideSpinner();
    }
  }

  async approveRequest(id: any) {
    this.dataService.showSpinner();

    const request = this.requests[findIndex(this.requests, { _id:  id})];

    this.pageRequestService.approvePageRequest({
      name: request.activePages.name,
      profilePic: "",
      ownerId: request._id,
      fbId: request.activePages.fbId
    })
      .then((res) => {
        if(res) {
          this.toastr.success('Page request approved!');

          this.ngOnInit();
        } else {
          this.toastr.error('Something went wrong!');
        }
      })
      .catch((err) => {
        throw new Error(err);
      })

    this.dataService.hideSpinner();
  }

  activateRequest(id: any) {
    // TBA
  }
}
