import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { SubCategoryService } from '../sub-category.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-add-sub-category',
  templateUrl: './add-sub-category.component.html',
  styleUrls: ['./add-sub-category.component.css']
})
export class AddSubCategoryComponent implements OnInit {
  constructor(public router: Router, private catService: SubCategoryService, private dataService: DataServiceService, private route: ActivatedRoute,private sanitizer: DomSanitizer,) { }
  public uploader: FileUploader;
  public validationMessages = {
    name: [
      {
        type: "required",
        message: "Name is required",
      },
    ],
    detail: [
      {
        type: "required",
        message: "Details is required",
      },
    ],picture: [
      {
        type: "required",
        message: "Picture is required",
      },
    ],
  };
  categories = []
  payload = {
    filters: {},
    pagination: { start: 0, limit: 999 },
    sort: {},
  }
  subCategoryForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    detail: new UntypedFormControl('', Validators.required),
    picture: new UntypedFormControl('', Validators.required),
    categoryId: new UntypedFormControl('', Validators.required),
  })

  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  urlID;
  orgs;
  selectedOrg;
  previewImg:any;
  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id;
    this.orgs = JSON.parse(localStorage.getItem('organizations'))
      this.selectedOrg = this.orgs.find(o=>o.selected)
    this.payload.filters = {storeId: this.selectedOrg.storeId};
    this.getCategories()
      if(this.urlID != 'add'){
        this.patchCategory()
      }
      const uploaderOptions: FileUploaderOptions = {
        url: environment.cloudinaryURL,

      };

      this.uploader = new FileUploader(uploaderOptions);
      this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
        form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
        form.append('file', fileItem);
        fileItem.withCredentials = false;
        return { fileItem, form };
      };

      this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
        let res: any = JSON.parse(response);
        this.subCategoryForm.patchValue({
          picture: res.url
        })
        return { item, response, status, headers };
      };

      this.uploader.onAfterAddingFile = async (fileItem) => {
        this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
        this.subCategoryForm.patchValue({
          picture: ''
        })
      }
    }


  gotoList(){
    this.router.navigate(['sub-categories-list/'+this.selectedOrg.storeId])
  }

  submit(){
    let payload = this.subCategoryForm.getRawValue();
    payload.storeId = this.selectedOrg.storeId
    this.catService.createSubCategory(payload);
  }

  update(){
    let payload = this.subCategoryForm.getRawValue();
    payload.storeId = this.selectedOrg.storeId
    this.catService.updateSubCategory(this.urlID, payload);
  }

  async patchCategory(){
    let response = await this.catService.getSingleSubCategory(this.urlID)
    if(response){
      this.subCategoryForm.patchValue(response.subCategory)
    }
  }

  removeImg() {
    this.uploader.clearQueue();
    this.subCategoryForm.patchValue({
      picture: ''
    })
  }

  getCategories(){
    this.dataService.getCategories(this.payload).subscribe(res=>{
      this.categories = res.category;
    })
  }

}

