import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import * as _ from 'lodash';
import { ToastrService, ToastContainerModule } from 'ngx-toastr';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DealsService } from './../deals.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from 'app/utilities/auth.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.css']
})
export class DealsComponent implements OnInit {
  totalRecords = 0;
  storeChosen;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  constructor(private router: Router, private permissionService: NgxPermissionsService, private ngModalService: NgbModal, private authService: AuthService, private dataService: DataServiceService,  private dealsService: DealsService, private toastr: ToastrService, private formBuilder: UntypedFormBuilder) {
  }
  pageLimit = 10;
  pageStart = 0;
  NoOfPages = 0;
  block = false;
  payload = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
    "fields": "dealName dealInfo dealPrice storeName expiryDate createdAt block"
  }
  storePayload = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: 1000 },
    "sort": {},
    "fields": "name"
  }
  allDeals = [];
  selectedStoreDeals = [];
  stores = [];
  user;
  async ngOnInit() {
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.allDeals = []
    let response;
    this.user = await this.authService.getUser()
    if (this.user.role == 'superAdmin') {
      response = await this.dealsService.getDeals(this.payload)
    }
    else {
      this.payload.filters = {
        "storeId": this.user.storeId
      }
      response = await this.dealsService.getStoreDeals(this.payload)
    }
    this.allDeals.push(response.deals)
    this.totalRecords = response.totalRecord
    this.createPagination()
    this.modifyDate()
  }

  async showStores() {
    this.stores = await this.dealsService.getStores(this.storePayload)
  }

  async storeSelected(event) {
    this.allDeals = []
    this.payload.filters = {
      "storeId": event
    }
    let response = await this.dealsService.getDeals(this.payload)
    this.allDeals.push(response.deals)
    this.totalRecords = response.totalRecord
    this.createPagination()
    this.modifyDate()
  }

  async sort(SortName: String) {
    if (SortName == 'dealName') {
      this.payload.sort = { "dealName": 1 }
    }
    if (SortName == 'storeName') {
      this.payload.sort = { "storeName": 1 }
    }
    if (SortName == 'expiryDate') {
      this.payload.sort = { "expiryDate": 1 }
    }
    if (SortName == 'createdAt') {
      this.payload.sort = { "createdAt": 1 }
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.allDeals = []
    let response = await this.dealsService.getDeals(this.payload)
    this.allDeals.push(response.deals)
    this.totalRecords = response.totalRecord
    this.createPagination()
    this.modifyDate()

  }

  async reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.allDeals = []
    let response = await this.dealsService.getDeals(this.payload)
    this.allDeals.push(response.deals)
    this.totalRecords = response.totalRecord
    this.createPagination()
    this.modifyDate()
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  async goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }

    this.allDeals = []
    let response = await this.dealsService.getDeals(this.payload)
    this.allDeals.push(response.deals)
    this.totalRecords = response.totalRecord
    this.createPagination()
    this.modifyDate()
  }

  async getAllDeals() {
    this.storeChosen = ''
    this.stores = [];
    this.payload.filters = {};
    this.allDeals = []
    /* let response = await this.dealsService.getDeals(this.payload) */
    let response;
    if (this.user.role == 'superAdmin') {
      response = await this.dealsService.getDeals(this.payload)
    }
    else {
      this.payload.filters = {
        "storeId": this.user.storeId
      }
      response = await this.dealsService.getStoreDeals(this.payload)
    }
    this.allDeals.push(response.deals)
    this.totalRecords = response.totalRecord
    this.createPagination()
    this.modifyDate()
  }

  addNewDeal() {
    this.router.navigate(['add-deals/add']);
  }

  updateDeal(id) {
    let index = _.findIndex(this.allDeals, { _id: id });
    this.router.navigateByUrl('add-deals/' + id, { state: { deal: this.allDeals[index] } })
  }
  async deleteDeal(id, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if(result=='yes'){

        let response = await that.dealsService.deleteDeal(id)
        
        if(response) {
          that.ngOnInit();
        }
      }
    })
    // let res = await this.confirmationService.confirmation('Are you sure you want to delete this deal?')
    // if(res){
    //   this.dealsService.deleteDeal(id)
    //   this.ngOnInit();
    // }

  }

  async toggleBlock(id) {
    let index = _.findIndex(this.allDeals[0], { _id: id });
    this.block = !this.allDeals[0][index].block
    let payloadBlock = {
      block: this.block
    }
    let response = await this.dealsService.blockDeal(id, payloadBlock)
    if (response.restUpdate.nModified == 1) {
      this.allDeals[0][index].block = !this.allDeals[0][index].block
    }
  }

  modifyDate() {
    this.allDeals[0].map(singleDeal => {
      singleDeal.expiryDate = this.dataService.formatDate(new Date(singleDeal.expiryDate))
      singleDeal.createdAt = this.dataService.formatDate(new Date(singleDeal.createdAt))
    })
  }

  counter(i) {
    return new Array(i)
  }

  downloadPDF() {
    var innerContents = document.getElementById('pdfTable').innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <style>
      #actionsHead, #actionsValue, #blockHead, #blockValue{
        display: none;
      }
      .sort, .fa{
        display: none !important
      }
      .paginationBtn{
        display: none
      }
      .w-10{
        width: 15%;
      }
    </style>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="content">${innerContents}</div>
</body>
</html>`);
    popupWinindow.document.close();
  }
  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }
}
