import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { InvoiceService } from 'app/pages/invoice/invoice.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invoice-payment-modal',
  templateUrl: './invoice-payment-modal.component.html',
  styleUrls: ['./invoice-payment-modal.component.css']
})
export class InvoicePaymentModalComponent implements OnInit {  
  @Input() public outstandingInfo;
  @Input() public storeId;
  
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  isPaymentShow = false;
  isPaymentSelected = false;
  isSubSelected = true;
  paymentMethod;
  tempStoreInfo;
  promoCode;
  isPromoCodeCallback = false;
  isPromoCodeCorrect = false;
  promoCodeDiscount = 0;
  isPromoCodeCallbackAPI = false;
  promoCodeInfo;
  currentAmount = 0;
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private invoiceService: InvoiceService, private dataService: DataServiceService, private toastr: ToastrService) { }

  ngOnInit(): void {    
    //check for isIIAP
    //if true currentAmount
    
    this.currentAmount =  this.outstandingInfo.amount      
    
  }

  passBack(){
    var payload = {
      invoiceId:this.outstandingInfo.invoicesId,
      invoiceRefNo: this.outstandingInfo.invoiceRefNo,
      invoiceAmount:this.currentAmount,
      promotionCode : this.promoCodeInfo?.code,
      promotionId : this.promoCodeInfo?._id,
      storeId: this.storeId,
      paymentVia: this.paymentMethod      
    }    
    this.invoiceService.makePayment(payload)
      .then((arg)=>{        
        if(arg){         
            window.location.href= environment.botURL + '/paySubscription?orderId='+ arg._id +'&pm='+ arg.paymentVia +'&mtId='+ arg.storeId
        }else{
          this.toastr.error('FAILED')
        }

      })

    // /invoice/makePayment
    //this.passEntry.emit(this.invoice);
    //this.activeModal.close(this.invoice);
  }

  close(){
    this.activeModal.close('close')
  }

  checkPromoCode(){
    let currentPromo = this.promoCode.trim()
    if(currentPromo.length > 3){
      this.isPromoCodeCallbackAPI= true;
      this.dataService.verifyPromoCode(currentPromo)
      .subscribe((arg) => {
        this.isPromoCodeCallbackAPI= false;
        if(arg){        
          this.isPromoCodeCallback =true;
          if(arg.isActive){
            this.promoCodeDiscount = arg.discount
            this.isPromoCodeCorrect = true;
            this.promoCodeInfo = arg;            
            // this.selectedSubscription['promotionCode'] = arg.code
            // this.selectedSubscription['promotionId'] = arg._id
            
            this.currentAmount = Math.round(this.outstandingInfo.amount - (this.outstandingInfo.amount * (arg.discount/100)))                                   
          }else{
            this.isPromoCodeCorrect = false;
          }
        }else{
          this.isPromoCodeCallback = true;
          this.isPromoCodeCorrect = false;
          this.currentAmount =  this.outstandingInfo.amount          
          
        }      
        
      });
    }else{
      this.isPromoCodeCallback = false;
      this.isPromoCodeCallbackAPI= false;
      this.currentAmount =  this.outstandingInfo.amount      
    }
    
  }

  selectPaymentType(key){
    this.isPaymentSelected = true;
    this.paymentMethod = key
  }

  isFormValid(){
    if(this.currentAmount > 0){
      if(this.paymentMethod){
        return true;
      }else{
        return false
      }
    }else{
      return true;
    }   
  }
}
