import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { DataServiceService } from 'app/utilities/data-service.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { PromoCodeService } from '../promo-code.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-promotion-code-list',
  templateUrl: './promotion-code-list.component.html',
  styleUrls: ['./promotion-code-list.component.css']
})
export class PromotionCodeListComponent implements OnInit {
  promoCodes;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  isActive
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
  }
  urlID;
  constructor(private router: Router, private route: ActivatedRoute,private ngModalService: NgbModal, private subService: PromoCodeService, private translate: TranslateService, private dataService: DataServiceService, private toastr: ToastrService) {

  }


  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id
    this.payload.filters = {storeId: this.urlID}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    this.dataService.getPromoCodes(this.payload)
      .subscribe(val => {
        this.dataService.hideSpinner()
        this.totalRecords = val.totalRecord
        this.promoCodes = val.promoCode
        this.createPagination()
      }, err => {
        this.dataService.hideSpinner()
      })
  }



  updatePromoCode(id: string) {
    this.router.navigate(['add-promotion-code/' + id]);
  }

  async delete(id: String, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if (result == 'yes') {

        that.dataService.showSpinner()
        that.dataService.deletePromoCodes(id).subscribe(val => {
          that.dataService.hideSpinner()
          that.ngOnInit();
          that.toastr.success('Promotion Code has been deleted successfully!')
        }, err => {
          that.dataService.hideSpinner()
          that.toastr.error('Failed!')
      })
    }
    });
  }


  reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getPromoCodes(this.payload)
      .subscribe(val => {
        this.promoCodes = val.promoCodes
        this.totalRecords = val.totalRecord
        this.createPagination()
      }, err => {
      }

      )
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getPromoCodes(this.payload)
      .subscribe(val => {
        this.promoCodes = val.promoCode
      }, err => {
      }

      )

  }
  addPromoCode() {
    this.router.navigate(['add-promotion-code/add'])
  }
  startPage() {
    return this.pageStart

  }
  counter(i) {
    return new Array(i)
  }


  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }

  async toggleActive(id) {
    let index = this.promoCodes.findIndex(t=>t._id == id)
    let sub = this.promoCodes.find(s=>s._id == id)
    this.isActive = !this.promoCodes[index].isActive
    let payloadBlock = {
      ...sub,
      isActive: this.isActive
    }
    let response:any = await this.subService.updatePromoCode(id, payloadBlock)
    if (response) {
      this.promoCodes[index].isActive = !this.promoCodes[index].isActive
    }
  }


}
