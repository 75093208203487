import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { PromotionService } from '../promotion.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';

@Component({
  selector: 'app-promotions-list',
  templateUrl: './promotions-list.component.html',
  styleUrls: ['./promotions-list.component.css']
})
export class PromotionsListComponent implements OnInit {

  constructor(private promotionService: PromotionService,private ngModalService: NgbModal, private router: Router, private toastr: ToastrService, private dataService: DataServiceService) { }

  pageLimit = 10;
  pageStart = 0;
  search = false;
  searchValue = ''
  totalRecords = 0;
  NoOfPages: Number;
  loader = false;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  payload:any = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
    fields: ''
  }
  promotions;
  org;
  async ngOnInit() {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.payload.filters['storeId'] = this.org.storeId;
    this.getPromotions()
   
  }

  async getPromotions(){
    this.loader = true;
    let response = await this.promotionService.getPromotions(this.payload);
    this.loader = false;
    this.promotions = response.promotion;
    this.totalRecords = response.totalRecord;
    setTimeout(() => {
      if(localStorage.getItem('lang') == 'ar'){
        document.getElementsByClassName('fa-times')[0]['style'].left = '145px';
      }
    }, 0);
  }
  
  addPromotion(){
    this.router.navigate(['create-promotion/add'])
  }

  async changeStatus(id, promo){
    let res = await this.promotionService.changeStatus({isActive: !promo.isActive}, id);
    if(res){
      promo.isActive = !promo.isActive;
    }
  }

  updatePromotion(id){
    this.router.navigate(['create-promotion/'+id])
  }

  async deletePromotion(id: string, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if (result == 'yes') {
        that.dataService.showSpinner()
      that.dataService.deletePromotion(id).subscribe(val => {
        that.dataService.hideSpinner()
        that.ngOnInit();
        that.toastr.success('Promotion has been deleted successfully!')
      }, err => {
        that.dataService.hideSpinner()
        that.toastr.error('Promotion Delete Failed!')
      });
      }
    });
      
  }

  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }

  async goToPage(Index) {
    this.loader = true;
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    let response = await this.promotionService.getPromotions(this.payload)
    this.loader = false;
    this.promotions = await response.promotion;
    this.totalRecords = response.totalRecord
    this.createPagination()
  }

  counter(i) {
    return new Array(i)
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  searchPromotions(type = undefined){
    if(type == 'clear' && !this.searchValue){
      return;
    }
    if(type == 'clear' || (type && !this.searchValue)){
      delete this.payload.filters.$or;
      this.searchValue = ''
      this.search = false;
      this.getPromotions()
      return;
    }
    if(!type || type.keyCode == 13){
      if(this.searchValue){        
        this.search = true;
          this.pageStart = 0;
          this.payload.pagination.start = 0;    
          this.payload.filters = {...this.payload.filters, $or: [{promotionName:{$regex:".*"+this.searchValue+".*",$options:'i'}},{promotionCode:{$regex:".*"+this.searchValue+".*",$options:'i'}}]}
        
        this.getPromotions()
      }
    }
  }

}
