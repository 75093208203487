import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal,NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { DataServiceService } from 'app/utilities/data-service.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
// import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sub-categories-list',
  templateUrl: './sub-categories-list.component.html',
  styleUrls: ['./sub-categories-list.component.css']
})
export class SubCategoriesListComponent implements OnInit {
  subCategories;
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  searchValue = '';
  search = false;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  payload:any = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
  }
  loader = false;
  urlID;
  constructor(private router: Router, private route: ActivatedRoute,private ngModalService: NgbModal, private translate: TranslateService, private permissionService: NgxPermissionsService,  private dataService: DataServiceService, private toastr: ToastrService) {
    
  }


  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id
    this.payload.filters = {storeId: this.urlID}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.getSubcategories()
   
  }

  getSubcategories(){
    this.loader = true;
    this.dataService.showSpinner()
    this.dataService.getSubCategories(this.payload)
      .subscribe(val => {
        this.loader = false;
        this.dataService.hideSpinner()
        this.totalRecords = val.totalRecord
        this.subCategories = val.subCategory
        this.createPagination()
        setTimeout(() => {
          if(localStorage.getItem('lang') == 'ar'){
            document.getElementsByClassName('fa-times')[0]['style'].left = '145px';
          }
        }, 0);
      }, err => {
        this.loader = false;
        this.dataService.hideSpinner()
      })
  }

  updateCategory(id: string) {
    this.router.navigate(['sub-category/' + id]);
  }

  async delete(id: String, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if (result == 'yes') {
        that.dataService.showSpinner()
        that.dataService.deleteSubCategories(id).subscribe(val => {
          that.dataService.hideSpinner()
          that.ngOnInit();
          that.toastr.success('Sub Category has been deleted successfully!')
        }, err => {
          that.dataService.hideSpinner()
          that.toastr.error('Failed!')
        });
      }
    });
    // this.confirmDialogService.confirmThis(message, async function () {
    //   that.dataService.showSpinner()
    //   that.dataService.deleteSubCategories(id).subscribe(val => {
    //     that.dataService.hideSpinner()
    //     that.ngOnInit();
    //     that.toastr.success('Sub Category has been deleted successfully!')
    //   }, err => {
    //     that.dataService.hideSpinner()
    //     that.toastr.error('Failed!')
    //   });
    // }, function () {
    // })
  }

  
  reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getSubCategories(this.payload)
      .subscribe(val => {
        this.subCategories = val.subCategories
        this.totalRecords = val.totalRecord
        this.createPagination()
      }, err => {
      }

      )
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getSubCategories(this.payload)
      .subscribe(val => {
        this.subCategories = val.subCategory
      }, err => {
      }

      )

  }
  addCategory() {
    this.router.navigate(['sub-category/add'])
  }
  startPage() {
    return this.pageStart

  }
  counter(i) {
    return new Array(i)
  }

  
  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }

  searchSubcategories(type = undefined){
    if(type == 'clear' && !this.searchValue){
      return;
    }
    if(type == 'clear' || (type && !this.searchValue)){
      delete this.payload.filters.$or;
      this.searchValue = ''
      this.search = false;
      this.getSubcategories()
      return;
    }
    if(!type || type.keyCode == 13){
      if(this.searchValue){        
        this.search = true;
          this.pageStart = 0;
          this.payload.pagination.start = 0;    
          this.payload.filters = {...this.payload.filters, $or: [{name:{$regex:".*"+this.searchValue+".*",$options:'i'}}]}
        
        this.getSubcategories()
      }
    }
  }

 
}
