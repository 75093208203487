import { Component, OnInit } from '@angular/core';
import { DealsService } from '../deals.service';
import { Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';

@Component({
  selector: 'app-deal-categories',
  templateUrl: './deal-categories.component.html',
  styleUrls: ['./deal-categories.component.css']
})
export class DealCategoriesComponent implements OnInit {
  pageLimit = 10;
  pageStart = 0;
  NoOfPages;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  payload = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
    "fields": "name startTime endTime storeName"
  }

  categories: any = [];

  constructor(
    private dealsService: DealsService,
    private router: Router,
    private dataService: DataServiceService,
    private ngModalService: NgbModal,
  ) { }

  async ngOnInit() {
    try {
      let response: any = await this.dealsService.getAllDealCategories(this.payload);
    
      this.categories = await response.dealCategory;

      this.modifyDate();      
    } catch (err) {

    } 
  }

  async goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    
    this.createPagination()
  }

  createPagination() {
    /* this.NoOfPages = Math.ceil(this.dealsService / this.pageLimit) */
  }

  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }

  async sort(SortName: String) {
    if (SortName == 'title') {
      this.payload.sort = { "dealName": 1 }
    }
    if (SortName == 'createdAt') {
      this.payload.sort = { "createdAt": 1 }
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    
    /* let response = await this.dealsService.getDeals(this.payload) */

    /* this.categories.splice(0, this.categories.length); */

    /* this.categories.push(response.deals) */

    /* this.createPagination() */
  }

  counter(i) {
    return new Array(i)
  }

  addNewCategory() {
    this.router.navigate(['add-deal-category/add']);
  }

  updateDeal(id) {
    this.router.navigate(['/add-deal-category/' + id]);
  }

  deleteDeal(id, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if(result == 'yes'){
        let response = await that.dealsService.deleteDealCategory(id)
        if(response) {
          that.ngOnInit();
        }
      }
    })
  }

  modifyDate() {
    // this.categories.map((category) => {
    //   category.endTime = this.dataService.formatDate(new Date(category.endTime));
    //   category.startTime = this.dataService.formatDate(new Date(category.startTime));
    // })
  }

  async reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.categories = []
    let response = await this.dealsService.getAllDealCategories(this.payload)
    this.categories = await response['dealCategory'];
    //this.totalRecords = response.totalRecord
    //this.createPagination()
    this.modifyDate()
  }
}
