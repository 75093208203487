import { Input, EventEmitter, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'app/sidebar.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-trail-subscription-notification',
  templateUrl: './trail-subscription-notification.component.html',
  styleUrls: ['./trail-subscription-notification.component.scss']
})
export class TrailSubscriptionNotificationComponent implements OnInit {
  user;
  org;
  @Input() store;
  @Output() reloadStore: EventEmitter<any> = new EventEmitter();

  //store;
  constructor(private sidebarService: SidebarService, private dataService: DataServiceService, private router: Router) { }

  ngOnInit(): void {
    // let orgs = JSON.parse(localStorage.getItem('organizations')) || []
    // this.org = orgs.find(o=>o.selected);
    // console.log(this.org)
    // this.sidebarService.getProfileObs().subscribe(user=>{
    //   this.user = user;
    //   if(this.user && this.org.storeId){
    //     this.dataService.getSingleStore(this.org.storeId).subscribe(single=>{
    //       console.log('got store')
    //       this.store = single.store
    //     })
    //   }
    // })

    this.dataService.getUserObs()
    .subscribe(message =>{
      this.reloadStore.emit()
    })
  }

  getLeftDays(){
    let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let firstDate: any = new Date();
    let secondDate: any = new Date(this.store?.tempStoreExpire);

    let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays
  }

  goToAddStore(){
    this.router.navigate([`add-store`], {
        queryParams: {
            storeId: this.store?._id
        },
    });
  }

}
