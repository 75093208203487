import { Injectable } from '@angular/core';
import { DataServiceService } from './utilities/data-service.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private dataService: DataServiceService) { }

  async getDashboardInfo(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getDashboardInfo(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
  async getOrderCountAggregated(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getOrderCountAggregated(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
  async getOrderCountAggregatedForSA(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getOrderCountAggregatedForSA(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getServing(payload) {
    this.dataService.showSpinner()

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getServing(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async updateServing(payload) {
    this.dataService.showSpinner()

    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateServing(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  
}
