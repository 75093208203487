import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { AuthService } from 'app/utilities/auth.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { FacebookLoginProvider } from "angularx-social-login";
import { MessagingService } from 'app/messaging.service';
import { first, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { StoreService } from '../settings/store/store.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  lang = 'English';
  countries = [];
  public validationMessages = {
    email: [{
      type: 'required', message: 'This field is required'
    },
    {
      type: 'pattern', message: 'Enter valid email address'
    }],
    password: [{
      type: 'required', message: 'This field is required'
    },
    {
      type: 'minlength', message: 'Password must be atleast 8 characters long'
    }
  ],
    firstName: [{
      type: 'required', message: 'This field is required'
    }],
    lastName: [{
      type: 'required', message: 'This field is required'
    }],
    confirmPassword: [{
      type: 'required', message: 'This field is required'
    },
    {
      type: 'maxlength', message: 'Password must be atleast 6 characters long'
    }
  ],
    phoneNumber: [{
      type: 'required', message: 'This field is required'
    },
    {
      type: 'maxlength', message: 'Invalid Phone Number'
    },
    {
      type: 'minlength', message: 'Invalid Phone Number'
    }
  ]
  }

  registerForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    phoneCode:['92', Validators.required],
    countryId:['167', Validators.required]
  })

  fbLoginOptions = {
    scope: environment.facebookScope
  };
  referCode
  isInAppBrowser = false;
  mySubscription: Subscription;
  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private router: Router, private socialAuthService: SocialAuthService, private dataService: DataServiceService, private messaging: MessagingService, private activatedRoute: ActivatedRoute, private storeService: StoreService) { }

  ngOnInit(): void {
    this.getBrowserInfo();
    this.getCountries()
    localStorage.setItem('lang','en')
    this.activatedRoute.queryParams.subscribe(params => {
      if(!localStorage.getItem('rc')){
        this.referCode = params['rc']
        if(this.referCode){
          localStorage.setItem('rc',this.referCode)
        }
      }

    })


    this.mySubscription = this.socialAuthService.authState.pipe(take(1)).subscribe(async (user) => {
      let fbSource = this.dataService.getFbSource()
      if(fbSource == 'register' && user.id){
        this.dataService.showSpinner()
        let refByCode = localStorage.getItem('rc')
        let paylaod = {
          fbId : user.id,
          token : user.authToken,
          email:user.email,
          name:user.name,
          pic: user.photoUrl,
          refByCode
        }
        this.dataService.handleSocialLogin(paylaod).subscribe(async response => {
          this.dataService.hideSpinner()
          this.authService.setToken(response.accessToken)
          localStorage.removeItem('rc')
          if(response.userInfo){
            this.messaging.requestPermission();

            let orgs = response.userInfo?.organizations || [];
            if(orgs.length){
              orgs[0].selected = true;
            }
            localStorage.setItem('organizations', JSON.stringify(orgs))
          }
          else{
            let userInfo = {
              role: response.role,
              name: response.userFullName
            }
            localStorage.setItem('superadmin', JSON.stringify(userInfo))
          }
          this.dataService.setFbSource('')
          this.router.navigate(['dashboard'])
       })
      }
    });
  }

  async register(){
    let payload = this.registerForm.getRawValue();
    let refByCode = localStorage.getItem('rc')
    delete payload.confirmPassword;
    payload['refByCode'] = refByCode;
    //payload.phoneNumber = '0'+payload.phoneNumber;
    payload.phoneNumber = payload.phoneNumber;
    let response = await this.authService.register(payload)
    this.authService.setToken(response.accessToken)
    if(response.userInfo){
      localStorage.removeItem('rc')
      if(response.userInfo){
        this.messaging.requestPermission();
        let orgs = response.userInfo?.organizations || [];
        if(orgs.length){
          orgs[0].selected = true;
        }
        localStorage.setItem('organizations', JSON.stringify(orgs))
      }
      this.dataService.setFbSource('')
      this.router.navigate(['dashboard'])
    }
  }

  signInWithFB(): void {
    this.dataService.setFbSource('register')
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID, this.fbLoginOptions);
  }


  getBrowserInfo(){
    let userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    //Instagram , FB_IAB , FBAV , FB4A
    //let str = navigator.userAgent;
    //alert(userAgent)
    if(this.isInAppBrowserCheck(userAgent)){
      this.isInAppBrowser = true;
    }
  }


  isInAppBrowserCheck(ua) {
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf("FB_IAB") > -1) || (ua.indexOf("FB4A") > -1) || (ua.indexOf("Instagram") > -1);
  }

  ngOnDestroy() {
    this.mySubscription.unsubscribe();
  }

  setLanguage(lang){
    this.lang = lang == 'ar' ? 'عربي' : 'English';
    this.authService.setLanguage(lang)
  }

  async getCountries(){
    let countryResponse = await this.storeService.getCountries();
    this.countries = countryResponse?.countries;
  }

  phoneCodeChange(event){
    let countryIndex =_.findIndex(this.countries, function(o) { return o.countryId == parseInt(event.target.value); });
    if (countryIndex >= 0) {
      this.registerForm.patchValue({
        //phoneCode: this.countries[countryIndex].phoneCode,
        countryId:  this.countries[countryIndex].countryId
      })
    }
  }
}
