import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'app/utilities/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  selectedOrg;
  constructor(private dataService: DataServiceService, private auth: AuthService, private toastr: ToastrService, private router: Router) { 
      auth.setOrganizations()
      auth.getOrganizations().subscribe(res=>{
        if(res && res.length){
          this.selectedOrg = res.find(r=>r.selected)          
        }
      })
    
  }


  async getSingleAdmin(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleAdmin(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val.user)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  createAdmin(payload) {
    this.dataService.showSpinner()
    this.dataService.createAdmin(payload)
      .subscribe(val => {
        this.dataService.hideSpinner()
        this.router.navigate(['admin-list/'+this.selectedOrg.storeId])
        this.toastr.success('Admin has been added successfully!')
      }, err => {
        this.dataService.hideSpinner()
        // this.toastr.error(err.error.error)
        this.toastr.error(err?.error?.message || 'Failed')
      });
  }

  addMember(payload) {
    this.dataService.showSpinner()
    this.dataService.addMember(payload)
      .subscribe(val => {
        this.dataService.hideSpinner()        
        // this.router.navigate(['admin-list/'+this.selectedOrg.storeId])
        // this.toastr.success('Member has been added successfully!')
        if(val?.code && val.code == 204){
          this.toastr.error(val.message)
        }
        else{
          this.toastr.success('Member has been added successfully!')
          this.router.navigate(['admin-list/'+this.selectedOrg.storeId])
        }
      }, err => {
        this.dataService.hideSpinner()
        // this.toastr.error(err.error.error)
        this.toastr.error(err?.error?.message || 'Failed')
      });
  }

  updateAdmin(payload, id) {
    this.dataService.showSpinner()
    this.dataService.updateAdmin(payload, id).subscribe(res => {
      this.dataService.hideSpinner()
      this.router.navigate(['admin-list/'+this.selectedOrg.storeId])
      this.toastr.success('Admin has been updated successfully!')
    }, err => {
      this.dataService.hideSpinner()
      this.toastr.error(err?.error?.message || 'Failed')
      // this.toastr.error('Failed!')
    });
  }

  updateMember(payload, id, restId) {
    this.dataService.showSpinner()
    this.dataService.updateMember(payload, id, restId).subscribe(res => {
      this.dataService.hideSpinner()
      this.router.navigate(['admin-list/'+this.selectedOrg.storeId])
      this.toastr.success('Member has been updated successfully!')
      let orgs = JSON.parse(localStorage.getItem('organizations'));
      orgs.find(o=>o.selected).permissions = payload.permissions;
      localStorage.setItem('organizations', JSON.stringify(orgs))
      this.auth.setOrganizations();
    }, err => {
      this.dataService.hideSpinner()
      this.toastr.error(err?.error?.message || 'Failed')
      // this.toastr.error('Failed!')
    });
  }

  
  removeMember(id, restId) {
    this.dataService.showSpinner()
    this.dataService.removeMember(id, restId).subscribe(res => {
      this.dataService.hideSpinner()
      this.toastr.success('Member has been removed successfully!')
    }, err => {
      this.dataService.hideSpinner()
      // this.toastr.error('Failed!')
      this.toastr.error(err?.error?.message || 'Failed')
    });
  }
  async getAdmins(payload, id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getAdmins(payload, id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getAllAdmins(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getAllAdmins(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async deleteAdmin(id) {
  
    let promise = new Promise<boolean>((resolve, reject) => {
      this.dataService.deleteAdmin(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Admin has been deleted successfully!')
          resolve(true)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('Failed!')
          reject(false)
        });
    })
    
    let result = await promise;
    return result;
  }

  async blockAdmin(id, payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.blockAdmin(id, payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.toastr.success('Admin modified')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('Failed')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getSingleSubscription(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleSubscription(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
}
