import { Component, OnInit, OnChanges, ViewChild } from "@angular/core";
import { AuthService } from 'app/utilities/auth.service';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { StoreProfileComponent } from 'app/pages/settings/store/store-profile/store-profile.component';
import { DataServiceService } from 'app/utilities/data-service.service';
import { SidebarService } from 'app/sidebar.service';
import { ModalService } from "app/_modal";
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { InfoModalComponent } from "app/pages/products/info-modal/info-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { StoreService } from "app/pages/settings/store/store.service";

let lang = localStorage.getItem('lang') || 'en'
export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  permission: string;
}



// export const


@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ['sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  message:string;
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: 'lg'
  }
  lang = localStorage.getItem('lang') || 'en'
  expand = {'products': false, 'settings': false, 'help':false}
  public menuItems: any[];
  user;
  storeWebsite:any;
  store = {name:''};
  ROUTES: RouteInfo[] = [
    { path: "/dashboard", title: this.lang == 'en' ?  "Dashboard" : "لوحة القيادة", icon: "nc-chart-bar-32", class: "title", permission: 'dashboard' },
    { path: "/orders", title: this.lang == 'en' ? "Orders" : "الطلبات", icon: "nc-single-copy-04", class: "title",permission:"orders.list" },
    { path: "/add-product", title: this.lang == 'en' ? "Products" : "المنتجات", icon: "nc-box-2", class: "title", permission: 'product.list' },
    { path: "/customers-list", title: this.lang == 'en' ? "Customers" : "العملاء", icon: "nc-single-02", class: "title",permission:"customers.list" },
    { path: "/invoice-list", title: this.lang == 'en' ? "Invoice" : "الفواتير", icon: "nc-paper", class: "title", permission:"invoice.list" },
    { path: "/promotions-list", title: this.lang == 'en' ? "Promotions" : "الترقيات", icon: "nc-circle-10", class: "title", permission: "admin.list" },
    { path: "/settings", title: this.lang == "en" ? "Settings" : "الإعدادات", icon: "nc-settings", class: "title", permission: "store-settings.update" },
    { path: "/sellKer-university", title: this.lang == "en" ? "SellKer University" : "جامعة سيلكر", icon: "nc-button-play", class: "title", permission: "sellKer-university" },
    { path: "/live-chat", title: this.lang == "en" ? "Live Chat" : "الدردشة الحية", icon: "nc-chat-33", class: "title", permission: "live-chat.add" },
    { path: "/help", title: this.lang == "en" ? "Help" : "مساعدة", icon: "nc-support-17", class: "title", permission: "help" },
  ];
  productItems = [
    // {title: 'Add new', route: '/add-product/', permission: 'product.add'},
    {title: this.lang == 'en' ? 'All Products' : "جميع المنتجات", route: '/list-products',permission: 'product.list'},
    {title: this.lang == 'en' ? 'Archived Products' : "المنتجات المؤرشفة", route: '/products-archived',permission: 'product.list'},
    {title: this.lang == 'en' ? 'Categories' : "التصنيفات", route: '/product-categories',permission: 'categories.list'},
    {title: this.lang == 'en' ? 'Sub Categories' : "الفئات الفرعية", route: '/product-sub-categories',permission: 'sub-categories.list'},
    {title: this.lang == 'en' ? 'Brands' : "العلامات التجارية", route: '/brands-list',permission: 'sub-categories.list'},
    {title: this.lang == 'en' ? 'Tags' : "العلامات", route: '/products-tags',permission: 'tags.list'},
    {title: this.lang == 'en' ? 'Attributes' : "صفات", route: '/products-attributes',permission: 'attributes.list'},
    {title: this.lang == 'en' ? 'Import/Export' : "استيراد و تصدير", route: '/import-products',permission: 'importexport.add'}
  ]
  settingItems = [
    {title: this.lang == 'en' ? 'Store Settings' : "إعدادات المتجر", route: '/store-register',permission: 'store-settings.update'},
    {title: this.lang == 'en' ? 'Delivery Settings' : "إعدادات التسليم", route: '/delivery-settings',permission: 'delivery-settings.list'},
    {title: this.lang == 'en' ? 'User Management' : "إدارةالمستخدم", route: '/admin-list',permission: 'admin.list'},
    {title: this.lang == 'en' ? 'Manage Channels' : "إدارة القنوات", route: '/manage-channels', permission: 'manage-channels.add'},
    {title: this.lang == 'en' ? 'Policies' : "سياسات", route: '/policies', permission: 'policies.add'},
    //{title: this.lang == 'en' ? 'Payment Integrations' : "تكامل الدفع", route: '/payment-integrations',permission: 'payment-integration.update'},
    {title: this.lang == 'en' ? 'Apps & Plugins' : "التطبيقات والمكونات الإضافية", route: '/plugins-list', permission: 'apps-plugins.list'},
  ]
  constructor(private authenticationService: AuthService, private storeService: StoreService, private translate: TranslateService, private ngModalService: NgbModal, private titleService: Title, private modalService: ModalService, private sidebarService: SidebarService, private router: Router, private permissionService: NgxPermissionsService, private dataService: DataServiceService){
    //translate.setDefaultthis.Lang('ar');
  }
  org;
  newUser = false;
  async ngOnInit() {
    this.authenticationService.language.subscribe(res=>{
      this.lang = res;
        this.ROUTES = [
          { path: "/dashboard", title: this.lang == 'en' ?  "Dashboard" : "لوحة القيادة", icon: "nc-chart-bar-32", class: "title", permission: 'dashboard' },
          { path: "/orders", title: this.lang == 'en' ? "Orders" : "الطلبات", icon: "nc-single-copy-04", class: "title",permission:"orders.list" },
          { path: "/add-product", title: this.lang == 'en' ? "Products" : "المنتجات", icon: "nc-box-2", class: "title", permission: 'product.list' },
          { path: "/customers-list", title: this.lang == 'en' ? "Customers" : "العملاء", icon: "nc-single-02", class: "title",permission:"customers.list" },
          { path: "/invoice-list", title: this.lang == 'en' ? "Invoice" : "الفواتير", icon: "nc-paper", class: "title", permission:"invoice.list" },
          { path: "/promotions-list", title: this.lang == 'en' ? "Promotions" : "الترقيات", icon: "nc-circle-10", class: "title", permission: "admin.list" },
          { path: "/settings", title: this.lang == "en" ? "Settings" : "الإعدادات", icon: "nc-settings", class: "title", permission: "store-settings.update" },
          { path: "/sellKer-university", title: this.lang == "en" ? "SellKer University" : "جامعة سيلكر", icon: "nc-button-play", class: "title", permission: "sellKer-university" },
          { path: "/live-chat", title: this.lang == "en" ? "Live Chat" : "الدردشة الحية", icon: "nc-chat-33", class: "title", permission: "live-chat.add" },
          { path: "/help", title: this.lang == "en" ? "Help" : "مساعدة", icon: "nc-support-17", class: "title", permission: "help" },
        ];
        this.productItems = [
          // {title: 'Add new', route: '/add-product/', permission: 'product.add'},
          {title: this.lang == 'en' ? 'All Products' : "جميع المنتجات", route: '/list-products',permission: 'product.list'},
          {title: this.lang == 'en' ? 'Archived Products' : "المنتجات المؤرشفة", route: '/products-archived',permission: 'product.list'},
          {title: this.lang == 'en' ? 'Categories' : "التصنيفات", route: '/product-categories',permission: 'categories.list'},
          {title: this.lang == 'en' ? 'Sub Categories' : "الفئات الفرعية", route: '/product-sub-categories',permission: 'sub-categories.list'},
          {title: this.lang == 'en' ? 'Brands' : "العلامات التجارية", route: '/brands-list',permission: 'sub-categories.list'},
          {title: this.lang == 'en' ? 'Tags' : "العلامات", route: '/products-tags',permission: 'tags.list'},
          {title: this.lang == 'en' ? 'Attributes' : "صفات", route: '/products-attributes',permission: 'attributes.list'},
          {title: this.lang == 'en' ? 'Import/Export' : "استيراد و تصدير", route: '/import-products',permission: 'importexport.add'}
        ]
        this.settingItems = [
          {title: this.lang == 'en' ? 'Store Settings' : "إعدادات المتجر", route: '/store-register',permission: 'store-settings.update'},
          {title: this.lang == 'en' ? 'Delivery Settings' : "إعدادات التسليم", route: '/delivery-settings',permission: 'delivery-settings.list'},
          {title: this.lang == 'en' ? 'User Management' : "إدارةالمستخدم", route: '/admin-list',permission: 'admin.list'},
          {title: this.lang == 'en' ? 'Manage Channels' : "إدارة القنوات", route: '/manage-channels', permission: 'manage-channels.add'},
          {title: this.lang == 'en' ? 'Policies' : "سياسات", route: '/policies', permission: 'policies.add'},
          //{title: this.lang == 'en' ? 'Payment Integrations' : "تكامل الدفع", route: '/payment-integrations',permission: 'payment-integration.update'},
          {title: this.lang == 'en' ? 'Apps & Plugins' : "التطبيقات والمكونات الإضافية", route: '/plugins-list', permission: 'apps-plugins.list'},
        ]
        this.formatRoutes();
    })
    this.formatRoutes();
  }

  formatRoutes(){
    this.router.events.subscribe(e => {
      if(this.outlet){
        this.outlet?.deactivate();
      }

    });

    this.menuItems = this.ROUTES.filter((menuItem) => menuItem);
    this.authenticationService.setOrganizations();
    this.authenticationService.getOrganizations().subscribe(res=>{
      if(res && res.length){
        this.org = res.find(r=>r.selected)
        this.newUser = false;
        this.setRoutes(false)
      }
      else{
        if(!localStorage.getItem('superadmin')){
          this.newUser = true;
        }
        else{
          this.newUser = false;
        }
      }
    })
    this.sidebarService.getProfileObs()
    .subscribe(async message =>{
      if(message && message.role){

        let superAdmin = message?.role == 'superAdmin' ? true : false;
        let orgs = JSON.parse(localStorage.getItem('organizations')) || []
        this.org = orgs.find(o=>o.selected);
        if(this.org || (message && superAdmin)){

          this.setRoutes(superAdmin)

        }
        this.user= message
        if(this.user && this.user.role != 'superAdmin' && !this.newUser){
          this.user.features = this.org.permissions?.length ? this.org.permissions : [];
          let storeResponse = await this.storeService.getSingleStore(this.org.storeId)
          console.log({storeResponse})
    this.storeWebsite = storeResponse.store;
        }
        if(this.user && this.user.role != 'superAdmin'){
          this.permissionService.addPermission(this.user.features);
          this.permissionService.loadPermissions(this.user.features);
          if(this.user.storeId){
            this.dataService.getSingleStore(this.user.storeId).subscribe(single=>{
              this.store = single.store
              let user = this.authenticationService.getUser();

              user.storeName = single.store.name;

              this.authenticationService.setUser(user);
            })
          }
        }
        if(this.user?.role == 'superAdmin'){
          this.menuItems = [
            { path: "/dashboard", title: "Dashboard", icon: "nc-chart-bar-32", class: ""},
            { path: "/store-list", title: "Stores", icon: "nc-shop", class: ""},
            { path: "/orders/store/all", title: "Orders", icon: "nc-single-copy-04", class: ""},
            { path: "/admin-list/all", title: "Admins", icon: "nc-single-02", class: ""},
            { path: "/store-categories-list", title: "Store Categories", icon: "nc-tag-content", class: ""},
            { path: "/page-requests/all", title: "Page Requests", icon: "nc-paper", class: ""},
            { path: "/subscription-list", title: "Subscriptions", icon: "nc-bell-55", class: ""},
            { path: "/promotion-code-list", title: "Promotion Codes", icon: "nc-bell-55", class: ""},
            { path: "/blinq-transactions", title: "Blinq Transactions", icon: "nc-money-coins", class: ""},
            { path: "/integrations-list", title: "Integrations", icon: "nc-app", class: ""},
            { path: "/banks-list", title: "Banks", icon: "nc-bank", class: ""},
            { path: "/tutorial-video-list", title: "Tutorial Videos", icon: "nc-album-2", class: ""}
          ]
        }
      }
    });
  }

  setRoutes(superAdmin){
    this.settingItems.find(s=>s.title == 'User Management' || s.title == "إدارةالمستخدم").route = !superAdmin ? '/admin-list/'+this.org.storeId : '/admin-list/all';
        this.settingItems.find(s=>s.title == 'Store Settings' || s.title == "إعدادات المتجر").route = !superAdmin ? '/store-register/'+this.org.storeId : '/store-register/all';
        this.settingItems.find(s=>s.title == 'Delivery Settings' || s.title == "إعدادات التسليم").route = !superAdmin ? '/delivery-settings/'+this.org.storeId : '/delivery-settings/all';
        this.productItems.find(s=>s.title == 'Categories' || s.title == "التصنيفات").route = !superAdmin ? '/categories-list/'+this.org.storeId : '/categories-list/all';
        this.productItems.find(s=>s.title == 'Sub Categories' || s.title == "الفئات الفرعية").route = !superAdmin ? '/sub-categories-list/'+this.org.storeId : '/sub-categories-list/all';
        this.productItems.find(s=>s.title == 'Brands' || s.title == "العلامات التجارية").route = !superAdmin ? '/brands-list/'+this.org.storeId : '/brands-list/all';
        // this.productItems.find(s=>s.title == 'Add new' || s.title == ).route = !superAdmin ? '/add-product/'+this.org.storeId+'/new' : '/add-product/all';
        this.productItems.find(s=>s.title == 'All Products' || s.title == "جميع المنتجات").route = !superAdmin ? '/products-list/'+this.org.storeId : '/products-list/all';
        this.productItems.find(s=>s.title == 'Archived Products' || s.title == "المنتجات المؤرشفة").route = !superAdmin ? '/products-archived/'+this.org.storeId : '/products-archived/all';
        this.productItems.find(s=>s.title == 'Tags' || s.title == "العلامات").route = !superAdmin ? '/tags-list/'+this.org.storeId : '/tags-list/all';
        this.productItems.find(s=>s.title == 'Attributes' || s.title == "صفات").route = !superAdmin ? '/attributes-list/'+this.org.storeId : '/attributes-list/all';
        this.ROUTES.find(s=>s.title == 'Orders' || s.title == "الطلبات").path = !superAdmin ? '/orders/store/'+this.org.storeId : '/orders/store/all';
  }


logout(){
    if (confirm('Are you sure you want to log out?')) {
        this.authenticationService.logout();
        this.sidebarService.profileObs$.next(null)
        this.router.navigate(['login'])
    }
}
navToDashboard(){
  this.router.navigate(['dashboard'])
}

navToStoreProfile(){
  this.modalService.open('change-store')
}

expandTab(item){
  if(item){
    this.expand[item] = !this.expand[item];
  }
}
routeTo(link,title, prods:undefined){
  setTimeout(() => {
    this.router.navigateByUrl(link)
  }, 500);
  this.titleService.setTitle(prods ? 'Products - '+ title + ' - SellKer' : title+' - SellKer')
}

support(){
  const modalRef = this.ngModalService.open(InfoModalComponent, this.modalOptions);
  modalRef.componentInstance.modal = 'support';
}

visit(){
  window.open('https://'+this.storeWebsite.websiteDomain +'.sellker.com')
}
}


