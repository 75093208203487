import { Injectable } from '@angular/core';
import { DataServiceService } from '../../utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(private dataService: DataServiceService, private router: Router, private toastr: ToastrService) { }

  async getIntegrations(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getIntegrations(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async createIntegration(payload) {
    this.dataService.showSpinner()
    this.dataService.createIntegrations(payload)
      .subscribe(val => {        
        this.router.navigate(['integrations-list']);
        this.toastr.success('Integration has been added successfully!')
        this.dataService.hideSpinner()
      }, err => {
        this.toastr.error('Failed!')
        this.dataService.hideSpinner()
      });
  }

  async updateIntegration(id, payload) {
    this.dataService.showSpinner()
    this.dataService.updateIntegrations(id, payload)
      .subscribe(val => {
        // this.router.navigate(['store-list']);
        this.router.navigate(['integrations-list']);
        this.toastr.success('Integration has been updated successfully!')
        this.dataService.hideSpinner()
      }, err => {
        this.toastr.error('Update failed!')
        this.dataService.hideSpinner()
      });
  }

  async getSingleIntegration(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleIntegration(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

}
