import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router) { }
  
  async connectSocialAccountToUser(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.connectSocialAccountToUser(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()          
          this.toastr.success('Facebook Login Succesful been added successfully!');
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
      })
      let result = await promise;
      return result;
  }

  async getUserFbPages(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getUserFbPages(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()        
          this.toastr.success('Facebook Login Succesful been added successfully!')
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async addUserPage(payload,userID) {
    this.dataService.showSpinner()
    ///storeOwner/addPage/:id
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.addUserPage(userID,payload)
        .subscribe(val => {
          this.dataService.hideSpinner()        
          //this.toastr.success('Page have been added, Our team will contact you sortly');
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async addSocialPlatform(payload) {
    this.dataService.showSpinner()
    ///storeOwner/addPage/:id
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.addSocialPlatform(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()                  
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  

  async getStoreOwner(userID) {
    this.dataService.showSpinner()    
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreOwner(userID)
        .subscribe(val => {
          this.dataService.hideSpinner()        
          //this.toastr.success('Page have been added, Our team will contact you sortly');
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async updateStoreOwner(userID,payload) {
    this.dataService.showSpinner()
    this.dataService.updateStoreOwner(userID,payload)
      .subscribe(val => {
        this.dataService.hideSpinner()                
        this.router.navigate(['/onboarding/setup-account'])
        this.toastr.success('Profile is updated successfully!')
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error(err.error.error)
      });
  } 

  async updateStoreBasicInfo(payload) {
    this.dataService.showSpinner()
    ///storeOwner/addPage/:id
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateStoreBasicInfo(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()        
          this.toastr.success('Store information have been updated');
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  setStore(store){
    localStorage.setItem('tempStore',JSON.stringify(store))
  }

  getStore(){
    return JSON.parse(localStorage.getItem('tempStore'))
  }

  async updateStoreSubscription(payload) {
    this.dataService.showSpinner()
    ///storeOwner/addPage/:id
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateStoreSubscription(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()        
          this.toastr.success('Store information have been updated');
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
  
  async getInvoiceDetail(invoiceId) {
    this.dataService.showSpinner()
    ///storeOwner/addPage/:id
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSubInvoiceDetail(invoiceId)
        .subscribe(val => {
          this.dataService.hideSpinner()                  
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getByFbId(payload) {
    this.dataService.showSpinner()
    ///storeOwner/addPage/:id
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getByFbId(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()                  
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async createOwnerStoreThroughWebsite(payload) {
    this.dataService.showSpinner()
    ///storeOwner/addPage/:id
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.createOwnerStoreThroughWebsite(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()        
          this.toastr.success('Information is saved');
          resolve(val);
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error(err.error.error)
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
}

//_id: "5f8426b8680cfa0017a96413"