import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'
@Component({
  selector: 'app-quick-edit-modal',
  templateUrl: './quick-edit-modal.component.html',
  styleUrls: ['./quick-edit-modal.component.css']
})
export class QuickEditModalComponent implements OnInit {
  schedule = false;
  @Input() public product:any;
  editForm:any
  salePriceDate = {from: '', to: ''}
  constructor(public activeModal: NgbActiveModal) {
    // console.log(this.product)
  }
  
  
  ngOnInit(): void {
    this.editForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.product.name, Validators.required),
      availableStock: new UntypedFormControl(this.product.availableStock),
      isManageStock: new UntypedFormControl(this.product.isManageStock),
      regularPrice: new UntypedFormControl(this.product.regularPrice, Validators.required),
      salePrice: new UntypedFormControl(this.product.salePrice),
      stockStatus: new UntypedFormControl(this.product.stockStatus),
      sku: new UntypedFormControl(this.product.sku)
    })
    if(this.product.salePriceDate && this.product.salePriceDate.from){
      this.salePriceDate.from = moment(new Date(this.product.salePriceDate.from)).format('YYYY-MM-DD')          
      this.salePriceDate.to = this.product.salePriceDate.to ? moment(new Date(this.product.salePriceDate.to)).format('YYYY-MM-DD') : ''
      this.schedule = true;
    }
  }

  setDate(e,type){    
    this.salePriceDate[type] = e.target.value;
  }

  openSchedule(){
    this.schedule = !this.schedule;
    if(!this.schedule){
      this.salePriceDate.from = ''
      this.salePriceDate.to = ''
    }    
  }

  close(type){
    if(type == 'submit'){
      let data = this.salePriceDate.from ? {...this.editForm.getRawValue(),salePriceDate: this.salePriceDate} : this.editForm.getRawValue();
      this.activeModal.close(data)
    }
    else{
      this.activeModal.close()
    }
  }

}
