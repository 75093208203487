import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TutorialVideosService } from '../tutorial-videos.service';

@Component({
  selector: 'app-add-tutorial-video',
  templateUrl: './add-tutorial-video.component.html',
  styleUrls: ['./add-tutorial-video.component.css']
})
export class AddTutorialVideoComponent implements OnInit {
  urlID
  public validationMessages = {
    name: [{
      type: 'required', message: 'Email is required'
    }],
    detail: [{
      type: 'required', message: 'Details are required'
    }],
    videoLink: [{
      type: 'required', message: 'Video Link is required'
    }],
  }
  tutorialVidForm = this.formBuilder.group({
    name: ['', Validators.required],
    detail: ['', Validators.required],
    videoLink: ['', Validators.required],
  })
  constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private tutService: TutorialVideosService) { }

  ngOnInit(): void {    
    this.urlID = this.route.snapshot.params.id;
    if(this.urlID!='add'){
      this.patchForm()
    }
  }

  submit(){
    let payload = this.tutorialVidForm.getRawValue();
    this.tutService.createTutorialVideo(payload);
  }
  update(){
    let payload = this.tutorialVidForm.getRawValue();
    this.tutService.updateTutorialVideo(this.urlID, payload);
  }

  async patchForm(){
    let resp = await this.tutService.getSingleTutorialVideo(this.urlID)    
    this.tutorialVidForm.patchValue(resp.tutorialVideos)
  }

}