import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'app/_modal';
import { ConfirmationDialogueService } from 'app/confirmation-dialogue.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  @Input() public message;
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  close(type){
    this.activeModal.close(type)
  }
}