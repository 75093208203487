import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AttributesService } from 'app/pages/attributes/attributes.service';
import { ModalService } from 'app/_modal';
import { DeleteModalComponent } from '../../../../delete-modal/delete-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AttributeModalComponent } from '../../attribute-modal/attribute-modal.component';
@Component({
  selector: 'app-variable-product',
  templateUrl: './variable-product.component.html',
  styleUrls: ['./variable-product.component.css']
})
export class VariableProductComponent implements OnInit {

  @Input() urlId;
  @Input() type;
  @Input() singleProduct;
  @Input() addProduct;
  @Input() addedAttributes;  
  @Input() salePriceDate;  
  selectedAttribute:any;
  attributes:any;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  selectedOrg:any;
  orgs:any;
  showVariants = false;
  tempAttributes={}
  variantFormGroup:UntypedFormGroup = new UntypedFormGroup({
    variants: new UntypedFormArray([])
  })
  constructor(private attService: AttributesService, private modalService: ModalService,private ngModalService: NgbModal,) { }

  ngOnInit(): void {    
    this.orgs = JSON.parse(localStorage.getItem('organizations'))
      this.selectedOrg = this.orgs.find(o=>o.selected)
    this.getAttributes()
    
  }

  checkDisable(){
    return !this.selectedAttribute ? true : this.addedAttributes.find(a=>a._id == this.selectedAttribute?._id) ? true : false;
  }

  submit(){
    if(!this.addedAttributes.find(a=>a._id == this.selectedAttribute._id)){
      this.addedAttributes.push(this.selectedAttribute);
    }
    else{
      let index = this.addedAttributes.findIndex(a=>a._id == this.selectedAttribute._id)
      this.addedAttributes[index] = this.selectedAttribute;
    }
    this.modalService.close('add-product-attribute');    
  }

  removeAttribute(ind){
    let currentAttributes =  this.addedAttributes[ind];
    currentAttributes.usedForVariation = false;
    currentAttributes.isVisiblePage = false;
    for (let index = 0; index < currentAttributes.options.length; index++) {
      const element = currentAttributes.options[index];
      element.checked = false      
    }
    this.addedAttributes.splice(ind, 1)
  }

  editAttribute(id){
    this.selectedAttribute = JSON.parse(JSON.stringify(this.addedAttributes.find(a=>a._id == id)))
    // this.modalService.open('add-product-attribute');
    this.showModal();
  }

  checkForAttributes(){
    if(!this.addedAttributes.length){
      return false
    }
    if(this.addedAttributes.find(a=>!a.options.filter(o=>o.checked).length && !a.usedForVariation)){
      return false;
    }
    else{
      return true;
    }
  }

  async getAttributes(){
    // console.log(this.singleProduct)
    let response = await this.attService.getAttributes(this.selectedOrg.storeId);
    if(response){
      this.attributes = response.attributes;
      // console.log(this.attributes)
      if(this.urlId!='new' && this.singleProduct.productType == 'variable'){
        if(this.singleProduct.productVariationCout > 0){
          let variantFormArray:any = this.variantFormGroup.get('variants') as UntypedFormArray
          variantFormArray.clear();
          for (let index = 0; index < this.singleProduct.productVariation.length; index++) {          
            variantFormArray.push(this.createVariantFormGroupPatch(this.singleProduct.productVariation[index]))
          }          
              this.attributes.forEach((att,ind)=>{
                let foundProduct = this.singleProduct.attributes.find(a=>a.attributeId == att._id);                
                if(foundProduct){
                  this.addedAttributes.push({...att, isVisiblePage: foundProduct.isVisiblePage, usedForVariation: foundProduct.usedForVariation})
                  this.addedAttributes[this.addedAttributes.length-1].options.forEach(o=>o.checked = false)
                  foundProduct.attributeValue.forEach((op,j)=>{
                      let i = this.addedAttributes[this.addedAttributes.length-1].options.findIndex(o=>o._id == op.optionId);
                      if(i!=-1){
                        this.addedAttributes[this.addedAttributes.length-1].options[i].checked = true;
                      }
                  })
                }
              })
        }
      }
      // console.log(this.addedAttributes)
    }
   }
 
   optionChanged(e, i){
     const checked = e.target.checked;
     this.selectedAttribute.options[i].checked = checked;
   }
 
   attributeSelected(e){
     this.selectedAttribute = this.attributes.find(a=>a._id == e.target.value);
     this.selectedAttribute.usedForVariation = false;
     this.selectedAttribute.isVisiblePage = false;
     this.selectedAttribute.options.forEach(o=>{
       o.checked = false;
     })
   }

   addVariations(){
     if(this.variantFormGroup.get('variants')['controls'].length){
       const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
       modalRef.componentInstance.message = 'Your data will be removed. Are you sure you want to add variations?';
       modalRef.result.then(async (result) => {
         if (result == 'yes') {
           this.resetVariations()
         }
       });
     }
     else{
       this.resetVariations();
     }
   }

   resetVariations(){
    this.showVariants = true;
    //console.log(this.addedAttributes)
    this.tempAttributes = {};
    for (let index = 0; index < this.addedAttributes.length; index++) {
      let currentAttributes = this.addedAttributes[index];
      this.tempAttributes[currentAttributes.name] = [];
      for (let k = 0; k < currentAttributes.options.length; k++) {
        if(currentAttributes.options[k].checked){
          //this.tempAttributes[currentAttributes.name].push(currentAttributes.options[k])
          this.tempAttributes[currentAttributes.name].push({name:currentAttributes.options[k].name})
        }
        
      }
      if(index == this.addedAttributes.length-1){
        this.createProductVariations(this.tempAttributes)
      }            
    }
   }

   usedChanged(e){
     const checked = e.target.checked;
     this.selectedAttribute.usedForVariation = checked
   }
   visibleChanged(e){
     const checked = e.target.checked;
     this.selectedAttribute.isVisiblePage = checked
   }
 
   openModal(){
    //  this.modalService.open('add-product-attribute')
     this.showModal()
   }
 
   close(){
     this.modalService.close('add-product-attribute')
   }

   createProductVariations(productAttributes){    
    let variantFormArray = this.variantFormGroup.get('variants') as UntypedFormArray
    variantFormArray.clear();
      let attrs = [];
      for (const property in productAttributes) {
         attrs.push(productAttributes[property].map(v => ({[property]:v['name']})));        
        //attrs.push(productAttributes[property].map(v => ({attributeName:property, attributeId:v['_id'], attributeValue:v['name']})));        
      }

      attrs = attrs.reduce((a, b) => a.flatMap(d => b.map(e => ({...d, ...e}))));

      let finalVariants = [];
      for (let i = 0; i < attrs.length; i++) {
        const currentVariant = attrs[i];
        let tempVariant = [];
        let entrieVariant = Object.entries(currentVariant);        
        for(let [p, [key, value]] of entrieVariant.entries()){
          for (let j = 0; j < this.addedAttributes.length; j++) {
            const currentAttributes = this.addedAttributes[j];
            if(currentAttributes.name == key){
              for (let k = 0; k < currentAttributes.options.length; k++) {
                const currentOption = currentAttributes.options[k];
                if(currentOption.name == currentVariant[key]){
                  tempVariant.push({attributeName:key, attributeId:currentAttributes._id, attributeValue:currentOption.name, optionId: currentOption._id})
                }
              }
            }
          }
          if(p == entrieVariant.length - 1){
            //finalVariants.push(tempVariant);
            variantFormArray.push(this.createVariantFormGroup(tempVariant))
          }
        }
        if(i == attrs.length-1){
          //console.log(variantFormArray)
        }
      }
   }

   createVariantFormGroup(variationKeys): UntypedFormGroup{
    let currentForm = new UntypedFormGroup({          
      'variationKeys': new UntypedFormArray([],Validators.required),
      'sku': new UntypedFormControl(''),
      'regularPrice': new UntypedFormControl('', Validators.required),
      'salePrice': new UntypedFormControl(''),
      'salePriceDate': new UntypedFormControl(''),
      'isOnSale': new UntypedFormControl(false, Validators.required),
      'displayPicture': new UntypedFormControl('', Validators.required),
      'isActive': new UntypedFormControl(true),
      'stockStatus': new UntypedFormControl(false),
      'availableStock': new UntypedFormControl(''),
      'isManageStock': new UntypedFormControl(false),
      'picturegallery': new UntypedFormArray([])
    })    
    const currentFormArray = currentForm.get('variationKeys') as UntypedFormArray
    for (let index = 0; index < variationKeys.length; index++) {
      const element = variationKeys[index];
      currentFormArray.push(new UntypedFormGroup({
        'attributeName': new UntypedFormControl(element.attributeName, Validators.required),
        'attributeId': new UntypedFormControl(element.attributeId, Validators.required),
        'attributeValue': new UntypedFormControl(element.attributeValue, Validators.required),
        'optionId': new UntypedFormControl(element.optionId, Validators.required)
      }))  
    }
    
    return currentForm;
  }

  createVariantFormGroupPatch(productVariation): UntypedFormGroup{
    let currentForm = new UntypedFormGroup({          
      // 'id': new FormControl(productVariation._id),
      'variationKeys': new UntypedFormArray([],Validators.required),
      'sku': new UntypedFormControl(productVariation.sku),
      'regularPrice': new UntypedFormControl(productVariation.regularPrice, Validators.required),
      'salePrice': new UntypedFormControl(productVariation.salePrice),
      'salePriceDate': new UntypedFormControl(productVariation.salePriceDate),
      'isOnSale': new UntypedFormControl(productVariation.isOnSale, Validators.required),
      'displayPicture': new UntypedFormControl(productVariation.displayPicture, Validators.required),
      'isActive': new UntypedFormControl(productVariation.isActive),
      'stockStatus': new UntypedFormControl(productVariation.stockStatus),
      'availableStock': new UntypedFormControl(productVariation.availableStock),
      'isManageStock': new UntypedFormControl(productVariation.isManageStock),
      'picturegallery': new UntypedFormArray([])
    })    
    if(this.type == 'update'){
      currentForm.addControl('id', new UntypedFormControl(productVariation._id))
    }
    const currentFormArray = currentForm.get('variationKeys') as UntypedFormArray
    for (let index = 0; index < productVariation.variationKeys.length; index++) {
      const element = productVariation.variationKeys[index];
      currentFormArray.push(new UntypedFormGroup({
        'attributeName': new UntypedFormControl(element.attributeName, Validators.required),
        'attributeId': new UntypedFormControl(element.attributeId, Validators.required),
        'attributeValue': new UntypedFormControl(element.attributeValue, Validators.required),
        'optionId': new UntypedFormControl(element.optionId, Validators.required)
      }))  
    }

    const picturegalleryFormArray = currentForm.get('picturegallery') as UntypedFormArray
    if(productVariation?.picturegallery?.length > 0){
      for (let index = 0; index < productVariation?.picturegallery?.length; index++) {
        const element = productVariation.picturegallery[index];
        picturegalleryFormArray.push(new UntypedFormControl(element))  
      }
    }
    
    
    return currentForm;
  }

  getFormStatus(){
    return this.variantFormGroup
  }

  showModal(){
    const modalRef = this.ngModalService.open(AttributeModalComponent,this.modalOptions);
    modalRef.componentInstance.selectedAttribute = this.selectedAttribute;
    modalRef.componentInstance.type = 'variable';
    modalRef.result.then(async (result) => {
      if (result == 'submit') {
        if(!this.addedAttributes.find(a=>a._id == this.selectedAttribute._id)){
          this.addedAttributes.push(this.selectedAttribute);
        }
        else{
          let index = this.addedAttributes.findIndex(a=>a._id == this.selectedAttribute._id)
          this.addedAttributes[index] = this.selectedAttribute;
        }
      }
    });
  }

}
