import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { WebSocketService } from 'app/utilities/webSocket.service';
import { StoreService } from '../settings/store/store.service';
import { LiveChatService } from './live-chat.service';

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.css']
})
export class LiveChatComponent implements OnInit {

  conversations:any = [];
  allConversations:any = [];
  org:any;
  selectedConversation;
  selectedConversationInfo;
  platform = "MESSENGER";
  platforms = ['MESSENGER','INSTAGRAM','all'];
  msg;
  isLive = true;
  sending = false;
  messagesCount;
  participantIds = [];
  messageReceived;
  storeId;
  user;
  store;
  constructor(private livechatService: LiveChatService, private dataService: DataServiceService, private webSocketService: WebSocketService, private storeService: StoreService) { }



  async ngOnInit(): Promise<void> {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    let storeResponse = await this.storeService.getSingleStore(this.org.storeId)
    this.store = storeResponse.store;

    this.getConversations();
    this.webSocketService.newMessageReceived().subscribe((data:any) => {
      //console.log({data})
      this.mapMessage(data);
    })

  }

  getConversations(){
    this.selectedConversationInfo = undefined
    this.selectedConversation = undefined
    let body = {
      storeId: this.org.storeId,
      platform: this.platform || undefined,
      pointer: null
    }
    this.livechatService.getConversations(body,this.livechatService.getMessage()).then(res=>{
      this.conversations = res.conversations.data;
      this.allConversations = JSON.parse(JSON.stringify(res.conversations.data));
      this.conversations.forEach(convo=>{
        this.participantIds[convo.participants.data.find(p=>p.isCustomer).id] = convo.id;
      })
      if(this.livechatService.getMessage()){
        this.dataService.showSpinner()
        let convoId = this.participantIds[this.livechatService.getMessage().userId];
        let tempConvo = this.conversations.find(c=>c.id == convoId);
        this.selectConversation(tempConvo)
      }
    },err=>{
      //console.log(err)
    })
  }

  searchConvo(e){
    let val = e.target.value.toLowerCase();
    //console.log(val,this.allConversations.find(c=>c.participants.data[0].name.includes(val)), this.allConversations)
    if(this.allConversations.find(c=>c.participants.data[0].name.toLowerCase().includes(val))){
      this.conversations = JSON.parse(JSON.stringify(this.allConversations.filter(c=>c.participants.data[0].name.toLowerCase().includes(val))))
    }
  }

  mapMessage(data){
    if(this.selectedConversation && this.selectedConversation.participantId == data.userId){
      this.selectedConversation.data.push({from:{name:this.selectedConversation.name}, message: data.text})
      setTimeout(() => {
        let elem = document.getElementById("convomsgs")
        elem.scrollTop = elem.scrollHeight;
      }, 100);
    }
    else{
      let id = this.participantIds[data.userId];
      if(id){
        this.messagesCount = {...this.messagesCount,
          [id]: this.messagesCount && this.messagesCount[id] ? this.messagesCount[id]+1 : 1
        }
      }
      else{
        this.getConversations()
      }
    }
  }

  selectConversation(convo){
    let body = {
      storeId: this.org.storeId,
      pointer: null,
      conversationId: convo.id
    }
    this.selectedConversationInfo = convo;
    this.livechatService.getConversationMessages(body).then(res=>{
      let tempConvo = this.conversations.find(c=>c.id == convo.id);
      this.selectedConversation = {name: tempConvo.participants.data[0].name, id: convo.id, participantId: tempConvo.participants.data.find(d=>d.isCustomer).id, data:res.conversation.data.reverse()};

      if(this.messagesCount && this.messagesCount[this.selectedConversation.id]){
        delete this.messagesCount[this.selectedConversation.id];
      }
      if(this.livechatService.getMessage()){
        this.messageReceived = this.livechatService.getMessage();
        this.mapMessage(this.messageReceived);
        this.livechatService.setMessage('');
      }
    },err=>{
     // console.log(err)
    })
  }

  selectPlatform(plat){
    this.platform = plat;
    this.platforms.forEach(p=>{
      document.getElementById(p).classList.remove('selected')
    })
    plat ? document.getElementById(plat).classList.add('selected') : document.getElementById('all').classList.add('selected')
    this.getConversations()
  }

  sendMessage(){
    this.sending = true;
    let body = {
      text: this.msg,
      storeId: this.org.storeId,
      platformId: this.selectedConversation.id
    }
    this.livechatService.sendMessage(body).then(res=>{
      this.selectedConversation.data.push({from:{name:this.org.storeName}, message: this.msg})
      this.msg = '';
      this.sending = false;
      let elem = document.getElementById("convomsgs")
      elem.scrollTop = elem.scrollHeight;
    },err=>{
     // console.log(err)
    })
  }

  messaging(e){
   e.preventDefault();
   this.sendMessage()
  }

  getTextAccordingtoScreen(value,count){
    value = value.trim()
    if(value?.length >= count){
      return value.slice(0, count) + '...';
    }else{
      return value
    }
  }

  getCustomerInfo(participants){
    let currentCustomer;
    for (let index = 0; index < participants.length; index++) {
      if(participants[index].isCustomer){
        currentCustomer = participants[index]
      }

      if(index == participants.length-1){
        return currentCustomer;
      }
    }
  }

  toggleLive(){
    this.isLive = !this.isLive;
  }

  platformExists(platform){
    return this.store?.platforms.includes(platform) ? true : false;
  }
}

