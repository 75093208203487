import { Component, OnInit } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { CustomerService } from 'app/pages/customer/customer.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bot-customers-list',
  templateUrl: './bot-customers-list.component.html',
  styleUrls: ['./bot-customers-list.component.css']
})
export class BotCustomersListComponent implements OnInit {
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  block =false;
  customers;
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
    fields: "customerName email createdAt"
  }
  constructor(private dataService: DataServiceService,private permissionService: NgxPermissionsService,private translate: TranslateService, private customerService: CustomerService, private router: Router, private toastr: ToastrService) {

   }

  async ngOnInit(): Promise<void> {
    let response = await this.customerService.getCustomers(this.payload);
    this.customers = response.customers;
    this.totalRecords = response.totalRecord;
    this.createPagination()
    this.customers.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })

  }

  async sort(SortName: String) {
    if (SortName == 'customerName') {
      this.payload.sort = { "customerName": 1 }
    }
    if (SortName == 'email') {
      this.payload.sort = { "email": 1 }
    }
    if (SortName == 'createdAt') {
      this.payload.sort = { "createdAt": 1 }
    }
    let response = await this.customerService.getCustomers(this.payload);
    this.customers = response.customers;
    this.totalRecords = response.totalRecord;
    this.createPagination()
    this.customers.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  async goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    let response = await this.customerService.getCustomers(this.payload);
    this.customers = response.customers;
    this.totalRecords = response.totalRecord;
    this.createPagination()
    this.customers.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })
  }

  async reset() {
    this.payload.sort = {}
    let response = await this.customerService.getCustomers(this.payload);
    this.customers = response.customers;
    this.totalRecords = response.totalRecord;
    this.createPagination()
    this.customers.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })
  }

  toggleBlock(id){
    let index = _.findIndex(this.customers, {_id: id});
    this.block = !this.customers[index].block
    let payloadBlock = {
      block: this.block
    }
    this.customerService.blockCustomer(id, payloadBlock)
  }

  updateCustomer(id){
    this.router.navigate(['bot-customer-profile/'+id])
  }

  showOrders(id){
    this.router.navigate(['bot-orders-list/customer/'+id])
  }

  counter(i) {
    return new Array(i)
  }

}
