import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
// import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { AuthService } from 'app/utilities/auth.service';
import { SidebarService } from 'app/sidebar.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ModalService } from 'app/_modal';
import { MessagingService } from 'app/messaging.service';
import { SocialAuthService } from 'angularx-social-login';
import { TranslateService } from '@ngx-translate/core';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit{
  language = localStorage.getItem('lang') == 'en' ? 'English' : 'عربي'
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;

    public isCollapsed = true;
    @ViewChild("navbar-cmp", {static: false}) button;
    store:any
    user:any;
    lang = localStorage.getItem('lang') || 'en'
    superAdmin
    ROUTES = [
      { path: "/dashboard", title: this.lang == 'en' ?  "Dashboard" : "لوحة القيادة", icon: "nc-chart-bar-32", class: "title", permission: 'dashboard' },
      { path: "/orders", title: this.lang == 'en' ? "Orders" : "الطلبات", icon: "nc-single-copy-04", class: "title",permission:"orders.list" },
      { path: "/add-product", title: this.lang == 'en' ? "Products" : "المنتجات", icon: "nc-box-2", class: "title", permission: 'product.list' },
      { path: "/customers-list", title: this.lang == 'en' ? "Customers" : "العملاء", icon: "nc-single-02", class: "title",permission:"customers.list" },
      { path: "/invoice-list", title: this.lang == 'en' ? "Invoice" : "الفواتير", icon: "nc-paper", class: "title", permission:"invoice.list" },
      { path: "/promotions-list", title: this.lang == 'en' ? "Promotions" : "الترقيات", icon: "nc-circle-10", class: "title", permission: "admin.list" },
      { path: "/settings", title: this.lang == "en" ? "Settings" : "الإعدادات", icon: "nc-settings", class: "title", permission: "store-settings.update" },
      { path: "/sellKer-university", title: this.lang == "en" ? "SellKer University" : "جامعة سيلكر", icon: "nc-button-play", class: "title", permission: "sellKer-university" },
      { path: "/live-chat", title: this.lang == "en" ? "Live Chat" : "الدردشة الحية", icon: "nc-chat-33", class: "title", permission: "live-chat.add" },
      { path: "/help", title: this.lang == "en" ? "Help" : "مساعدة", icon: "nc-support-17", class: "title", permission: "help" },
    ];
    constructor(location:Location, private modalService : ModalService, private messagingService: MessagingService, private element : ElementRef, private authService: AuthService, private sidebarService: SidebarService,private dataService: DataServiceService, private router: Router, private socialAuthService: SocialAuthService, private translate: TranslateService) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    // ngOnInit(){
    //     this.listTitles = ROUTES.filter(listTitle => listTitle);
    //     var navbar : HTMLElement = this.element.nativeElement;
    //     this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    //     this.router.events.subscribe((event) => {
    //       this.sidebarClose();
    //    });
    // }
    ngOnInit(){
      this.authService.language.subscribe(res=>{
        this.lang = res;
        console.log(this.lang)
        this.ROUTES = [
          { path: "/dashboard", title: this.lang == 'en' ?  "Dashboard" : "لوحة القيادة", icon: "nc-chart-bar-32", class: "title", permission: 'dashboard' },
          { path: "/orders", title: this.lang == 'en' ? "Orders" : "الطلبات", icon: "nc-single-copy-04", class: "title",permission:"orders.list" },
          { path: "/add-product", title: this.lang == 'en' ? "Products" : "المنتجات", icon: "nc-box-2", class: "title", permission: 'product.list' },
          { path: "/customers-list", title: this.lang == 'en' ? "Customers" : "العملاء", icon: "nc-single-02", class: "title",permission:"customers.list" },
          { path: "/invoice-list", title: this.lang == 'en' ? "Invoice" : "الفواتير", icon: "nc-paper", class: "title", permission:"invoice.list" },
          { path: "/promotions-list", title: this.lang == 'en' ? "Promotions" : "الترقيات", icon: "nc-circle-10", class: "title", permission: "admin.list" },
          { path: "/settings", title: this.lang == "en" ? "Settings" : "الإعدادات", icon: "nc-settings", class: "title", permission: "store-settings.update" },
          { path: "/sellKer-university", title: this.lang == "en" ? "SellKer University" : "جامعة سيلكر", icon: "nc-button-play", class: "title", permission: "sellKer-university" },
          { path: "/live-chat", title: this.lang == "en" ? "Live Chat" : "الدردشة الحية", icon: "nc-chat-33", class: "title", permission: "live-chat.add" },
          { path: "/help", title: this.lang == "en" ? "Help" : "مساعدة", icon: "nc-support-17", class: "title", permission: "help" },
        ];
      })
      //this.translate.use('en')
      if(this.authService.getSuperAdmin()){
        this.superAdmin = this.authService.getSuperAdmin();
      }
      this.listTitles = this.ROUTES.filter(listTitle => listTitle);
      var navbar : HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
      this.router.events.subscribe((event) => {
        this.sidebarClose();
     });
     this.sidebarService.getProfileObs().subscribe(user=>{
      this.user = user;
      if(this.user && this.user.storeId){
        this.dataService.getSingleStore(this.user.storeId).subscribe(single=>{
          this.store = single.store
        })
      }
    })
  }
    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
      }
      sidebarOpen() {
          const toggleButton = this.toggleButton;
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          setTimeout(function(){
              toggleButton.classList.add('toggled');
          }, 500);

          html.classList.add('nav-open');
          if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
          }
          this.sidebarVisible = true;
      };
      sidebarClose() {
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          if (window.innerWidth < 991) {
            setTimeout(function(){
              mainPanel.style.position = '';
            }, 500);
          }
          this.toggleButton.classList.remove('toggled');
          this.sidebarVisible = false;
          html.classList.remove('nav-open');
      };
      collapse(){
        this.isCollapsed = !this.isCollapsed;
        const navbar = document.getElementsByTagName('nav')[0];

        if (!this.isCollapsed) {
          navbar.classList.remove('navbar-transparent');
          navbar.classList.add('bg-white');
        }else{
          navbar.classList.add('navbar-transparent');
          navbar.classList.remove('bg-white');
        }

      }

      openNotiModal(){
        this.modalService.open('show-noti')
      }

      close(){
        this.modalService.close('show-noti')
      }

      logout(){
        if (confirm('Are you sure you want to log out?')) {
          if(localStorage.getItem('pushedToken')){
            this.messagingService.pullToken('logout');
          }
          else{
            if(this.user && this.user.fbId){
              this.socialAuthService.signOut()
            }
            let currentLang = localStorage.getItem('lang')
            localStorage.clear()
            localStorage.setItem('lang', currentLang)

            this.sidebarService.profileObs$.next(null)
            this.router.navigate(['login'])
          }
      }
      }

      setLanguage(lang){
        this.language = lang == 'ar' ? 'عربي' : 'English';
        this.authService.setLanguage(lang)
      }

}
