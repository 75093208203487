import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { validatePassword } from 'app/validators/validatePassword';
import { DataServiceService } from 'app/utilities/data-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/utilities/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public validationMessages = {
    currentPassword: [{
      type: 'required', message: 'Old password is required'
    }],
    newPassword: [{
      type: 'required', message: 'New password is required'
    }],
    confirmPassword: [{
      type: 'required', message: 'Confirm new password'
    },
    {
      type: 'mustMatch', message: 'Passwords do not match'}
    ]
  }

  changePassForm = this.formBuilder.group({
    currentPassword: ['', Validators.required],
    newPassword: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  },
  {
    validator: validatePassword('newPassword', 'confirmPassword')
  })
  constructor(private formBuilder: UntypedFormBuilder,private authenticationService: AuthService , private dataService: DataServiceService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  submit(){
    this.authenticationService.changePassword(this.changePassForm.value)
  }

}
