import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';

@Injectable({
  providedIn: 'root'
})
export class PageRequestService {

  constructor(
    private dataService: DataServiceService
  ) { }

  async allPageRequests() {
    return await new Promise<any>((resolve, reject) => {
      this.dataService.getPageRequests()
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        })
    })
  }

  async approvePageRequest(payload) {
    return await new Promise<any>((resolve, reject) => {
      this.dataService.setPageApproved(payload)
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        })
    })
  }

  /* async activatePageRequest(payload) {
    return await new Promise<any>((resolve, reject) => {
      this.dataService.setPageActivated(payload)
        .subscribe(val => {
          resolve(val)
        }, err => {
          reject(err)
        })
    })
  } */
}
