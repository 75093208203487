import { Injectable } from '@angular/core';
import { DataServiceService } from '../../utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService) { }

  async getInvoiceList(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getInvoiceList(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async makePayment(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.makePayment(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          //this.toastr.success('PAID')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('FAILED')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async upgradeSubscriptionForStore(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.upgradeSubscriptionForStore(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          //this.toastr.success('PAID')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('FAILED')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getUserOutstandingAndCurrentInvoice(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getUserOutstandingAndCurrentInvoice(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          //this.toastr.success('PAID')
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('FAILED')
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
}
