
import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TutorialVideosService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router) { }



  async getSingleTutorialVideo(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleTutorialVideos(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async createTutorialVideo(payload) {
    this.dataService.showSpinner()
    this.dataService.createTutorialVideos(payload)
      .subscribe(val => {
        this.router.navigate(['tutorial-video-list/'+payload.storeId]);
        this.toastr.success('Tutorial Video has been added successfully!')
        this.dataService.hideSpinner()
        this.router.navigate(['tutorial-video-list'])
      }, err => {
        this.toastr.error(err?.error?.error || 'Failed!')
        this.dataService.hideSpinner()
      });
  }

  async updateTutorialVideo(id, payload) {
    this.dataService.showSpinner()
    this.dataService.updateTutorialVideos(id, payload)
      .subscribe(val => {
        // this.router.navigate(['store-list']);
        this.router.navigate(['tutorial-video-list/'+payload.storeId]);
        this.toastr.success('Tutorial Video has been updated successfully!')
        this.dataService.hideSpinner()
        this.router.navigate(['tutorial-video-list'])
      }, err => {
        this.toastr.error('Update failed!')
        this.dataService.hideSpinner()
      });
  }


  
}
