import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ProductService } from '../product.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-archived',
  templateUrl: './products-archived.component.html',
  styleUrls: ['./products-archived.component.scss']
})
export class ProductsArchivedComponent implements OnInit {

  constructor(private dataService: DataServiceService, private translate: TranslateService, private toastr: ToastrService, private ngModalService: NgbModal, private productService: ProductService, public router: Router, private route: ActivatedRoute) {

   }
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  urlId;
  search = false;
  searchValue = ''
  NoOfPages = 0;
  status;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  shareProduct;
  loader=false;
  payload:any = {
    "filters": {storeId: this.route.snapshot.params.id},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
  }
  products: any = [];
  ngOnInit(): void {
    this.urlId = this.route.snapshot.params.id;
    this.getProducts()
  }


  getProducts(){
    this.loader = true;
    // this.payload.filters = {storeId: this.urlId}
    this.dataService.showSpinner();
    this.dataService.getArchivedProducts(this.payload).subscribe(res=>{
     // console.log({res})
      this.loader = false;
      this.products = res.product;
      this.dataService.hideSpinner();
      this.totalRecords = res.totalRecord;
      this.createPagination();
    },err=>{
      this.loader = false;
    })
  }


  searchProducts(type = undefined){
    if(type == 'clear' || (type && !this.searchValue)){
      delete this.payload.filters.name;
      this.searchValue = ''
      this.getProducts();
      this.search = type == 'clear' ? false : true;
      return;
    }
    if(!type || type.keyCode == 13){
      if(this.search && this.searchValue){
        let currentText = this.searchValue.trim()
        let currentTextArray = currentText.split(' ').map(item=>item.trim());
        let currentTextExtras = currentTextArray.map(item=>item.replace(/(?:es|[sx])$/, ''))
        currentTextArray = currentTextArray.concat(currentTextExtras);
        currentText = currentTextArray.map(item=>item.trim()).join("|")
        this.pageStart = 0;
        this.payload.pagination.start = 0;
        this.payload.filters = {...this.payload.filters, name:{'$regex': currentText, '$options':'i'}}

        this.getProducts()
      }
      else{
        this.search = true
      }
    }
  }

  async restoreProduct(id){
    let payload = {storeId: this.urlId}
    //console.log('hereee',payload)
    let res = await this.productService.restoreProduct(payload,id)
    //console.log({res})
    if(res.code == 200){
      this.toastr.success('Product has been restored')
      this.getProducts();
    }
    else{
      this.toastr.error(res.message)
    }
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  async goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.getProducts()
  }

  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.payload.pagination.limit = this.pageLimit;
    this.ngOnInit();
  }

  counter(i) {
    return new Array(i)
  }

}
