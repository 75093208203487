import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { MessagingService } from './messaging.service';
import { ModalService } from './_modal';
import { ConfirmationDialogueService } from './confirmation-dialogue.service';
import { DataServiceService } from './utilities/data-service.service';
import { SidebarService } from './sidebar.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './utilities/auth.service';
import { ToastrService } from 'ngx-toastr';
import { WebSocketService } from './utilities/webSocket.service';
import { AlertComponent } from './shared/_alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    message;
    show;
    orgs = [];
    permissions = [];
    subscribed = false;
    lang = 'en'

constructor(private messagingService: MessagingService, private authService: AuthService, private alertComponent: AlertComponent, private translate: TranslateService, private toastr: ToastrService, private dataService: DataServiceService, private sidebar: SidebarService, private permissionService: NgxPermissionsService, private router: Router, private modalService: ModalService, private auth: AuthService, private webSocketService: WebSocketService, protected $gaService: GoogleAnalyticsService, private titleService: Title, private route: ActivatedRoute) {
  // localStorage.setItem('lang','en')
  //Code to Track Page View
  // this.router.events.pipe(
  //   filter(event => event instanceof NavigationEnd)
  // ).subscribe((event: NavigationEnd) => {
  //   const pageTitle = this.getPageTitle(this.route.root);
  //   console.log('Page Title:', pageTitle);
  //    this.$gaService.pageView(event.urlAfterRedirects, 'Teste de Title')
  // })

  this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Extract the page title from the route data
      const pageTitle = this.getPageTitle(this.route.root);
      this.$gaService.pageView(event.urlAfterRedirects, pageTitle)
    });
}
async ngOnInit() {
  // localStorage.setItem('lang',this.lang)
  this.authService.setLanguage(localStorage.getItem('lang') || this.lang)

  this.message = this.messagingService.currentMessage
  if((!localStorage.getItem('superAdmin')) && localStorage.getItem('access_token')){
    if(!localStorage.getItem('pushedToken')){
      this.subscribed = false
      this.messagingService.subscribed.next(false)
      this.messagingService.requestPermission()
    }
    else{
      this.subscribed = true
      this.messagingService.subscribed.next(true)
      this.messagingService.receiveMessage();
    }
  }
  this.messagingService.subscribed.subscribe(val=>{
    this.subscribed = val;
  })
  this.auth.setOrganizations();
  if (!this.auth.getUser()) {
    await this.dataService.getUser().subscribe(async response => {
      // this.permissionService.addPermission(response.user.features);
      // this.permissionService.loadPermissions(response.user.features);
      let res = response.user;
        if(!res.role){
          res.role = 'admin'
        }
      this.auth.setUser(res)
      this.sidebar.setProfileObs(res);
    })
  }
  // if(this.auth.getOrganizations().subscribe(res=>res.length>0)){
    this.auth.getOrganizations().subscribe(res=>{
      if(res){
        this.orgs = res;
        let resId = res.find(r=>r.selected).storeId
        let userId = this.auth.getUser()?._id;
        if(userId){
        this.dataService.getPermissions(userId, resId).subscribe(res=>{
          this.permissions = res.orgDoc.permissions;
          this.permissions.push('home')
          this.permissionService.addPermission(this.permissions);
          this.permissionService.loadPermissions(this.permissions);
        },(err)=>{
          //console.log(err)
        })
      }
      }
    })
}

selectStore(id){
  delete this.orgs.find(o=>o.selected).selected;
  this.orgs.find(o=>o.storeId == id).selected = true;
  localStorage.setItem('organizations', JSON.stringify(this.orgs))
  this.auth.setOrganizations();
  this.alertComponent?.clearAll()
  setTimeout(() => {
    this.modalService.close('change-store')
    this.router.navigate(['dashboard'])
    this.dataService.setOrgObs();
    let userId = this.auth.getUser()?._id;
    this.webSocketService.switchMyStore({storeId:id, agentId:userId})
  }, 1000);


}

addStore(){
  this.modalService.close('change-store')
  this.router.navigate(['add-store'])
}

  // close(){
  //   this.modalService.close('change-store')
  // }

  close(id){
    this.modalService.close(id)
  }

  async toggleSubscribe(){
    if(this.subscribed){
      this.messagingService.pullToken();
    }
    else{
      this.messagingService.requestPermission(true).then(res=>{
       // console.log(res)
      },err=>{
        this.toastr.warning(err)
      });
    }
  }

  private getPageTitle(route: any): string {
    if (route.firstChild) {
      return this.getPageTitle(route.firstChild);
    } else if (route.snapshot.data && route.snapshot.data.title) {
      return route.snapshot.data.title;
    } else {
      return '';
    }
  }
}
