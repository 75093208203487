import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { DealsService } from 'app/pages/deal/deals.service';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { PromotionService } from '../promotion.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment'

@Component({
  selector: 'app-create-promotion',
  templateUrl: './create-promotion.component.html',
  styleUrls: ['./create-promotion.component.css']
})
export class CreatePromotionComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder, private dealsService: DealsService, private atp: AmazingTimePickerService, private route: ActivatedRoute,
     private proService: PromotionService, public router: Router) { }
  
  stores;
  storePayload = {
    "filters": {},
    "pagination": { start: 0, limit: 50 },
    "sort": {},
    "fields": "name"
  }
  isPercentage = false;
  patchIsActive = false;
  allMenu = [];
  addPromotionForm = this.formBuilder.group({
    storeId: ['', [Validators.required]],
    promotionCode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(12)]],
    promotionName: ['', Validators.required],
    promotionOption: ['', Validators.required],
    startDate: [moment(new Date()).format('YYYY-MM-DD'), Validators.required],
    promotionDescription: ['', Validators.required],
    // promotionExpiry: ['', Validators.required],
    // startDate: ['', Validators.required],
    // endDate: ['', Validators.required],
    discount: ['', Validators.required]
  });
  public validationMessages = {
    discount: [{
      type: 'min', message: 'Discount must be atleast 1'
    },
    {
      type: 'max', message: 'Discount must not exceed from 100'
    }
    ],
    promoCode: [{
      type: 'minlength', message: 'Promotion code must be atleast of 4 digits'
    },
    {
      type: 'maxlength', message: 'Promotion code must not exceed from 12 digits'
    }
    ],
  }
  activeDays = [];
  user;
  options = [{value: 'REGULAR', label: 'Regular'},{value: 'FIRST_CUSTOMER', label: 'First Customer'},{value: 'ABANDONED CART', label: 'Abandoned Cart'}];
  days = [{value: 0, label: 'Monday'},{value: 1, label: 'Tuesday'},{value: 2, label: 'Wednesday'},{value: 3, label: 'Thursday'},
  {value: 4, label: 'Friday'},{value: 5, label: 'Saturday'},{value: 6, label: 'Sunday'}
  ]
  urlID;
  singlePromotion;
  validated = false;
  org;

  async ngOnInit() {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.addPromotionForm.get('storeId').setValue(this.org.storeId)
    this.urlID = this.route.snapshot.params.id;
    if(this.urlID != 'add') {
      this.validated = true;
      let res = await this.proService.getSinglePromotion(this.urlID);
      this.singlePromotion = res.promotion;
      this.patchPromotionValue()
    }
    this.user = JSON.parse(localStorage.getItem('user_info'));
    // if(this.user.role == 'admin'){
    //   this.addPromotionForm.removeControl('storeId')
    // }
    // this.stores = await this.dealsService.getStores(this.storePayload);
  }

  async submit(){
    let payload = this.addPromotionForm.getRawValue();
    if(this.activeDays.length > 0){
      payload['activeDays'] = this.activeDays;
    }
    payload['createdBy'] = this.user._id;
    // if(payload.startDate && payload.endDate){
    //   payload['startDate'] = new Date(moment(payload['startDate']).format('YYYY MM DD'));
    //   payload['endDate'] = new Date(moment(payload['endDate']).format('YYYY MM DD'));
    // }
    if(payload.promotionExpiry){
      payload['promotionExpiry'] = new Date(moment(payload['promotionExpiry']).format('YYYY MM DD'));
    }
    // if(this.user.role != 'admin'){
    //   payload.storeName = this.stores.find(res=>res._id == payload.storeId).name;
    // }
    payload.storeName = this.org.storeName;
    payload.isActive = true;
    payload.isPercentage = this.isPercentage;
    if(!this.addPromotionForm.get('startTime')){
      payload.startTime = null;
      payload.endTime = null;
    }
    if(this.urlID == 'add'){
      let res = await this.proService.createPromotion(payload);
      if(res){
        this.router.navigate(['promotions-list'])
      }
    }
    else{
      payload.isActive = this.patchIsActive;
      let res = await this.proService.updatePromotion(payload, this.urlID);
      if(res){
        this.router.navigate(['promotions-list'])
      }
    }
  }

  optionSelected(e){
    if(!this.singlePromotion){
    if(e == 'REGULAR'){
      this.addPromotionForm.addControl('activeDays', new UntypedFormArray([], Validators.required));
      this.activeDays = [];
      // this.days.forEach(d=>{
      //   (this.addPromotionForm.controls.activeDays as FormArray).push(d.value) 
      // })
      // (this.addPromotionForm.controls.activeDays as FormArray).push(0,1,2,3,4,5,6)
      // this.addPromotionForm.addControl('startTime', new FormControl('', Validators.required));
      // this.addPromotionForm.addControl('endTime', new FormControl('', Validators.required));
      this.days.forEach((o, i) => {
        const control = new UntypedFormControl(o.value);
        (this.addPromotionForm.controls.activeDays as UntypedFormArray).push(control);
        this.activeDays.push(o.value);
        this.validated = true;
      });
      // console.log(this.addPromotionForm)
      if(!this.addPromotionForm.controls.activeDays.valid){
        this.validated = false;
      }
    }
    if(e == 'FIRST_CUSTOMER' || e == 'ABANDONED CART'){
      this.validated = true;
      this.addPromotionForm.removeControl('activeDays');
      // this.addPromotionForm.removeControl('endTime');
      // this.addPromotionForm.removeControl('startTime');
      e == 'ABANDONED CART' ? this.addPromotionForm.removeControl('promotionExpiry') : null
    }}
  }

  timeLimit(val){
    let checked = val.target.checked;
    if(checked){
      this.addPromotionForm.addControl('startTime', new UntypedFormControl('', Validators.required));
      this.addPromotionForm.addControl('endTime', new UntypedFormControl('', Validators.required));
    }
    else{
      this.addPromotionForm.removeControl('endTime');
      this.addPromotionForm.removeControl('startTime');
    }
  }

  daySelected(e, i){
    let val = e.target.checked;
    if (val) {
      // console.log('ifff')
      this.activeDays.push(this.days[i].value);
    }
    else {
      // console.log('elseeee')
      this.activeDays.splice(this.activeDays.findIndex(n => n == this.days[i].value), 1)
      // console.log(this.activeDays)
    }
    if(this.activeDays.length > 0){
      this.validated = true;
    }
    else{
      this.validated = false;
    }
  }

  patchPromotionValue(){
    this.addPromotionForm.patchValue({
      promotionName: this.singlePromotion.promotionName,
      promotionCode: this.singlePromotion.promotionCode,
      promotionDescription: this.singlePromotion.promotionDescription,
      promotionOption: this.singlePromotion.promotionOption,
      startDate: moment(new Date(this.singlePromotion.startDate)).format('YYYY-MM-DD'),
      // startDate: moment(new Date(this.singlePromotion.startDate)).format('YYYY-MM-DD'),
      // endDate: moment(new Date(this.singlePromotion.endDate)).format('YYYY-MM-DD'),
      // promotionExpiry: moment(new Date(this.singlePromotion.promotionExpiry)).format('YYYY-MM-DD'),
      discount: this.singlePromotion.discount
    })
    this.patchIsActive = this.singlePromotion.isActive;
    this.isPercentage = this.singlePromotion.isPercentage;
    if(this.singlePromotion.promotionExpiry){
      // this.addPromotionForm.patchValue({
      //   promotionExpiry: moment(new Date(this.singlePromotion.promotionExpiry)).format('YYYY-MM-DD')
      // });
      this.addPromotionForm.addControl('promotionExpiry', new UntypedFormControl(moment(new Date(this.singlePromotion.promotionExpiry)).format('YYYY-MM-DD'), Validators.required));
      document.getElementById('exp')['checked'] = true;
    }
    if(this.singlePromotion.storeId){
      this.addPromotionForm.controls.storeId.setValue(this.singlePromotion.storeId)
    }
    if(this.singlePromotion.promotionOption == 'REGULAR'){
      this.addPromotionForm.addControl('activeDays', new UntypedFormArray([], Validators.required));
      // this.addPromotionForm.addControl('startTime', new FormControl('', Validators.required));
      // this.addPromotionForm.addControl('endTime', new FormControl('', Validators.required));
      // let selectedDays = [];
      // selectedDays = this.days.map((d,i)=>{
      //   if(this.singlePromotion.activeDays.includes(d.value)){
      //     this.activeDays.push(i);
      //     return true
      //   }
      //   else{
      //     return null
      //   }
      // })
      this.activeDays = this.singlePromotion.activeDays
      // console.log('activedayssssss',this.activeDays)
      this.days.forEach((o, i) => {
        if(this.activeDays.find(a=>a==o.value) == 0 || this.activeDays.find(a=>a==o.value)){
          // console.log('pushing ... ',o,this.days)
          const control = new UntypedFormControl(o.value);
          (this.addPromotionForm.controls.activeDays as UntypedFormArray).push(control);
        }
        else{
          const control = new UntypedFormControl();
          (this.addPromotionForm.controls.activeDays as UntypedFormArray).push(control);
        }
      });      
      if(this.singlePromotion.startTime){
        this.addPromotionForm.addControl('startTime', new UntypedFormControl('', Validators.required));
      this.addPromotionForm.addControl('endTime', new UntypedFormControl('', Validators.required));
      this.addPromotionForm.patchValue({
        startTime: this.singlePromotion.startTime,
        endTime: this.singlePromotion.endTime
      })
      }
      
      
    }
  }

  openTimepicker(type) {
    let amazingTimePicker;
    if(this.urlID == 'add'){
      amazingTimePicker = this.atp.open();
    }
    else{
      amazingTimePicker = this.atp.open({
        theme: 'light',
        time:this.addPromotionForm.controls[type].value
      });
    }
    amazingTimePicker.afterClose().subscribe(time => {
      if (type == 'startTime') {
        this.addPromotionForm.controls.startTime.setValue(time)
      }
      if (type == 'endTime') {
        this.addPromotionForm.controls.endTime.setValue(time)
      }
    });
  }

  close(){
    this.router.navigate(['promotions-list'])
  }

  discountType(val, type){
    if(type=='amount' && val.target.checked){
      this.isPercentage = false;
      // this.addPromotionForm.get('discount').reset();
      this.addPromotionForm.get('discount').clearValidators();
      this.addPromotionForm.get('discount').setValidators([Validators.required])
      this.addPromotionForm.get('discount').updateValueAndValidity();
    }
    if(type=='rate' && val.target.checked){
      this.isPercentage = true;
      // this.addPromotionForm.get('discount').reset();
      this.addPromotionForm.get('discount').clearValidators();
      this.addPromotionForm.get('discount').setValidators([Validators.required, Validators.min(1), Validators.max(100)])
      this.addPromotionForm.get('discount').updateValueAndValidity();
    }
  }

  noExpiry(e){
    if(e.target.checked){
      // document.getElementById('exp')['disabled'] = true;
      this.addPromotionForm.addControl('promotionExpiry', new UntypedFormControl('', Validators.required));
      
    }
    else{
      // document.getElementById('exp')['disabled'] = false;
      this.addPromotionForm.removeControl('promotionExpiry');
    }
  }

  // noDate(e){
  //   if(e.target.checked){
  //     document.getElementById('start')['disabled'] = true;
  //     document.getElementById('end')['disabled'] = true;
  //     this.addPromotionForm.removeControl('startDate');
  //     this.addPromotionForm.removeControl('endDate');
  //   }
  //   else{
  //     document.getElementById('start')['disabled'] = false;
  //     document.getElementById('end')['disabled'] = false;
  //     this.addPromotionForm.addControl('startDate', new FormControl('', Validators.required));
  //     this.addPromotionForm.addControl('endDate', new FormControl('', Validators.required));
  //   }
  // }


}
