import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators, FormArray, FormControl, } from '@angular/forms';
import { FileUploader, FileUploaderOptions, } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { CountrycodeService } from 'app/utilities/countrycode.service';
import { of } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { StoreService } from '../store.service';
import { AuthService } from 'app/utilities/auth.service';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-store-register',
  templateUrl: './store-register.component.html',
  styleUrls: ['./store-register.component.css']
})

export class StoreRegisterComponent implements OnInit {
  @ViewChild('labelView', { read: ElementRef, static: false })
  view: ElementRef;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  user;
  storeCats = []
  @Output() save: EventEmitter<Event> = new EventEmitter();
  public countryVar = 'Select country';
  public codes = this.countryCodes.getAllCountries();
  createPayload;
  public validationMessages = {
    name: [{
      type: 'required', message: 'Name is required'
    },
    {
      type: 'minlength', message: 'Name must be atleast 3 characters long'
    }
    ],
    storeCategory: [{
      type: 'required', message: 'Store Category is required'
    }],
    // branch: [{
    //   type: 'required', message: 'Branches are required'
    // }],
    lat: [{
      type: 'required', message: 'Latitude is required'
    }],
    lng: [{
      type: 'required', message: 'Longitude is required'
    }],
    email: [{
      type: 'required', message: 'Email is required'
    },
    {
      type: 'pattern', message: 'Enter valid email address'
    }],
    minDeliveryTime: [
      {
        type: 'required', message: 'Minimum delivery time is required'
      },
      {
        type: 'pattern', message: "Only Numbers are allowed."
      }
    ],
    minOrderPrice: [
      {
        type: 'required', message: 'Minimum order price is required'
      },
      {
        type: 'pattern', message: "Only Numbers are allowed."
      }
    ],
    webURL: [{
      type: 'required', message: 'Web URL is required'
    }],
    address: [{
      type: 'required', message: 'Address is required'
    },
    {
      type: 'minlength', message: 'Address must be atleast 4 characters long'
    }
    ],
    city: [{
      type: 'required', message: 'City is required'
    },
    {
      type: 'minlength', message: 'City name must be atleast 3 characters long'
    }],
    isSMS: [{
      type: 'required', message: 'SMS is required'
    }],
    applyGST: [{
      type: 'required', message: 'Applied GST ( in % ) is required'
    }],
    country: [{
      type: 'required', message: 'Country is required'
    }],
    rating: [{
      type: 'required', message: 'Rating is required'
    }],
    phone: [
      {
        type: 'required', message: 'Phone is required'
      },
      {
        type: 'pattern', message: "Please enter atleast 8 or 15 digit number"
      }
    ],
    deliveryFee: [
      {
        type: 'required', message: 'Delivery fee is required'
      },
      {
        type: 'pattern', message: "Only Numbers are allowed."
      }
    ],
    budget: [{
      type: 'required', message: 'Budget is required'
    }],
    serving: [{
      type: 'required', message: 'Serving is required'
    }],
    areas: [{
      type: 'required', message: 'Please mention your delivering areas'
    }],
    /* areasShow: [{
      type: 'required', message: 'Please mention your delivering areas for Message'
    }], */
    fbPageID: [{
      type: 'required', message: 'Facebook page ID is required'
    }],
    fbPageToken: [{
      type: 'required', message: 'Facebook page token is required'
    }],
    googleLink: [{
      type: 'required', message: 'Google page link is required'
    }],
    startHour: [{
      type: 'required', message: 'Starting hour is required'
    }],
    startMin: [{
      type: 'required', message: 'Starting minute is required'
    }],
    endHour: [{
      type: 'required', message: 'Ending hour is required'
    }],
    endMin: [{
      type: 'required', message: 'Ending minute is required'
    }],
    commissionValue: [{
      type: 'required', message: 'Commission value is required'
    }],
    commissionType: [{
      type: 'required', message: 'Commission type is required'
    }],
    smsPhoneNumber: [{
      type: 'required', message: 'SMS Phone is required'
    },
    {
      type: 'pattern', message: "Please enter atleast 8 or 15 digit number"
    }
    ],
    deliveryCities: [{
      type: 'required', message: 'Delivery Cities fee is required'
    }]
  }
  getPayload = {
    filters: {},
    pagination: { start: 0, limit: 9999 },
    sort: {}
  }

  deliveryPayload = {
    filters: {},
    pagination: {},
    sort: {}
  }

  // branches = []
  servingTime = { monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [] }
  hours = [];
  minutes = []
  items = [];
  imageURL;
  foodCuisine = [];
  deliveryLocations;
  deliveryArea;
  food;
  areas = []
  areasShow = []
  phoneNumbers = [];
  urlID;
  store;
  returnedLocation = [];
  updatePayload;
  updatedAt;
  createdAt;
  storeRegister: any;
  ordersData = [];
  orderTypes = [];
  selectedTagItems = [];
  isSMS = false;
  isGST = false;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities = [];
  countries = [];
  areaCities = [];
  areaForShowCities = [];
  deliveryCities = [];
  deliveryCustomCities = [];
  deliveryCustomAreas = [];
  selectedItems = [];
  selectedItemsForShow = []
  selectedCities = [];
  selectedCountries = [];
  dropdownSettings = {};
  dropdownForShowSettings = {};
  dropdownCitySettings = {};
  dropdownCountrySettings = {};
  dropdownCustomSettings = {};
  allFood: any;
  returnedServingTime = {};
  public uploader: FileUploader;


  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  public previewImg: any;
  foodSelected = [];
  days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
  friday = [];
  dayValues = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  }
  color:string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private translate: TranslateService,
    private dataService: DataServiceService,
    private countryCodes: CountrycodeService,
    private route: ActivatedRoute,
    private router: Router,
    private atp: AmazingTimePickerService,
    private storeService: StoreService,
    private toastr: ToastrService,
    private ngModalService: NgbModal,
  ) {
    this.urlID = this.route.snapshot.params.id;

    this.storeRegister = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]],
      // minDeliveryTime: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      // minOrderPrice: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      //webURL: [null],
      // commissionValue: [null],
      // commissionType: [''],
      // smsPhoneNumber: ['', [Validators.required, Validators.pattern("[0-9]{8,15}")]],
      address: ['', Validators.minLength(6)],
      // rating: ['', Validators.required],
      // applyGST: [''],
      deliveryCities: [''],
      deliveryCountries: [''],
      phone: ['', [Validators.required, Validators.pattern("[0-9]{8,15}")]],
      // budget: [null, Validators.required],
      // orders: new FormArray([], [Validators.required]),
      country: ['', Validators.required],
      fbPageID: [''],
      fbPageToken: [''],
      googleLink: [''],
      picture: ['', Validators.required],
      storeCategory: ['', Validators.required]
      // foodCuisine: new FormArray([], [Validators.required]),
    }
    );
    if (this.urlID == 'add') {
      // this.dataService.getFoodCusinies(this.getPayload)
      //   .subscribe(val => {
      //     this.foodCuisine = val.foodCuisine;
      //     this.allFood = val.foodCuisine;
      //     of(this.getFoodCuisine()).subscribe(food => {
      //       this.food = food[0];
      //       this.addFoodCheckboxes();
      //     });
      //   }, err => {
      //   })
      // of(this.getOrders()).subscribe(orders => {
      //   this.ordersData = orders;
      //   this.addOrdersCheckboxes();
      // });
    }
  }
  // private addOrdersCheckboxes() {
  //   if (this.orderTypes) {
  //     this.ordersData.forEach((o, i) => {
  //       const control = new FormControl();
  //       (this.storeRegister.controls.orders as FormArray).push(control);
  //     });
  //   }
  // }
  // private addFoodCheckboxes() {
  //   if (this.food) {
  //     this.allFood.forEach((o, i) => {
  //       const control = new FormControl();
  //       (this.storeRegister.controls.foodCuisine as FormArray).push(control);
  //     });
  //   }
  // }



  async ngOnInit() {
    this.user = await this.authService.getUser();
    this.getStoreCats()
    for (let i = 1; i <= 12; i++) {
      this.hours.push(i)
    }
    for (let i = 1; i <= 60; i++) {
      this.minutes.push(i)
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.dropdownForShowSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.dropdownCitySettings = {
      singleSelection: false,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.dropdownCountrySettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'countryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.dropdownCustomSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'city',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    if (this.urlID == 'add') {
      let response = await this.storeService.getDeliveryLocations(this.getPayload);
      this.deliveryLocations = response.deliveryLocation;
      this.cities = [];
      this.deliveryLocations.map(location => {
        this.cities.push({
          item_text: location.name,
          item_id: location._id
        })
      })
      const uploaderOptions: FileUploaderOptions = {
        url: environment.cloudinaryURL,

      };

      this.uploader = new FileUploader(uploaderOptions);
      this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
        form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
        form.append('file', fileItem);
        fileItem.withCredentials = false;
        return { fileItem, form };
      };

      this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
        let res: any = JSON.parse(response);
        this.storeRegister.patchValue({
          picture: res.url
        })
        return { item, response, status, headers };
      };

      this.uploader.onAfterAddingFile = async (fileItem) => {
        this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
        this.storeRegister.patchValue({
          picture: ''
        })
      }
    }
    else {
      let response = await this.storeService.getDeliveryLocationsCity(this.getPayload)
      let countryResponse = await this.storeService.getCountries();
      // this.deliveryLocations = response.deliveryLocation;
      this.cities = [];
      response.city.map(location => {
        this.cities.push({
          name: location.city,
          _id: location._id,
        })
      })
      countryResponse.countries.map(country => {
        this.countries.push({
          countryName: country.countryName,
          id: country.countryId,
          currency: country.currency,
          currencySymbol: country.currencySymbol
        })
      })
      const uploaderOptions: FileUploaderOptions = {
        url: environment.cloudinaryURL,

      };

      this.uploader = new FileUploader(uploaderOptions);
      this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
        form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
        form.append('file', fileItem);
        fileItem.withCredentials = false;
        return { fileItem, form };
      };

      this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
        let res: any = JSON.parse(response);
        this.storeRegister.patchValue({
          picture: res.url
        })
        return { item, response, status, headers };
      };

      this.uploader.onAfterAddingFile = (fileItem) => {
        this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
        this.storeRegister.patchValue({
          picture: ''
        })
      }

      /* let deliveryResponse = await this.storeService.getDeliveryLocations(this.getPayload)
      this.deliveryLocations = deliveryResponse.deliveryLocation; */
      let storeResponse = await this.storeService.getSingleStore(this.urlID)
      this.store = storeResponse.store;
      this.createdAt = storeResponse.store.createdAt;
      this.updatedAt = storeResponse.store.updatedAt;
      /* this.selectedItems = [];
      this.selectedItemsShow = []; */

      this.selectedCities = this.store.cityIds;
      this.selectedCountries = this.store.countriesDeliver;
      /* for (let i = 0; i < storeResponse.store.deliveryArea.length; i++) {
        this.locSelected(this.store.deliveryArea[i].delivery, this.store.deliveryArea[i].deliveryFee);

        this.selectedItems.push([{
          item_id: this.store.deliveryArea[i]._id,
          item_text: this.store.deliveryArea[i].delivery
        }])
      } */

      /* for (let i = 0; i < storeResponse.store.deliveryAreaForShow.length; i++) {
        this.addForShowSelected(this.store.deliveryAreaForShow[i].delivery, this.store.deliveryAreaForShow[i].deliveryFee);

        this.selectedItemsForShow.push([{
          item_id: this.store.deliveryAreaForShow[i]._id,
          item_text: this.store.deliveryAreaForShow[i].delivery
        }])
      } */

      /* for (let i = 0; i < this.store.deliveryArea.length; i++) {
        let index = _.findIndex(deliveryResponse.deliveryLocation, { _id: this.store.deliveryArea[i] });
        if (index >= 0) {
          this.selectedItems.push({ id: deliveryResponse.deliveryLocation[index]._id, name: deliveryResponse.deliveryLocation[index].name })
        }
      } */
      /* for (let i = 0; i < this.store.deliveryAreaForShow.length; i++) {
        let index = _.findIndex(deliveryResponse.deliveryLocation, { _id: this.store.deliveryAreaForShow[i] });
        if (index >= 0) {
          this.selectedItemsShow.push({ id: deliveryResponse.deliveryLocation[index]._id, name: deliveryResponse.deliveryLocation[index].name })
        }
      } */
      // let foodResponse = await this.storeService.getFoodCuisines(this.getPayload)
      // this.allFood = foodResponse.foodCuisine;
      // for (let j = 0; j < this.store.foodCuisine.length; j++) {
      //   this.foodSelected[j] = false;
      //   let index2 = _.findIndex(foodResponse.foodCuisine, { _id: this.store.foodCuisine[j] });
      //   if (index2 >= 0) {
      //     this.foodCuisine[index2] = {
      //       name: foodResponse.foodCuisine[index2].name,
      //       _id: foodResponse.foodCuisine[index2]._id,
      //     }
      //     this.foodSelected[index2] = true;
      //   }

      // }
      // of(this.getFoodCuisine()).subscribe(food => {
      //   this.food = food[0];
      //   this.addFoodCheckboxes();
      // });
      // this.orderTypes = this.store.orderType;
      // of(this.getOrders()).subscribe(orders => {
      //   this.ordersData = orders;
      //   this.addOrdersCheckboxes();
      // });
      // let orderType = [null, null, null, null];
      // this.orderTypes.map(single => {
      //   let index = _.findIndex(this.ordersData, { id: single });
      //   if (index >= 0) {
      //     orderType[index] = true;
      //   }
      // })
      // storeResponse.store.branch.map(branch => {
      //   this.branches.push({ display: branch, name: branch })
      // })

      this.isSMS = this.store.isSMS;
      this.isGST = this.store.isGST;

      this.storeRegister.patchValue({
        name: this.store?.name,
        // branch: this.branches,
        address: this.store?.address,
        //webURL: this.store?.webURL,
        email: this.store?.email,
        picture: this.store?.profilePic,
        // minOrderPrice: this.store?.minimumOrderAmount,
        // minDeliveryTime: this.store?.minimumDeliveryTime,
        // budget: this.store?.budget?.toString(),
        // rating: this.store?.rating,
        // smsPhoneNumber: this.store?.smsPhoneNumber,
        // commissionType: this.store?.commissionType,
        // commissionValue: this.store?.commissionValue,
        googleLink: this.store?.googleMapLink,
        // applyGST: this.store?.gst,
        fbPageID: this.store?.fbId,
        fbPageToken: this.store?.fbPageToken,
        phone: this.store?.phoneNumber[0],
        storeCategory: this.store.storeCategoryId,
        /* areas: this.selectedItems.map(single => {
          return { item_text: single.name, item_id: single.id }
        }), */
        /* areasShow: this.selectedItemsShow.map(single => {
          return { item_text: single.name, item_id: single.id }
        }), */
        // orders: orderType,
        country: parseInt(this.store?.countryId),
      })
      this.color = this.store.storeColor;

      let el: HTMLElement = this.view?.nativeElement;
      el?.click();

      this.dataService.hideSpinner()

    }
  }

  // getOrders() {
  //   return [
  //     { id: 0, name: 'Delivery' },
  //     { id: 1, name: 'Take away' },
  //     { id: 2, name: 'Reservation' },
  //     { id: 3, name: 'Digital menu' }
  //   ];
  // }

  // getFoodCuisine() {
  //   return [
  //     this.foodCuisine.map(single => {
  //       return { id: single._id, name: single.name }
  //     })
  //   ]
  // }

  async submit() {
    // let selectedOrderIds = [];
    // this.storeRegister.value.orders.map((o,i) => {
    //   if(o){
    //     selectedOrderIds.push(i)
    //   }
    // })
        // const selectedFoodCuisine = this.storeRegister.value.foodCuisine.map((v, i) => v ? this.allFood[i]._id : null).filter(v => v !== null);

    if (this.storeRegister.value.phone != '') {
      this.phoneNumbers.push(this.storeRegister.value.phone)
    }
    else {
      this.phoneNumbers = [];
    }
    // this.storeRegister.value.branch.map(branch => {
    //   this.branches.push(branch.display)
    // })
    this.createPayload = {
      name: this.storeRegister.value.name,
      // branch: this.branches,
      email: this.storeRegister.value.email,
      //webURL: this.storeRegister.value.webURL,
      address: this.storeRegister.value.address,
      // commissionValue: this.storeRegister.value.commissionValue,
      // commissionType: this.storeRegister.value.commissionType,
      phoneNumber: this.phoneNumbers,
      profilePic: this.storeRegister.value.picture,
      // isSMS: this.isSMS,
      // isGST: this.isGST,
      // gst: this.storeRegister.value.applyGST ? this.storeRegister.value.applyGST : 0,
      // smsPhoneNumber: this.storeRegister.value.smsPhoneNumber,
      // minimumOrderAmount: this.storeRegister.value.minOrderPrice,
      // minimumDeliveryTime: this.storeRegister.value.minDeliveryTime.toString(),
      // budget: parseInt(this.storeRegister.value.budget),
      // rating: this.storeRegister.value.rating,
      googleMapLink: this.storeRegister.value.googleLink,
      storeColor: this.color,
      fbId: this.storeRegister.value.fbPageID,
      fbPageToken: this.storeRegister.value.fbPageToken,
      // foodCuisine: selectedFoodCuisine,
      // orderType: selectedOrderIds,
      cityIds: this.selectedCities,
      countriesDeliver: this.selectedCountries,
      /* deliveryArea: this.storeRegister.value.areas.map(single => {
        return single.item_id
      }), */
      /* deliveryAreaForShow: this.storeRegister.value.areasShow.map(single => {
        return single.item_id
      }), */
      countryId: this.storeRegister.value.country,
      countryName: this.countries.find(c=>c.id == this.storeRegister.value.country).countryName,
      storeCurrency: this.countries.find(c=>c.id == this.storeRegister.value.country).currency,
      storeCurrencySymbol: this.countries.find(c=>c.id == this.storeRegister.value.country).currencySymbol,
      storeCategoryId: this.storeRegister.value.storeCategory,
      storeCategoryName: this.storeCats.find(st=>st._id == this.storeRegister.value.storeCategory)
    }
    this.dataService.setHoldData(this.createPayload);
    this.storeService.createStore(this.createPayload);
  }

  update() {

    // let selectedOrderIds = [];
    // this.storeRegister.value.orders.map((o,i) => {
    //   if(o){
    //     selectedOrderIds.push(i)
    //   }
    // })
        // const selectedFoodCuisine = this.storeRegister.value.foodCuisine.map((v, i) => v ? this.allFood[i]._id : null).filter(v => v !== null);
    // if(this.storeRegister.value.areas != ''){this.storeRegister.value.areas.map(area=>{
    //   this.areas.push(area._id);
    // })}
    if (this.storeRegister.value.phone != '') {
      this.phoneNumbers.push(this.storeRegister.value.phone)
    }
    else {
      this.phoneNumbers = [];
    }

    this.updatePayload = {
      name: this.storeRegister.value.name,
      // branch: this.branches,
      email: this.storeRegister.value.email,
      block: "false",
      admin: "ali",
      //webURL: this.storeRegister.value.webURL,
      address: this.storeRegister.value.address,
      phoneNumber: this.phoneNumbers,
      profilePic: this.storeRegister.value.picture,
      // minimumOrderAmount: this.storeRegister.value.minOrderPrice,
      // minimumDeliveryTime: this.storeRegister.value.minDeliveryTime,
      // smsPhoneNumber: this.storeRegister.value.smsPhoneNumber,
      storeColor: this.color,
      // budget: parseInt(this.storeRegister.value.budget),
      // commissionValue: this.storeRegister.value.commissionValue,
      // commissionType: this.storeRegister.value.commissionType,
      // isSMS: this.isSMS,
      // isGST: this.isGST,
      // gst: this.storeRegister.value.applyGST,
      // rating: this.storeRegister.value.rating,
      googleMapLink: this.storeRegister.value.googleLink,
      fbId: this.storeRegister.value.fbPageID,
      fbPageToken: this.storeRegister.value.fbPageToken,
      // foodCuisine: selectedFoodCuisine,
      // orderType: selectedOrderIds,
      cityIds: this.selectedCities.map(c=>{
        // console.log(c)
        return {name: c.name, _id: c._id}
      }),
      countriesDeliver: this.selectedCountries,
      countryId: this.storeRegister.value.country,
      countryName: this.countries.find(c=>c.id == this.storeRegister.value.country).countryName,
      storeCurrency: this.countries.find(c=>c.id == this.storeRegister.value.country).currency,
      storeCurrencySymbol: this.countries.find(c=>c.id == this.storeRegister.value.country).currencySymbol,
      storeCategoryId: this.storeRegister.value.storeCategory,
      storeCategoryName: this.storeCats.find(st=>st._id == this.storeRegister.value.storeCategory).name
    }    
    this.storeService.updateStore(this.urlID, this.updatePayload)
  }


  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  // close() {
  //   this.router.navigate(['store-list']);
  // }

  // foodChanged(event, i) {
  //   if (this.urlID != 'add') {
  //     if (this.foodSelected[i] == true && event == false) {
  //       this.foodSelected[i] = false;
  //     }
  //     if (event == true) {
  //       this.foodSelected[i] = true;
  //     }
  //   }
  // }

  openTimepicker(i, type, day) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      if (type == 'from') {
        this.storeRegister.controls[day].controls[i].patchValue({
          from: time
        })
      }
      if (type == 'to') {
        this.storeRegister.controls[day].controls[i].patchValue({
          to: time
        })
      }
    });
  }



  removeImg() {
    this.uploader.clearQueue();
    this.storeRegister.patchValue({
      picture: ''
    })
  }

  changeIsSMS(event) {
    if (event.target.checked) {
      this.isSMS = true;
    } else {
      this.isSMS = false;
    }
  }

  changeIsGST(event) {
    if (event.target.checked) {
      this.isGST = true;
    } else {
      this.isGST = false;
    }
  }

  async getStoreCats(){
    let payload = {
      filters: {},
    pagination: { start: 0, limit: 9999 },
    sort: {}
    }
    let response = await this.storeService.getStoreCategories(payload)
    if(response){
      this.storeCats = response.storeCategory
    }
  }

  onChangeColor(color: string): void {
    this.color = color;
  }

  updateLive(message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if(result=='yes'){
        that.confirmLive();
      }
      else{
        document.getElementById('live-toggle')['checked'] = !document.getElementById('live-toggle')['checked'];

      }
    })
  }

  confirmLive(){
    this.dataService.setStoreStatus({storeId:this.store._id, status:!this.store.isLive})
      .subscribe(val => {
        if(val.code == 200){
          this.toastr.success(!this.store.isLive ? 'Your Store is Live Successfully, It will take some time to reflect changes on your FB Page. Please refresh your Facebook to see changes' : val.message);
          this.store.isLive = !this.store.isLive
        }else if(val.code == 202 || val.code == 203){
          document.getElementById('live-toggle')['checked'] = !document.getElementById('live-toggle')['checked'];
          this.toastr.error(val.message);
        }
        this.dataService.hideSpinner()
      }, err => {
        console.log(err)
        document.getElementById('live-toggle')['checked'] = !document.getElementById('live-toggle')['checked'];
        this.dataService.hideSpinner()
        this.toastr.error('Failed to update store');
      });
  }
}
