
import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router) { }



  async getSingleSubCategory(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleSubCategories(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async createSubCategory(payload) {
    this.dataService.showSpinner()
    this.dataService.createSubCategories(payload)
      .subscribe(val => {
        this.router.navigate(['sub-categories-list/'+payload.storeId]);
        this.toastr.success('Sub Category has been added successfully!')
        this.dataService.hideSpinner()
      }, err => {
        this.toastr.error('Failed!')
        this.dataService.hideSpinner()
      });
  }

  async updateSubCategory(id, payload) {
    this.dataService.showSpinner()
    this.dataService.updateSubCategories(id, payload)
      .subscribe(val => {
        // this.router.navigate(['store-list']);
        this.router.navigate(['sub-categories-list/'+payload.storeId]);
        this.toastr.success('Sub Category has been updated successfully!')
        this.dataService.hideSpinner()
      }, err => {
        this.toastr.error('Update failed!')
        this.dataService.hideSpinner()
      });
  }


  
}
