import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from 'app/utilities/auth.service';
import { Router } from '@angular/router';
 
@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate {
 
  constructor(public auth: AuthService, private router: Router) {}
 
  canActivate(): boolean {
    if(!localStorage.getItem('access_token')){
      return true;
    }
    //Muddassir    
    //this.router.navigate(['login']);    
    return false;
  }

  
}