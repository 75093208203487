import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'app/pages/settings/store/store.service';
import { OnboardingService } from 'app/utilities/onboarding.service';
import * as _ from 'lodash';
import { NgWizardService } from 'ng-wizard';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.css']
})
export class StoreInfoComponent implements OnInit {
  @Input('selectedPlatform') selectedPlatform: any;
  storeCats = []
  getStartedForm: any;
  owner;
  user;
  color:string;
  storeId;
  countries = [];
  tempStoreInfo;
  isDomainAvailable = false;
  showDomainSign = false;
  newUser = false;
  validationMessages = {
    name: [{
      type: 'required', message: 'Store Name is required'
    }],
    domain: [{
      type: 'required', message: 'Store Domain name is required',
    },{
      type: 'pattern', message: "Store Domain name should only include alphanumeric character & only '-' is allowed from special character"
    }],
    storeCategory: [{
      type: 'required', message: 'Store Category is required'
    }],
    color: [{
      type: 'required', message: 'Theme Color is required'
    }],
    country: [{
      type: 'required', message: 'Country is required'
    }],
    phone: [{
      type: 'required', message: 'Phone Number is required'
    }, {
      type: 'pattern', message: 'Phone number should be numeric & atleast 11 digits long'
    }]
  };

  constructor(private formBuilder: UntypedFormBuilder, private onboardingService: OnboardingService, private storeService: StoreService, private ngWizardService: NgWizardService, private route: ActivatedRoute, private router: Router) {
    this.getStartedForm = this.formBuilder.group({
      name:[''],
      domain:[''],
      storeCategory: ['', Validators.required],
      color: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern("[0-9]{11,13}")]],
      country: ['', [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void>  {
    console.log(this.selectedPlatform)
    this.tempStoreInfo = this.onboardingService.getStore()
    if(!this.tempStoreInfo){
      if(this.selectedPlatform == 'website'){
        let nameControl= this.getStartedForm.get('name');
        let domainControl= this.getStartedForm.get('domain');
        nameControl.setValidators([Validators.required]);
        nameControl.updateValueAndValidity();
        domainControl.setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$')]);
        domainControl.updateValueAndValidity();
      }else{
        let store = await this.onboardingService.getByFbId({fbId:this.tempStoreInfo._id})
        this.getStartedForm.patchValue({
          storeCategory : store.storeCategoryId,
          color : store.storeColor,
          phone : store.phoneNumber[0]
        })
      }

    }else{
      if(this.selectedPlatform == 'website'){
        let nameControl= this.getStartedForm.get('name');
        let domainControl= this.getStartedForm.get('domain');
        nameControl.setValidators([Validators.required]);
        nameControl.updateValueAndValidity();
        domainControl.setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$')]);
        domainControl.updateValueAndValidity();
      }else{
        this.getStartedForm.patchValue({
          storeCategory : this.tempStoreInfo.storeCategoryId,
          color : this.tempStoreInfo.storeColor,
          phone : this.tempStoreInfo.phoneNumber[0]
        })
      }

    }
    this.getStoreCats()
    this.getCountries()
    this.user = localStorage.getItem("user_info");
    if(!JSON.parse(localStorage.getItem('organizations'))?.length && !localStorage.getItem('superadmin')){
      this.newUser = true;
    }
    //this.owner = await this.onboardingService.getStoreOwner(this.user._id);
    //this.patchValue();
  }

  async updateProfile(){
    let storeCatId = this.getStartedForm.value.storeCategory
    let currentStoreCat = _.find(this.storeCats, function(o) { return o._id == storeCatId; });
    let payload = {
      storeCategory: this.getStartedForm.value.storeCategory,
      color: this.getStartedForm.value.color,
      phone: this.getStartedForm.value.phone,
      storeCategoryName: currentStoreCat.name,
      storeId:this.tempStoreInfo._id,
      countryId: this.getStartedForm.value.country,
      countryName: this.countries.find(c=>c.countryId == this.getStartedForm.value.country).countryName,
      storeCurrency: this.countries.find(c=>c.countryId == this.getStartedForm.value.country).currency,
      storeCurrencySymbol: this.countries.find(c=>c.countryId == this.getStartedForm.value.country).currencySymbol,
      isTempStore:this.newUser
    }
    let update = await this.onboardingService.updateStoreBasicInfo(payload);
    if(update.storeUpdate.nModified == 1){
      if(this.newUser){
        this.ngWizardService.show(2);
      }else{
        this.ngWizardService.next();
      }
    }
  }

  // patchValue(){
  //   this.getStartedForm.patchValue({
  //     storeCategory: this.owner.storeOwner.storeCategory,
  //     color: this.owner.storeOwner.color,
  //     phone: this.owner.storeOwner.phone
  //   })
  // }

  async getStoreCats(){
    let payload = {
      filters: {},
    pagination: { start: 0, limit: 9999 },
    sort: {}
    }
    let response = await this.storeService.getStoreCategories(payload)
    if(response){
      this.storeCats = response.storeCategory
    }
  }

  onChangeColor(color: string): void {
    this.color = color;
    this.getStartedForm.patchValue({
      color: this.color
    })
  }

  async getCountries(){
    let countryResponse = await this.storeService.getCountries();
    this.countries = countryResponse?.countries;
  }

  async createWebsite(){
    let storeCatId = this.getStartedForm.value.storeCategory
    let currentStoreCat = _.find(this.storeCats, function(o) { return o._id == storeCatId; });
    let payload = {
      ownerId:JSON.parse(this.user)._id,
      name : this.getStartedForm.value.name,
      domain : this.getStartedForm.value.domain,
      storeCategory: this.getStartedForm.value.storeCategory,
      color: this.getStartedForm.value.color,
      phone: this.getStartedForm.value.phone,
      storeCategoryName: currentStoreCat.name,
      countryId: this.getStartedForm.value.country,
      countryName: this.countries.find(c=>c.countryId == this.getStartedForm.value.country).countryName,
      storeCurrency: this.countries.find(c=>c.countryId == this.getStartedForm.value.country).currency,
      storeCurrencySymbol: this.countries.find(c=>c.countryId == this.getStartedForm.value.country).currencySymbol,
      isTempStore:this.newUser
    }
    let update = await this.onboardingService.createOwnerStoreThroughWebsite(payload);
    if(update){
      this.onboardingService.setStore(update);
      this.storeService.updateStoreInLayout.next('Update');
      if(this.newUser){
        this.ngWizardService.show(2);
      }else{
        let state = {storeId: update._id}
        this.router.navigate([], {
          relativeTo: this.route, queryParams: {
            ...state
          },
          queryParamsHandling: 'merge'
        });
        this.ngWizardService.next();
      }

    }
  }

  async checkDomainAvailability(){
    let domain = this.getStartedForm.value.domain
    let domainControl= this.getStartedForm.get('domain');
    if(domainControl.valid){
      let domainAvailable = await this.storeService.checkDomainAvailability(domain);
      this.showDomainSign = true
      this.isDomainAvailable = domainAvailable.isDomainAvailable
    }
  }
}
