import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { DataServiceService } from 'app/utilities/data-service.service';
import { OrdersService } from 'app/pages/order/orders.service';
import * as L from 'leaflet';
import { ModalService } from 'app/_modal';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bot-order-details',
  templateUrl: './bot-order-details.component.html',
  styleUrls: ['./bot-order-details.component.css']
})
export class BotOrderDetailsComponent implements OnInit {
  urlID;
  statusForm: any;
  constructor(private router: Router, private translate: TranslateService,private permissionService: NgxPermissionsService, private route: ActivatedRoute, private toastr: ToastrService, private formBuilder: UntypedFormBuilder, private modalService: ModalService, private dataService: DataServiceService, private ordersService: OrdersService) {

    this.statusForm = this.formBuilder.group({
      status: ['', Validators.required],
      selectedStatus: [formBuilder.group({
      })]
    })
   }
  order = {
  createdAt: "",
  customerAddress: "",
  customerAddressCoordinates: {lat: null, lng: null},
  customerId: "",
  customerName: "",
  deliveryFee: null,
  deliveryTime: null,
  discount:null,
  discountedPrice: null,
  fbId: "",
  item: [],
  month: null,
  orderRefNo: "",
  orderType: "",
  phoneNumber: "",
  restMinimumOrderAmount: null,
  storeId: "",
  storeName: "",
  status: "",
  totalPrice: null,
  updatedAt: "",
  year: null,
  __v: null,
  _id: ""
  }
  flavors = []
  allOrderTypes = [
    { id: 0, name: 'Delivery' },
    { id: 1, name: 'Take away' },
    { id: 2, name: 'Reservation' },
    { id: 3, name: 'Digital menu' }
  ];
  status =[
    { id: 0, name: 'pending'},
    { id: 1, name: 'confirm'},
    { id: 2, name: 'rejected'},
    { id: 3, name: 'preparing'},
    { id: 4, name: 'served/dispatched'},
    { id: 5, name: 'billed'}
  ];
  selectedStatus = {};
  async ngOnInit(): Promise<void> {
    this.dataService.showSpinner()
    this.urlID = this.route.snapshot.params.id;
    this.order = await this.ordersService.getSingleOrder(this.urlID)
    this.modifyOrderData()
    this.createMap(this.order.customerAddressCoordinates.lat, this.order.customerAddressCoordinates.lng);
    this.dataService.hideSpinner()
  }

  modifyOrderData(){
    let index = _.findIndex(this.allOrderTypes, {id: parseInt(this.order.orderType)});
    this.order.orderType = this.allOrderTypes[index].name
    this.order.createdAt = this.dataService.formatDate(new Date(this.order.createdAt))
  }

  createMap(lat,lng){
    var mapPosition = new L.LatLng(lat,lng);
    var map = new L.Map("map", {
      center: mapPosition,
      zoom: 15,
      zoomControl: true,
      scrollWheelZoom: false,
      layers: new L.TileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png")
    });
    map.zoomControl.setPosition('topright');
    var marker = new L.Marker(mapPosition);
    marker.bindPopup("Delivery Location");
    map.addLayer(marker);
    map.on('click', function() {
      if (map.scrollWheelZoom.enabled()) {
        map.scrollWheelZoom.disable();
        }
        else {
        map.scrollWheelZoom.enable();
        }
    });
  }

  confirmOrder(){
    this.dataService.showSpinner()
    let payload = {
      "storeId": this.order.storeId,
      "status": 'confirm'
    }
    this.dataService.changeOrderStatus(this.order._id, payload).subscribe(val=>{
      this.order.status = 'confirm'
      this.toastr.success('Status updated')
      this.dataService.hideSpinner()
    },err=>{
      this.dataService.hideSpinner()
    })
  }
  rejectOrder(){
    this.dataService.showSpinner()
    let payload = {
      "storeId": this.order.storeId,
      "status": 'rejected'
    }
    this.dataService.changeOrderStatus(this.order._id, payload).subscribe(val=>{
      this.order.status = 'rejected'
      this.dataService.hideSpinner()
    },err=>{
      this.dataService.hideSpinner()
    })
  }

  changeStatus(){
    this.modalService.open('change-status')
  }

  closeModal(id){
    this.modalService.close(id)
  }

  updateStatus(){
    this.dataService.showSpinner()
    let index = _.findIndex(this.status, {id: parseInt(this.statusForm.value.status)});
    this.statusForm.patchValue({
      selectedStatus: this.status[index]
    })
    let payload = {
      "storeId": this.order.storeId,
      "status": this.status[index].name
    }
    this.dataService.changeOrderStatus(this.order._id, payload).subscribe(val=>{
      this.modalService.close('change-status');
      this.toastr.success('Status updated!');
      if(val.orderUpdate.nModified == 1){
        this.order.status = this.status[index].name
      }
      this.dataService.hideSpinner()
    },err=>{
        this.toastr.error('Status update failed')
        this.dataService.hideSpinner()
    })
  }

  isShow(orderStatus, currentStep){
    let index = _.findIndex(this.status, {name: orderStatus});
    if(index>=0){
    if(this.status[index].id > currentStep){
      return true;
    }
  }
  }

  viewFlavors(id){
    this.modalService.open('view-flavors')
    let index = _.findIndex(this.order.item, {_id: id});
    this.flavors = this.order.item[index].flavors;
  }

  close(){
    this.router.navigate(['bot-orders/all/view'])
  }

}
