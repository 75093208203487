import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DataServiceService } from 'app/utilities/data-service.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../product.service';
import { AlertService } from 'app/shared/_alert';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-import-products',
  templateUrl: './import-products.component.html',
  styleUrls: ['./import-products.component.css']
})
export class ImportProductsComponent implements OnInit {
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: 'lg'
  }
  categoryId;
  subCategoryId;
  categories = [];
  payload:any = {
    filters: {},
    pagination: { start: 0, limit: 999 },
    sort: {},
  }
  storeId;
  subCategories = []
  rejectedBulkProducts: any;
  progressInterval;
  progress;
  org:any;
  constructor(private router: Router, private toastr: ToastrService, private translate: TranslateService, private ngModalService: NgbModal, private productService: ProductService, private dataService: DataServiceService) {

   }

  ngOnInit(): void {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o => o.selected)
    this.storeId = this.org.storeId;
    // const modalRef = this.ngModalService.open(InfoModalComponent, this.modalOptions);
    // modalRef.result.then(async (result) => { });
  }

  getCategories() {
    this.payload.filters.storeId = this.storeId;
    this.dataService.getCategories(this.payload)
      .subscribe(val => {
        this.categories = val.category;
        if(this.categories.length == 0){
          this.toastr.error('No categories found in your store. Kindly add category before importing products.')
        }
      })
  }

  import() {
    this.getCategories();
  }

  getSubCategories(catId) {
    let payload = JSON.parse(JSON.stringify(this.payload))
    payload.filters = { 'categoryId': catId, storeId: this.storeId }
    this.dataService.getSubCategories(payload).subscribe(res => {
      this.subCategories = res.subCategory;
    })
  }

  upload() {
    document.getElementById('bulk').click()
  }

  goToInvoices(){
    this.router.navigate(['invoice-list'])
  }

  csvUpload(e,type) {
    let files = e.target.files;
    if (files[0].size > 10000000) {
      this.toastr.error('File can not be larger than 10mb')
      return;
    }
    let ext = files[0].name.split('.')[1];
    if (ext == 'xlsx' || ext == 'csv') {
      let fileReader = new FileReader()
      fileReader.readAsBinaryString(files[0]);
      let that = this;
      let user = JSON.parse(localStorage.getItem("user"));
      fileReader.onload = async function (event) {
        var data = event.target.result;
        var foundHeaders = [];
        var workbook: any = XLSX.read(data, {
          type: "binary"
        });
        if (workbook.SheetNames.length == 1) {
          let products: any = XLSX.utils.sheet_to_json
            (
              workbook.Sheets[workbook.SheetNames[0]]
            );
          if(that.org.subscriptionId == '620e39f95e098a0016ac24d2'){
            if(products.filter(prod=>prod.product_type == 'simple' || prod.product_type == 'variable').length > 10){
              that.toastr.warning('You can only upload 10 products in the starter package. To import more products, you need to upgrade your subscription package. Click here to upgrade your package', 'Warning', {
                timeOut: 30000
              }).onTap.subscribe(res=>{
                that.router.navigate(['invoice-list'])
              })
              return;
            }
          }
          that.rejectedBulkProducts = type == 'update' ? [...products.filter(product => {
            return product.product_type == 'simple' ?
            (!product.SKU ||
              !product.long_detail ||
              !product.manage_stock ||
              !product.product_name ||
              !product.regular_price ||
              !product.short_detail ||
              !product.stock ||
              !product.stock_status ||
              !product.product_type) :
              product.product_type == 'variable' ?
              (
                !product.long_detail ||
                !product.product_name ||
                !product.short_detail ||
                !product.product_type) :
                (!product.SKU ||
                  !product.manage_stock ||
                  !product.regular_price ||
                  !product.stock ||
                  !product.stock_status ||
                  !product.product_type)
          })] : [...products.filter(product => {
            return product.product_type == 'simple' ?
             (!product.SKU ||
              !product.long_detail ||
              !product.manage_stock ||
              !product.product_images ||
              !product.product_name ||
              !product.regular_price ||
              !product.short_detail ||
              !product.stock ||
              !product.stock_status ||
              !product.product_type) :
              product.product_type == 'variable' ?
              (
                !product.SKU ||
                !product.long_detail ||
                !product.product_name ||
                !product.short_detail ||
                !product.product_images ||
                !product.attribute_option ||
                !product.attribute_name ||
                !product.product_type) :
                (!product.SKU ||
                  !product.manage_stock ||
                  !product.product_images ||
                  !product.regular_price ||
                  !product.stock ||
                  !product.stock_status ||
                  !product.parent_SKU ||
                  !product.attribute_option ||
                !product.attribute_name ||
                  !product.product_type)
          })];
          if (that.rejectedBulkProducts.length > 0 && type == 'import') {
            for (let p in that.rejectedBulkProducts[0]) {
              if (p != 'SKU' && p != 'long_detail' && p != 'manage_stock' && p != 'product_brand' && p != 'product_images' && p != 'product_name' && p != 'product_tags' &&
                p != 'regular_price' && p != 'sale_price' && p != 'short_detail' && p != 'attribute_name' && p != 'attribute_option' && p != 'stock' && p != 'stock_status' && p != 'parent_SKU' && p != 'product_type') {
                foundHeaders.push(p)
              }
            }
            that.rejectedBulkProducts.map(rp => {
              products.splice(products.findIndex(p => p.SKU == rp.SKU), 1)

            })
          }
          if (that.rejectedBulkProducts.length > 0 && type == 'update') {
            for (let p in that.rejectedBulkProducts[0]) {
              if (p!='_id' && p != 'SKU' && p != 'long_detail' && p != 'manage_stock' && p != 'product_name' &&
                p != 'regular_price' && p != 'sale_price' && p != 'short_detail' && p != 'stock' && p != 'stock_status' && p != 'parent_SKU' && p != 'product_type') {
                foundHeaders.push(p)
              }
            }
            that.rejectedBulkProducts.map(rp => {
              products.splice(products.findIndex(p => p._id == rp._id), 1)

            })
          }
         // console.log('Rejected Products: ', that.rejectedBulkProducts)
          //console.log('Imported Products: ', JSON.parse(JSON.stringify(products)))

          if (
            //   that.rejectedBulkProducts.length && !that.rejectedBulkProducts.find(rej=>{
            //   return rej.SKU &&
            //   rej.long_detail &&
            //   rej.manage_stock &&
            //   rej.product_brand &&
            //   rej.product_images &&
            //   rej.product_name &&
            //   rej.product_tags &&
            //   rej.regular_price &&
            //   rej.sale_price &&
            //   rej.short_detail &&
            //   rej.stock &&
            //   rej.stock_status &&
            //   rej.parent_SKU &&
            //   rej.product_type
            // }) &&
            foundHeaders.length) {
            that.toastr.error(`Upload failed due to wrong headers in the file! Wrong headers: ${foundHeaders.map(f => f)}`)
            that.progress = 0;
            clearInterval(that.progressInterval)
          }
          else {
            let optionalFields = ['parent_SKU','product_brand','product_tags','sale_price','product_name','short_detail','long_detail','SKU','regular_price','stock','stock_status','stock_status','manage_stock','attribute_name','attribute_option']
            products.map(rp => {
              optionalFields.forEach(op=>{
                if(!rp[op]){
                  rp[op] = ''
                }
              })
            })
            let payload = type == 'import' ? {
              products,
              storeId: that.storeId,
              categoryId: that.categoryId,
              subCategoryId: that.subCategoryId
            } : {
              products,
              storeId: that.storeId,
            }
            let response:any;
            if(type == 'import'){
              response = await that.productService.importProducts(payload);
            }
            else{
              response = await that.productService.updateImportProducts(payload);
            }
            //console.log(response)
            if(response?.message?.rejectedInputs.length){
              that.rejectedBulkProducts = [...that.rejectedBulkProducts, ...response?.message?.rejectedInputs];
            }
              if (that.rejectedBulkProducts.length) {

                // that.rejectedBulkProducts.map(rp => {
                //   if (rp.parent_SKU == 'abc') {
                //     rp.parent_SKU = ''
                //   }
                // })
                let tempProds = that.rejectedBulkProducts.filter(r=>r.product_type == 'simple' || r.product_type == 'variable')
                // that.toastr.error(`${tempProds.length} product(s) have been rejected due to some wrong entry in the uploaded file!`)

                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(that.rejectedBulkProducts)
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                XLSX.writeFile(wb, 'Rejected_Products_todayDate.xlsx');
              }
              const modalRef = that.ngModalService.open(InfoModalComponent, that.modalOptions);
                 modalRef.componentInstance.response = response.message;
                 modalRef.componentInstance.modal = 'import';
                  modalRef.result.then(async (result) => {
                    that.router.navigate(['products-list/'+that.storeId])
                  })
          //  console.log({ products }, that.rejectedBulkProducts)
          }
        }
        else {
          that.progress = 0;
          that.toastr.error('Found more than 1 sheets in the uploaded file')
        }
      }
    }
    else {
      this.toastr.error('File not supported')
    }
  }

  async export(){
    let resp = await this.productService.exportProducts(this.storeId);
    if(resp.data){
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(resp.data)
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'Exported_Products.xlsx');
    }
  }

}
