import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StoreService } from 'app/pages/settings/store/store.service';

@Component({
  selector: 'app-facebook-messenger-plugin',
  templateUrl: './facebook-messenger-plugin.component.html',
  styleUrls: ['./facebook-messenger-plugin.component.scss']
})
export class FacebookMessengerPluginComponent implements OnInit {

  constructor(private dataService: DataServiceService, private route: ActivatedRoute, private toastr: ToastrService, private router: Router, private storeService: StoreService) { }
  org;
  plugin;
  loader:any = true;
  storeId
  user;
  store;
  showFacebookConnectWizard = false;
  isCallBack = false;
  async ngOnInit() {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.storeId = this.org.storeId
    this.user = JSON.parse(localStorage.getItem("user_info"));
    this.getPlugin()
    let storeResponse = await this.storeService.getSingleStore(this.storeId)
    this.store = storeResponse.store;
  }

  getPlugin(){
    this.dataService.showSpinner();
    this.dataService.getSinglePlugin(this.org.storeId, this.route.snapshot.routeConfig.path).subscribe(res=>{
      this.plugin = res.currentAppPlugin;
      this.dataService.hideSpinner();
      this.loader = false;
    },err=>{
      this.dataService.hideSpinner();
      this.toastr.error('Error loading plugin')
      this.loader = 'failed';
    })
  }

  goToPlugins(){
    this.router.navigate(['plugins-list'])
  }

  platformExists(platform){
    return this.store?.platforms.includes(platform) ? true : false;
  }

  async addPlatform(platform){
    this.isCallBack = true;
    this.dataService.showSpinner()
    if(this.store.fbId && this.store.fbPageToken){
      this.dataService.addPlatformStore(this.store._id,{platform,userId:this.user._id})
      .subscribe(async (val) => {
        this.isCallBack = false;
        if(val.code == 200){
          let msg = platform == 'facebook' ?  'Facebook is added succesfully' : 'Instagram is added successfully.'
          this.toastr.success(msg);
          let storeResponse = await this.storeService.getSingleStore(this.storeId)
          this.store = storeResponse.store;
        }else if(val.code == 201){
          this.dataService.hideSpinner()
          this.toastr.error(val.message);
        }
        this.dataService.hideSpinner()
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Failed to update connect the channel');
      });
    }else{
      this.isCallBack = false;
      this.dataService.hideSpinner()
      //Show user wizard with login with Fb, select page acc to platform selection
      this.showSocailConnectWizard(platform)

    }

  }

  async showSocailConnectWizard(key){
    if(key == 'facebook'){
      this.showFacebookConnectWizard = true;
    }
  }

  async platformConnected(key){
    let storeResponse = await this.storeService.getSingleStore(this.storeId)
    this.store = storeResponse.store;
    this.showFacebookConnectWizard = false;
  }

  toggleStatus(id, platform){
    let key = platform == 'facebook' ? 'isLive' :  platform == 'website' ? 'isLiveWebsite' : 'isInstaLive';
    let functionName = platform == 'facebook' ? 'setStoreStatus'  :  platform == 'website' ? 'setStoreStatusWebsite'  : 'setStoreStatusInsta';
    this.dataService.showSpinner()
    this.dataService[functionName]({storeId:this.store._id, status:!this.store[key]})
      .subscribe(val => {
        if(val.code == 200){
          let msg = platform == 'facebook' ?
          'Facebook is live Successfully, It will take some time to reflect changes on your FB Page. Please refresh your Facebook to see changes' :
          platform == 'website'? 'Website is live Successfully, It will take some time to reflect changes on your domain.' :
          'Instagram is live Successfully, It will take some time to reflect changes on your Instagram Page. Please refresh your Instagram to see changes'
          this.toastr.success(!this.store[key] ? msg : val.message);
          this.store[key] = !this.store[key]
        }else if(val.code == 202 || val.code == 203){
          document.getElementById(id)['checked'] = !document.getElementById(id)['checked'];
          this.toastr.error(val.message);
        }
        this.dataService.hideSpinner()
      }, err => {
        console.log(err)
        document.getElementById(id)['checked'] = !document.getElementById(id)['checked'];
        this.dataService.hideSpinner()
        this.toastr.error('Failed to update store');
      });
  }
}
