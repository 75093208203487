import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { DataServiceService } from 'app/utilities/data-service.service';
import { OrdersService } from 'app/pages/order/orders.service';
import * as L from 'leaflet';
import { ModalService } from 'app/_modal';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from 'app/utilities/auth.service';
import { ConfirmationDialogueService } from 'app/confirmation-dialogue.service';
import { Location } from '@angular/common';
import { htmlToText } from 'html-to-text';
//const { htmlToText } = require('html-to-text');

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {
  urlID;
  statusForm: any;
  addOns = {
    name: '',
    price: '',
    quantity: ''
  };
  reqFlavor: any;
  constructor(public router: Router, private translate: TranslateService, private confirmationService: ConfirmationDialogueService, private authService: AuthService, private permissionService: NgxPermissionsService, private route: ActivatedRoute, private toastr: ToastrService, private formBuilder: UntypedFormBuilder, private modalService: ModalService, private dataService: DataServiceService, private ordersService: OrdersService, private location: Location) {

    this.statusForm = this.formBuilder.group({
      statusReason: ['', Validators.required],
      status: ['']
    })
  }
  order = {
    createdAt: "",
    customerAddress: "",
    customerAddressCoordinates: { lat: null, lng: null },
    customerId: "",
    customerName: "",
    deliveryFee: null,
    deliveryTime: null,
    discount: null,
    discountedPrice: null,
    fbId: "",
    items: [],
    month: null,
    orderRefNo: "",
    orderType: "",
    phoneNumber: "",
    restMinimumOrderAmount: null,
    storeId: "",
    storeName: "",
    status: "",
    totalPrice: null,
    updatedAt: "",
    year: null,
    __v: null,
    _id: ""
  }
  flavors = []
  allOrderTypes = [
    { id: 0, name: 'Delivery' },
    { id: 1, name: 'Take away' },
    { id: 2, name: 'Reservation' },
    { id: 3, name: 'Digital menu' }
  ];
  status = [
    { id: 0, name: 'pending' },
    { id: 1, name: 'confirmed' },
    { id: 2, name: 'hold' },
    { id: 2, name: 'shipped' },
    { id: 2, name: 'complete' },
    { id: 3, name: 'cancelled' }
  ];
  selectedStatus = {};
  user;
  isDeal = false;

  async ngOnInit(): Promise<void> {
    try {
      this.user = await this.authService.getUser()
      this.dataService.showSpinner()
      this.urlID = this.route.snapshot.params.id;
      this.order = await this.ordersService.getSingleOrder(this.urlID)

      if (this.order) {
        if (this.order.status == "pick-up") {
          this.order.status = "Picked-up";
        }

        this.modifyOrderData()
        // try {
        //   this.createMap(this.order.customerAddressCoordinates.lat, this.order.customerAddressCoordinates.lng);
        // }
        // catch (err) {
        //   // alert('Address coordinates not found')
        // }
      } else {
        this.toastr.error("Invalid Order");

        this.router.navigateByUrl('/orders/all/view');
      }
      this.dataService.hideSpinner()
    } catch (err) {
      this.toastr.error("Invalid Order");

      this.router.navigateByUrl('/orders/all/view');
    }
  }

  modifyOrderData() {
    let index = _.findIndex(this.allOrderTypes, { id: parseInt(this.order.orderType) });
    if (index >= 0) {
      this.order.orderType = this.allOrderTypes[index].name
      this.order.createdAt = this.dataService.formatDate(new Date(this.order.createdAt))
    }
  }

  createMap(lat, lng) {
    var mapPosition = new L.LatLng(lat, lng);
    var map = new L.Map("map", {
      center: mapPosition,
      zoom: 15,
      zoomControl: true,
      scrollWheelZoom: false,
      layers: new L.TileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png")
    });
    map.zoomControl.setPosition('topright');

    var blueIcon = L.icon({
      iconUrl: 'assets/img/marker-icon.png',

      iconSize: [25, 41],
    });

    var marker = new L.Marker(mapPosition, { icon: blueIcon });
    marker.bindPopup("Delivery Location");
    map.addLayer(marker);
    map.on('click', function () {
      if (map.scrollWheelZoom.enabled()) {
        map.scrollWheelZoom.disable();
      }
      else {
        map.scrollWheelZoom.enable();
      }
    });
  }

  async changeStatus(status) {
    let statuses = [
      {pending: 1},
      {confirmed: 2},
      {cancelled: 3},
      {hold: 4},
      {shipped: 5},
      {complete: 6}
    ]
    let res;
    let payload;
    if (status == 'confirmed') {
      res = await this.confirmationService.confirmation('Are you sure you want to confirm this order?')
    }
    if (status == 'cancelled') {
      // this.statusForm.patchValue({
      //   status: 'cancelled'
      // })
      res = await this.confirmationService.confirmation('Are you sure you want to cancel this order?')
    }
    if (status == 'hold') {
      res = await this.confirmationService.confirmation('Are you sure you want to hold this order?')
    }
    if (status == 'shipped') {
      res = await this.confirmationService.confirmation('Are you sure you want to ship this order?')
    }
    if (status == 'complete') {
      res = await this.confirmationService.confirmation('Are you sure you want to complete this order?')
    }
    if (res) {
      payload = {
        "storeId": this.order.storeId,
        "status": status,
        branch: this.user.branch,
        approvedBy: this.user._id,
      }
      this.dataService.showSpinner()
      this.dataService.changeOrderStatus(this.order._id, payload).subscribe(val => {
        this.order.status = status
        this.toastr.success('Status updated')
        this.dataService.hideSpinner()
      }, async err => {
        if (err.status == 405) {
          this.toastr.error(err.error.message)

          this.order = await this.ordersService.getSingleOrder(this.urlID)

          if (this.order.status == "pick-up") {
            this.order.status = "Picked-up";
          }
          this.dataService.hideSpinner()
          this.modifyOrderData()
        } else {
          this.toastr.error('Status update failed!')
          this.dataService.hideSpinner()
          throw new Error(err)
        }

        this.dataService.hideSpinner()
      })
    }
    else{
      let select:any = document.getElementById('order-status');
    }
  }

  closeModal(id) {
    this.modalService.close(id)
  }

  async updateStatus(status) {
    let res;
    if (status == 'cancelled') {
      res = await this.confirmationService.confirmation('Are you sure you want to cancel this order?')
    }
    if (status == 'hold') {
      res = await this.confirmationService.confirmation('Are you sure you want to hold this order?')
    }
    if (status == 'shipped') {
      res = await this.confirmationService.confirmation('Are you sure you want to ship this order?')
    }
    if (status == 'complete') {
      res = await this.confirmationService.confirmation('Are you sure you want to complete this order?')
    }
    if (status == 'confirmed') {
      res = await this.confirmationService.confirmation('Are you sure you want to confirm this order?')
    }
    if (res) {
      this.dataService.showSpinner()
      let payload = {
        "storeId": this.order.storeId,
        "status": status,
        "statusReason": '',
        "branch": this.user.branch,
        "approvedBy": this.user._id,
      }
      this.dataService.changeOrderStatus(this.order._id, payload).subscribe(val => {
        this.modalService.close('change-status');
        this.toastr.success('Status updated!');
        if (val.orderUpdate.nModified == 1) {
          this.order.status = status
          this.closeModal('change-status')
        }
        this.dataService.hideSpinner()
      }, async err => {
        if (err.status == 405) {
          this.toastr.error(err.error.message)

          this.order = await this.ordersService.getSingleOrder(this.urlID)

          if (this.order.status == "pick-up") {
            this.order.status = "Picked-up";
          }

          this.modifyOrderData()
        } else {
          this.toastr.error('Status update failed!')

          throw new Error(err)
        }

        this.dataService.hideSpinner()
      })
    }
  }


  otherSelected() {
    this.statusForm.addControl('statusReasonText', new UntypedFormControl('', Validators.required))
  }

  viewFlavors(id) {
    this.modalService.open('view-flavors')
    let index = _.findIndex(this.order.items, { _id: id });

    this.flavors = this.order.items[index].selectedFlavor;
    this.reqFlavor = this.order.items[index].reqFlavor;

    if (this.order.items[index].itemKey == "deal") {
      this.isDeal = true;
    }
  }

  viewAddOns(id) {
    this.modalService.open('view-addOns')
    let index = _.findIndex(this.order.items, { _id: id });
    this.addOns = {
      name: this.order.items[index].addOnsName,
      price: this.order.items[index].addOnsPrice,
      quantity: this.order.items[index].addOnQuantity
    }
  }

  reasonSelected() {
    this.statusForm.removeControl('statusReasonText')
  }

  close() {
    /* this.router.navigate(['orders/all/view']) */
    this.location.back();
  }

  downloadThermalPrint() {
    const time = new Date(this.order.createdAt);

    let h = time.getHours();
    let m: any = time.getMinutes();

    let ampm = h >= 12 ? 'PM' : 'AM';

    h = h % 12;
    h = h ? h : 12;
    m = m < 10 ? '0' + m : m;

    const strTime = h + ':' + m + ' ' + ampm;

    let orderString: string = "";

    /* for (let i = 0; i < this.order.items.length; i++) {
      orderString += `
        <div class="item">
          <div class="left">
            - ${ this.order.items[i].quantity} ${this.order.items[i].subMenuName} | ${this.order.items[i]?.optionName}
          </div>
          <div class="right text-right">
            ${ this.order.items[i].totalPrice}
          </div>

          <br/>
        </div>
      `
    } */

    for (let i = 0; i < this.order.items.length; i++) {
      if (!this.order.items[i].isFlavor && this.order.items[i].itemKey == "single") {
        orderString += `
        <tr>
          <td align="right" valign="top">- ${ this.order.items[i].quantity} ${this.order.items[i].subMenuName} | ${this.order.items[i]?.optionName}</td>
          <td align="right" valign="top"></td>
          <td align="right" valign="top"></td>
          <td align="right" valign="top">
          ${ this.order.items[i].subMenuDiscount > 0 ? this.order.items[i].calculatedPrice : this.order.items[i].totalPrice}
          </td>
        </tr>`;
      } else {
        if (this.order.items[i].itemKey == "deal") {
          orderString += `
        <tr>
          <td align="right" valign="top">- ${ this.order.items[i].quantity} ${this.order.items[i].subMenuName}</td>
          <td align="right" valign="top"></td>
          <td align="right" valign="top"></td>
          <td align="right" valign="top">
          ${ this.order.items[i].subMenuDiscount > 0 ? this.order.items[i].calculatedPrice : this.order.items[i].totalPrice}
          </td>
        </tr>`;

          for (let j = 0; j < this.order.items[i].selectedFlavor.length; j++) {
            orderString += `
                <tr>
                  <td align="right" valign="top">&nbsp; => ${j + 1} ${this.order.items[i].selectedFlavor[j].name}</td>
                  <td align="right" valign="top"></td>
                  <td align="right" valign="top"></td>
                  <td align="right" valign="top">
                  </td>
                </tr>`
            for (let k = 0; k < this.order.items[i].selectedFlavor[j].selectedFlavor.length; k++) {
              orderString += `
                <tr>
                  <td align="right" valign="top">&nbsp; &nbsp;* ${this.order.items[i].selectedFlavor[j].selectedFlavor[k]}</td>
                  <td align="right" valign="top"></td>
                  <td align="right" valign="top"></td>
                  <td align="right" valign="top"></td>
                </tr>`
            }
          }
        }

        if (this.order.items[i].itemKey == "single") {
          orderString += `
        <tr>
          <td align="right" valign="top">- ${ this.order.items[i].quantity} ${this.order.items[i].subMenuName} | ${this.order.items[i]?.optionName}</td>
          <td align="right" valign="top"></td>
          <td align="right" valign="top"></td>
          <td align="right" valign="top">
          ${ this.order.items[i].subMenuDiscount > 0 ? this.order.items[i].calculatedPrice : this.order.items[i].totalPrice}
          </td>
        </tr>`;

          for (let j = 0; j < this.order.items[i].selectedFlavor.length; j++) {
            orderString += `
                <tr>
                  <td align="right" valign="top">&nbsp; * ${ this.order.items[i].selectedFlavor[j]}</td>
                  <td align="right" valign="top"></td>
                  <td align="right" valign="top"></td>
                  <td align="right" valign="top">
                  </td>
                </tr>`
          }
        }
      }

    }

    const hugeString =
      `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>

        <style>
            .left {
                float: left;
            }

            .right {
                float: right;
            }

            .width-100 {
                width: 100% !important;
            }

            .text-center {
                text-align: center !important;
            }

            .text-right {
                text-align: right !important;
            }

            hr {
                border-style: dashed;
            }

            .margin-bottom-30 {
                margin-bottom: 30px !important;
            }
        </style>
    </head>
    <body onload="window.print()">
        <div class="width-100">
             ${ this.order.storeName}
        </div>
        <hr />
        <div class="width-100 text-right margin-bottom-30">
            <div class="left">Date: ${ time.getDate() + "/" + (time.getMonth() + 1) + "/" + time.getFullYear()}</div>
            <div class="right">Time: ${ strTime}</div>
        </div>

        <br/>
        <br/>

        Reference #: ${ this.order.orderRefNo}</div>
        <div>Customer name: ${ this.order.customerName}</div>
        <div>Customer phone:${ this.order.phoneNumber}</div>

        <div class="width-100">
          Address:
        </div>

        <br/>

        <div class="width-100">
          ${ this.order.customerAddress}
        </div>


        <hr/>

        <div class="width-100">
          ${ orderString}
        </div>

        <hr/>
        <hr/>

        <div class="width-100">
            <div class="left">
                <div>Delivery Fee:</div>
                <div>Sub total:</div>
                <div>Discount:</div>
                <div>Total ( Without delivery ):</div>
                <div>GST (${ this.order["gstPercentage"]} %):</div>
                <div>Total:</div>
            </div>


            <div class="right text-right">
                <div>${ this.order.deliveryFee}</div>
                <div>${ this.order["subTotalPrice"]}</div>
                <div>${ this.order.discountedPrice}</div>
                <div>${ this.order["subTotalPrice"]}</div>
                <div>${ this.order["gstAmount"]}</div>
                <div>${ this.order.totalPrice}</div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>

        <hr/>
        <hr/>

        <div class="width-100">
            <div class="text-center">
                <h1>Thank You</h1>
                <h3>Powered By SellKer</h3>
            </div>
        </div>
    </body>
    </html>
    `;

    let x = `<table cellpadding="0" cellspacing="0" border="0">
    <hr/>
    <tr>
      <td>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${ this.order.storeName}
      </td>
      <td></td>
    </tr>
    <hr/>
    <br/>
    <tr>
      <td>
        Date: ${ time.getDate() + "/" + (time.getMonth() + 1) + "/" + time.getFullYear()}
      </td>
      <td></td>
    </tr>
    <br/>
    <tr>
      <td>
      Time: ${ strTime}
      </td>
      <td></td>
    </tr>
    <br/>

    <tr>
      <td>
      Reference #: ${ this.order.orderRefNo}
      </td>
      <td></td>
    </tr>
    <br/>

    <tr>
      <td>
      Customer name: ${ this.order.customerName}
      </td>
      <td></td>
    </tr>
    <br/>

    <tr>
      <td>
      Customer phone:${ this.order.phoneNumber}
      </td>
      <td></td>
    </tr>
    <br/>

    <tr>
      <td>
      Address: ${ this.order.customerAddress}
      </td>
      <td></td>
    </tr>
    <br/>

    <hr/>

    <table id="items">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
        ${orderString}
      </tbody>
      </table>
    <br/>

    <hr/>

    <tr>
      <td>
        <hr/>
        <table id="invoice">
          <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td align="right" valign="top">Delivery Fee: </td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top">
              ${this.order.deliveryFee}
            </td>
          </tr>

          <tr>
            <td align="right" valign="top">Sub Total: </td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top">
              ${this.order["subTotalPrice"]}
            </td>
          </tr>

          <tr>
            <td align="right" valign="top">Discount:</td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top">
              ${this.order.discountedPrice}
            </td>
          </tr>

          <tr>
            <td align="right" valign="top">Total ( Without delivery ): </td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top">
              ${this.order["subTotalPrice"]}
            </td>
          </tr>

          <tr>
            <td align="right" valign="top">GST (${this.order["gstPercentage"]} %): </td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top">
            ${ this.order["gstAmount"]}
            </td>
          </tr>

          <tr>
            <td align="right" valign="top">Total: </td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top"></td>
            <td align="right" valign="top">
              ${this.order.totalPrice}
            </td>
          </tr>
          </tbody>
        </table>

      </td>
      <td></td>
    </tr>
  </table>

  <hr />
  <h2>Powered By SellKer (www.sellker.com)</h2>`

    const text = htmlToText(x,
      {
        wordwrap: false,
        tables: ['#invoice', '.address', '#items'],
        tags: {
          'table': {
            options: {
              maxColumnWidth: 28
            }
          }
        }
      }
    );
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open()
    popupWinindow.document.write('<pre>' + text + '</pre>');
    popupWinindow.document.close();
  }

  downloadPDF() {
    var orderSummary = document.getElementById('orderSummary').innerHTML;
    var orderDetails = document.getElementById('orderDetails').innerHTML;
    var userInfo = document.getElementById('userInfo').innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <style>
    .row{
      margin-left: -5px !important;
      width: 100% !important;
    }
    .first{
      margin-top: 25px !important;
    }
     .summaryContent, .detailsContent, .userInfo{
       padding: 20px;
       margin: 10px;
       display: inline-block;
     }
     .summaryContent{
       height: 80% !important;
       width: 50%;
       border-left: 1px solid rgb(206, 206, 206)
     }

     .userInfo{
       width: 35%;
       height: 80% !important;
       float: left !important;
     }
     .detailsContent{
       border-top: 1px solid rgb(206, 206, 206);
       width: 91%;
     }
     h2{
       margin-bottom: 20px
     }
     .value{
       margin-bottom: 0px !important;
       margin-left: 220px !important;
     }
     .user-value{
       margin-bottom: 0px !important;
       margin-left: 105px !important;
       width: 70% !important;
     }
     .mt15{
       margtin-top: -80px !important;
     }
     .lbl{
       display: inline-block !important;
       width: 50%
     }
     .col-val{
       margin-top: -25px !important;
     }
    </style>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="summaryContent">
<h3>Order Summary</h3>
${orderSummary}
</div>

<div class="userInfo">
<h3>User Information</h3>
${userInfo}
</div>

<div class="detailsContent">
<h3>Order Details</h3>
${orderDetails}
</div>
</body>
</html>`);
    popupWinindow.document.close();
  }

}
