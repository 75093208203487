import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { AttributesService } from '../attributes.service';

@Component({
  selector: 'app-add-attribute-modal',
  templateUrl: './add-attribute-modal.component.html',
  styleUrls: ['./add-attribute-modal.component.css']
})
export class AddAttributeModalComponent implements OnInit {
  @Input() public selectedId:any;
  @Input() public attributeForm:any;
  @Input() public imagesURL:any;
  @Input() public imagesTemp:any;
  @Input() public imagesFile:any;
  @Input() public imgIndex:any;
  @Input() public attributes:any;
  @Input() public urlId:any;
  @Input() public submitted:any;
  public uploaderTemp: FileUploader;
  public uploader: FileUploader;
  uploaderOptions: FileUploaderOptions = {
    url: environment.cloudinaryURL,
  };

  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  constructor(private toastr: ToastrService, private translate: TranslateService, private attService:AttributesService, private sanitizer: DomSanitizer,public activeModal: NgbActiveModal) {

   }

  ngOnInit(): void {
    this.imageUploadConfig()
  }

  deleteOption(index){
    this.imagesURL.splice(index,1)
    this.imagesTemp.splice(index,1)
    this.imagesFile.splice(index,1)
    this.imgIndex-=1;
    if(this.attributeForm.controls.options['controls'].length == 1){
      this.toastr.error('You must have atleast one option selected')
    }
    else{
      (this.attributeForm.controls.options as UntypedFormArray).removeAt(index)
    }
  }

  removeImage(i){
    this.imagesURL.splice(i,1)
    this.imagesTemp.splice(i,1)
    this.imagesFile.splice(i,1)
  }

  openFile(id){
    document.getElementById('att-img-'+id).click();
  }

  onFileChange(event, ind) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (!file.type.startsWith('image')) {
      } else {
        if(!this.imagesFile[ind] && !this.imagesTemp[ind] && !this.imagesURL[ind]){
          this.imagesFile[ind] = file;
          this.imagesTemp[ind] = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)))
          this.imagesURL[ind] = ''
        }
        else{
          this.imagesFile.push(file)
          this.imagesTemp.push(this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file))))
          this.imagesURL.push('')
        }
      }
    }
  }

  uploadCurrentImg(imgIndex) {

    let file = this.imagesFile[imgIndex]
    this.uploaderTemp.addToQueue([file], { autoUpload: true });
    this.imgIndex = imgIndex;
    this.uploaderTemp.uploadAll();
  }

  checkDisable(){
    const val = this.attributeForm.controls.options.value;
    if(!val[val.length-1].name){
      return true
    }
    else{
      false
    }
  }

  imageUploadConfig() {
    this.uploader = new FileUploader(this.uploaderOptions);
    this.uploaderTemp = new FileUploader(this.uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };
    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);
    };


    this.uploaderTemp.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.uploaderTemp.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);
      this.imagesURL[this.imgIndex] = res.url;
      this.imagesFile[this.imgIndex] = ''
      this.imagesTemp[this.imgIndex] = ''

    };
  }

  addOptions(){
    this.imgIndex+=1;
    (this.attributeForm.get('options') as UntypedFormArray).push(new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      // price: new FormControl(0, Validators.required)
    }))
  }

  async submit(){
    this.submitted = true;
    if(this.attributeForm.valid){
    let payload = {storeId: this.urlId, attributes: []};
    payload.attributes = [...this.attributes];
    payload.attributes.forEach(s=>{
      // delete s._id;
      s.options.forEach(o=>{
        delete o.pictureFile;
        delete o.pictureTemp;
        delete o._id;
      })
    })
    if(this.selectedId){
      payload.attributes.splice(payload.attributes.findIndex(s=>s._id == this.selectedId),1);
      payload.attributes.push({...this.attributeForm.getRawValue(), _id: this.selectedId})
    }
    else{

      payload.attributes.push(this.attributeForm.getRawValue())
    }
    payload.attributes[payload.attributes.length-1].options.forEach((o,ind)=>{
      o.imgURL = this.imagesURL[ind];
    })
    let response = await this.attService.addAttribute(payload)
    if(response && response.updateAttribute?.nModified == 1){
      if(this.selectedId){
        this.toastr.success('Attribute has been updated')
      }
      else{
        this.toastr.success('Attribute has been added')
      }
      // this.modal.close('add-attribute')
      this.selectedId = '';
      this.imgIndex = 0;
    this.imagesTemp = [];
    this.imagesURL = [];
    this.imagesFile = [];
    this.activeModal.close('submit')
    }
  }
  }

  close(){
    this.activeModal.close('close')
  }

}
