import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'
import { DataServiceService } from '../../../../utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { AdminService } from '../admin.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { parseHTML, parseJSON } from 'jquery';
import { AuthService } from 'app/utilities/auth.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { StoreService } from '../../store/store.service';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent implements OnInit {
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  status;
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
    fields: "firstName lastName email phoneNumber role branch block storeId"
  }
  usersList = [];
  block = false;
  storePayload = {
    "filters": {},
    "pagination": { start: 0, limit: 1000 },
    "sort": {},
    "fields": "name"
  }
  stores
  modal: string;
  id: any;
  message: any;
  urlId;
  selectedOrg;
  loader = false;
  constructor(public router: Router, private auth: AuthService,private ngModalService: NgbModal, private route: ActivatedRoute, private translate: TranslateService, private permissionService: NgxPermissionsService, private adminService: AdminService, private dataService: DataServiceService, private toastr: ToastrService) {

  }

  async ngOnInit(): Promise<void> {
    this.loader = true;
    this.auth.getOrganizations().subscribe(res=>{
      this.selectedOrg = res.find(r=>r.selected)
    })
    this.urlId = this.route.snapshot.params.id
    if(this.urlId != 'all'){
      this.checkUsersLimit();
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }


    this.dataService.showSpinner()
    let response
    if(this.urlId != 'all'){
      response = await this.adminService.getAdmins(this.payload, this.urlId)
      this.loader = false;
    }
    else{
      response = await this.adminService.getAllAdmins(this.payload)
      this.loader = false;
    }
    if (response) {
      this.totalRecords = response.totalRecord
      this.usersList = response.userList;
      // this.usersList.map(single => {
      //   // single.block = JSON.parse(single.block);
      //   let index = _.findIndex(this.stores.stores, { _id: single.storeId })
      //   if (index >= 0) {
      //     single.storeName = this.stores.stores[index].name
      //   }
      // })
      this.createPagination()
    }
  }
  deleteAdmin(id, message) {
    let that = this;
    // this.confirmDialogService.confirmThis(message, async function () {
    //   that.dataService.showSpinner()
    //   let response:any = await that.adminService.removeMember(id, that.selectedOrg.storeId)

    //   if (response) {
    //     that.ngOnInit()
    //   }
    // }, function () {
    // })
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if (result == 'yes') {
       // console.log(result)
      let response:any = await that.adminService.removeMember(id, that.selectedOrg.storeId)
    this.ngOnInit()
      }
    });
  }

  updateAdmin(id: string) {
    this.router.navigate(['admin-register/' + id]);
  }
  // async deleteAdmin(id: string, modalId, message) {
  //   let res = await this.confirmationService.confirmation(message)
  //   // this.id = modalId;
  //   // this.message = message;
  //   // this.modalService.open('delete-admin')
  //   // let element = document.getElementById('admin-list')
  //   // element.innerHTML = element.innerHTML + '<app-delete-modal></app-delete-modal>'
  //   // this.modalService.open('delete-admin')
  //   if(res){
  //   this.dataService.showSpinner()
  //   let response = await this.adminService.deleteAdmin(id)
  //   this.ngOnInit()
  // }
  // }
  async sort(SortName: String) {
    this.loader = true;
    if (SortName == 'firstName') {
      this.payload.sort = { "firstName": 1 }
    }
    if (SortName == 'email') {
      this.payload.sort = { "email": 1 }

    }
    if (SortName == 'role') {
      this.payload.sort = { "role": 1 }
    }
    if (SortName == 'branch') {
      this.payload.sort = { "branch": 1 }
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    let response;
    if(this.urlId != 'all'){
      response = await this.adminService.getAdmins(this.payload, this.urlId)
      this.loader = false;
    }
    else{
      response = await this.adminService.getAllAdmins(this.payload)
      this.loader = false;
    }
    if (response) {
      this.totalRecords = response.totalRecord
      this.usersList = response.userList;
      this.usersList.map(single => {
        single.block = JSON.parse(single.block);
        // let index = _.findIndex(this.stores.stores, { _id: single.storeId })
        // if (index >= -0) {
        //   single.storeName = this.stores.stores[index].name
        // }
      })
      this.createPagination()
    }

  }
  async reset() {
    this.loader = true;
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    let response;
    if(this.urlId != 'all'){
      response = await this.adminService.getAdmins(this.payload, this.urlId)
      this.loader = false;
    }
    else{
      response = await this.adminService.getAllAdmins(this.payload)
      this.loader = false;
    }
    if (response) {
      this.totalRecords = response.totalRecord
      this.usersList = response.userList;
      this.usersList.map(single => {
        single.block = JSON.parse(single.block);
        // let index = _.findIndex(this.stores.stores, { _id: single.storeId })
        // if (index >= -0) {
        //   single.storeName = this.stores.stores[index].name
        // }
      })
      this.createPagination()
    }
  }

  addNewAdmin() {
    if(this.status == 204){
      this.toastr.warning('You have reached the team member limit, to add more team members update your subscription ')
      return;
    }
    this.router.navigate(['admin-register/add'])
  }
  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  async goToPage(Index) {
    this.loader = true;
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    let response;
    if(this.urlId != 'all'){
      response = await this.adminService.getAdmins(this.payload, this.urlId)
      this.loader = false;
    }
    else{
      response = await this.adminService.getAllAdmins(this.payload)
      this.loader = false;
    }
    if (response) {
      this.totalRecords = response.totalRecord
      this.usersList = response.userList;
      this.usersList.map(single => {
        single.block = JSON.parse(single.block);
        // let index = _.findIndex(this.stores.stores, { _id: single.storeId })
        // if (index >= -0) {
        //   single.storeName = this.stores.stores[index].name
        // }
      })
      this.createPagination()
    }
  }

  async toggleBlock(id) {
    let index = _.findIndex(this.usersList, { _id: id });
    this.block = !this.usersList[index].block
    let payloadBlock = {
      block: this.block
    }
    let response = await this.adminService.blockAdmin(id, payloadBlock)
    if (response.userUpdate.nModified == 1) {
      this.usersList[index].block = !this.usersList[index].block
    }
  }
  counter(i) {
    return new Array(i)
  }

  downloadPDF() {
    var innerContents = document.getElementById('pdfTable').innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <style>
      #actionsHead, #actionsValue, #blockHead, #blockValue{
        display: none;
      }
      .sort, .fa{
        display: none !important
      }
      .paginationBtn{
        display: none
      }
    </style>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="content">${innerContents}</div>
</body>
</html>`);
    popupWinindow.document.close();
  }
  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0
    this.ngOnInit()
  }

  checkUsersLimit(){
    this.dataService.checkUsersLimit(this.urlId).subscribe(res=>{
      // if(res.code == 204){
      //   document.getElementById('admin-add')['disabled'] = true;
      // }
      // if(res.code == 200){
      //   document.getElementById('admin-add')['disabled'] = false;
      // }
      this.status = res.code;
    },err=>{
      console.log({err})
    })
  }



}
