import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { ActivatedRoute } from '@angular/router';
import { OnboardingService } from 'app/utilities/onboarding.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { StoreService } from 'app/pages/settings/store/store.service';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  currentStep = 0;
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };

  // config: NgWizardConfig = {
  //   selected: 0,
  //   theme: THEME.arrows,
  //   keyNavigation: false,
  //   toolbarSettings: {
  //     toolbarExtraButtons: [
  //       { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
  //     ],
  //   }
  // };
  user;
  invoiceId
  invoice
  selectedPlatform = 'website';
  storeId;
  constructor(private ngWizardService: NgWizardService, private activatedRoute: ActivatedRoute, private onboardingService: OnboardingService, private dataService:DataServiceService, private storeService: StoreService) {
  }

  ngOnInit() {
    this.user = localStorage.getItem('user_info')
    this.user = JSON.parse(this.user)
    this.activatedRoute.queryParams.subscribe(params => {
      this.invoiceId = params['orderId']
      this.storeId = params['storeId']
      if(this.invoiceId){
        this.getInvoiceDetail(this.invoiceId)
      }else if(this.storeId){
        this.getStoreInfo(this.storeId)
      }
    })
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    this.currentStep = args.step.index
  }

  isValidTypeBoolean: boolean = true;

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }

  test(a){
    //console.log(this)
  }

  async getInvoiceDetail(invoiceId){
    this.invoice = await this.onboardingService.getInvoiceDetail(invoiceId)
    if(this.invoice){
      this.onboardingService.setStore({_id: this.invoice.storeId, name: this.invoice.storeName})
      if(this.invoice.status=='paid'){
        this.ngWizardService.show(2);
      }else{
        this.ngWizardService.show(1);
      }
    }else{
      this.ngWizardService.show(0);
    }
  }

  async getStoreInfo(storeId){
    let storeResponse = await this.storeService.getSingleStore(storeId)
    let store = storeResponse.store;
    if(store){
      this.onboardingService.setStore({_id: store._id, name: store.name})
      this.ngWizardService.show(1);
    }else{
      this.ngWizardService.show(0);
    }
  }
}
