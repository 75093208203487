import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService) { }

  async updatePaymentMethod(payload, id){
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.updatePaymentMethod(payload, id).
    subscribe(val => {
      this.dataService.hideSpinner()
      this.toastr.success('Payment method has been updated')
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        console.log(err)
        this.toastr.error(err?.error?.error || 'Payment method update failed')
        // this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }

  async updateBankDetail(payload, id){
    this.dataService.showSpinner()
  let promise = new Promise<any>((resolve, reject) => {
    this.dataService.updateBankDetail(payload, id).
    subscribe(val => {
      this.dataService.hideSpinner()
      this.toastr.success('Bank Account Details have been updated')
        resolve(val)
      },err=>{      
        this.dataService.hideSpinner()
        console.log(err)
        this.toastr.error(err?.error?.error || 'Bank Account Details update failed')
        // this.toastr.error('Failed!')
        reject(err)
      });
    })
      let result = await promise;
      return result;
  }
}
