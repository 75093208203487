import { Injectable } from '@angular/core';
import { DataServiceService } from '../../utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router) { }

  async getCustomers(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getCustomers(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
  async getOrderedCustomers(payload, storeId) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getOrderedCustomers(payload, storeId)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }
  async getUnconvertedCustomers(payload, storeId) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getUnconvertedCustomers(payload, storeId)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getCustomerProfile(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleCustomer(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val.customer)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async updateCustomer(id, payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.updateCustomer(id, payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
          this.router.navigate(['customer-profile/' + id])
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async deleteCustomer(id) {
    this.dataService.showSpinner()
    this.dataService.deleteCustomer(id)
      .subscribe(val => {
        this.router.navigate(['customers-list'])
        this.toastr.success('Deleted customer')
        this.dataService.hideSpinner()
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Internal server error')
      });
  }

  blockCustomer(id, payload) {
    this.dataService.blockCustomer(id, payload).subscribe(val => {
      this.toastr.success('Customer modified')
    }, err => {
      this.toastr.error('Internal server error')
    })
  }
  blockCustomerUnordered(id, payload) {
    this.dataService.blockNotorderedCustomer(id, payload).subscribe(val => {
      this.toastr.success('Customer modified')
    }, err => {
      this.toastr.error('Internal server error')
    })
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

}
