import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogueService {
  constructor() {
   }
  confirmation(prompt){
    
    if (confirm(prompt)) {
      return true
  } else {
      return false
  }
}
  }


