
import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router) { }



  async getSingleTag(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleTags(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async createTag(payload) {
    this.dataService.showSpinner()
    this.dataService.createTags(payload)
      .subscribe(val => {        
        this.router.navigate(['tags-list/'+payload.storeId]);
        this.toastr.success('Tag has been added successfully!')
        this.dataService.hideSpinner()
      }, err => {
        this.toastr.error('Failed!')
        this.dataService.hideSpinner()
      });
  }

  async updateTag(id, payload) {
    this.dataService.showSpinner()
    this.dataService.updateTags(id, payload)
      .subscribe(val => {
        // this.router.navigate(['store-list']);
        this.router.navigate(['tags-list/'+payload.storeId]);
        this.toastr.success('Tag has been updated successfully!')
        this.dataService.hideSpinner()
      }, err => {
        this.toastr.error('Update failed!')
        this.dataService.hideSpinner()
      });
  }


  
}

