import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/utilities/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private authService: AuthService, private router: Router) { }

  forgotPassForm = this.formBuilder.group({
    newPassword: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  })
  token;
  ngOnInit(): void {
    this.token = this.route.snapshot.params.token
  }

  async submit(){
    let payload = this.forgotPassForm.getRawValue()
    delete payload.confirmPassword;
    payload.token = this.token
    let resp = await this.authService.newPassword(payload)
    if(resp){
      setTimeout(() => {
          this.router.navigate(['login'])
      }, 1000);
    }
  }

}