import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import * as _ from 'lodash';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { OrdersService } from 'app/pages/order/orders.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from 'app/utilities/auth.service';
import { SidebarService } from 'app/sidebar.service';
import { MessagingService } from 'app/messaging.service';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, OnDestroy {
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  loader = false;
  orders;
  stores = [];
  platforms = ['facebook','instagram','website','all']
  status = [
    'pending',
    'confirmed',
    'hold',
    'cancelled',
    'shipped',
    'complete'
  ];

  filters = [
    { key: 'all', text: 'All' },
    { key: 'website', text: 'Website' },
    { key: 'facebook', text: 'Facebook' },
    { key: 'instagram', text: 'Instagram' }
  ];

  icons = [
    {logo:'fb',enable:true},
    {logo:'instagram',enable:true}
  ]
  ordersForm: any;
  selectedStore: any;
  selectedStatus: any;
  selectedRefNo: any = true;
  constructor(private dataService: DataServiceService, private messagingService: MessagingService, private authService: AuthService, private permssionService: NgxPermissionsService, private translate: TranslateService, private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, public router: Router, private ordersService: OrdersService) {

    this.ordersForm = this.formBuilder.group({
      storeId: ['', Validators.required],
      status: ['', Validators.required],
      refNo: ['', Validators.required]
    })
  }
  urlID;
  urlKEY;
  branch;
  payload:any = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
    "fields": "storeName orderType status orderRefNo totalPrice createdAt storeId branch customerName customerId paymentType"
  }
  storePayload = {
    "filters": {},
    "pagination": { start: 0, limit: 10000 },
    "sort": {},
    "fields": "name"
  }
  track;
  allOrderTypes = [
    { id: 0, name: 'Delivery' },
    { id: 1, name: 'Take away' },
    { id: 2, name: 'Reservation' },
    { id: 3, name: 'Digital menu' }
  ]

  destroyed: Subject<string> = new Subject();
  selectedPlatform: string = '';

  async ngOnInit(): Promise<void> {
    this.loader = true;
    let that = this
    this.messagingService.getOrderTrack()
      .subscribe(async track => {
        if (track) {
          /* that.refreshPageOnNotification() */
          that.reload();
        }
      })
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    this.urlID = this.route.snapshot.params.id;
    this.urlKEY = this.route.snapshot.params.key;
    if(this.urlID != 'all'){
      if (this.urlKEY == 'all') {
        this.payload.filters = {}
      }
      if (this.urlKEY == 'customer') {
        this.payload.filters = {
          ...this.payload.filters, "customerId": this.urlID
        }
      }
      if (this.urlKEY == 'store') {
        this.payload.filters = {
          ...this.payload.filters, "storeId": this.urlID
        }
      }
    }
    let response = await this.ordersService.getOrders(this.payload)
    this.loader = false;
    this.orders = await response.orders;
    this.totalRecords = response.totalRecord
    this.modifyOrdersData()
    this.createPagination()
    this.dataService.hideSpinner()

    this.ordersForm.get('refNo').valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroyed),
      debounceTime(2000),
      map((v: string) => v.trim())
    ).subscribe(() => {
      this.enteredRefNo();
    })
  }

  ngOnDestroy(): void {
    this.destroyed.next('');
    this.destroyed.complete();
  }

  async showStores() {
    this.dataService.showSpinner()
    this.selectedRefNo = false;
    if (this.stores.length == 0) {
      this.stores = await this.ordersService.getStores(this.storePayload);
      this.dataService.hideSpinner()
    }
    this.dataService.hideSpinner()
    this.selectedStore = true;
  }

  reload() {
    window.location.reload();
  }

  showStatus() {
    this.selectedRefNo = false;
    this.selectedStatus = true;
  }

  async sort(SortName: String) {
    if (SortName == 'storeName') {
      this.payload.sort = { "storeName": 1 }
    }
    if (SortName == 'orderType') {
      this.payload.sort = { "orderType": 1 }
    }
    if (SortName == 'status') {
      this.payload.sort = { "status": 1 }
    }
    if (SortName == 'price') {
      this.payload.sort = { "totalPrice": 1 }
    }
    if (SortName == 'createdAt') {
      this.payload.sort = { "createdAt": 1 }
    }
    if (SortName == 'customer') {
      this.payload.sort = { "customerName": 1 }
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    let response = await this.ordersService.getOrders(this.payload)
    this.orders = response.orders;
    this.totalRecords = response.totalRecord;
    this.modifyOrdersData();
    this.createPagination();
  }

  async goToPage(Index) {
    this.loader = true;
    this.dataService.showSpinner()
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    let response = await this.ordersService.getOrders(this.payload)
    this.orders = await response.orders;
    this.loader = false;
    this.dataService.hideSpinner()
    this.totalRecords = response.totalRecord
    this.modifyOrdersData()
    this.createPagination()
  }

  async reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    let response = await this.ordersService.getOrders(this.payload)
    this.orders = await response.orders;
    this.totalRecords = response.totalRecord
    this.modifyOrdersData()
    this.createPagination()
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  getByRefNo() {
    this.selectedRefNo = true
    this.selectedStore = false;
    this.selectedStatus = false;
  }

  async enteredRefNo() {
    this.ordersForm.value.refNo = this.ordersForm.value.refNo.toString()
    // if (this.ordersForm.value.refNo.length == 8) {

      this.loader = true;
      this.dataService.showSpinner()
      // document.getElementById('err-msg').classList.remove('show')
      if(this.ordersForm.value.refNo.trim()){
        this.payload.filters = {...this.payload.filters, $or: [{customerName:{$regex:".*"+this.ordersForm.value.refNo+".*",$options:'i'}},{orderRefNo:{$regex:".*"+this.ordersForm.value.refNo+".*",$options:'i'}},{phoneNumber:{$regex:".*"+this.ordersForm.value.refNo+".*",$options:'i'}}]}
      }else{
        delete this.payload.filters['$or']
        this.payload.filters = {...this.payload.filters}
      }
      let response = await this.ordersService.getOrders(this.payload)
      this.loader = false;
      this.orders = await response.orders;
      this.totalRecords = response.totalRecord
      this.modifyOrdersData()
      this.createPagination()
      this.dataService.hideSpinner()

    // }
    // else {
    //   document.getElementById('err-msg').classList.add('show')
    // }
  }

  async storeSelected(event) {
    this.loader = true;
    this.dataService.showSpinner()
    this.selectedStore = event;

    if (this.selectedStore) {
      this.payload.filters = {
        storeId: event,
      }
    }

    let response = await this.ordersService.getOrders(this.payload)
    this.orders = await response.orders;
    this.loader = false;
    this.totalRecords = response.totalRecord
    this.modifyOrdersData()
    this.createPagination()
    this.dataService.hideSpinner()
  }

  async statusSelected(event) {
    this.loader = true;
    this.dataService.showSpinner()
    if(this.urlID != 'all'){
    if (this.urlKEY == "customer") {
      if (event == "all") {
        this.payload.filters = {
          "customerId": this.urlID,
        }
      }

      else if (event !== "all" && this.selectedStore) {
        this.payload.filters = {
          "customerId": this.urlID,
          "storeId": this.selectedStore,
          "status": event,
        }
      }

      else {
        this.payload.filters = {
          "customerId": this.urlID,
          "status": event
        }
      }
    } else if (this.urlKEY == "store") {
      if (event == "all") {
        this.payload.filters = {
          "storeId": this.urlID,
        }
      } else {
        this.payload.filters = {
          "storeId": this.urlID,
          "status": event
        }
      }
    } else {
      this.selectedStatus = event;
      if (this.selectedStore) {
        this.payload.filters = {
          "storeId": this.selectedStore,
          "status": event
        }
        if (event == 'all') {
          this.payload.filters = {
            "storeId": this.selectedStore,
          }
        }
      }
      else {
        this.payload.filters = {
          "status": event
        }
      }
      if (event == 'all' && !this.selectedStore) {
        this.payload.filters = {}
      }
    }
  }
  else{
    if(event!='all'){

      this.payload.filters = {
        status: event
      }
    }
    else{
      this.payload.filters = {}
    }
  }

    let response = await this.ordersService.getOrders(this.payload)
    this.orders = await response.orders;
    this.loader = false;
    this.totalRecords = response.totalRecord
    this.modifyOrdersData()
    this.createPagination()
    this.dataService.hideSpinner()
  }

  async getAllOrders() {
    this.selectedStore = null;
    this.selectedStatus = '';
    if (this.urlKEY == 'all') {
      this.payload.filters = {}
    }
    if (this.urlKEY == 'customer') {
      this.payload.filters = {
        "customerId": this.urlID
      }
    }
    if(this.urlID != 'all'){
      if (this.urlKEY == 'store') {
        this.payload.filters = {
          "storeId": this.urlID
        }
      }
    }

    let response = await this.ordersService.getOrders(this.payload)
    this.orders = await response.orders;
    this.totalRecords = response.totalRecord
    this.modifyOrdersData()
    this.createPagination()

    this.ordersForm.patchValue({
      status: ''
    })
  }

  modifyOrdersData() {
    this.orders.map(single => {
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
      let index = _.findIndex(this.allOrderTypes, { id: parseInt(single.orderType) });
      if (index >= 0) {
        single.orderType = this.allOrderTypes[index].name;
      }
    })
  }


  viewOrder(id) {
    this.router.navigate(['order-detail/' + id])
  }

  counter(i) {
    return new Array(i)
  }

  downloadPDF() {
    var innerContents = document.getElementById('pdfTable').innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <style>
      #actionsHead{
        display: none
      }
      #actionsValue{
        display: none
      }
      .sort, .fa{
        display: none !important
      }
      .paginationBtn{
        display: none
      }
    </style>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="content">${innerContents}</div>
</body>
</html>`);
    popupWinindow.document.close();
  }
  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }

  async refreshPageOnNotification() {
    this.loader = true;
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    this.urlID = this.route.snapshot.params.id;
    this.urlKEY = this.route.snapshot.params.key;
    if (this.urlKEY == 'all') {
      this.payload.filters = {}
    }
    if (this.urlKEY == 'customer') {
      this.payload.filters = {
        "customerId": this.urlID
      }
    }
    if (this.urlKEY == 'store') {
      this.payload.filters = {
        "storeId": this.urlID
      }
    }
    let response = await this.ordersService.getOrders(this.payload)
    this.orders = await response.orders;
    this.loader = false;
    this.totalRecords = response.totalRecord
    this.modifyOrdersData()
    this.createPagination()
    this.dataService.hideSpinner()
  }

  toggleIcon(icon){
    this.icons.find(i=>i.logo == icon).enable = !this.icons.find(i=>i.logo == icon).enable;
  }

  changeOption(option){
    // console.log(option.target.value)
    /* this.platforms.forEach(p=>{
      document.getElementById(p).classList.remove('selected')
    })
    document.getElementById(option).classList.add('selected') */
    this.selectedPlatform = option;
    if(option!='all'){
      this.payload.filters.platform = option;
      if(this.ordersForm.value.refNo?.toString() !== ''){
        this.ngOnInit();
      }else{
        delete this.payload.filters['$or'];
        this.ngOnInit();
      }

    }
    else{
      delete this.payload.filters.platform;
      if(this.ordersForm.value.refNo?.toString() !== ''){
        this.ngOnInit();
      }else{
        delete this.payload.filters['$or'];
        this.ngOnInit();
      }
    }
  }

  dateShort(date) {
    return moment(date).calendar(null, {
      sameDay: '[Today,] hh:MM A',
      lastDay: '[Yesterday,] hh:MM A',
      sameElse: 'DD/MM/YYYY, hh:MM A'
    });
  }
}
