import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataServiceService } from '../../../utilities/data-service.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgForOf } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delivery-locations-cities-list',
  templateUrl: './delivery-locations-cities-list.component.html',
  styleUrls: ['./delivery-locations-cities-list.component.css']
})
export class DeliveryLocationsCitiesListComponent implements OnInit {
  deliveryLocations;
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
    fields: "city createdAt lat lng"
  }

  constructor(private router: Router, private translate: TranslateService, private ngModalService: NgbModal, private permissionService: NgxPermissionsService, private dataService: DataServiceService, private toastr: ToastrService) {

  }

  deliveryLocationsCityForm = new UntypedFormGroup({
    $id: new UntypedFormControl(null),
    city: new UntypedFormControl(null),
    lng: new UntypedFormControl(null),
    lat: new UntypedFormControl(null)
  });

  ngOnInit(): void {
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    this.dataService.getDeliveryLocationsCity(this.payload)
      .subscribe(val => {
        this.dataService.hideSpinner()
        this.totalRecords = val.totalRecord
        this.deliveryLocations = val.city
        this.createPagination()
      }, err => {
        this.dataService.hideSpinner()
      })
  }

  updateDeliveryLocation(id: string) {
    this.router.navigate(['delivery-location-register-cities/' + id]);
  }

  async delete(id: String, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if(result=='yes'){
        that.dataService.showSpinner()
        that.dataService.deleteDeliveryLocationsCity(id).subscribe(val => {
          that.dataService.hideSpinner()
          that.ngOnInit();
          that.toastr.success('Delivery location city has been deleted successfully!')
        }, err => {
          that.dataService.hideSpinner()
          that.toastr.error('Failed!')
        });
      }
    })
  }

  sort(sortName: String) {

    if (sortName == 'name') {
      this.payload.sort = { "name": 1 }
    }
    if (sortName == 'lng') {
      this.payload.sort = { "lng": 1 }
    }
    if (sortName == 'lat') {
      this.payload.sort = { "lat": 1 }
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getDeliveryLocationsCity(this.payload)
      .subscribe(val => {
        this.deliveryLocations = val.deliveryLocation
        this.totalRecords = val.totalRecord
        this.createPagination()
      }, err => {
      }

      )


  }
  reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getDeliveryLocationsCity(this.payload)
      .subscribe(val => {
        this.deliveryLocations = val.deliveryLocation
        this.totalRecords = val.totalRecord
        this.createPagination()
      }, err => {
      }

      )
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.getDeliveryLocationsCity(this.payload)
      .subscribe(val => {
        this.deliveryLocations = val.deliveryLocation
      }, err => {
      }

      )

  }
  addDeliveryLocationCity() {
    this.router.navigate(['delivery-location-register-cities/add'])
  }
  startPage() {
    return this.pageStart

  }
  counter(i) {
    return new Array(i)
  }

  downloadPDF() {
    var innerContents = document.getElementById('pdfTable').innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <style>
      #actionsHead{
        display: none
      }
      #actionsValue{
        display: none
      }
      .sort, .fa{
        display: none !important
      }
      .paginationBtn{
        display: none
      }
    </style>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="content">${innerContents}</div>
</body>
</html>`);
    popupWinindow.document.close();
  }
  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }
}
