import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { StoreService } from '../store.service';
import { AuthService } from 'app/utilities/auth.service';

@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.css']
})
export class StoreProfileComponent implements OnInit {

  constructor(private route: ActivatedRoute, private authService: AuthService, private translate: TranslateService, private storeService: StoreService, private permissionService: NgxPermissionsService, private dataService: DataServiceService, private router: Router) {

  }
  storeProfile;
  urlID;
  async ngOnInit(): Promise<void> {
    let user: any = await this.authService.getUser()
    this.urlID = user.storeId;
    let response = await this.storeService.getSingleStore(this.urlID)
    this.storeProfile = await response.store
    this.storeProfile.createdAt = this.dataService.formatDate(new Date(this.storeProfile.createdAt))
    this.storeProfile.updatedAt = this.dataService.formatDate(new Date(this.storeProfile.updatedAt))
  }

  goToOrders(id) {
    this.router.navigate(['orders/store/' + id])
  }

  goToMenu(id) {
    this.router.navigate(['menu-list/' + id])
  }

}
