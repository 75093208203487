import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delivery-location-cities-register',
  templateUrl: './delivery-location-cities-register.component.html',
  styleUrls: ['./delivery-location-cities-register.component.css']
})
export class DeliveryLocationCitiesRegisterComponent implements OnInit {
  DeliveryLocationCityRegister = this.formBuilder.group({
    city: ["", [Validators.required]],
    lat: ["", [Validators.required, Validators.min(9)]],
    lng: ["", [Validators.required, Validators.min(9)]],
  });
  id;
  public validationMessages = {
    name: [
      {
        type: "required",
        message: "Name is required",
      },
    ],
    lat: [
      {
        type: "required",
        message: "Latitude is required",
      },
      {
        type: "min",
        message: "Latitude must be atleast 10",
      },
    ],
    lng: [
      {
        type: "required",
        message: "Longitude is required",
      },
      {
        type: "min",
        message: "Longitude must be atleast 10",
      }
    ],
    city: [
      {
        type: "required",
        message: "City is required."
      }
    ]
  };

  payload = {
    filters: {},
    pagination: { start: 0, limit: 50 },
    sort: {}
  }
  deliveryLocationCity: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private dataService: DataServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    if (this.id != "add") {
      this.dataService.showSpinner()
      this.dataService.getSingleDeliveryLocationsCity(this.id).subscribe(val => {
        this.dataService.hideSpinner()
        this.deliveryLocationCity = val.city;

        if (this.deliveryLocationCity == null) {
          this.toastr.error("Invalid Delivery Location");

          this.router.navigateByUrl('/delivery-locations-list-cites');
        } else {
          this.DeliveryLocationCityRegister.patchValue({
            city: this.deliveryLocationCity.city,
            lat: this.deliveryLocationCity.lat,
            lng: this.deliveryLocationCity.lng
          })

        }
      }, err => {
        this.dataService.hideSpinner()
      })
    }
  }

  gotoList() {
    this.router.navigate(['delivery-location-list-cities'])
  }

  update() {
    this.dataService.showSpinner()
    this.DeliveryLocationCityRegister.value.lat = this.DeliveryLocationCityRegister.value.lat.toString();
    this.DeliveryLocationCityRegister.value.lng = this.DeliveryLocationCityRegister.value.lng.toString();

    this.dataService.updateDeliveryLocationsCity(this.id, this.DeliveryLocationCityRegister.value).subscribe(val => {
      this.dataService.hideSpinner()
      this.router.navigate(['delivery-location-list-cities'])
      this.toastr.success('Delivery location City has been updated successfully!')
    },err =>{
      this.dataService.hideSpinner()
      this.toastr.error('Failed!')
    });
    // this.router.navigate(['delivery-locations-list'])
  }

  async submit() {
    this.dataService.showSpinner()
    this.DeliveryLocationCityRegister.value.lat = this.DeliveryLocationCityRegister.value.lat.toString();
    this.DeliveryLocationCityRegister.value.lng = this.DeliveryLocationCityRegister.value.lng.toString();

    let isDuplicate = false;

    this.dataService.getDeliveryLocationsCity(this.payload)
      .subscribe(val => {
        this.dataService.hideSpinner()

        for (let i = 0; i < val.legnth; i++) {
          if (val[i].lat == this.DeliveryLocationCityRegister.value.lat && val[i].lng == this.DeliveryLocationCityRegister.value.lng) {
            isDuplicate = true;
            break;
          }
        }
      }, err => {
        this.dataService.hideSpinner()
      })

    if (!isDuplicate) {
      this.dataService.createDeliveryLocationsCity(this.DeliveryLocationCityRegister.value)
        .subscribe(val => {
          this.dataService.hideSpinner()
          this.router.navigate(['delivery-location-list-cities'])
          this.toastr.success('Delivery location City has been added successfully!')
        }, err => {
          this.dataService.hideSpinner()
          this.toastr.error('Failed!')
        });
    } else {
      this.toastr.error("A location already exists with same coordinates.")
      this.dataService.hideSpinner()
    }
  }
}
