import { Component, OnDestroy, OnInit } from "@angular/core";
import { AlertService } from "app/shared/_alert";
import * as _ from "lodash";
import { DataServiceService } from "app/utilities/data-service.service";
import { StoreService } from "app/pages/settings/store/store.service";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  newUser = false;
  store;
  lang = localStorage.getItem("lang");
  tempStore = null;
  constructor(
    private alertService: AlertService,
    private storeService: StoreService,
    private dataService: DataServiceService,
    private translate: TranslateService
  ) {}

  destroyed: Subject<string> = new Subject<string>();

  ngOnInit() {
    this.checkUserHistory();

    this.dataService.getOrgObs().subscribe((message) => {
      this.showStoreStatusBanner();
    });

    this.dataService.getUserObs().subscribe((message) => {
      this.checkUserHistory();
    });

    this.storeService.updateStoreInLayout.pipe(
      takeUntil(this.destroyed)
    ).subscribe((res) => {
      if(res) {
        this.getStoreStatus();
      }
    })
  }

  ngOnDestroy(): void {
      this.destroyed.next('');
      this.destroyed.complete();
  }

  ngAfterViewInit() {
    //this.reloadStore()
    if (this.lang == "ar") {
      document.getElementsByClassName("main-panel")[0]["style"][
        "margin-right"
      ] = "260px";
      document.getElementsByClassName("sidebar")[0]["style"].left = "auto";
    } else {
      document.getElementsByClassName("main-panel")[0]["style"][
        "margin-right"
      ] = "0px";
      document.getElementsByClassName("main-panel")[0]["style"]["width"] =
        "calc(100% - 260px);";
      document.getElementsByClassName("sidebar")[0]["style"].left = "0px";
    }
    //this.showStoreStatusBanner()
  }

  getStoreStatus() {
    this.tempStore = JSON.parse(localStorage.getItem('tempStore'));
  }

  showStoreStatusBanner() {
    let organizations = JSON.parse(localStorage.getItem("organizations"));
    if (organizations && organizations.length) {
      let currentOrg = _.find(organizations, function (org) {
        return org.selected == true;
      });
      this.dataService.getSingleStore(currentOrg.storeId).subscribe((arg) => {
        this.store = arg.store;
        this.tempStore = arg.store;
        this.storeService.setStore(arg.store);
        let ind = organizations.findIndex(
          (org) => org.storeId == currentOrg.storeId
        );
        if (ind != -1) {
          organizations[ind].subscriptionId = arg.store.subscriptionId;
        }
        localStorage.setItem("organizations", JSON.stringify(organizations));
        if (arg.store.isOnboarded == false) {
          //MUD
          //Check is the onbording is incomplete for Website move user to subscription page with store Info

          this.alertService.error(
            arg?.store?.name +
              ' is not connected succesfully. Kindly complete the <span id="my-button" (click)="goToAddStore()" data-storeId="' +
              currentOrg.storeId +
              '" class="alert-a">onboarding flow</span>.',
            {
              autoClose: false,
              keepAfterRouteChange: true,
            }
          );
        } else {
          this.alertService.clear();
        }
      });
    }
  }

  checkUserHistory() {
    if (
      !JSON.parse(localStorage.getItem("organizations"))?.length &&
      !localStorage.getItem("superadmin")
    ) {
      this.newUser = true;
    } else {
      this.newUser = false;
    }
  }

  reloadStore() {
    this.showStoreStatusBanner();
  }
}
