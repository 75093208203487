import { Component, OnInit } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-botnide-university',
  templateUrl: './botnide-university.component.html',
  styleUrls: ['./botnide-university.component.css']
})
export class BotnideUniversityComponent implements OnInit {

  constructor(private dataService: DataServiceService, private router: Router, private sanitizer: DomSanitizer) { }
  pageLimit = 50;
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  payload = {
    filters: {},
    pagination: { start: this.pageStart, limit: this.pageLimit },
    sort: {},
  }
  org;
  tutorialVideos:any = [];
  ngOnInit(): void {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    // this.payload.filters = {storeId: this.org.storeId}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.dataService.showSpinner()
    this.dataService.getTutorialVideos(this.payload)
      .subscribe(val => {
        this.dataService.hideSpinner()
        this.totalRecords = val.totalRecord
        val.tutorialVideos.forEach(t=>{
          t.searched = true;
          t.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(t.videoLink)
        })
        this.tutorialVideos = val.tutorialVideos;

        // this.createPagination()
      }, err => {
        this.dataService.hideSpinner()
      })
  }

  addStore(){
    this.router.navigate(['add-store'])
  }

  search(e){
    let val = e.target.value;
    if(val){
      this.tutorialVideos.filter(v=>!v.name.toLowerCase().includes(val)).forEach(t=>t.searched = false)
      this.tutorialVideos.filter(v=>v.name.toLowerCase().includes(val)).forEach(t=>t.searched = true)
    }
    else{
      this.tutorialVideos.forEach(t=>t.searched = true)
    }
  }

  getSearchedVideos(){
    return this.tutorialVideos.filter(t=>t.searched)
  }

}
