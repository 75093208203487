import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {io, Socket} from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private  socketInstance: Socket;
  baseURL = environment.baseURL;

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private router: Router) {
    //FOR PROD
    //this.socketInstance = io(this.baseURL);
   }

  async makeMeLive(payload) {
    this.socketInstance.emit('imlive', payload);
  }

  async switchMyStore(payload) {
    //this.socketInstance.emit('switch-store', payload);
  }

  newMessageReceived() {
    const observable = new Observable<{ user: String, message: String}>(observer => {
      this.socketInstance.on('message-store', (data) => {
        observer.next(data);
      });
      return () => {
        this.socketInstance.disconnect();
      };
    });
    return observable;
  }


}

//_id: "5f8426b8680cfa0017a96413"
