import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-platform',
  templateUrl: './select-platform.component.html',
  styleUrls: ['./select-platform.component.css']
})
export class SelectPlatformComponent implements OnInit {
  @Output('selectPlatform') selectPlatform: EventEmitter<any> = new EventEmitter;
  small=false;
  big=true;
  constructor() { }

  ngOnInit(): void {
  }

  socialPlatform(platformS){
    this.selectPlatform.emit(platformS);
  }

}
