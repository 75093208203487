import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { StoreCategoryService } from '../store-category.service';

@Component({
  selector: 'app-add-store-category',
  templateUrl: './add-store-category.component.html',
  styleUrls: ['./add-store-category.component.css']
})
export class AddStoreCategoryComponent implements OnInit {
  constructor(private router: Router, private catService: StoreCategoryService, private route: ActivatedRoute,private sanitizer: DomSanitizer,) { }
  public uploader: FileUploader;
  public validationMessages = {
    name: [
      {
        type: "required",
        message: "Name is required",
      },
    ],
    detail: [
      {
        type: "required",
        message: "Details is required",
      },
    ]
  };
  categoryForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    detail: new UntypedFormControl('', Validators.required),
  })
  urlID;
  orgs = [];
  selectedOrg;
  previewImg:any;
  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id;
    this.orgs = JSON.parse(localStorage.getItem('organizations')) || []
      this.selectedOrg = this.orgs.length ? this.orgs.find(o=>o.selected) : null
      if(this.urlID != 'add'){
        this.patchCategory()
      }      
    }
  

  gotoList(){
    
    if(this.selectedOrg){
      this.router.navigate(['store-categories-list'])
    }
    else{
      this.router.navigate(['store-categories-list'])
    }
  }

  submit(){
    let payload = this.categoryForm.getRawValue();
    console.log({payload})
    if(this.selectedOrg){
      payload.storeId = this.selectedOrg.storeId
    }
    this.catService.createCategory(payload);
  }

  update(){
    let payload = this.categoryForm.getRawValue();
    if(this.selectedOrg){
      payload.storeId = this.selectedOrg.storeId
    }
    this.catService.updateCategory(this.urlID, payload);
  }

  async patchCategory(){
    let response = await this.catService.getSingleCategory(this.urlID)
    if(response){
      this.categoryForm.patchValue(response.storeCategory)
    }
  }

  removeImg() {
    this.uploader.clearQueue();
    this.categoryForm.patchValue({
      picture: ''
    })
  }

}

