import { Injectable } from '@angular/core';
import { DataServiceService } from '../../utilities/data-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DealsService {

  constructor(private dataService: DataServiceService, private router: Router, private toastr: ToastrService) { }

  async getSingleStore(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleStore(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val.store.orderType)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getSingleDeal(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleDeal(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val.deal)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getStores(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreList(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val.stores)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getSingleMenu(id) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getSingleMenu(id)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val.menu.menu)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  createDeal(payload) {
    this.dataService.showSpinner()
    this.dataService.createDeal(payload).subscribe(val => {
      this.dataService.hideSpinner()
      this.toastr.success('Deal has been added successfully!')
      this.router.navigate(['deals'])
    }, err => {
      this.dataService.hideSpinner()
      this.toastr.error('Internal server error!')
    });
  }

  updateDeal(payload, id) {
    this.dataService.showSpinner()
    this.dataService.updateDeal(payload, id).subscribe(val => {
      this.dataService.hideSpinner()
      this.toastr.success('Deal has been updated successfully!')
      this.router.navigate(['deals'])
    }, err => {
      this.dataService.hideSpinner()
      this.toastr.error('Internal server error!')
    });
  }

  async blockDeal(id, payload) {
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.blockDeal(id, payload).subscribe(val => {
        this.toastr.success('Deal modified')
        resolve(val)
      }, err => {
        this.toastr.error('Internal server error')
        reject(err)
      });
    })
    let result = await promise;
    return result;
  }

  async getDeals(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getDeals(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getStoreAllDeals(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreAllDeals(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async getStoreDeals(payload) {
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getStoreDeals(payload)
        .subscribe(val => {
          this.dataService.hideSpinner()
          resolve(val)
        }, err => {
          this.dataService.hideSpinner()
          reject(err)
        });
    })
    let result = await promise;
    return result;
  }

  async deleteDeal(id) {
    this.dataService.showSpinner()
    
    let promise = new Promise<boolean>((resolve, reject) => {
      this.dataService.deleteDeal(id).subscribe(val => {
        this.dataService.hideSpinner()
        this.toastr.success('Deal has been deleted successfully!')
        resolve(true)
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Internal server error!')
        resolve(false)
      })
    })

    let result = await promise;
    return result;
  }

  async addDealCategory(payload) {
    this.dataService.showSpinner()
    
    let promise = new Promise<boolean>((resolve, reject) => {
      this.dataService.addDealCategory(payload).subscribe(val => {
        this.dataService.hideSpinner()
        this.toastr.success('Deal category added!')
        resolve(val)
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Internal server error!')
        reject(err)
      })
    })

    let result = await promise;
    return result;
  }

  async getAllDealCategories(payload) {
    this.dataService.showSpinner()
    
    let promise = new Promise<boolean>((resolve, reject) => {
      this.dataService.allDealCategories(payload).subscribe(val => {
        this.dataService.hideSpinner()
        resolve(val)
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Internal server error!')
        reject(err)
      })
    })

    let result = await promise;
    return result;
  }

  async getSingleDealCategory(id) {
    this.dataService.showSpinner()
    
    let promise = new Promise<boolean>((resolve, reject) => {
      this.dataService.singleDealCategory(id).subscribe(val => {
        this.dataService.hideSpinner()
        resolve(val)
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Internal server error!')
        reject(err)
      })
    })

    let result = await promise;
    return result;
  }

  async updateDealCategory(id, payload) {
    this.dataService.showSpinner()
    
    let promise = new Promise<boolean>((resolve, reject) => {
      this.dataService.updateDealCategory(id, payload).subscribe(val => {
        this.dataService.hideSpinner()
        this.toastr.success('Deal Category has been updated successfully!')
        resolve(val)
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Internal server error!')
        reject(err)
      })
    })

    let result = await promise;
    return result;
  }

  async deleteDealCategory(id) {
    this.dataService.showSpinner()
    
    let promise = new Promise<boolean>((resolve, reject) => {
      this.dataService.deleteDealCategory(id).subscribe(val => {
        this.dataService.hideSpinner()
        this.toastr.success('Deal Category has been deleted successfully!')
        resolve(val)
      }, err => {
        this.dataService.hideSpinner()
        this.toastr.error('Internal server error!')
        reject(err)
      })
    })

    let result = await promise;
    return result;
  }
}
