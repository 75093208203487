import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-single-variant-details',
  templateUrl: './single-variant-details.component.html',
  styleUrls: ['./single-variant-details.component.css']
})
export class SingleVariantDetailsComponent implements OnInit {
  
  @Input('variant') variantForm;
  @Input('ind') ind;
  @Input('urlId') urlId;
  constructor(private sanitizer: DomSanitizer) { }
  public dpUploader: FileUploader;
  public uploaderTemp: FileUploader;
  uploading = false;
  @Input() singleProduct;
  // variantForm = new FormGroup({
  //   regularPrice: new FormControl('', Validators.required),
  //   salePrice: new FormControl('', Validators.required),
  //   stockStatus: new FormControl('', Validators.required),
  //   sku: new FormControl('', Validators.required),
  //   displayPicture: new FormControl('', Validators.required)
  // })
  schedule = false;
  previewImg:any;
  imgIndex = 0;
  picturegallery:any = []
  productGallery:any = []
  uploaderOptions: FileUploaderOptions = {
    url: environment.cloudinaryURL,
  };
  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  ngOnInit(): void {
    console.log(this.singleProduct)
    const uploaderOptions: FileUploaderOptions = {
      url: environment.cloudinaryURL,
            
    };

    this.dpUploader = new FileUploader(uploaderOptions);
    this.dpUploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      // console.log('hereeeeeeeeee2',fileItem)
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.dpUploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      // console.log('hereeeeeeeeeeee',response)
      let res: any = JSON.parse(response);
      // this.picturegallery.push(res.url)
      this.variantForm.patchValue({
        displayPicture: res.url
      })
      return { item, response, status, headers };
    };

    this.dpUploader.onAfterAddingFile = async (fileItem) => {
      this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
      this.variantForm.patchValue({
        displayPicture: ''
      })
    }
    // console.log(this.variantForm)
    this.previewImg = this.variantForm.getRawValue().displayPicture ? this.variantForm.getRawValue().displayPicture : '';
    this.imageUploadConfig();

    const picturegalleryFormArray = this.variantForm.get('picturegallery') as UntypedFormArray
    
    if(picturegalleryFormArray.controls.length > 0){
        picturegalleryFormArray.controls.forEach(c=>{
        this.productGallery.push({temp: c.value, file: c.value, url: c.value})
      })
    }
    
  }

  openSchedule(){
    this.schedule = !this.schedule;
    if(!this.schedule){
      this.variantForm.removeControl('salePriceDate')
    }
    else{
      this.variantForm.addControl('salePriceDate', new UntypedFormGroup({ from: new UntypedFormControl('', Validators.required), to: new UntypedFormControl('', Validators.required) }))
    }
  }

  removeImg() {
    this.previewImg = '';
    this.dpUploader.clearQueue();
    this.variantForm.patchValue({
      displayPicture: ''
    })
  }

  async dpUpload(){
    this.uploading = true;
    let uploaded:any = await this.dpUploader.uploadAll()
    // setTimeout(() => {
      if(uploaded){
        this.uploading = false;
      }
      // }, 4000);
  }

  checkUpload(){
    return this.productGallery.filter(p=>!p.uploaded).length ? false : true;
  }

  removeGalleryImg(i){
    // this.uploaderTemp.removeFromQueue(this.productGallery[i].file)
    this.productGallery.splice(i,1)
    const picturegalleryFormArray = this.variantForm.get('picturegallery') as UntypedFormArray    
    if (picturegalleryFormArray.controls.length > 1) {
      picturegalleryFormArray.removeAt(i)      
    }
    this.picturegallery.splice(i,1)
    if(!this.productGallery.length){
      this.uploaderTemp.clearQueue()
    }
  }

  imageUploadConfig() {
    this.uploaderTemp = new FileUploader(this.uploaderOptions);

    this.uploaderTemp.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.uploaderTemp.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);
        this.picturegallery.push(res.url)
        this.productGallery.forEach(gal=>{
          gal.uploaded = true;
        })
        this.addImgToProductForm(res.url);         
    };
  }


  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files
      // console.log(file.length)
      for(let i=0; i<file.length; i++){
        // if(!this.productGallery){
        //   this.productGallery = [];
        // }
        // console.log(this.productGallery)
        this.productGallery.push({
          file: file[i],
          temp: this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file[i]))),
          url: ''
        })
      }
      // console.log(this.productGallery)
    }
  }

  uploadImages(){
    // let that = this;
    this.productGallery.forEach((image,i)=>{
      // setTimeout(() => {
        if(!image.uploaded){
        let file = image.file
        this.imgIndex = i;
        this.uploaderTemp.addToQueue([file], { autoUpload: true });
        this.uploaderTemp.uploadAll();
        }
        // if(i == this.productGallery.length -1){
        //   this.productGallery = [];
        // }
      // }, 3000);
    })
  }

  addFile(){
    document.getElementById('add-variant-img-'+this.ind).click();
  }

  allowDropFunction(){
    let type = 4;        
      return (dragData: any) => dragData.width== type || dragData.estAdaptable == true;
  }

  getVariationName(){
    let variationKeys = this.variantForm.controls.variationKeys.value
    let variantName = ''
    for (let index = 0; index < variationKeys.length; index++) {
      variantName += variationKeys[index].attributeName+ ': ' + variationKeys[index].attributeValue + ' ';
      if(index == variationKeys.length-1){
        return variantName
      }
    }    
  }

  checkUploadDisable(){
    let result:any = [];
    this.productGallery.forEach(gal=>{
      if(this.singleProduct?.picturegallery.find(p=>p==gal.url)){
        result.push(true)
      }
      else{
        result.push(false)
      }
    })
    if(!result.includes(false)){
      return true
    }
    else{
      return false
    }
  }

  addImgToProductForm(imgURL){
    const currentFormArray = this.variantForm.get('picturegallery') as UntypedFormArray
    currentFormArray.push(new UntypedFormControl(imgURL, Validators.required))
  }

  setDate(e,type){    
    this.variantForm.controls.salePriceDate.setValue({...this.variantForm.controls.salePriceDate.value,[type]:e.target.value})
    // this.salePriceDate[type] = e.target.value;
    console.log(this.variantForm)
  }
}
