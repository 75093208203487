import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './utilities/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private messageSource = new BehaviorSubject(null);
  public profileObs$ = new BehaviorSubject(this.auth.getUser());

  currentMessage = this.messageSource.asObservable();
  constructor(private auth: AuthService) { }
    getProfileObs(): Observable<any> {
        return this.profileObs$.asObservable();
    }

    setProfileObs(user: any) {
        this.profileObs$.next(user);
    }

}
