import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AttributesService } from 'app/pages/attributes/attributes.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ModalService } from 'app/_modal';
import * as moment from 'moment';
import { ProductService } from '../../product.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddAttributeModalComponent } from 'app/pages/attributes/add-attribute-modal/add-attribute-modal.component';
import { AttributeModalComponent } from '../../attribute-modal/attribute-modal.component';

@Component({
  selector: 'app-simple-product',
  templateUrl: './simple-product.component.html',
  styleUrls: ['./simple-product.component.css']
})
export class SimpleProductComponent implements OnInit {
  @Input() urlId;
  @Input() singleProduct;
  @Input() addProduct;
  @Input() addedAttributes;  
  @Input() salePriceDate;  

  constructor(private dataService: DataServiceService,private ngModalService: NgbModal, private productService: ProductService, private attService: AttributesService, private modalService: ModalService) { }
  schedule = false;
  editing = false;
  orgs = [];
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  selectedOrg;
  attributes = [];
  selectedAttribute:any = {name: '', options: [], _id: '', isVisiblePage: false};
  payload = {
    filters: {},
    pagination: { start: 0, limit: 999 },
    sort: {},
  }
  ngOnInit(): void {
    this.orgs = JSON.parse(localStorage.getItem('organizations'))
      this.selectedOrg = this.orgs.find(o=>o.selected)
    this.getAttributes()    
    if(this.urlId!='new' && this.singleProduct.productType == 'simple'){
      setTimeout(() => {
        this.addProduct.get('regularPrice').setValue(this.singleProduct.regularPrice)
        this.addProduct.get('salePrice').setValue(this.singleProduct.salePrice)
        this.addProduct.get('sku').setValue(this.singleProduct.sku)
        this.addProduct.get('stockStatus').setValue(this.singleProduct.stockStatus)
        if(this.singleProduct.salePriceDate && this.singleProduct.salePriceDate.from){
          this.schedule = true;
          this.salePriceDate.from = moment(new Date(this.singleProduct.salePriceDate.from)).format('YYYY-MM-DD')          
          this.salePriceDate.to = this.singleProduct.salePriceDate.to ? moment(new Date(this.singleProduct.salePriceDate.to)).format('YYYY-MM-DD') : ''
        }        
        this.attributes.forEach((att,ind)=>{
          let foundProduct = this.singleProduct.attributes.find(a=>a.attributeId == att._id)
          if(foundProduct){
            this.addedAttributes.push({...att,isVisiblePage: foundProduct.isVisiblePage})            
            this.addedAttributes[this.addedAttributes.length-1].options.forEach(o=>o.checked = false)            
            foundProduct.attributeValue.forEach((op,j)=>{
              // if(this.addedAttributes[this.addedAttributes.length-1].options.find(o=>o._id == op.optionId)){
                // this.addedAttributes[this.addedAttributes.length-1].options[j].checked = true;
                let i = this.addedAttributes[this.addedAttributes.length-1].options.findIndex(o=>o._id == op.optionId);
                if(i!=-1){
                  this.addedAttributes[this.addedAttributes.length-1].options[i].checked = true;
                }
              // }
            })
          }
        })        
      }, 4000);
    }
    
  }

  setDate(e,type){    
    this.salePriceDate[type] = e.target.value;
  }

  openSchedule(){
    this.schedule = !this.schedule;
    if(!this.schedule){
      this.salePriceDate.from = ''
      this.salePriceDate.to = ''
    }    
  }


  async getAttributes(){
   let response = await this.attService.getAttributes(this.selectedOrg.storeId);
   if(response){
     this.attributes = response.attributes;
   }
  }

  optionChanged(e, i){
    const checked = e.target.checked;
    this.selectedAttribute.options[i].checked = checked;
  }

  attributeSelected(e){
    this.editing = false;
    this.selectedAttribute =  JSON.parse(JSON.stringify(this.attributes.find(a=>a._id == e.target.value)))
    this.selectedAttribute.options.forEach(o=>{
      o.checked = false;
    })
  }

  openModal(){
    this.editing = false;
    // this.modalService.open('add-product-attribute')
    this.showModal()
  }

  // close(){
  //   this.editing = false;
  //   this.modalService.close('add-product-attribute')
  // }

  // submit(){
  //   if(!this.addedAttributes.find(a=>a._id == this.selectedAttribute._id)){
  //     this.addedAttributes.push(this.selectedAttribute);
  //   }
  //   else{
  //     let index = this.addedAttributes.findIndex(a=>a._id == this.selectedAttribute._id)
  //     this.addedAttributes[index] = this.selectedAttribute;
  //   }
  //   this.modalService.close('add-product-attribute');
  // }

  removeAttribute(ind){
    this.addedAttributes.splice(ind, 1)
  }

  editAttribute(id){
    this.editing = true;
    this.selectedAttribute = JSON.parse(JSON.stringify(this.addedAttributes.find(a=>a._id == id)))    
    // this.modalService.open('add-product-attribute');
    this.showModal()
  }

  showModal(){
    const modalRef = this.ngModalService.open(AttributeModalComponent,this.modalOptions);
    modalRef.componentInstance.editing = this.editing;
    modalRef.componentInstance.selectedAttribute = this.selectedAttribute;
    modalRef.componentInstance.type = 'simple';
    modalRef.result.then(async (result) => {
      if (result == 'submit') {
        if(!this.addedAttributes.find(a=>a._id == this.selectedAttribute._id)){
          this.addedAttributes.push(this.selectedAttribute);
        }
        else{
          let index = this.addedAttributes.findIndex(a=>a._id == this.selectedAttribute._id)
          this.addedAttributes[index] = this.selectedAttribute;
        }
      }
    });
  }

  checkDisable(){
    // console.log(this.selectedAttribute)
    return !this.selectedAttribute.name || this.addedAttributes.find(a=>a._id == this.selectedAttribute._id) ? true : false;
  }
  

}
