import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../invoice/invoice.service';

@Component({
  selector: 'app-change-subscription',
  templateUrl: './change-subscription.component.html',
  styleUrls: ['./change-subscription.component.css']
})
export class ChangeSubscriptionComponent implements OnInit {

  subscriptions = [];
  selectedSubscription;
  isPaymentShow = false;
  isPaymentSelected = false;
  isSubSelected = true;
  paymentMethod;
  tempStoreInfo;
  promoCode;
  isPromoCodeCallback = false;
  isPromoCodeCorrect = false;
  promoCodeDiscount = 0;
  isPromoCodeCallbackAPI = false;
  promoCodeInfo;
  currentAmount = 0;
  invoiceInfo;
  storeId = ''
  orgs;
  selectedOrg;
  currentSubIndex = 0;
  selectedSubscriptionIndex = 0;
  callBack = false;
  newSubAmount = 0;
  currentSubAmount = 0;
  outstandingInfo

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private activatedRoute: ActivatedRoute, private router: Router, private invoiceService: InvoiceService) { }

  ngOnInit(): void {
    this.orgs = JSON.parse(localStorage.getItem('organizations')) || []
    this.selectedOrg = this.orgs.length ? this.orgs.find(o=>o.selected) : null;
    this.getInvoice()

  }


  getSubscription(){
    let query = {filters:{}, pagination:{start:0, limit:50}, sort:{"createdAt":1}}
    this.dataService.getSubscriptions(query)
      .subscribe((arg) => {
        for (let index = 0; index < arg.subscriptions.length; index++) {
          if(arg.subscriptions[index]._id == this.invoiceInfo?.subscriptionId){
            this.currentSubIndex = index;
            arg.subscriptions[index]['isSelected'] = true;
            this.selectedSubscription = arg.subscriptions[index];
          }else{
            arg.subscriptions[index]['isSelected'] = false;
          }


          if(index == arg.subscriptions.length-1){
            this.subscriptions = arg.subscriptions;
            this.callBack = false;
            this.dataService.hideSpinner()
          }
        }
      });
  }

  selectSubscription(sub,index){
    this.selectedSubscription = sub;
    this.isPromoCodeCallback = false;
    this.isPromoCodeCallbackAPI = false;
    this.promoCode = undefined;
    this.currentAmount = Math.round(this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price);
    for (let index = 0; index < this.subscriptions.length; index++) {
      this.subscriptions[index]['isSelected'] = false;
    }
    this.subscriptions[index]['isSelected'] = true;
    this.selectedSubscriptionIndex = index
    this.isSubSelected = true;
    if(this.selectedSubscriptionIndex !== 3 && (this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price) > 0){
      if(this.isUpgradeNow()){
        this.isPaymentShow =true;
        this.newSubAmount = this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price;
        this.currentSubAmount = Math.round(this.subscriptions[this.currentSubIndex]?.isDiscount ? (this.subscriptions[this.currentSubIndex]?.price - this.subscriptions[this.currentSubIndex]?.discount) :this.subscriptions[this.currentSubIndex]?.price);
        if(this.outstandingInfo?.amount > 0 ){
          if(this.invoiceInfo?.isIIAP){
            this.currentAmount = Math.round((this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
          }else{
            this.currentAmount = Math.round(this.currentSubAmount + (this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
          }

        }else{
          if(this.invoiceInfo?.isIIAP){
            this.currentAmount = Math.round(this.newSubAmount - this.currentSubAmount);
          }else{
            this.currentAmount = Math.round(this.currentSubAmount + (this.newSubAmount - this.currentSubAmount));
          }
        }

        // console.log(newSubAmount, currentSubAmount)
      }else{
        this.isPaymentShow =false;
      }

    }else{
      this.isPaymentShow =false;
    }
  }

  selectPaymentType(key){
    this.isPaymentSelected = true;
    this.paymentMethod = key
  }


  async updateStoreSubscription(){
    let payload = {
      storeId: this.selectedOrg.storeId,
      subscriptionId:this.selectedSubscription?._id,
      subscriptionName:this.selectedSubscription?.name,
      subscriptionAmount:0,
      paymentVia:this.paymentMethod,
      currentInvoiceId: this.invoiceInfo?._id,
      currentInvoiceRefNo : this.invoiceInfo?.invoiceRefNo,
      invoiceId: this.outstandingInfo.invoicesId,
      invoiceRefNo: this.outstandingInfo.invoiceRefNo,
      initialInvoiceAmount: this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price
    }
    if(this.selectedSubscription?.promotionCode){
      payload.subscriptionAmount = this.selectedSubscription?.discountAmount
      payload['promotionCode'] = this.selectedSubscription?.promotionCode
      payload['promotionId'] = this.selectedSubscription?.promotionId
    }else{
      this.newSubAmount = this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price;
      this.currentSubAmount = Math.round(this.subscriptions[this.currentSubIndex]?.isDiscount ? (this.subscriptions[this.currentSubIndex]?.price - this.subscriptions[this.currentSubIndex]?.discount) :this.subscriptions[this.currentSubIndex]?.price);


      if(this.outstandingInfo?.amount > 0 ){
        payload.subscriptionAmount = Math.round((this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
      }else{
        payload.subscriptionAmount = Math.round(this.newSubAmount - this.currentSubAmount);
      }
    }


    this.invoiceService.upgradeSubscriptionForStore(payload)
    .then((arg)=>{
      if(payload.subscriptionAmount > 0){
        window.location.href= environment.botURL + '/paySubscription?orderId='+ arg.masterInvoiceCurrent._id +'&pm='+ arg.masterInvoiceCurrent.paymentVia +'&mtId='+ arg.masterInvoiceCurrent.storeId
      }else{
        this.toastr.error('FAILED')
      }
    })

  }

  isFormValid(){
    if (this.isSubSelected) {
      if((this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price) == 0){
        return true;
      }else{
        if(this.paymentMethod){
          return true;
        }else{
          return false
        }
      }
    } else {
      return false;
    }
  }

  calculatePercentage(price,discount){
    if(price && discount){
      return Math.ceil((discount/price)*100)
    }
  }

  checkPromoCode(){
    let currentPromo = this.promoCode.trim()
    if(currentPromo.length > 3){
      this.isPromoCodeCallbackAPI= true;
      this.dataService.verifyPromoCode(currentPromo)
      .subscribe((arg) => {
        this.isPromoCodeCallbackAPI= false;
        if(arg){
          this.isPromoCodeCallback =true;
          if(arg.isActive){
            this.promoCodeDiscount = arg.discount
            this.isPromoCodeCorrect = true;
            this.promoCodeInfo = arg;
            this.newSubAmount = this.selectedSubscription?.price - (this.selectedSubscription?.price * (arg.discount/100));
            this.currentSubAmount = Math.round(this.subscriptions[this.currentSubIndex]?.isDiscount ? (this.subscriptions[this.currentSubIndex]?.price - this.subscriptions[this.currentSubIndex]?.discount) :this.subscriptions[this.currentSubIndex]?.price);
            let diffAmount
            if(this.outstandingInfo?.amount > 0 ){
              if(this.invoiceInfo?.isIIAP){
                diffAmount = Math.round((this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
              }else{
                diffAmount = Math.round((this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount ));

              }

            }else{
              if(this.invoiceInfo?.isIIAP){
                diffAmount = Math.round(this.newSubAmount - this.currentSubAmount);
              }else{
                diffAmount = Math.round(this.currentSubAmount + (this.newSubAmount - this.currentSubAmount));
              }

            }

            if(diffAmount > 0){
              this.selectedSubscription['discountAmount'] = diffAmount
            }else{
              this.selectedSubscription['discountAmount'] = 0
            }
            this.selectedSubscription['promotionCode'] = arg.code
            this.selectedSubscription['promotionId'] = arg._id
            this.currentAmount = Math.round(this.selectedSubscription['discountAmount'])
          }else{
            this.isPromoCodeCorrect = false;
            this.newSubAmount = this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price;
            this.currentSubAmount = Math.round(this.subscriptions[this.currentSubIndex]?.isDiscount ? (this.subscriptions[this.currentSubIndex]?.price - this.subscriptions[this.currentSubIndex]?.discount) :this.subscriptions[this.currentSubIndex]?.price);

            if(this.outstandingInfo?.amount > 0 ){
              if(this.invoiceInfo?.isIIAP){
                this.currentAmount = Math.round((this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
              }else{
                this.currentAmount = Math.round(this.currentSubAmount + (this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
              }

            }else{
              if(this.invoiceInfo?.isIIAP){
                this.currentAmount = Math.round(this.newSubAmount - this.currentSubAmount);
              }else{
                this.currentAmount = Math.round(this.currentSubAmount + (this.newSubAmount - this.currentSubAmount));
              }
            }
          }
        }else{
          this.isPromoCodeCallback = true;
          this.isPromoCodeCorrect = false;
          this.selectedSubscription['promotionCode'] = undefined
          this.selectedSubscription['promotionId'] = undefined
          this.selectedSubscription['discountAmount'] = 0
          this.promoCodeInfo = undefined

          this.newSubAmount = this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price;
          this.currentSubAmount = Math.round(this.subscriptions[this.currentSubIndex]?.isDiscount ? (this.subscriptions[this.currentSubIndex]?.price - this.subscriptions[this.currentSubIndex]?.discount) :this.subscriptions[this.currentSubIndex]?.price);
          if(this.outstandingInfo?.amount > 0 ){
            if(this.invoiceInfo?.isIIAP){
              this.currentAmount = Math.round((this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
            }else{
              this.currentAmount = Math.round(this.currentSubAmount + (this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
            }

          }else{
            if(this.invoiceInfo?.isIIAP){
              this.currentAmount = Math.round(this.newSubAmount - this.currentSubAmount);
            }else{
              this.currentAmount = Math.round(this.currentSubAmount + (this.newSubAmount - this.currentSubAmount));
            }
          }
        }

      });
    }else{
      this.isPromoCodeCallback = true;
      this.isPromoCodeCorrect = false;
      this.selectedSubscription['promotionCode'] = undefined
      this.selectedSubscription['promotionId'] = undefined
      this.selectedSubscription['discountAmount'] = 0
      this.promoCodeInfo = undefined
      this.newSubAmount = this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price;
      this.currentSubAmount = Math.round(this.subscriptions[this.currentSubIndex]?.isDiscount ? (this.subscriptions[this.currentSubIndex]?.price - this.subscriptions[this.currentSubIndex]?.discount) :this.subscriptions[this.currentSubIndex]?.price);
      if(this.outstandingInfo?.amount > 0 ){
        if(this.invoiceInfo?.isIIAP){
          this.currentAmount = Math.round((this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
        }else{
          this.currentAmount = Math.round(this.currentSubAmount + (this.outstandingInfo?.amount - this.currentSubAmount) + (this.newSubAmount - this.currentSubAmount));
        }

      }else{
        if(this.invoiceInfo?.isIIAP){
          this.currentAmount = Math.round(this.newSubAmount - this.currentSubAmount);
        }else{
          this.currentAmount = Math.round(this.currentSubAmount + (this.newSubAmount - this.currentSubAmount));
        }
      }
    }

  }

  checkPromoCodeForSubscriptionDegered(){
    let currentPromo = this.promoCode.trim()
    if(currentPromo.length > 3){
      this.isPromoCodeCallbackAPI= true;
      this.dataService.verifyPromoCode(currentPromo)
      .subscribe((arg) => {
        this.isPromoCodeCallbackAPI= false;
        if(arg){
          this.isPromoCodeCallback =true;
          if(arg.isActive){
            this.promoCodeDiscount = arg.discount
            this.isPromoCodeCorrect = true;
            this.promoCodeInfo = arg;
            this.selectedSubscription['discountAmount'] = this.selectedSubscription?.price - (this.selectedSubscription?.price * (arg.discount/100))
            this.selectedSubscription['promotionCode'] = arg.code
            this.selectedSubscription['promotionId'] = arg._id
            this.currentAmount = Math.round(this.selectedSubscription['discountAmount'])
          }else{
            this.isPromoCodeCorrect = false;
          }
        }else{
          this.isPromoCodeCallback = true;
          this.isPromoCodeCorrect = false;
        }

      });
    }

  }

  getInvoice(){
    this.callBack = true;
    this.dataService.showSpinner()
    this.dataService.getCurrentInvoice(this.selectedOrg.storeId)
      .subscribe((arg) => {
        this.invoiceInfo = arg
        this.getUserOutstandingAndCurrentInvoice();
        this.getSubscription();
      })
  }

  requestForSubscriptionChange(){
    let payload = {
      storeId: this.selectedOrg.storeId,
      storeName: this.invoiceInfo.storeName,
      subscriptionId:this.selectedSubscription?._id,
      invoiceId: this.invoiceInfo?._id,
      subscriptionName:this.selectedSubscription?.name,
      subscriptionAmount:0,
      initialInvoiceAmount: this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price
    }
    if(this.selectedSubscription?.promotionCode){
      payload.subscriptionAmount = this.selectedSubscription?.discountAmount
      payload['promotionCode'] = this.selectedSubscription?.promotionCode
      payload['promotionId'] = this.selectedSubscription?.promotionId
    }else{
      payload.subscriptionAmount = this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price;
    }
    this.dataService.showSpinner()
    this.dataService.requestForSubscriptionChange(payload)
      .subscribe((arg) => {
        this.toastr.success('Your subscription Will be changed in next billing cycle 😉');
        this.dataService.hideSpinner()
        this.router.navigate(['invoice-list'])
        // Your Subscription Will be changed in next billing cycle
      }, err => {
        if(err.status == 409){
          this.toastr.error(err.error.message);
        }

        this.dataService.hideSpinner()

      })
  }

  isRequestForSubscriptionChange(){
    if(this.selectedSubscriptionIndex && (this.currentSubIndex > this.selectedSubscriptionIndex) && this.selectedSubscriptionIndex !== 3 && (this.currentSubIndex !== this.selectedSubscriptionIndex)){
      return true;
    }else{
      return false;
    }
  }

  isUpgradeNow(){
    if(this.currentSubIndex < this.selectedSubscriptionIndex && this.selectedSubscriptionIndex !== 3 && (this.currentSubIndex !== this.selectedSubscriptionIndex)){
      return true;
    }else{
      return false;
    }
  }

  back(){
    this.router.navigate(['invoice-list'])
  }

  async getUserOutstandingAndCurrentInvoice(){
    let currentAPIResponse= await this.invoiceService.getUserOutstandingAndCurrentInvoice({storeId:this.selectedOrg.storeId})
     if(currentAPIResponse){
       this.outstandingInfo = currentAPIResponse.outstandingInfo
     }
   }
}

