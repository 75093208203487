import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, from, BehaviorSubject } from "rxjs";
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
//import { ToastController } from '@ionic/angular';
//import { Storage } from '@ionic/storage';
//import { LoadingController } from '@ionic/angular';
import * as _ from 'lodash';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'environments/environment';



@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  //Development

  //baseURL = "http://localhost:8080";
  baseURL = environment.baseURL;
  botURL = environment.botURL;

  data;
  loading;
  fbSource;
  private org$ = new BehaviorSubject('');
  private user$ = new BehaviorSubject('');
  private checkTrail$ = new BehaviorSubject('');

  constructor(public http: HttpClient, private spinner: NgxSpinnerService) {
   }

  signIn(payload) {
    return <any>(
      this.http.post(this.baseURL + "/sign-in", payload).pipe(
        map(res => res)
      )
    );
  }
  signUp(payload) {
    return <any>(
      this.http.post(this.baseURL + "/sign-up", payload).pipe(
        map(res => res)
      )
    );
  }

  setHoldData(payload) {
    payload = this.data;
  }

  getHoldData() {
    return this.data
  }

  createFoodCusinies(payload) {
    return <any>(
      this.http.post(this.baseURL + "/foodCuisine/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateFoodCusinies(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/foodCuisine/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getFoodCusinies(payload) {
    return <any>(
      this.http.post(this.baseURL + "/foodCuisine/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleFoodCusinies(id) {
    return <any>(
      this.http.get(this.baseURL + "/foodCuisine/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteFoodCusinies(id) {
    return <any>(
      this.http.delete(this.baseURL + "/foodCuisine/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  createDeliveryLocations(payload) {
    return <any>(
      this.http.post(this.baseURL + "/deliveryLocation/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateDeliveryLocations(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/deliveryLocation/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getDeliveryLocations(payload) {
    return <any>(
      this.http.post(this.baseURL + "/deliveryLocation/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleDeliveryLocations(id) {
    return <any>(
      this.http.get(this.baseURL + "/deliveryLocation/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteDeliveryLocations(payload) {
    return <any>(
      this.http.delete(this.baseURL + "/deliveryLocation/delete/" + payload).pipe(
        map(res => res)
      )
    );
  }

  createCategories(payload) {
    return <any>(
      this.http.post(this.baseURL + "/category/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateCategories(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/category/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getCategories(payload) {
    return <any>(
      this.http.post(this.baseURL + "/category/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleCategories(id) {
    return <any>(
      this.http.get(this.baseURL + "/category/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteCategories(id) {
    return <any>(
      this.http.delete(this.baseURL + "/category/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  createStoreCategories(payload) {
    return <any>(
      this.http.post(this.baseURL + "/storeCategory/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateStoreCategories(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/storeCategory/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  // getStoreCategories(payload) {
  //   return <any>(
  //     this.http.post(this.baseURL + "/storeCategory/get", payload).pipe(
  //       map(res => res)
  //     )
  //   );
  // }

  getSingleStoreCategories(id) {
    return <any>(
      this.http.get(this.baseURL + "/storeCategory/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteStoreCategories(id) {
    return <any>(
      this.http.delete(this.baseURL + "/storeCategory/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  createSubscriptions(payload) {
    return <any>(
      this.http.post(this.baseURL + "/subscription/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateSubscriptions(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/subscription/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getSubscriptions(payload) {
    return <any>(
      this.http.post(this.baseURL + "/subscription/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleSubscriptions(id) {
    return <any>(
      this.http.get(this.baseURL + "/subscription/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteSubscriptions(id) {
    return <any>(
      this.http.delete(this.baseURL + "/subscription/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  createPromoCodes(payload) {
    return <any>(
      this.http.post(this.baseURL + "/promoCode/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updatePromoCodes(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/promoCode/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getPromoCodes(payload) {
    return <any>(
      this.http.post(this.baseURL + "/promoCode/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSinglePromoCodes(id) {
    return <any>(
      this.http.get(this.baseURL + "/promoCode/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deletePromoCodes(id) {
    return <any>(
      this.http.delete(this.baseURL + "/promoCode/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  createTutorialVideos(payload) {
    return <any>(
      this.http.post(this.baseURL + "/tutorialVideos/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateTutorialVideos(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/tutorialVideos/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getTutorialVideos(payload) {
    return <any>(
      this.http.post(this.baseURL + "/tutorialVideos/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleTutorialVideos(id) {
    return <any>(
      this.http.get(this.baseURL + "/tutorialVideos/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteTutorialVideos(id) {
    return <any>(
      this.http.delete(this.baseURL + "/tutorialVideos/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  createBrands(payload) {
    return <any>(
      this.http.post(this.baseURL + "/brand/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateBrands(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/brand/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getBrands(payload) {
    return <any>(
      this.http.post(this.baseURL + "/brand/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleBrands(id) {
    return <any>(
      this.http.get(this.baseURL + "/brand/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteBrands(id) {
    return <any>(
      this.http.delete(this.baseURL + "/brand/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  createTags(payload) {
    return <any>(
      this.http.post(this.baseURL + "/tag/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateTags(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/tag/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getTags(payload) {
    return <any>(
      this.http.post(this.baseURL + "/tag/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleTags(id) {
    return <any>(
      this.http.get(this.baseURL + "/tag/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteTags(id) {
    return <any>(
      this.http.delete(this.baseURL + "/tag/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  createSubCategories(payload) {
    return <any>(
      this.http.post(this.baseURL + "/subCategory/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateSubCategories(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/subCategory/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getSubCategories(payload) {
    return <any>(
      this.http.post(this.baseURL + "/subCategory/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleSubCategories(id) {
    return <any>(
      this.http.get(this.baseURL + "/subCategory/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteSubCategories(id) {
    return <any>(
      this.http.delete(this.baseURL + "/subCategory/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  createDeliveryLocationsCity(payload) {
    return <any>(
      this.http.post(this.baseURL + "/city/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateDeliveryLocationsCity(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/city/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getDeliveryLocationsCity(payload) {
    return <any>(
      this.http.post(this.baseURL + "/city/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleDeliveryLocationsCity(id) {
    return <any>(
      this.http.get(this.baseURL + "/city/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteDeliveryLocationsCity(id) {
    return <any>(
      this.http.delete(this.baseURL + "/city/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  getStoreList(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/get", payload).pipe(
        map(res => res)
      )
    );
  }
  getDeliverySettings(payload) {
    return <any>(
      this.http.post(this.baseURL + "/shipping/get", payload).pipe(
        map(res => res)
      )
    );
  }
  getStoreCategories(payload) {
    return <any>(
      this.http.post(this.baseURL + "/storeCategory/get", payload).pipe(
        map(res => res)
      )
    );
  }
  getRegions(payload) {
    return <any>(
      this.http.post(this.baseURL + "/deliveryLocation/get", payload).pipe(
        map(res => res)
      )
    );
  }

  createStore(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/create", payload).pipe(
        map(res => res)
      )
    );
  }
  createDeliverySetting(payload) {
    return <any>(
      this.http.post(this.baseURL + "/shipping/create", payload).pipe(
        map(res => res)
      )
    );
  }
  createProduct(payload) {
    return <any>(
      this.http.post(this.baseURL + "/product/create", payload).pipe(
        map(res => res)
      )
    );
  }
  importProducts(payload) {
    return <any>(
      this.http.post(this.baseURL + "/product/importProducts", payload).pipe(
        map(res => res)
      )
    );
  }
  updateImportProducts(payload) {
    return <any>(
      this.http.put(this.baseURL + "/product/updateProductsInBulk", payload).pipe(
        map(res => res)
      )
    );
  }
  exportProducts(storeId) {
    return <any>(
      this.http.get(this.baseURL + "/product/exportProductsForUpdate?storeId="+storeId).pipe(
        map(res => res)
      )
    );
  }


  getSingleStore(id) {
    return <any>(
      this.http.get(this.baseURL + "/store/get/" + id).pipe(
        map(res => res)
      )
    );
  }
  getSingleProduct(id) {
    return <any>(
      this.http.get(this.baseURL + "/product/get/" + id).pipe(
        map(res => res)
      )
    );
  }
  getSingleDeliverySetting(id) {
    return <any>(
      this.http.get(this.baseURL + "/shipping/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteStore(id) {
    return <any>(
      this.http.delete(this.baseURL + "/store/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  deleteProduct(id) {
    return <any>(
      this.http.delete(this.baseURL + "/product/delete/" + id).pipe(
        map(res => res)
      )
    );
  }
  deleteDeliverySetting(id) {
    return <any>(
      this.http.delete(this.baseURL + "/shipping/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  getSingleMenu(id) {
    return <any>(
      this.http.get(this.baseURL + "/menu/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  updateStore(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/store/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }
  updateProduct(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/product/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }
  quickEditProduct(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/product/updateProductQuick/" + id, payload).pipe(
        map(res => res)
      )
    );
  }
  updateDeliverySetting(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/shipping/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  updateMenu(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/menu/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }
  createAdmin(payload) {
    return <any>(
      this.http.post(this.baseURL + "/sign-up", payload).pipe(
        map(res => res)
      )
    );
  }

  getAdmins(payload, id) {
    return <any>(
      this.http.post(this.baseURL + "/store/getUsers?storeId="+id, payload).pipe(
        map(res => res)
      )
    );
  }

  getAllAdmins(payload) {
    return <any>(
      this.http.post(this.baseURL + "/user/list", payload).pipe(
        map(res => res)
      )
    );
  }
  getProducts(payload) {
    return <any>(
      this.http.post(this.baseURL + "/product/get", payload).pipe(
        map(res => res)
      )
    );
  }
  getCountries() {
    return <any>(
      this.http.get(this.baseURL + "/countries/getAllCountriesName").pipe(
        map(res => res)
      )
    );
  }
  getCountriesData(payload) {
    return <any>(
      this.http.get(this.baseURL + "/countries/getCountriesDataByIds?countryIds="+JSON.stringify(payload)).pipe(
        map(res => res)
      )
    );
  }

  updateMember(payload, id, resId) {
    return <any>(
      this.http.put(resId ? this.baseURL + "/user/updateMember/" + id+'?storeId='+resId : this.baseURL + "/user/updateMember/" + id, payload, {headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    );
  }
  removeMember(id, resId) {
    return <any>(
      this.http.delete(this.baseURL + "/user/removeMember/" + id+'?storeId='+resId, {headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    );
  }

  addMember(payload) {
    return <any>(
      this.http.post(this.baseURL + "/user/addMember", payload, {headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    );
  }

  getSingleAdmin(id) {
    return <any>(
      this.http.get(this.baseURL + "/user/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  updateAdmin(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/update-user/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  deleteAdmin(id) {
    return <any>(
      this.http.delete(this.baseURL + "/user/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  blockAdmin(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/user/block/" + id, payload).pipe(
        map(res => res)
      )
    );
  }
  createDeal(payload) {
    return <any>(
      this.http.post(this.baseURL + "/deal/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateDeal(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/deal/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getDeals(payload) {
    return <any>(
      this.http.post(this.baseURL + "/deal/get", payload).pipe(
        map(res => res)
      )
    );
  }
  getStoreAllDeals(id) {
    return <any>(
      this.http.get(this.baseURL + "/deal/getStoreAllDeals/" + id).pipe(
        map(res => res)
      )
    );
  }

  getStoreDeals(payload) {
    return <any>(
      this.http.post(this.baseURL + "/deal/getStoreDeals/", payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleDeal(id) {
    return <any>(
      this.http.get(this.baseURL + "/deal/get/" + id).pipe(
        map(res => res)
      )
    );
  }
  deleteDeal(id) {
    return <any>(
      this.http.delete(this.baseURL + "/deal/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  blockStore(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/store/block/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  blockDeal(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/deal/block/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getOrder(payload) {
    return <any>(
      this.http.post(this.baseURL + "/order/get", payload).pipe(
        map(res => res)
      )
    );
  }
  getSingleOrder(id) {
    return <any>(
      this.http.get(this.baseURL + "/order/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  changeOrderStatus(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/order/status/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getCustomers(payload) {
    return <any>(
      this.http.post(this.baseURL + "/customer/get", payload).pipe(
        map(res => res)
      )
    );
  }
  getUnconvertedCustomers(payload, storeId) {
    return <any>(
      this.http.post(this.baseURL + "/customer/getUnconvertedCustomer/"+storeId, payload).pipe(
        map(res => res)
      )
    );
  }
  getOrderedCustomers(payload, storeId) {
    return <any>(
      this.http.post(this.baseURL + "/customer/getOrderedCustomer/"+storeId, payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleCustomer(id) {
    return <any>(
      this.http.get(this.baseURL + "/customer/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  blockCustomer(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/customer/block/" + id, payload).pipe(
        map(res => res)
      )
    );
  }
  blockNotorderedCustomer(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/notOrderedCustomer/block/" + id, payload).pipe(
        map(res => res)
      )
    );
  }
  updateCustomer(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/customer/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  deleteCustomer(id) {
    return <any>(
      this.http.delete(this.baseURL + "/customer/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  getInvoiceList(id) {
    return <any>(
      this.http.get(this.baseURL + "/invoice/getStoreAllInvoice/"+id ).pipe(
        map(res => res)
      )
    );
  }

  makePayment(payload) {
    return <any>(
      this.http.post(this.baseURL + "/invoice/makePayment", payload).pipe(
        map(res => res)
      )
    );
  }


  changePassword(payload) {
    return <any>(
      this.http.post(this.baseURL + "/change-password", payload).pipe(
        map(res => res)
      )
    );
  }
  resetPassword(payload) {
    return <any>(
      this.http.post(this.baseURL + "/reset-password", payload).pipe(
        map(res => res)
      )
    );
  }
  newPassword(payload) {
    return <any>(
      this.http.post(this.baseURL + "/new-password", payload).pipe(
        map(res => res)
      )
    );
  }
  verifyEmail(payload) {
    return <any>(
      this.http.post(this.baseURL + "/verify", payload).pipe(
        map(res => res)
      )
    );
  }

  getUser() {
    return <any>(
      this.http.get(this.baseURL + "/user",{headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    );
  }

  getDashboardInfo(payload) {
    return <any>(
      this.http.post(this.baseURL + "/dashboard/get", payload).pipe(
        map(res => res)
      )
    );
  }
  getOrderCountAggregated(payload) {
    return <any>(
      this.http.post(this.baseURL + "/dashboard/getOrderCountAggregated", payload).pipe(
        map(res => res)
      )
    );
  }
  getOrderCountAggregatedForSA(payload) {
    return <any>(
      this.http.post(this.baseURL + "/dashboard/getAppAggregated", payload).pipe(
        map(res => res)
      )
    );
  }

  getServing(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/get/isServing", payload).pipe(
        map(res => res)
      )
    )
  }

  updateServing(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/update/isServing/", payload).pipe(
        map(res => res)
      )
    )
  }

  getClosed(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/get/isOpen", payload).pipe(
        map(res => res)
      )
    )
  }
  getCustomMessage(id) {
    return <any>(
      this.http.get(this.baseURL + "/store/getStoreAnnouncementBar/"+id).pipe(
        map(res => res)
      )
    )
  }
  getStoreShippingPolicy(id) {
    return <any>(
      this.http.get(this.baseURL + "/store/getStoreShippingPolicyMessage/"+id).pipe(
        map(res => res)
      )
    )
  }
  getStoreRefundExchange(id) {
    return <any>(
      this.http.get(this.baseURL + "/store/getStoreRefundExchangeMessage/"+id).pipe(
        map(res => res)
      )
    )
  }
  getTermsAndConditions(id) {
    return <any>(
      this.http.get(this.baseURL + "/store/getStoreTermsConditions/"+id).pipe(
        map(res => res)
      )
    )
  }

  updateClosed(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/update/isOpen", payload).pipe(
        map(res => res)
      )
    )
  }
  updateCustomMessage(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/store/setStoreAnnouncementBar/"+id, payload).pipe(
        map(res => res)
      )
    )
  }
  updateStoreShippingPolicy(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/store/setStoreShippingPolicyMessage/"+id, payload,{headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    )
  }
  updateStoreExchangePolicy(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/store/setStoreRefundExchangeMessage/"+id, payload,{headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    )
  }
  updateTermsAndConditions(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/store/setStoreTermsConditions/"+id, payload,{headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    )
  }

  pushNotification(payload) {
    return <any>(
      this.http.post(this.baseURL + "/user/subscribePush", payload).pipe(
        map(res => res)
      )
    );
  }

  pullToken(payload) {
    return <any>(
      this.http.put(this.baseURL + "/user/pullBrowserToken", payload).pipe(
        map(res => res)
      )
    );
  }

  syncFbData() {
    return <any>(
      this.http.get(this.botURL + "/store/sync/fbToken").pipe(
        map(res => res)
      )
    );
  }

  resetBotStore() {
    return <any>(
      this.http.get(this.botURL + "/store/reset/store").pipe(
        map(res => res)
      )
    );
  }

  resetBotMenus() {
    return <any>(
      this.http.get(this.botURL + "/store/reset/menu").pipe(
        map(res => res)
      )
    );
  }

  formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + strTime;
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  registerStoreOwner(payload) {
    return <any>(
      this.http.post(this.baseURL + "/storeOwner/create", payload).pipe(
        map(res => res)
      )
    );
  }

  getUserFbPages(id) {
    return <any>(
      this.http.get(this.baseURL + "/storeOwner/fbPage/"+id).pipe(
        map(res => res)
      )
    );
  }

  addUserPage(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/storeOwner/addPage/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  addSocialPlatform(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/addSocialPlatform", payload).pipe(
        map(res => res)
      )
    );
  }



  getStoreOwner(id) {
    return <any>(
      this.http.get(this.baseURL + "/storeOwner/get/"+id).pipe(
        map(res => res)
      )
    );
  }

  getDeliveryLocationsForCity(payload) {
    return <any>(
      this.http.get(this.baseURL + "/deliveryLocation/get/", payload).pipe(
        map(res => res)
      )
    );
  }

  getPageRequests() {
    return <any>(
      this.http.get(this.baseURL + "/storeOwner/getfbPage").pipe(
        map(res => res)
      )
    );
  }

  setPageApproved(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/createShort", payload).pipe(
        map(res => res)
      )
    );
  }

  getStoreStatus(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/get/status", payload).pipe(
        map(res => res)
      )
    );
  }

  updateStoreOwner(id,payload){
    return <any>(
      this.http.put(this.baseURL + "/storeOwner/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  /* setPageActivated(payload) {
    return <any>(
      this.http.get(this.baseURL + "<", payload).pipe(
        map(res => res)
      )
    );
  } */

  addDealCategory(payload) {
    return <any>(
      this.http.post(this.baseURL + "/dealCategory/create", payload).pipe(
        map(res => res)
      )
    );
  }

  allDealCategories(payload) {
    return <any>(
      this.http.post(this.baseURL + "/dealCategory/get", payload).pipe(
        map(res => res)
      )
    );
  }

  singleDealCategory(id) {
    return <any>(
      this.http.get(this.baseURL + "/dealCategory/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  updateDealCategory(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/dealCategory/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  deleteDealCategory(id) {
    return <any>(
      this.http.delete(this.baseURL + "/dealCategory/delete/" + id).pipe(
        map(res => res)
      )
    );
  }


  getPermissions(userId, storeId){
    return <any>(
      this.http.get(this.baseURL + `/organization/getUserStorePermission?userId=${userId}&storeId=${storeId}`).pipe(
        map(res => res)
      )
    );

  }

  createPromotion(payload) {
    return <any>(
      this.http.post(this.baseURL + "/promotion/create", payload).pipe(
        map(res => res)
      )
    );
  }

  getPromotions(payload) {
    return <any>(
      this.http.post(this.baseURL + "/promotion/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getSinglePromotion(id) {
    return <any>(
      this.http.get(this.baseURL + "/promotion/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deletePromotion(id) {
    return <any>(
      this.http.delete(this.baseURL + "/promotion/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  changePromoStatus(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/promotion/changeStatus/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  updatePromotion(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/promotion/update/"+id, payload).pipe(
        map(res => res)
      )
    );
  }

  getAttributes(id) {
    return <any>(
      this.http.get(this.baseURL + "/attribute/get/?storeId="+id).pipe(
        map(res => res)
      )
    );
  }

  addAttribute(payload) {
    return <any>(
      this.http.put(this.baseURL + "/attribute/update",payload).pipe(
        map(res => res)
      )
    );
  }

  connectSocialAccountToUser(payload) {
    return <any>(
      this.http.post(this.baseURL + "/connectSocialAccountToUser",payload).pipe(
        map(res => res)
      )
    );
  }

  updateStoreBasicInfo(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/updateStoreBasicInfo",payload).pipe(
        map(res => res)
      )
    );
  }


  updateStoreSubscription(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/updateStoreSubscriptionExternal",payload).pipe(
        map(res => res)
      )
    );
  }

  getSubInvoiceDetail(invoiceId) {
    return <any>(
      this.http.get(this.baseURL + "/masterInvoice/get/"+invoiceId).pipe(
        map(res => res)
      )
    );
  }

  getUserOrganzation(userId) {
    return <any>(
      this.http.get(this.baseURL + "/getUserOrganzations/"+userId).pipe(
        map(res => res)
      )
    );
  }


  handleSocialLogin(payload) {
    return <any>(
      this.http.post(this.baseURL + "/handleSocialUser",payload).pipe(
        map(res => res)
      )
    );
  }

  setFbSource(fbSoruceK: any){
    this.fbSource = fbSoruceK
  }

  getByFbId(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/getByFbId",payload).pipe(
        map(res => res)
      )
    );
  }


  getFbSource(){
    return this.fbSource
  }

  verifyPromoCode(promoCode) {
    return <any>(
      this.http.post(this.baseURL + "/promoCode/check",{code:promoCode}).pipe(
        map(res => res)
      )
    );
  }

  setStoreStatus(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/setStoreStatus",payload).pipe(
        map(res => res)
      )
    );
  }

  getOrgObs(): Observable<any> {
    return this.org$.asObservable();
  }

  setOrgObs() {
    this.org$.next('');
  }

  getUserObs(): Observable<any> {
    return this.user$.asObservable();
  }

  setUserObs() {
    this.user$.next('');
  }

  getCurrentInvoice(id) {
    return <any>(
      this.http.get(this.baseURL + "/invoice/getCurrentInvoice/"+id ).pipe(
        map(res => res)
      )
    );
  }

  requestForSubscriptionChange(payload) {
    return <any>(
      this.http.post(this.baseURL + "/subscriptionChangeRequest/create",payload).pipe(
        map(res => res)
      )
    );
  }

  upgradeSubscriptionForStore(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/upgradeSubscriptionForStore",payload).pipe(
        map(res => res)
      )
    );
  }

  getUserOutstandingAndCurrentInvoice(payload) {
    return <any>(
      this.http.get(this.baseURL + "/invoice/getUserOutstandingAndCurrentInvoice/"+payload.storeId).pipe(
        map(res => res)
      )
    );
  }

  addPlatformStore(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/store/addPlatform/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  setStoreStatusInsta(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/setStoreStatusInsta",payload).pipe(
        map(res => res)
      )
    );
  }



  debounce(func, timeout = 500){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  getConversations(body) {
    return <any>(
      this.http.post(this.baseURL + "/liveChat/getConversationsByStore",body, {headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    );
  }

  getConversationMessages(body) {
    return <any>(
      this.http.post(this.baseURL + "/liveChat/getConversationMessages",body, {headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    );
  }

  sendMessage(body) {
    return <any>(
      this.http.post(this.baseURL + "/liveChat/sendMessageToCustomer",body, {headers: {authorization: localStorage.getItem('access_token')}}).pipe(
        map(res => res)
      )
    );
  }


  createOwnerStoreThroughWebsite(payload) {
    return <any>(
      this.http.post(this.baseURL + "/createOwnerStoreThroughWebsite", payload).pipe(
        map(res => res)
      )
    );
  }

  checkDomainAvailability(domain) {
    return <any>(
      this.http.get(this.baseURL + "/store/checkDomainAvailability/"+domain).pipe(
        map(res => res)
      )
    );
  }

  addWebsitePlatform(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/addWebsitePlatform", payload).pipe(
        map(res => res)
      )
    );
  }

  updateProductFeatured(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/product/updateProductFeatured/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getBannerSettings(id) {
    return <any>(
      this.http.get(this.baseURL + "/storeWebsite/getSingleByStoreId/"+id).pipe(
        map(res => res)
      )
    );
  }

  getSocialLinks(id) {
    return <any>(
      this.http.get(this.baseURL + "/storeWebsite/getSingleByStoreId/"+id).pipe(
        map(res => res)
      )
    );
  }


  updateBannerSettings(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/storeWebsite/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  updateSocialLinks(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/storeWebsite/socialLink/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }





  setStoreStatusWebsite(payload) {
    return <any>(
      this.http.post(this.baseURL + "/store/setStoreStatusWebsite",payload).pipe(
        map(res => res)
      )
    );
  }

  checkProductLimit(id) {
    return <any>(
      this.http.get(this.baseURL + "/store/getAllowedProductForStore/"+id).pipe(
        map(res => res)
      )
    );
  }

  checkUsersLimit(id) {
    return <any>(
      this.http.get(this.baseURL + "/store/getAllowedUsersForStore/"+id).pipe(
        map(res => res)
      )
    );
  }

  changePublish(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/product/updateProductIsPublished/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getArchivedProducts(payload) {
    return <any>(
      this.http.post(this.baseURL + "/product/getArchivedProducts", payload).pipe(
        map(res => res)
      )
    );
  }

  restoreProduct(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/product/restoreProduct/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getSingleSubscription(id) {
    return <any>(
      this.http.get(this.baseURL + "/subscription/getForPortal/" + id).pipe(
        map(res => res)
      )
    );
  }

  setCheckForStoreTrail() {
    this.checkTrail$.next('');
  }

  getCheckForStoreTrail(): Observable<any> {
    return this.checkTrail$.asObservable();
  }

  createBanks(payload) {
    return <any>(
      this.http.post(this.baseURL + "/bank/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateBanks(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/bank/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  getBanks(payload) {
    return <any>(
      this.http.post(this.baseURL + "/bank/get", payload).pipe(
        map(res => res)
      )
    );
  }

  getBanksForUser() {
    return <any>(
      this.http.get(this.baseURL + "/bank/getAllForUser").pipe(
        map(res => res)
      )
    );
  }

  getSingleBanks(id) {
    return <any>(
      this.http.get(this.baseURL + "/bank/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  deleteBanks(id) {
    return <any>(
      this.http.delete(this.baseURL + "/bank/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  updatePaymentMethod(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/storePaymentSetting/updatePaymentMethod/"+id, payload).pipe(
        map(res => res)
      )
    );
  }

  getPaymentMethod(id) {
    return <any>(
      this.http.get(this.baseURL + "/storePaymentSetting/getPaymentMethod/"+id).pipe(
        map(res => res)
      )
    );
  }


  getBankDetail(id) {
    return <any>(
      this.http.get(this.baseURL + "/storePaymentSetting/getBankAccountDetail/"+id).pipe(
        map(res => res)
      )
    );
  }

  updateBankDetail(payload, id) {
    return <any>(
      this.http.put(this.baseURL + "/storePaymentSetting/updateBankAccountDetail/"+id, payload).pipe(
        map(res => res)
      )
    );
  }

  updateGoogleAnalytics(payload,id){
    return <any>(
      this.http.put(this.baseURL + "/storeWebsite/googleAnalyticsLink/update/"+id, payload).pipe(
        map(res => res)
      )
    );

  }

  getIntegrations(payload) {
    return <any>(
      this.http.post(this.baseURL + "/app-plugins/get",payload).pipe(
        map(res => res)
      )
    );
  }

  createIntegrations(payload) {
    return <any>(
      this.http.post(this.baseURL + "/app-plugins/create", payload).pipe(
        map(res => res)
      )
    );
  }

  updateIntegrations(id, payload) {
    return <any>(
      this.http.put(this.baseURL + "/app-plugins/update/" + id, payload).pipe(
        map(res => res)
      )
    );
  }

  deleteIntegrations(id) {
    return <any>(
      this.http.delete(this.baseURL + "/app-plugins/delete/" + id).pipe(
        map(res => res)
      )
    );
  }

  getSingleIntegration(id) {
    return <any>(
      this.http.get(this.baseURL + "/app-plugins/get/" + id).pipe(
        map(res => res)
      )
    );
  }

  getPlugins(id) {
    return <any>(
      this.http.get(this.baseURL + "/app-plugins/store/"+id).pipe(
        map(res => res)
      )
    );
  }

  getSinglePlugin(id, route) {
    return <any>(
      this.http.get(this.baseURL + "/app-plugins/store/"+id+"/"+route).pipe(
        map(res => res)
      )
    );
  }

  connectPlugin(payload) {
    return <any>(
      this.http.post(this.baseURL + "/app-plugins/addPluginToStore", payload).pipe(
        map(res => res)
      )
    );
  }

  getCustomerActivityCountForWeb(payload) {
    return <any>(
      this.http.post(this.baseURL + "/customerActivity/getCustomerActivityCountForWeb", payload).pipe(
        map(res => res)
      )
    );
  }
}

