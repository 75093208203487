import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationsService } from '../integrations.service';
import { DataServiceService } from 'app/utilities/data-service.service';

@Component({
  selector: 'app-add-integration',
  templateUrl: './add-integration.component.html',
  styleUrls: ['./add-integration.component.scss']
})
export class AddIntegrationComponent implements OnInit {
  constructor(private router: Router, private integrationService: IntegrationsService, private dataService: DataServiceService, private route: ActivatedRoute,private sanitizer: DomSanitizer, private translate: TranslateService) {
    translate.setDefaultLang(localStorage.getItem('lang'))
  }
 public uploader: FileUploader;
 payload:any = {
  filters: {},
  pagination: { start: 0, limit: 1000 },
  sort: {},
}
 public validationMessages = {
   name: [
     {
       type: "required",
       message: "Name is required",
     },
   ],
   key: [
    {
      type: "required",
      message: "Key is required",
    },
  ],
  // videoLink: [
  //   {
  //     type: "required",
  //     message: "Video Link is required",
  //   },
  // ],
  route: [
    {
      type: "required",
      message: "Route is required",
    },
  ],
   detail: [
     {
       type: "required",
       message: "Details is required",
     },
   ],picture: [
     {
       type: "required",
       message: "Picture is required",
     },
   ],
 };
 integrationForm = new UntypedFormGroup({
   name: new UntypedFormControl('', Validators.required),
   detail: new UntypedFormControl('', Validators.required),
   picture: new UntypedFormControl('', Validators.required),
   status: new UntypedFormControl(true, Validators.required),
   videoLink: new UntypedFormControl(''),
   route: new UntypedFormControl('', Validators.required),
   key: new UntypedFormControl('', Validators.required),
   subscriptionIds: new UntypedFormControl('', Validators.required),
 })

 CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
 urlID;
 orgs = [];
 previewImg:any;
 subscriptions = [];
 ngOnInit(): void {
   this.getSubscriptions();
   this.urlID = this.route.snapshot.params.id;
   this.orgs = JSON.parse(localStorage.getItem('organizations')) || []
     if(this.urlID != 'add'){
       this.patchIntegration()
     }
     const uploaderOptions: FileUploaderOptions = {
       url: environment.cloudinaryURL,

     };

     this.uploader = new FileUploader(uploaderOptions);
     this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
       form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
       form.append('file', fileItem);
       fileItem.withCredentials = false;
       return { fileItem, form };
     };

     this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
       let res: any = JSON.parse(response);
       this.integrationForm.patchValue({
         picture: res.url
       })
       return { item, response, status, headers };
     };

     this.uploader.onAfterAddingFile = async (fileItem) => {
       this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
       this.integrationForm.patchValue({
         picture: ''
       })
     }
   }


 gotoList(){
    this.router.navigate(['integrations-list'])
 }

 submit(){
   let payload = this.integrationForm.getRawValue();
   payload.imageURL = payload.picture;
   delete payload.picture;
   this.integrationService.createIntegration(payload);
 }

 update(){
   let payload = this.integrationForm.getRawValue();
   payload.imageURL = payload.picture;
   delete payload.picture;
  //  payload.isActive = payload.isActive.toString();
   this.integrationService.updateIntegration(this.urlID, payload);
 }

 async patchIntegration(){
   let response = await this.integrationService.getSingleIntegration(this.urlID)
   console.log({response})
   if(response?.appsPlugins){
     this.integrationForm.patchValue(response.appsPlugins)
     this.integrationForm.controls.picture.patchValue(response.appsPlugins.imageURL)
   }
 }

 removeImg() {
   this.uploader.clearQueue();
   this.integrationForm.patchValue({
     picture: ''
   })
 }

 getSubscriptions(){
  this.dataService.getSubscriptions(this.payload)
      .subscribe(val => {
        this.subscriptions = val.subscriptions
      }, err => {
        console.log({err})
      })
 }

}

