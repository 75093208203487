import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-facebook-pixel-plugin',
  templateUrl: './facebook-pixel-plugin.component.html',
  styleUrls: ['./facebook-pixel-plugin.component.scss']
})
export class FacebookPixelPluginComponent implements OnInit {


  constructor(private dataService: DataServiceService, private route: ActivatedRoute, private toastr: ToastrService, private router: Router) { }
  org;
  plugin;
  loader:any = true;
  ngOnInit(): void {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.getPlugin()
  }

  getPlugin(){
    this.dataService.showSpinner();
    this.dataService.getSinglePlugin(this.org.storeId, this.route.snapshot.routeConfig.path).subscribe(res=>{
      this.plugin = res.currentAppPlugin;
      console.log(this.plugin)
      this.loader = false;
      this.dataService.hideSpinner();
    },err=>{
      this.dataService.hideSpinner();
      this.toastr.error('Error loading plugin')
      this.loader = 'failed';
    })
  }

  goToPlugins(){
    this.router.navigate(['plugins-list'])
  }
}
