import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { PaymentService } from 'app/utilities/payment.service';

@Component({
  selector: 'app-online-payments-plugin',
  templateUrl: './online-payments-plugin.component.html',
  styleUrls: ['./online-payments-plugin.component.scss']
})
export class OnlinePaymentsPluginComponent implements OnInit {

  constructor(private dataService: DataServiceService, private route: ActivatedRoute, private toastr: ToastrService, private router: Router, private paymentService: PaymentService, private ngModalService: NgbModal) { }
  org;
  plugin;
  //loader:any = true;
  bankForm = new UntypedFormGroup({
    title: new UntypedFormControl('', Validators.required),
    accountNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(34)]),
    bankId: new UntypedFormControl('')
  })
  tab = 1;
  payload:any = {
    filters: {},
    pagination: { start: 0, limit: 1000 },
    sort: {},
  }
  lang = localStorage.getItem('lang')
  public validationMessages = {
    title: [
      {
        type: "required",
        message: this.lang == 'en' ? "Title is required" : "العنوان مطلوب",
      },
    ],
    accountNumber: [
      {
        type: "required",
        message: this.lang == 'en' ? "Account number is required" : "رقم الحساب مطلوب",
      },
      {
        type: "maxlength",
        message: this.lang == 'en' ? "Account number digits limit exceeded" : "تجاوز حد أرقام رقم الحساب",
      },
    ]
  };
  paymentMethods = [
    {label: 'COD', key: 'cod', value: true},
    {label: this.lang == 'en' ? 'Card Payment' : 'الدفع بالبطاقة', key: 'cardPayment', value: false},
    {label: this.lang == 'en' ? 'Bank to Bank ( coming soon )' : 'بنك إلى بنك (قريبا )', key: 'bank2bank', value: false},
    {label: this.lang == 'en' ? 'EasyPaisa ( coming soon )' : 'إيزي بايسا (قريبا )', key: 'easyPaisa', value: false}
  ]
  agreed = false;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  loader:any = false;
  ids = {
    paymentMethod: "",
    bankDetail: ""
  }
  banks = [];
  orgs = JSON.parse(localStorage.getItem('organizations')) || []
  selectedOrg = this.orgs.length ? this.orgs.find(o=>o.selected) : null;
  ngOnInit(): void {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.getPlugin()
  }

  getPlugin(){
    this.loader = true;
    this.dataService.showSpinner();
    this.dataService.getSinglePlugin(this.org.storeId, this.route.snapshot.routeConfig.path).subscribe(res=>{
      this.plugin = res.currentAppPlugin;
      this.loader = false;
      if(this.plugin?.isConnected){
        this.getMethods();
        this.getBankDetails();
        this.getBanks();
      }
      this.dataService.hideSpinner();
    },err=>{
      this.dataService.hideSpinner();
      this.toastr.error('Error loading plugin')
      this.loader = 'failed';
    })
  }

  goToPlugins(){
    this.router.navigate(['plugins-list'])
  }

  async update(tab){
    if(tab == 1){
      let payload:any = {};
      this.paymentMethods.forEach(method=>{
        payload[method.key] = method.value
      })
      payload.termsAccepted = true;
      payload.storeId = this.selectedOrg.storeId;
      let updated = await this.paymentService.updatePaymentMethod(payload, this.ids.paymentMethod);
    }
    else{
      let payload = {
        ...this.bankForm.getRawValue(),
        bankName: this.banks.find(bank=>bank._id == this.bankForm.getRawValue().bankId).name,
        storeId: this.selectedOrg.storeId,
        termsAccepted: true
      }
      let updated = await this.paymentService.updateBankDetail(payload, this.ids.bankDetail);
    }

  }

  getMethods(){
    this.dataService.getPaymentMethod(this.selectedOrg.storeId).subscribe(res=>{
      this.paymentMethods.forEach(method=>{
        method.value = res.methods[method.key];
      })
      this.ids.paymentMethod = res._id;
    })
  }

  getBankDetails(){
    this.loader = true;
    this.dataService.getBankDetail(this.selectedOrg.storeId).subscribe(res=>{
      this.ids.bankDetail = res._id;
      this.loader = false;
      if(res.bankAccountDetail?.accountNumber){
        this.bankForm.patchValue(res.bankAccountDetail)
      }
    },err=>{
      this.paymentMethods.splice(1,1)
      this.loader = false;
    })
  }

  confirm(message){
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if (result == 'yes') {
            this.update(this.tab);
      }
    });
  }

  getBanks(){
    this.dataService.getBanksForUser().subscribe(res=>{
      this.banks = res.bank;
    })
  }

  tabSwitch(tab){
    this.tab = tab;
    this.agreed = false;
  }

  disabled(){
    return this.paymentMethods.find(p=>p.key == 'cardPayment') ? (this.paymentMethods.find(p=>p.key == 'cardPayment')?.value && !this.agreed) : true;
  }



}
