import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BanksService } from '../banks.service';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit {
  urlID;
  orgs = []
  selectedOrg;
  isActive = false;
  public validationMessages = {
    name: [
      {
        type: "required",
        message: "Name is required",
      },
    ],
    detail: [
      {
        type: "required",
        message: "Details is required",
      },
    ]
  };
  constructor(private route: ActivatedRoute, private translate: TranslateService, private bankService: BanksService, private router: Router) { 
    
  }
  bankForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    detail: new UntypedFormControl(''),
  })
  ngOnInit(): void {
    this.urlID = this.route.snapshot.params.id;
      if(this.urlID != 'add'){
        this.patchBanks()
      }
  }

  submit(){
    let payload = this.bankForm.getRawValue();
    payload.isActive = true;
    this.bankService.createBank(payload);
  }

  update(){
    let payload = this.bankForm.getRawValue();
    payload.isActive = this.isActive;
    this.bankService.updateBank(this.urlID, payload);
  }

  gotoList(){
    this.router.navigate(['banks-list'])
  }


  async patchBanks(){
    let response = await this.bankService.getSingleBank(this.urlID)
    if(response){
      this.bankForm.patchValue(response.bank)
      this.isActive = response.bank.isActive;
    }
  }


}
