import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plugin-single-card',
  templateUrl: './plugin-single-card.component.html',
  styleUrls: ['./plugin-single-card.component.scss']
})
export class PluginSingleCardComponent implements OnInit {
  org;

  @Input('plugin') plugin
  @Input('callFrom') callFrom
  @Output('changed') changed : EventEmitter<any> = new EventEmitter;

  constructor(private dataService: DataServiceService, private toastr: ToastrService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if(this.plugin?.videoLink){
      this.plugin.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.plugin?.videoLink)
    }

    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
  }

  connect(){
    this.dataService.showSpinner()
    this.dataService.connectPlugin({storeId: this.org.storeId, pluginId: this.plugin._id}).subscribe(res=>{
      this.changed.emit()
      this.dataService.hideSpinner()
      this.toastr.success('Plugin Connected Successfully')
    },err=>{
      console.log({err})
      this.dataService.hideSpinner()
      this.toastr.error('Failed connecting Plugin')
    })
  }
}
