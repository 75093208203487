import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StoreService } from '../../store/store.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-custom-message',
  templateUrl: './custom-message.component.html',
  styleUrls: ['./custom-message.component.css']
})
export class CustomMessageComponent implements OnInit {

  constructor(private storeService: StoreService, private dataService: DataServiceService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }
  validationMessages = {
    textColor: [{
      type: 'required', message: 'Text Color is required'
    }],
    barColor: [{
      type: 'required', message: 'Bar Color is required'
    }],
  };
  customMsgForm = new UntypedFormGroup({
    text: new UntypedFormControl('', Validators.required),
    textColor: new UntypedFormControl('', Validators.required),
    barColor: new UntypedFormControl('', Validators.required),
  })
  selectedOrg;
  org;
  urlID = 'add'
  plugin;
  loader:any = true;
  textColor = '#FAF9F6';
  barColor = '#000000';
  ngOnInit(): void {
    this.selectedOrg = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.getPlugin()
  }

  submit(){
    let payload = this.customMsgForm.getRawValue();
    this.storeService.updateCustomMessage(payload, this.selectedOrg.storeId);
  }

  update(){
    let payload = this.customMsgForm.getRawValue();
    this.storeService.updateCustomMessage(payload, this.selectedOrg.storeId);
  }

  async getMessage(){
    let resp = await this.storeService.getCustomMessage(this.selectedOrg.storeId)
    if(resp.announcementBar){
      this.customMsgForm.patchValue(resp.announcementBar)
    }
  }

  async patchCutomMsg(){
    // let response = await this.brandService.getSingleBrand(this.urlID)
    // if(response){
    //   this.customMsgForm.patchValue(response.brand)
    // }
  }

  getPlugin(){
    this.dataService.showSpinner();
    this.dataService.getSinglePlugin(this.selectedOrg.storeId, this.route.snapshot.routeConfig.path).subscribe(res=>{
      this.plugin = res.currentAppPlugin;
      if(this.plugin.isConnected){
        this.getMessage()
      }
      this.loader = false;
      this.dataService.hideSpinner();
    },err=>{
      this.dataService.hideSpinner();
      this.toastr.error('Error loading plugin')
      this.loader = 'failed';
    })
  }

  goToPlugins(){
    this.router.navigate(['plugins-list'])
  }
}
