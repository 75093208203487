import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'app/_modal/modal.service';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { AttributesService } from '../attributes.service';
import { environment } from 'environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddAttributeModalComponent } from '../add-attribute-modal/add-attribute-modal.component';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-attributes-list',
  templateUrl: './attributes-list.component.html',
  styleUrls: ['./attributes-list.component.css']
})
export class AttributesListComponent implements OnInit {
  totalRecords = 0;
  attributeForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    // pick: new FormControl('single', Validators.required),
    options: new UntypedFormArray([
      this.formBuilder.group({name: ''})
    ])
  })
  constructor(private attService: AttributesService,private ngModalService: NgbModal, private translate: TranslateService, private sanitizer: DomSanitizer, private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private toastr: ToastrService, private modal: ModalService) {
    
   }
  urlId;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  imagesFile = [];
  imagesTemp = [];
  loader=false;
  imagesURL = [];
  attributes = [];
  selectedId;
  submitted = false;
  public uploader: FileUploader;
  public uploaderTemp: FileUploader;
  uploaderOptions: FileUploaderOptions = {
    url: environment.cloudinaryURL,
  };
  imgIndex = 0;

  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  ngOnInit(): void {
    this.urlId = this.route.snapshot.params.id;
    // this.imageUploadConfig();
    this.getAttributes()
  }

  imageUploadConfig() {
    this.uploader = new FileUploader(this.uploaderOptions);
    this.uploaderTemp = new FileUploader(this.uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };
    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);      
    };


    this.uploaderTemp.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.uploaderTemp.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);
      this.imagesURL[this.imgIndex] = res.url;
      this.imagesFile[this.imgIndex] = ''
      this.imagesTemp[this.imgIndex] = ''      
      
    };
  }

  removeImage(i){
    this.imagesURL.splice(i,1)
    this.imagesTemp.splice(i,1)
    this.imagesFile.splice(i,1)    
  }

  openFile(id){
    document.getElementById('att-img-'+id).click();
  }

  onFileChange(event, ind) {    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;      
      if (!file.type.startsWith('image')) {
      } else {
        if(this.imagesFile[ind] == '' && this.imagesTemp[ind] == '' && this.imagesURL[ind]){
          this.imagesFile[ind] = file;
          this.imagesTemp[ind] = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)))
          this.imagesURL[ind] = ''
        }
        else{
          this.imagesFile.push(file)
          this.imagesTemp.push(this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file))))
          this.imagesURL.push('')
        }
      }
    }    
  }

  uploadCurrentImg(imgIndex) {

    let file = this.imagesFile[imgIndex]
    this.uploaderTemp.addToQueue([file], { autoUpload: true });
    this.imgIndex = imgIndex;
    this.uploaderTemp.uploadAll();
  }

  async getAttributes(){
    this.loader = true;
    this.attributes = await this.attService.getAttributes(this.urlId);
    this.loader = false;
    this.attributes = this.attributes['attributes'];
  }

  updateAttribute(id){
    this.selectedId = id;
    // this.modal.open('add-attribute')
    let selected = {...this.attributes.find(s=>s._id == id)};
    // selected.forEach(s=>{
      delete selected._id;
      selected.options.forEach(o=>{
        this.imagesURL.push(o.imgURL)
        this.imagesTemp.push('')
        this.imagesFile.push('')
        delete o._id;
      })
    // })
    this.attributeForm.get('name').setValue(selected.name);
    // this.attributeForm.get('pick').setValue(selected.pick);
    this.removeOptions()
    if(this.attributeForm.controls.options.value.length == 0){
      selected.options.forEach((o,i)=>{
          (this.attributeForm.get('options') as UntypedFormArray).push(new UntypedFormGroup({
            name: new UntypedFormControl(o.name, Validators.required),
            // price: new FormControl(o.price, Validators.required)
          }))
        
      })
    }
    let that = this;
    const modalRef = this.ngModalService.open(AddAttributeModalComponent,this.modalOptions);
    modalRef.componentInstance.selectedId = this.selectedId;
    modalRef.componentInstance.attributeForm = this.attributeForm;
    modalRef.componentInstance.imagesURL = this.imagesURL;
    modalRef.componentInstance.imagesFile = this.imagesFile;
    modalRef.componentInstance.imagesTemp = this.imagesTemp;
    modalRef.componentInstance.imgIndex = this.imgIndex;
    modalRef.componentInstance.attributes = this.attributes;
    modalRef.componentInstance.urlId = this.urlId;
    modalRef.componentInstance.submitted = this.submitted;
    modalRef.result.then(async (result) => {
      if (result) {
        if(result == 'submit'){
          that.getAttributes()
        }
        else{
          this.submitted = false;
    this.selectedId = '';
    this.imgIndex = 0;
    this.imagesTemp = [];
    this.imagesURL = [];
    this.imagesFile = [];
        }
      }
    });

  }

  close(){
    this.submitted = false;
    // this.modal.close('add-attribute')
    this.selectedId = '';
    this.imgIndex = 0;
    this.imagesTemp = [];
    this.imagesURL = [];
    this.imagesFile = [];
    // this.attributeForm.reset();
  }

  async submit(){    
    this.submitted = true;
    if(this.attributeForm.valid){
    let payload = {storeId: this.urlId, attributes: []};
    payload.attributes = [...this.attributes];
    payload.attributes.forEach(s=>{
      // delete s._id;
      s.options.forEach(o=>{
        delete o.pictureFile;
        delete o.pictureTemp;
        delete o._id;
      })
    })
    if(this.selectedId){
      payload.attributes.splice(payload.attributes.findIndex(s=>s._id == this.selectedId),1);
      payload.attributes.push({...this.attributeForm.getRawValue(), _id: this.selectedId})
    }
    else{

      payload.attributes.push(this.attributeForm.getRawValue())
    }
    payload.attributes[payload.attributes.length-1].options.forEach((o,ind)=>{
      o.imgURL = this.imagesURL[ind];
    })    
    let response = await this.attService.addAttribute(payload)
    if(response && response.updateAttribute?.nModified == 1){
      if(this.selectedId){
        this.toastr.success('Attribute has been updated')
      }
      else{
        this.toastr.success('Attribute has been added')
      }
      this.modal.close('add-attribute')
      this.selectedId = '';
      this.imgIndex = 0;
    this.imagesTemp = [];
    this.imagesURL = [];
    this.imagesFile = [];
      this.getAttributes()
    }
  }
  }

  addOptions(){
    this.imgIndex+=1;
    (this.attributeForm.get('options') as UntypedFormArray).push(new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      // price: new FormControl(0, Validators.required)
    }))
  }

  addAttribute(){
this.selectedId = '';
    this.submitted = false;
    this.imgIndex = 0;
    this.imagesTemp = [];
    this.imagesURL = [];
    this.imagesFile = [];
    this.attributeForm.reset();
    // this.attributeForm.get('pick').setValue('single');
    this.removeOptions();
    (this.attributeForm.get('options') as UntypedFormArray).push(new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      // price: new FormControl(0, Validators.required)
    }));

    // this.modal.open('add-attribute')
    let that = this;
    const modalRef = this.ngModalService.open(AddAttributeModalComponent,this.modalOptions);
    modalRef.componentInstance.selectedId = this.selectedId;
    modalRef.componentInstance.attributeForm = this.attributeForm;
    modalRef.componentInstance.imagesURL = this.imagesURL;
    modalRef.componentInstance.imagesFile = this.imagesFile;
    modalRef.componentInstance.imagesTemp = this.imagesTemp;
    modalRef.componentInstance.imgIndex = this.imgIndex;
    modalRef.componentInstance.attributes = this.attributes;
    modalRef.componentInstance.urlId = this.urlId;
    modalRef.componentInstance.submitted = this.submitted;
    modalRef.result.then(async (result) => {
      if (result) {
        if(result == 'submit'){
          that.getAttributes()
        }
        else{
          this.submitted = false;
    this.selectedId = '';
    this.imgIndex = 0;
    this.imagesTemp = [];
    this.imagesURL = [];
    this.imagesFile = [];
        }
      }
    });
  }

  async deleteAttribute(id){
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = 'Are you sure you want to delete this attribute?';
    modalRef.result.then(async (result) => {
      if (result == 'yes') {
        
        let payload = {storeId:this.urlId, attributes: [] }
        payload.attributes = [...this.attributes];
        payload.attributes.splice(this.attributes.findIndex(s=>s._id == id), 1)
        let response = await this.attService.addAttribute(payload)
        if(response){
          this.toastr.success('Attribute has been deleted')
          this.attributes.splice(this.attributes.findIndex(s=>s._id == id), 1)
        }
      }
    });
  }

  deleteOption(index){
    this.imagesURL.splice(index,1)
    this.imagesTemp.splice(index,1)
    this.imagesFile.splice(index,1)
    this.imgIndex-=1;
    if(this.attributeForm.controls.options['controls'].length == 1){
      this.toastr.error('You must have atleast one option selected')
    }
    else{
      (this.attributeForm.controls.options as UntypedFormArray).removeAt(index)
    }
  }

  removeOptions(){
    let length = this.attributeForm.get('options').value.length;
      if(length){
        for(let i=0; i<length; i++){
          (this.attributeForm.controls.options as UntypedFormArray).removeAt(length - (i+1))
        }
      }
  }

  checkDisable(){
    const val = this.attributeForm.controls.options.value;
    if(!val[val.length-1].name){
      return true
    }
    else{
      false
    }
  }

}
