import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/utilities/auth.service';
import { DataServiceService } from 'app/utilities/data-service.service';
import { OnboardingService } from 'app/utilities/onboarding.service';
import { environment } from 'environments/environment';
import { NgWizardService } from 'ng-wizard';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from "@angular/platform-browser";



@Component({
  selector: 'app-select-subscription',
  templateUrl: './select-subscription.component.html',
  styleUrls: ['./select-subscription.component.css']
})
export class SelectSubscriptionComponent implements OnInit {
  @Input('invoice') invoice;
  subscriptions = [];
  selectedSubscription;
  isPaymentShow = false;
  isPaymentSelected = false;
  isSubSelected = true;
  paymentMethod;
  tempStoreInfo;
  promoCode;
  isPromoCodeCallback = false;
  isPromoCodeCorrect = false;
  promoCodeDiscount = 0;
  isPromoCodeCallbackAPI = false;
  promoCodeInfo;
  currentAmount = 0;
  selectedSubIndex = 0;
  showSubSelection = true;
  payMobIframeId = environment.payMobIframeCard;
  payMobPaymentToken;

  constructor(private dataService: DataServiceService, private onboardingService: OnboardingService, private ngWizardService: NgWizardService, private toastr: ToastrService, private authenticationService: AuthService, @Inject(DOCUMENT) document: Document, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.tempStoreInfo = this.onboardingService.getStore()
    this.getSubscription();
    if(this.invoice && this.invoice.status == 'unpaid'){
      this.getUserOrganzation();
      this.toastr.error('We unable to process your subscription payment, Kindly retry !');
    }
  }


  getSubscription(){
    let query = {filters:{status:'true'}, pagination:{start:0, limit:50}, sort:{"createdAt":1}}
    this.dataService.getSubscriptions(query)
      .subscribe((arg) => {
        for (let index = 0; index < arg.subscriptions.length; index++) {
          arg.subscriptions[index]['isSelected'] = false;
        }
        arg.subscriptions[0]['isSelected'] = true;
        this.selectedSubscription = arg.subscriptions[0];
        this.subscriptions = arg.subscriptions;
      });
  }

  selectSubscription(sub,index){
    this.selectedSubscription = sub
    this.selectedSubIndex = index
    this.currentAmount = Math.round(this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price);
    for (let indexf = 0; indexf < this.subscriptions.length; indexf++) {
      this.subscriptions[indexf]['isSelected'] = false;
    }
    this.subscriptions[index]['isSelected'] = true;
    this.isSubSelected = true;
    this.promoCode = undefined;
    this.promoCodeDiscount = 0;
    this.isPromoCodeCallback =false;
    this.isPromoCodeCorrect = false;
    if(index !== 3){
      if((this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price) > 0){
        this.isPaymentShow =true;
      }else{
        this.isPaymentShow =false;
      }
    }else{
      this.isPaymentShow =false;
    }

  }

  selectPaymentType(key){
    this.isPaymentSelected = true;
    this.paymentMethod = key
  }


  async updateStoreSubscription(){
    let payload = {
      storeId: this.tempStoreInfo._id,
      subscriptionId:this.selectedSubscription?._id,
      subscriptionName:this.selectedSubscription?.name,
      subscriptionAmount:0,
      paymentVia:'VM',
      initialInvoiceAmount: this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price
    }
    if(this.selectedSubscription?.promotionCode){
      payload.subscriptionAmount = this.selectedSubscription?.discountAmount
      payload['promotionCode'] = this.selectedSubscription?.promotionCode
      payload['promotionId'] = this.selectedSubscription?.promotionId
    }else{
      payload.subscriptionAmount = this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price;
    }
    let update = await this.onboardingService.updateStoreSubscription(payload);
    if(update.storeUpdate.nModified == 1){

     if(update?.paymentKeyRequest){
      this.payMobPaymentToken = update?.paymentKeyRequest;
      let tf = this.payMobPaymentToken.slice(0, 7);
      let ts = this.payMobPaymentToken.slice(7, this.payMobPaymentToken.length);
      //this.showSubSelection = false;
      //if((this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price) > 0){
        window.location.href= environment.paymentURL + '/paySubscription?orderId='+ update.masterInvoiceCurrent._id +'&pm='+ update.masterInvoiceCurrent.paymentVia +'&mtId='+ update.masterInvoiceCurrent.storeId+'&tf='+ tf+'&ts='+ ts
     }else{
        this.ngWizardService.next();
     }

    }
  }

  isFormValid(){
    if (this.isSubSelected) {
      if((this.selectedSubscription?.isDiscount ? (this.selectedSubscription?.price - this.selectedSubscription?.discount) :this.selectedSubscription?.price) == 0){
        return true;
      }else{
        if(this.selectedSubIndex !== 3){
          return true;
        }else{
          return true;
        }

      }
    } else {
      return false;
    }
  }

  calculatePercentage(price,discount){
    if(price && discount){
      return Math.ceil((discount/price)*100)
    }


  }

  getUserOrganzation(){
    let userId = JSON.parse(localStorage.getItem('user_info'))._id
    this.dataService.getUserOrganzation(userId)
    .subscribe((arg) => {
      arg.organizations[0]['selected'] = true;
      localStorage.setItem('organizations', JSON.stringify(arg.organizations))
      this.authenticationService.setOrganizations();
    });
  }

  checkPromoCode(){
    let currentPromo = this.promoCode.trim()
    if(currentPromo.length > 3){
      this.isPromoCodeCallbackAPI= true;
      this.dataService.verifyPromoCode(currentPromo)
      .subscribe((arg) => {
        this.isPromoCodeCallbackAPI= false;
        if(arg){
          this.isPromoCodeCallback =true;
          if(arg.isActive){
            this.promoCodeDiscount = arg.discount
            this.isPromoCodeCorrect = true;
            this.promoCodeInfo = arg;
            this.selectedSubscription['discountAmount'] = this.selectedSubscription?.price - (this.selectedSubscription?.price * (arg.discount/100))
            this.selectedSubscription['promotionCode'] = arg.code
            this.selectedSubscription['promotionId'] = arg._id
            this.currentAmount = Math.round(this.selectedSubscription['discountAmount'])
          }else{
            this.isPromoCodeCorrect = false;
          }
        }else{
          this.isPromoCodeCallback = true;
          this.isPromoCodeCorrect = false;
        }

      });
    }

  }

  checkFeatureSubscription(key, subscription){
    let featureIndex = _.indexOf(subscription.allowedFeatures, key);
    if(featureIndex < 0){
      return false;
    }else{
      return true;
    }
  }

  showMore(name){
    let docT = document.getElementById('section-'+name);
    let showT = document.getElementById('section-'+name +'-show');
    let hideT = document.getElementById('section-'+name+'-hide');
    docT.className = 'show'
    showT.className = 'showmore-label hide'
    hideT.className = 'showmore-label show'

  }

  hideMore(name){
    let docT = document.getElementById('section-'+name);
    let showT = document.getElementById('section-'+name +'-show');
    let hideT = document.getElementById('section-'+name+'-hide');
    docT.className = 'hide'
    showT.className = 'showmore-label show'
    hideT.className = 'showmore-label hide'
  }

  getFrameURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://pakistan.paymob.com/api/acceptance/iframes/"+this.payMobIframeId+"?payment_token=" + this.payMobPaymentToken)
    //return this.sanitizer.bypassSecurityTrustResourceUrl("https://pakistan.paymob.com/api/acceptance/iframes/80540?payment_token=ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5WDJsa0lqbzFOak15TkN3aVlXMXZkVzUwWDJObGJuUnpJam94T1RrNU1EQXNJbU4xY25KbGJtTjVJam9pVUV0U0lpd2lhVzUwWldkeVlYUnBiMjVmYVdRaU9qVTNNelEzTENKdmNtUmxjbDlwWkNJNk1UTXpPREV3TENKaWFXeHNhVzVuWDJSaGRHRWlPbnNpWm1seWMzUmZibUZ0WlNJNkltMXZiVzhpTENKc1lYTjBYMjVoYldVaU9pSlRaV3hzUzJWeUlpd2ljM1J5WldWMElqb2lUa0VpTENKaWRXbHNaR2x1WnlJNklrNUJJaXdpWm14dmIzSWlPaUpPUVNJc0ltRndZWEowYldWdWRDSTZJazVCSWl3aVkybDBlU0k2SWs1Qklpd2ljM1JoZEdVaU9pSk9RU0lzSW1OdmRXNTBjbmtpT2lKT1FTSXNJbVZ0WVdsc0lqb2lhVzVtYjBCaWIzUnVhV1JsTG1OdmJTSXNJbkJvYjI1bFgyNTFiV0psY2lJNklqYzNOemMzTnpjM056YzNJaXdpY0c5emRHRnNYMk52WkdVaU9pSk9RU0lzSW1WNGRISmhYMlJsYzJOeWFYQjBhVzl1SWpvaVRrRWlmU3dpYkc5amExOXZjbVJsY2w5M2FHVnVYM0JoYVdRaU9tWmhiSE5sTENKbGVIQWlPakUyTmprNE9URXhPVEFzSW5CdGExOXBjQ0k2SWpNMExqSXpOUzQ0T0M0M05pSjkuenBNejlTWi1udXJ5RGJkOUFwMlAzRWZmcG5MQk5MNF9wNkVhUzVKTHRIMjN0QU5BV2pMcTMydnlSakxYeVhsU2JZTkZJckxXS2MtTWhSRE9tUl9CUWc=");

  }

}
