import { Injectable, SecurityContext } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable } from 'rxjs'
import { DataServiceService } from './utilities/data-service.service';
import { AuthService } from './utilities/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
//import * as firebase from "firebase/app";
import { timeout, take } from 'rxjs/operators';
import { SidebarService } from './sidebar.service';

@Injectable()
export class MessagingService {
safeNotification;
audio;

subscribed = new BehaviorSubject(false)
toastClicked = new BehaviorSubject(false)
currentMessage = new BehaviorSubject(null);
  private orderTrack = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging, private sidebarService: SidebarService, private router: Router, private toastr: ToastrService, private dataService: DataServiceService, private authService: AuthService) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging: any) => {
        _messaging._next = (payload: any) => {

        };
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )

  }

  async pullToken(logout:any = undefined){
    let user = await this.authService.getUser()
    if(user){

      let payload = {
        vapidKey: localStorage.getItem('pushedToken'),
        userId: user && user._id
      }
      this.dataService.pullToken(payload).subscribe(res=>{
        if(logout){
          let currentLang = localStorage.getItem('lang')
          localStorage.clear()
          localStorage.setItem('lang', currentLang)
          this.sidebarService.profileObs$.next(null)
          this.router.navigate(['login'])
        }
        else{
          this.toastr.success('Notifications Unsubscribed')
          this.subscribed.next(false)
          localStorage.removeItem('pushedToken')
        }
      },err=>{
        this.toastr.error('Failed to unsubscribe')
      })
    }
  }

    requestPermission(subscribe:any = undefined) {
      // this.playAudio();
      return new Promise((res,rej)=>{
        this.angularFireMessaging.requestToken.subscribe(
      async (token) => {
        let user = await this.authService.getUser()
        if(user){
        let payload = {
          userId: user._id,
          vapidKey: token.toString()
        }
        this.dataService.pushNotification(payload).subscribe(val=>{
          if(subscribe){
            this.toastr.success('Subscribed to notifications')
          }
          localStorage.setItem('pushedToken', token)
          this.subscribed.next(true)
          this.receiveMessage()
        })
      }
      res(true)
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
        //console.log(err)
        if(subscribe){
          rej('Notifications are blocked by your browser. You can allow it from your browser settings')
        }
      }
      );
      })
    }

    receiveMessage() {
      this.angularFireMessaging.messages.subscribe(
      (payload:any) => {
        if(payload){
          this.toastr.success(payload.notification.body)}
          this.currentMessage.next(payload);
        if(payload){
          let url = window.location.href;
          url.toString()
          if(url.includes('orders')){
            this.setOrderTrack(true)
          }
          this.playAudio();
        this.toastr.success('Click to stop the sound', 'New Order Received', {
          tapToDismiss: true,
          disableTimeOut: true
        })
        .onTap
        .pipe(take(1))
        .subscribe(() => this.toasterClickedHandler());}


      })
    }
    playAudio(){
      let audio = new Audio();
      audio.src = "../assets/audio/mixkit-warning-alarm-buzzer-991.wav";
      audio.load();
      audio.play();

      audio.addEventListener("ended", function () {
        audio.play();
    }, false);
    this.toastClicked.subscribe(res=>{
      if(res){
        audio.pause()
        audio = undefined
        this.toastClicked.next(false)
      }
    })
      // audio.pause();
    }

  getOrderTrack(): Observable<any> {
    return this.orderTrack.asObservable();
  }
  toasterClickedHandler(){
    this.toastClicked.next(true)
  }
  setOrderTrack(val: any) {
      this.orderTrack.next(val);
  }
}



