import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app/utilities/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private router: Router) { }
  forgotPassForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]]
  })
  ngOnInit(): void {
  }

  async submit(){
    let resp = await this.authService.resetPassword(this.forgotPassForm.getRawValue())
    if(resp){
      setTimeout(() => {
          this.router.navigate(['login'])
      }, 1000);
    }
  }

}
