import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';


import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { AuthService } from './utilities/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.authService.getToken()    
    if(request.body == null || !(request.body.email && request.body.password)){
        if(token){
          const customReq = request.clone({
            headers: request.headers.set('Authorization',token)            
          });
          return next.handle(customReq);
        }else{
          return next.handle(request);  
        }                     
      
    }else{
      return next.handle(request);
    }


     
    
    

   
  }
}
