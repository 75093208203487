import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { DataServiceService } from 'app/utilities/data-service.service';
import { StoreService } from '../store/store.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/utilities/auth.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AttachPropertyComponent } from "./attach-property/attach-property.component";

@Component({
  selector: 'app-store-website-config',
  templateUrl: './store-website-config.component.html',
  styleUrls: ['./store-website-config.component.scss']
})
export class StoreWebsiteConfigComponent implements OnInit {
  @Input('storeId' )storeId
  @Input('store' )store;
  @Input('storeWebsiteConfigId' )storeWebsiteConfigId;


  public uploaderTemp: FileUploader;
  public dpUploader = [];
  bannerSettings = [];
  open = {
    'social':false,
    'banner': false,
    'google':false
  };
  uploaderOptions: FileUploaderOptions = {
    url: environment.cloudinaryURL,
  };
  links = {
    facebookURL:'',
    instagramURL:'',
    twitterURL:''
  }
  googlePayload = {
    googleAnalyticsKey: "",
    isGoogleAnalytics: false
  }
  googleError = '';
  banners = [];
  loading = {
    banner:false,
    social:false
  };
  socialForm = this.formBuilder.group({
    facebookURL: ['', Validators.pattern("^(http[s]?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]{1,30}$")],
    instagramURL: ['', Validators.pattern(/^\w[\w.]{1,28}[\w]$/)],
    twitterURL: ['', Validators.pattern(/^@(?=.*\w)[\w]{1,15}$/)]
  })
  attachCurrentProperty: { picture: string; name: string, _id: string } | null;
  public validationMessages = {
    facebookURL: [
    {
      type: 'pattern', message: 'Enter valid facebook URL'
    }],
    instagramURL: [
      {
        type: 'pattern', message: 'Enter valid instagram username'
      }],
    twitterURL: [
      {
        type: 'pattern', message: 'Enter valid twitter username'
      }],
  }
  lang = localStorage.getItem('lang')
  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  constructor( private ngModalService: NgbModal, private formBuilder: FormBuilder, private authService: AuthService, private dataService: DataServiceService, private toastr: ToastrService) { }

  modalOptions: NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }

  ngOnInit(): void {
    this.authService.language.subscribe(res=>{
      this.lang = res;
    })
    this.storeId = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected).storeId;
    this.initializeImageUpload()
  }

  initializeImageUpload(){
    const uploaderOptions: FileUploaderOptions = {
      url: environment.cloudinaryURL,

    };
  }


  addBanner(){
    if(this.banners.length<3){
      this.banners.push({
        name:'',
        buttonText:'',
        buttonLink:'',
        isShowButton:false,
        image:'',
        imgUrl: ''
      })
      this.imageUploadConfig(this.banners.length-1)
    }
  }

  uploadImg(id){
    document.getElementById(id).click();
  }

  imgSelected(e,i){
    let file = e.target.files[0]
    setTimeout(() => {

      document.getElementById('bannerimg-'+i).setAttribute('src',URL.createObjectURL(file))
    }, 100);
    // this.tempUrls[i] = {url:this.banners[i].imgUrl,image:this.banners[i].image};
    this.banners[i].image = URL.createObjectURL(file);
    this.banners[i].imgUrl = '';
  }


  // cancelImage(i){
  //   this.banners[i].image = this.tempUrls[i].image;
  //   document.getElementById('bannerimg-'+i).setAttribute('src',URL.createObjectURL(this.tempUrls[i].image))
  //   this.banners[i].imgUrl = this.tempUrls[i].url;
  // }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.banners, event.previousIndex, event.currentIndex);
  }

  allowDropFunction() {
    let type = 4;
    return (dragData: any) => dragData.width == type || dragData.estAdaptable == true;
  }

  remove(i){
    this.banners.splice(i,1)
  }

  imageUploadConfig(i) {
    this.dpUploader[i] = new FileUploader(this.uploaderOptions);

    this.dpUploader[i].onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.dpUploader[i].onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);
      this.banners[i].imgUrl = res.url;
    };
  }

  async uploadImage(i){
    let uploaded = await this.dpUploader[i].uploadAll()
  }

  submitBanner(){
    let payload = {
      storeId: this.storeId,
     mainBanners: this.banners.map(b=>{
      return {
        buttonLink:b.buttonLink,
        buttonText:b.buttonText,
        imgUrl:b.imgUrl,
        isShowButton:b.isShowButton,
        name:b.name,
      }
     })
    }
    this.dataService.updateBannerSettings(this.storeWebsiteConfigId,payload).subscribe(res=>{
      this.toastr.success('Banner(s) added successfully')
    },err=>{
      console.log(err)
      this.toastr.error(err?.error?.error || 'Banner add failed')
    })

  }

  disabled(){
    let disable = false;
    this.banners.forEach(banner=>{
      if(banner.isShowButton) {
        if(!banner.buttonLink || !banner.buttonText || !banner.name || !banner.imgUrl) {
          disable = true;
        }
      } else {
        if(!banner.name || !banner.imgUrl){
          disable = true;
        }
      }
    })
    return disable;
  }

  toggle(type){
    this.open[type] = !this.open[type]
    if(type != 'social' && !this.banners.length){
      this.getStoreWebsiteSetting();
    }
    if(type == 'social'){
      this.getSocialLinks();
    }
  }

  toggleBanner(i){
    this.banners[i].isShowButton = this.banners[i].isShowButton ? true : false;
  }


  getStoreWebsiteSetting(){
    this.loading['banner'] = true;
    this.dataService.getBannerSettings(this.storeId).subscribe(res=>{
      this.loading['banner'] = false
      if(res.storeWebsite){
        this.googlePayload.googleAnalyticsKey = res.storeWebsite.googleAnalyticsKey;
        this.googlePayload.isGoogleAnalytics = res.storeWebsite.isGoogleAnalytics;
        this.storeWebsiteConfigId = res.storeWebsite._id;
        this.banners = res.storeWebsite.mainBanners;

        
        this.banners.forEach((b,i)=>{
          b.image = b.imgUrl;
        })

        this.attachPropertiesToBanner();
        
        setTimeout(() => {
          for(let i=0;i<this.banners.length;i++){
            document.getElementById('bannerimg-'+i).setAttribute('src',this.banners[i].imgUrl)
          }
          console.log(this.banners)
        }, 500);
      }

     },err=>{
      this.loading['banner'] = false;
     })
  }

  updateLinks(){
    let tempLinks = this.socialForm.value;

    if(tempLinks.facebookURL.trim() == ''){
      delete tempLinks.facebookURL
    }else{
      tempLinks.facebookURL = tempLinks.facebookURL.trim()
    }
    if(tempLinks.instagramURL.trim() == ''){
      delete tempLinks.instagramURL
    }else{
      tempLinks.instagramURL = tempLinks.instagramURL.trim()
    }
    if(tempLinks.twitterURL.trim() == ''){
      delete tempLinks.twitterURL
    }else{
      tempLinks.twitterURL = tempLinks.twitterURL.trim()
    }



    let payload = {...tempLinks, storeId: this.storeId}
    this.dataService.updateSocialLinks(this.storeWebsiteConfigId, payload).subscribe(res=>{
      if(res.storeWebsiteUpdate.ok == 1){
        this.toastr.success('Social media links updated')
      }
    },err=>{
      console.log({err})
      this.toastr.error(err?.error?.error || 'Social media linked update failed')
    })
  }

  getSocialLinks(){
    this.loading['social'] = true;
    this.dataService.getSocialLinks(this.storeId).subscribe(res=>{
      this.loading['social'] = false;
      this.storeWebsiteConfigId = res.storeWebsite._id;
      let { facebookURL, instagramURL, twitterURL } = res.storeWebsite;
     this.socialForm.patchValue({
      facebookURL,
      instagramURL,
      twitterURL
     })
    },err=>{
      this.loading['social'] = false;
      console.log({err})
    })
  }

  updateGoogleId(){
    let payload = {...this.googlePayload, storeId: this.storeId}
    this.dataService.updateGoogleAnalytics(payload, this.storeWebsiteConfigId).subscribe(res=>{
      if(res?.storeWebsiteUpdate?.nModified == 1){
        this.toastr.success('Store configuration updated')
      }
    },err=>{
      console.log({err})
      this.toastr.error('Store configuration update failed')
    })
  }

  enteringGoogleId(e){
    let value = e.target.value;
    if(/(UA|YT|MO)-\d+-\d+/i.test(value) || !value){
      this.googleError = '';
    }
    else{
      this.googleError = 'Invalid ID'
    }
  }

  toggleGoogle(){
    this.googlePayload.isGoogleAnalytics = !this.googlePayload.isGoogleAnalytics
  }

  openAttachModal(property: string, index: number) {
    const modalRef = this.ngModalService.open(AttachPropertyComponent,this.modalOptions);
    modalRef.componentInstance.attachTo = property;
    modalRef.componentInstance.storeId = this.storeId;
    modalRef.componentInstance.index = index;
    modalRef.result.then(async (result: string) => {
      if (result.startsWith('attach')) {
        const payload: string[] = result.split("_-_");

        if(payload.length === 2) {
          const parsedPayload: {
            url: string;
            bannerIndex: number;
            property: { picture: string; name: string; _id: string; };
            type: "category" | "sub-category" | "product"
          } = JSON.parse(payload[1]);

          if(parsedPayload.bannerIndex !== -1 && parsedPayload.url && parsedPayload.property) {
            this.banners[parsedPayload.bannerIndex].buttonLink = parsedPayload.url;
            this.banners[parsedPayload.bannerIndex].isShowButton = true;
            this.banners[parsedPayload.bannerIndex].attachCurrentProperty = parsedPayload.property;
          }
        }
      }
    });
  }

  resetBanner(banner, index) {
    this.banners[index].attachCurrentProperty = null; 
    this.banners[index].buttonLink = '';

    console.log(this.banners)
  }

  async attachPropertiesToBanner() {
    let payload = { filters: { storeId: this.storeId }, pagination: { start: 0, limit: 1 }, sort: {}, fields: "" }

    for(let i = 0; i < this.banners.length; i++) {
      let apiToCall: "category" | "subCategory" | "product" | "" = "";

      try {
        const url: string = this.banners[i].buttonLink;

        if (typeof url === "string") {
          payload.fields = url.includes("categoryId=") || url.includes("subCategoryId=") ? "name picture _id" : "name displayPicture _id";
          apiToCall = url.includes("categoryId=") ? "category" : url.includes("subCategoryId=") ? "subCategory" : "product";
        }

        if(payload.fields && apiToCall) {
          let response;

          if (apiToCall === "product") {
            response = await this.dataService.getProducts(payload).toPromise();
            this.banners[i].attachCurrentProperty = response.product[0] || null;
          }
    
          if (apiToCall === "subCategory") {
            response = await this.dataService.getSubCategories(payload).toPromise();
            this.banners[i].attachCurrentProperty = response.subCategory[0] || null;
          }
    
          if (apiToCall === "category") {
            response = await this.dataService.getCategories(payload).toPromise();
            this.banners[i].attachCurrentProperty = response.category[0] || null;
          }

          this.banners[i].attachCurrentProperty.type = apiToCall;
        }
      } catch(error) {

      }
    }
    
  }
}
