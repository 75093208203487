import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StoreService } from '../store/store.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent implements OnInit {

  
  constructor(private storeService: StoreService) { }
  selectedOrg;
  orgs:any = [];
  urlID = 'add'
  storeShippingPolicy = false;
  storeRefundExchange = false;
  storeTermsConditions = false;
  storeShippingPolicyShow;
  storeRefundExchangeShow;
  storeTermsConditionsShow;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '150px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize','insertImage',
      'insertVideo', 'toggleEditorMode'],
      
    ]
};
  ngOnInit(): void {
    this.orgs = JSON.parse(localStorage.getItem('organizations'))
    this.selectedOrg = this.orgs.find(o=>o.selected)
    this.getPolicies()
  }

  submit(type){
    if(type=='shipping'){
      this.storeService.updateStoreShippingPolicy({storeShippingPolicy: this.storeShippingPolicy, storeShippingPolicyShow:this.storeShippingPolicyShow}, this.selectedOrg.storeId);
    }
    if(type=='refund'){
      this.storeService.updateStoreExchangePolicy({storeRefundExchange: this.storeRefundExchange, storeRefundExchangeShow: this.storeRefundExchangeShow}, this.selectedOrg.storeId);
    }
    if(type=='terms'){
      this.storeService.updateTermsAndConditions({storeTermsConditions: this.storeTermsConditions, storeTermsConditionsShow:this.storeTermsConditionsShow}, this.selectedOrg.storeId);
    }
  }

  async getPolicies(){
    let resp = await this.storeService.getStoreShippingPolicy(this.selectedOrg.storeId)
    let resp2 = await this.storeService.getStoreRefundExchangeMessage(this.selectedOrg.storeId)
    let resp3 = await this.storeService.getTermsAndConditions(this.selectedOrg.storeId)
    this.storeShippingPolicy = resp.storeShippingPolicy;
    this.storeRefundExchange = resp2.storeRefundExchange;
    this.storeTermsConditions = resp3.storeTermsConditions;
    this.storeShippingPolicyShow = resp.storeShippingPolicyShow;
    this.storeRefundExchangeShow = resp2.storeRefundExchangeShow;
    this.storeTermsConditionsShow = resp3.storeTermsConditionsShow;
  }
}
