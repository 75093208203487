import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ProductService } from '../product.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { QuickEditModalComponent } from '../quick-edit-modal/quick-edit-modal.component';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'app/pages/settings/store/store.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css']
})
export class ProductsListComponent implements OnInit {

  constructor(private dataService: DataServiceService, private translate: TranslateService, private toastr: ToastrService, private ngModalService: NgbModal, private productService: ProductService, private router: Router, private route: ActivatedRoute, private storeService: StoreService) {
    
   }
  
  pageLimit = 10;
  pageStart = 0;
  totalRecords = 0;
  urlId;
  search = false;
  searchValue = ''
  NoOfPages = 0;
  status;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  shareProduct;
  loader=false;
  payload:any = {
    "filters": {storeId: this.route.snapshot.params.id},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
  }
  products: any = [];
  store
  ngOnInit(): void {
    this.checkProductLimit()
    this.urlId = this.route.snapshot.params.id;
    this.getStoreInfo()
    this.getProducts()
    let that = this;
    document.getElementsByTagName('body')[0].addEventListener('click',function(e:any){
      if(e.target.id != 'share-icons'){
        that.shareProduct = '';
      }
    })
  }

  addProduct(){
    if(this.status == 204){
      this.toastr.warning('You have reached the product limit, to add more products update your subscription')
      return;
    }
    this.router.navigate(['add-product/'+this.urlId+'/new'])
  }

  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }

  async goToPage(Index) {
    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.getProducts()
  }

  getProducts(){
    this.loader = true;
    // this.payload.filters = {storeId: this.urlId}
    this.dataService.showSpinner();
    this.dataService.getProducts(this.payload).subscribe(res=>{
      this.loader = false;
      this.products = res.product;
      this.dataService.hideSpinner();
      this.totalRecords = res.totalRecord;
      this.createPagination();
    },err=>{
      this.loader = false;
    })
  }
  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.payload.pagination.limit = this.pageLimit;
    this.ngOnInit();
  }
  counter(i) {
    return new Array(i)
  }

  updateProduct(id){
    // this.productService.setProductId(id)
    this.router.navigate(['add-product/'+this.urlId+'/'+id])
  }
  cloneProduct(id){
    this.productService.setProductId(id)
    this.router.navigate(['add-product/'+this.urlId+'/clone'])
  }
  delete(id, message){
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if (result == 'yes') {
            let response = await that.productService.deleteProduct(id)
            that.ngOnInit()
      }
    });
    // this.confirmDialogService.confirmThis(message, async function () {
    // }, function () {
    // })
  }

  quickEditProduct(id){
    let product = this.products.find(p=>p._id == id)
    const modalRef = this.ngModalService.open(QuickEditModalComponent,this.modalOptions);
    modalRef.componentInstance.product = product;
    modalRef.result.then(async (result) => {
      if (result) {
        this.dataService.showSpinner()
            let response = await this.productService.quickEditProduct(id, result);
            this.getProducts()
      }
    });
  } 

  searchProducts(type = undefined){
    if(type == 'clear' || (type && !this.searchValue)){
      delete this.payload.filters.name;
      this.searchValue = ''
      this.getProducts();
      this.search = type == 'clear' ? false : true;
      return;
    }
    if(!type || type.keyCode == 13){
      if(this.search && this.searchValue){        
        let currentText = this.searchValue.trim()
        let currentTextArray = currentText.split(' ').map(item=>item.trim());
        let currentTextExtras = currentTextArray.map(item=>item.replace(/(?:es|[sx])$/, ''))
        currentTextArray = currentTextArray.concat(currentTextExtras);
        currentText = currentTextArray.map(item=>item.trim()).join("|")
        this.pageStart = 0;
        this.payload.pagination.start = 0;    
        this.payload.filters = {...this.payload.filters, name:{'$regex': currentText, '$options':'i'}}

        this.getProducts()
      }
      else{
        this.search = true
      }
    }
  }

  async toggleFeature(product){
    let payload = {isFeatured: !product.isFeatured}
    let updated = await this.productService.updateProductFeatured(product._id, payload)
    if(updated.productUpdate){
      this.products.find(p=>p._id == product._id).isFeatured= !this.products.find(p=>p._id == product._id).isFeatured;
    }
  }

  share(id){
    this.shareProduct = this.shareProduct == id ? '' : id;
  }

  checkProductLimit(){
    this.dataService.checkProductLimit(this.route.snapshot.params.id).subscribe(res=>{
      // if(res.code == 204){
      //   document.getElementById('add-btn')['disabled'] = true;
      // }
      // if(res.code == 200){
      //   document.getElementById('add-btn')['disabled'] = false;
      // }
      this.status = res.code;
    },err=>{
      console.log({err})
    })
  }

  async changePublish(id, product){
    let res = await this.productService.changePublish({isPublished: !product.isPublished, storeId: this.urlId}, id);
    if(res.code == 200){
      product.isActive = !product.isActive;
    }
    else{
      this.dataService.hideSpinner();
      this.toastr.error(res.message)
    }
  }

  chkPermission(){
    let org = JSON.parse(localStorage.getItem('organizations')).find(o => o.selected)
    return org.permissions.includes('product.add') ? true : false;
  }

  async getStoreInfo(){
    let storeResponse = await this.storeService.getSingleStore(this.urlId)   
    this.store = storeResponse.store;    
  }
}
