import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core'
import { DataServiceService } from '../../../../utilities/data-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { AdminService } from 'app/pages/settings/admin/admin.service';
import { AuthService } from 'app/utilities/auth.service';
import { StoreService } from '../../store/store.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-admin-register',
  templateUrl: './admin-register.component.html',
  styleUrls: ['./admin-register.component.css']
})
export class AdminRegisterComponent implements OnInit {
  isTempWork = environment.isTempWork
  featureAdded = false;
  id;
  allowedFeatures = [];
  branch;
  countries = [];
  features = [
    { name: "dashboard", options: ['dashboard'], key: "Dashboard" },
    { name: "orders", options: ['list', 'update'], key: "Orders" },
    { name: "product", options: ['add','list','update','delete'], key: "Products" },
    { name: "categories", options: ['add','list','update','delete'], key: "Categories" },
    { name: "sub-categories", options: ['add','list','update','delete'], key: "Sub Categories" },
    { name: "tags", options: ['add', 'list', 'update', 'delete'], key: "Tags" },
    { name: "attributes", options: ['add','list','update','delete'], key: "Attributes" },
    { name: "customers", options: ['list', 'update'], key: "Customers" },
    { name: "invoice", options: ['list', 'update'], key: "Invoice" },
    { name: "promotions", options: ['add', 'list', 'update', 'delete'], key: "Promotions" },
    { name: "store-settings", options: ['update'], key: "Store Settings" },
    { name: "delivery-settings", options: ['add', 'list', 'update', 'delete'], key: "Delivery Settings" },
    { name: "manage-channels", options: ['add'], key: "Manage Channels" },
    { name: "policies", options: ['add'], key: "Store Policies" },
    { name: "admin", options: ['add', 'list', 'update', 'delete'], key: "Admins" },
  ];
  allPermissions = [
    'dashboard',
        'orders.list','orders.update',
        'store-settings.update',
        'admin.add','admin.update','admin.list','admin.delete',
      'promotions.add','promotions.list','promotions.update','promotions.delete',
      'categories.list','categories.update','categories.add','categories.delete',
      'invoice.list','invoice.update',
      'customers.list','customers.update',
      'sub-categories.list','sub-categories.update','sub-categories.add','sub-categories.delete',
      'delivery-settings.list','delivery-settings.update','delivery-settings.add','delivery-settings.delete',
      'manage-channels.add',
      'policies.add',
      'product.list','product.update','product.add','product.delete',
      'tags.list','tags.update','tags.add','tags.delete',
      'attributes.list','attributes.update','attributes.add','attributes.delete',
  ]
  tableHeadings = ["add", "list", "update", "delete"]
  allChecked = false;
  public validationMessages = {
    firstName: [{
      type: 'required', message: 'First name is required'
    },
    {
      type: 'minlength', message: 'First name should be of atleast 3 characters'
    }],
    lastName: [{
      type: 'required', message: 'Last name is required'
    },
    {
      type: 'minlength', message: 'Last name should be of atleast 3 characters'
    }],
    phoneNumber: [{
      type: 'required', message: 'Phone number is required'
    },
    {
      type: 'minlength', message: 'Phone number should be of atleast 7 numbers'
    },
    {
      type: 'maxlength', message: 'Phone number should not exceed from 13 numbers'
    },
    {
      type: 'pattern', message: 'Phone number should not contain characters'
    }],
    email: [{
      type: 'required', message: 'Email is required',
    },
    {
      type: 'pattern', message: 'Enter valid email address'
    }],
    // password: [{
    //   type: 'required', message: 'Password is required'
    // },
    // {
    //   type: 'minlength', message: 'Password must be atleast 6 characters long'
    // }],
    // rePassword: [{
    //   type: 'required', message: 'Please enter password again'
    // }],
    storeId: [{
      type: 'required', message: 'store name is required'
    }],
    role: [{
      type: 'required', message: 'Role is required'
    }]
  }

  payload = {
    "filters": {},
    "pagination": { start: 0, limit: 50 },
    "sort": {},
    "fields": "name"
  }
  selectedFeatures = []

  stores = [];
  updatePayload: { branchId: any;permissions: [] };
  adminRegister: any;
  adminChosen: boolean;
  branches = []
  permissions = [];
  constructor(public router: Router, private storeService: StoreService, private auth: AuthService,
    private formBuilder: UntypedFormBuilder, private translate: TranslateService, private adminService: AdminService, private dataService: DataServiceService, private toastr: ToastrService,
    private route: ActivatedRoute) {
    //
    this.id = this.route.snapshot.params.id;
    this.adminRegister = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      phoneNumber: ['', [Validators.required, Validators.maxLength(13), Validators.minLength(7), Validators.pattern('^[0-9]*$')]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]],
      // password: ['', [Validators.required, Validators.minLength(6)]],
      // rePassword: ['', [Validators.required, Validators.minLength(6)]],
      // role: ['', Validators.required],
      features: new UntypedFormArray([]),
      phoneCode:['92', Validators.required],
      countryId:['167', Validators.required]
    }
    );
  }

  user;
  selectedOrg;
  orgs = [];
  async ngOnInit() {
    if(this.isTempWork){
    //   this.features.push({ name: "payment-integration", options: ['update'], key: "Payment Integrations" })
    //   this.allPermissions.push('payment-integration.update');
    this.features.push({ name: "apps-plugins", options: ['list'], key: "Apps and Plugins" })
    this.allPermissions.push('apps-plugins.list');

    }
    this.getCountries()
    this.getSubscription(true)
    // this.auth.getOrganizations().subscribe(res=>{
      this.orgs = JSON.parse(localStorage.getItem('organizations')) || []
      this.selectedOrg = this.orgs.length ? this.orgs.find(o=>o.selected) : null;
    // })
    // this.stores = await this.adminService.getstores(this.payload)
    if (this.id != "add" && this.selectedOrg) {
      try {
        this.dataService.getPermissions(this.id, this.selectedOrg.storeId).subscribe(async res=>{

          this.permissions = res?.orgDoc.permissions;
          this.branch = res?.orgDoc.branchId
          // console.log(this.permissions)
          this.user = await this.adminService.getSingleAdmin(this.id);
      this.getSubscription(false)
        })

      } catch(err) {
        if(err) {
          this.toastr.error("Invalid Administrator");

          if(this.selectedOrg.storeId){
            this.router.navigate(['admin-list/'+ this.selectedOrg.storeId])
          }
          else{
            this.router.navigate(['admin-list/all'])
          }
        }
      }
    }
    if(this.id != "add" && !this.selectedOrg){
      this.user = await this.adminService.getSingleAdmin(this.id);
      this.getSubscription(false)
    }

  }

  // FoodCuisineList() {
  //   this.router.navigate(['food-cusinies-list'])
  // }

  async submit() {
    this.dataService.showSpinner()
    // delete this.adminRegister.value.rePassword;
    this.adminRegister.value.phoneNumber = parseInt(this.adminRegister.value.phoneNumber);
    let payload = {...this.adminRegister.value};
    payload.storeId = this.selectedOrg ? this.selectedOrg.storeId : null;
    payload.organization = {
      storeId: this.selectedOrg ? this.selectedOrg.storeId : null,
      branchId: payload.branchId,
      permissions: payload.features,
      role: 'admin'
    }
    if(!payload.organization.storeId){
      delete payload.organization.storeId
    }
    delete payload.branchId;
    delete payload.features;
    // console.log(payload)
    let response:any = await this.adminService.addMember(payload)
  }

  update() {

    this.dataService.showSpinner()
    this.updatePayload = {
      branchId: this.adminRegister.value.branchId,
      permissions: this.adminRegister.get('features').value
    }
    // console.log(this.updatePayload)
    this.adminService.updateMember(this.updatePayload, this.id, this.selectedOrg ? this.selectedOrg.storeId : null)
  }

  patchAdminInfo(features = undefined) {
    // if (this.user.role == 'admin') {
      this.adminChosen = true
      this.features = [
        { name: "dashboard", options: ['dashboard'], key: "Dashboard" },
        { name: "orders", options: ['list', 'update'], key: "Orders" },
        { name: "product", options: ['add','list','update','delete'], key: "Products" },
        { name: "categories", options: ['add','list','update','delete'], key: "Categories" },
        { name: "sub-categories", options: ['add','list','update','delete'], key: "Sub Categories" },
        { name: "tags", options: ['add', 'list', 'update', 'delete'], key: "Tags" },
        { name: "attributes", options: ['add','list','update','delete'], key: "Attributes" },
        { name: "customers", options: ['list', 'update'], key: "Customers" },
        { name: "invoice", options: ['list', 'update'], key: "Invoice" },
        { name: "promotions", options: ['add', 'list', 'update', 'delete'], key: "Promotions" },
        { name: "store-settings", options: ['update'], key: "Store Settings" },
        { name: "delivery-settings", options: ['add', 'list', 'update', 'delete'], key: "Delivery Settings" },
        { name: "policies", options: ['add'], key: "Store Policies" },
        { name: "admin", options: ['add', 'list', 'update', 'delete'], key: "Admins" },
        { name: "manage-channels", options: ['add'], key: "Manage Channels" },
        //{ name: "live-chat", options: ['add'], key: "Live Chat" },
      ];

      if(this.isTempWork){
      //   this.features.push({ name: "payment-integration", options: ['update'], key: "Payment Integrations" })
        this.features.push({ name: "apps-plugins", options: ['list'], key: "Apps and Plugins" })
      }
      if(features){
        if(features.includes('import-export-product')){
          this.features.push({ name: "importexport", options: ['add'], key: "Import/Export Products" })
          this.allPermissions.push('importexport.add')
        }
        if(features.includes('inbox')){
          this.features.push({ name: "live-chat", options: ['add'], key: "Live Chat" })
          this.allPermissions.push('live-chat.add')
        }
      }
      else{
          this.features.push({ name: "importexport", options: ['add'], key: "Import/Export Products" })
          this.features.push({ name: "live-chat", options: ['add'], key: "Live Chat" })
          this.allPermissions.push('importexport.add')
          this.allPermissions.push('live-chat.add')
      }

      let all = this.permissions.map(p=>{
        if(this.allPermissions.includes(p)){
          return true
        }
      })
      if(all.includes(false)){
        this.allChecked = false;
      }
      else{
        this.allChecked = true;
      }
    // }
    // console.log('brach',this.branch)
    this.adminRegister.patchValue({
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      phoneNumber: this.user.phoneNumber,
      email: this.user.email,
      branchId: this.branch,
      // password: 'password',
      // rePassword: 'password',
    })
    // console.log(this.adminRegister)
    if (this.permissions) {
      this.permissions.map(single => {
        this.adminRegister.controls.features.value.push(single)
      })
    }
  }

  addFeature(e, feature, option, i) {
    this.featureAdded = true;
    this.selectedFeatures = [...this.adminRegister.controls.features.value];
    const element = document.getElementById(feature.name + option) as HTMLInputElement
    if (feature.options.length == 1 && feature.options[0] == feature.name && option == 'list' && element.checked) {
      this.adminRegister.controls.features.value.push(feature.name)
      this.selectedFeatures.push(feature.name)
    }
    else if (feature.options.length == 1 && feature.options[0] == feature.name && option == 'list' && !element.checked) {
      let index2 = this.adminRegister.controls.features.value.indexOf(feature.name)
      this.adminRegister.value.features.splice(index2, 1)
      this.selectedFeatures.splice(index2, 1)
    }
    if (element.checked && feature.options[0] != feature.name) {
      this.adminRegister.controls.features.value.push(feature.name + '.' + option)
      this.selectedFeatures.push(feature.name + '.' + option)
    }
    else if (this.adminRegister.controls.features.value.length > 0 && feature.options[0] != feature.name) {
      let index2 = this.adminRegister.controls.features.value.indexOf(feature.name + '.' + option)
      this.adminRegister.value.features.splice(index2, 1)
      this.selectedFeatures.splice(index2, 1)
    }
    // console.log(feature, option, this.adminRegister.get('features').value)
  }

  isFound(feature, option) {
    // console.log('is found', feature, option)
    if (this.adminRegister.value.features) {
      if (feature.options.length == 1 && feature.options[0] == feature.name && option == 'list') {
        let index = this.adminRegister.value.features.indexOf(feature.name)
        if (index >= 0) {
          return true
        }
        else {
          return false
        }
      }
      else {
        let index = this.adminRegister.value.features.indexOf(feature.name + '.' + option)
        if (index >= 0) {
          // console.log('if')
          return true
        }
        else {
          // console.log('else')
          return false
        }
      }
    }
  }


  pushDefaultPermissions(){
    // console.log('pushedddd')
    this.adminRegister.controls.features.value.push(
      'orders.list','orders.update',
      'menu.list','menu.update',
      'deals-category.list','deals-category.update',
      'deals.list','deals.update',
      'reservations.list','reservations.update',
      'customers.list','customers.update',
      'branches.list','branches.update',
      // 'promotions.list','promotions.update',
    )
  }

  checkAll(e){
    if(e.target.checked){
      this.allPermissions.map(per=>{
        this.adminRegister.controls.features.value.push(
          per
        )
      })

        // console.log(this.adminRegister.getRawValue(), this.allPermissions)
    }
    else{
      // console.log('empty')
      this.adminRegister.get('features').setValue([])
    }
    this.allChecked = !this.allChecked;
    // console.log(this.adminRegister)
  }

  isOptionFound(heading, options, name) {
    if (options.length == 1 && options[0] == name && heading == 'list') {
      return true
    }
    else {
      let index = options.indexOf(heading)
      if (index >= 0) {
        return true
      }
      return false
    }
  }
  close() {
    if(this.selectedOrg?.storeId){
      this.router.navigate(['admin-list/'+ this.selectedOrg.storeId])
    }
    else{
      this.router.navigate(['admin-list/all'])
    }
  }

  async getSubscription(initial){
    //console.log('get subscription')
    this.storeService.getStore().subscribe(async res=>{
      if(res && res.subscriptionId){
        let response = await this.adminService.getSingleSubscription(res.subscriptionId)
        if(response.subscription){
          this.allowedFeatures = response.subscription.allowedFeatures;
          if(!initial){
            this.patchAdminInfo(this.allowedFeatures)
          }
          else{
            if(this.allowedFeatures.includes('import-export-product')){
              this.features.push({ name: "importexport", options: ['add'], key: "Import/Export Products" })
              this.allPermissions.push('importexport.add')
            }
            if(this.allowedFeatures.includes('inbox')){
              this.features.push({ name: "live-chat", options: ['add'], key: "Live Chat" })
              this.allPermissions.push('live-chat.add')
            }
          }

        }
      }
      else{
        if(!initial){
          this.patchAdminInfo()
        }
      }
    })
  }

  async getCountries(){
    let countryResponse = await this.storeService.getCountries();
    this.countries = countryResponse?.countries;
  }

  phoneCodeChange(event){
    let countryIndex =_.findIndex(this.countries, function(o) { return o.countryId == parseInt(event.target.value); });
    if (countryIndex >= 0) {
      this.adminRegister.patchValue({
        //phoneCode: this.countries[countryIndex].phoneCode,
        countryId:  this.countries[countryIndex].countryId
      })
    }
  }
}
