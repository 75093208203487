import { Injectable } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AttributesService {

  constructor(private dataService: DataServiceService, private toastr: ToastrService) { }

  async getAttributes(id){
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.getAttributes(id)
        .subscribe(val =>{ 
          this.dataService.hideSpinner()
          resolve(val)
        },err=>{      
          this.dataService.hideSpinner()
          reject(err)
        });
      })
        let result = await promise;
        return result;
    }

    async addAttribute(payload){
      this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.addAttribute(payload).
      subscribe(val => {
        this.dataService.hideSpinner()
          resolve(val)
        },err=>{      
          this.dataService.hideSpinner()
          this.toastr.error('Failed!')
          reject(err)
        });
      })
        let result = await promise;
        return result;
    }
}
