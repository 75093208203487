import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DataServiceService } from 'app/utilities/data-service.service';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DealsService } from './../deals.service';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { AuthService } from 'app/utilities/auth.service';
import { DeleteModalComponent } from 'app/delete-modal/delete-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-add-deals',
  templateUrl: './add-deals.component.html',
  styleUrls: ['./add-deals.component.css']
})
export class AddDealsComponent implements OnInit {
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  dealsForm: any;
  orderTypes: { id: number; name: string; }[];
  stores: any;
  storePayload = {
    "filters": {},
    "pagination": { start: 0, limit: 50 },
    "sort": {},
    "fields": "name"
  }
  public uploader: FileUploader;
  previewImg: any;
  urlID;
  allOrderTypes = [
    { id: 0, name: 'Delivery' },
    { id: 1, name: 'Take away' },
    { id: 2, name: 'Reservation' },
    { id: 3, name: 'Digital menu' }
  ]

  isDealFeatured: boolean = false;
  CLOUDINARY_UPLOAD_PRESET = environment.cloudinaryUploadPreset;
  public validationMessages = {
    name: [{
      type: 'required', message: 'Name is required'
    }],
    info: [{
      type: 'required', message: 'Info is required'
    }],
    exp: [{
      type: 'required', message: 'Expiry date is required'
    }]
  }

  selectedItems = [];

  currentOrderTypes: any[][];
  selectedOrderTypesForDeal = [];
  deals: { menu: any; };
  currentDeal;
  selectedOrderTypes = [];
  menus = [];
  subMenus = [];
  options = [];
  quantity = [];
  totalPrice = 0;
  payload;
  flavors = []
  dealCategories: any = [];
  selectedCategory: any;
  emptySubMenu: any;
  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private dataService: DataServiceService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private dealsService: DealsService,
    private authService: AuthService,
    private ngModalService: NgbModal,
  ) {

    const uploaderOptions: FileUploaderOptions = {
      url: environment.cloudinaryURL,
            
    };
    this.uploader = new FileUploader(uploaderOptions);
  }
  private addOrdersCheckboxes() {
    this.dealsForm.controls.orderTypes.controls = []
    if (this.orderTypes) {
      this.orderTypes.forEach((o, i) => {
        const control = new UntypedFormControl(); // if first item set to true, else false
        (this.dealsForm.controls.orderTypes as UntypedFormArray).push(control);
      });
    }
  }

  async storeSelected(event) {
    this.flavors = []
    this.dealsForm.controls.items.controls = []
    this.quantity = [];
    this.totalPrice = 0;
    this.getMenuForSelectedStore()
    this.currentOrderTypes = await this.dealsService.getSingleStore(event)
    if (this.currentOrderTypes != undefined) {
      of(this.getOrderTypes()).subscribe(orders => {
        this.orderTypes = orders[0];
        this.addOrdersCheckboxes();
      });
    }

    await this.getDealCategories(event);
  }

  async getDealCategories(id) {
    try {
      const user = this.authService.getUser();

      let payload;

      if(user.role === "superAdmin") {
        payload = {
          "filters": {
            "storeId": id
          },
          "pagination": { start: 0, limit: 9999 },
          "sort": {},
          "fields": "name"
        }
      } else {
        payload = {
          "filters": {
            "storeId": user.storeId
          },
          "pagination": { start: 0, limit: 9999 },
          "sort": {},
          "fields": "name"
        }
      }

      let response: any = await this.dealsService.getAllDealCategories(payload);

      this.dealCategories = response.dealCategory;
    } catch (err) {
      throw new Error(err)
    }
  }

  getOrderTypes() {
    this.selectedOrderTypesForDeal = []
    return [
      this.currentOrderTypes.map((single, ind) => {
        let index = _.findIndex(this.allOrderTypes, { id: single });
        this.selectedOrderTypesForDeal.push({ id: single, name: this.allOrderTypes[index].name })
        return this.selectedOrderTypesForDeal[ind]
      })
    ]
  }

  async ngOnInit() {
    try {
      this.urlID = this.route.snapshot.params.id;
      this.buildDealForm()
      if (this.urlID != 'add') {
        await this.preDisplayDealData()
      }
      this.imageUploadConfig()
      this.stores = await this.dealsService.getStores(this.storePayload)

    } catch (err) {
      if (err) {
        this.toastr.error("Invalid Deal");

        this.router.navigateByUrl('/deals');
      }
    }
  }
  async addItems(i) {
    // await this.getMenuForSelectedStore()
    if (i != 'new') {
      await this.preDisplayDealItems(i)
    }

    if (i == 'new') {
      this.dealsForm.controls.items.push(this.formBuilder.group({
        menuID: ['', Validators.required],
        subMenuID: ['', Validators.required],
        optionsID: ['', Validators.required],
        quantity: ['', [Validators.required, Validators.min(1)]],
        amount: ['', Validators.required],
        subMenu: [''],
        options: [''],
        itemPrice: [''],
        isFlavor: false,
        requiredFlavor: null
      }))
    }
  }

  menuSelected(event, i, type) {
    this.flavors[i] = []
    let index = _.findIndex(this.menus, { _id: event.target.value });
    if (index >= 0) {
      if (this.menus[index].subMenu[0].name != "--Select--") {
        this.menus[index].subMenu.unshift({ addOns: [], description: '', discount: 0, name: "--Select--", options: [], picture: '', _id: '0' })
      }

      if(this.dealsForm.controls.items.controls[i].controls.flavors) {
        this.dealsForm.controls.items.controls[i].patchValue({
          menuID: this.menus[index]._id,
          subMenu: this.menus[index].subMenu,
          subMenuID: '',
          optionsID: '',
          quantity: '',
          amount: '',
          options: '',
          flavors: []
        })
        this.dealsForm.controls.items.controls[i].removeControl('flavors');
      }

      this.dealsForm.controls.items.controls[i].patchValue({
        menuID: this.menus[index]._id,
        subMenu: this.menus[index].subMenu,
        subMenuID: '',
        optionsID: '',
        quantity: '',
        amount: '',
        options: '',
      })
    }
  }

  subMenuSelected(event, i, type) {
    this.flavors[i] = []
    let index = _.findIndex(this.subMenus, { _id: event.target.value });
    if (index >= 0) {
      if (this.subMenus[index].isFlavor) {
        this.flavors[i] = this.subMenus[index].flavors;
        this.dealsForm.controls.items.controls[i].addControl('requiredFlavor', new UntypedFormControl(this.subMenus[index].requiredFlavor))
        this.dealsForm.controls.items.controls[i].addControl('isFlavor', new UntypedFormControl(true))
        this.dealsForm.controls.items.controls[i].addControl('flavors', new UntypedFormControl(this.subMenus[index].flavors, Validators.required))
      } else {
        this.dealsForm.controls.items.controls[i].addControl('requiredFlavor', new UntypedFormControl(this.subMenus[index].requiredFlavor))
        this.dealsForm.controls.items.controls[i].addControl('isFlavor', new UntypedFormControl(false))
      }
      if (this.subMenus[index].options[0].name != "--Select--") {
        this.subMenus[index].options.unshift({ description: '', price: 0, name: "--Select--", _id: '0' })
      }
      if (this.subMenus[index].isFlavor) {
        this.dealsForm.controls.items.controls[i].patchValue({
          subMenuID: this.subMenus[index]._id,
          options: this.subMenus[index].options,
          optionsID: '',
          quantity: '',
          amount: '',
          flavors: this.flavors[i].length > 0 ? this.flavors[i] : []
        })
      } else {
        this.dealsForm.controls.items.controls[i].patchValue({
          subMenuID: this.subMenus[index]._id,
          options: this.subMenus[index].options,
          optionsID: '',
          quantity: '',
          amount: ''
        })
      }
    }
    else {
      this.dealsForm.controls.items.controls[i].patchValue({
        subMenuID: '',
        options: [],
        optionsID: '',
        quantity: '',
        amount: ''
      })
    }
  }

  optionSelected(event, i, type) {
    this.dealsForm.controls.items.controls[i].patchValue({
      quantity: 1
    });
    let index = _.findIndex(this.options, { _id: event.target.value });
    if (index >= 0) {
      this.dealsForm.controls.items.controls[i].patchValue({
        optionsID: this.options[index]._id,
        amount: this.options[index].price
      })
      this.totalPrice = 0
      this.dealsForm.get('items').controls[i].get('itemPrice').value = this.dealsForm.get('items').controls[i].get('quantity').value * this.dealsForm.get('items').controls[i].get('amount').value;
      for (let j = 0; j < this.dealsForm.get('items').controls.length; j++) {
        this.totalPrice = this.totalPrice + this.dealsForm.get('items').controls[j].get('itemPrice').value;
      }
    }

  }
  amountSelected(event, i) {
    this.totalPrice = 0;
    this.dealsForm.get('items').controls[i].get('itemPrice').value = this.dealsForm.get('items').controls[i].get('quantity').value * this.dealsForm.get('items').controls[i].get('amount').value;
    for (let j = 0; j < this.dealsForm.get('items').controls.length; j++) {
      this.totalPrice = this.totalPrice + this.dealsForm.get('items').controls[j].get('itemPrice').value;
    }
  }

  priceChanged(e) {
    this.totalPrice = parseInt(e.target.value);
  }

  async preDisplayDealData() {
    this.currentDeal = await this.dealsService.getSingleDeal(this.urlID)

    if(this.currentDeal.isFeatured) {
      this.isDealFeatured = true;
    }

    this.currentOrderTypes = await this.dealsService.getSingleStore(this.currentDeal.storeId);
    if (this.currentOrderTypes != undefined) {
      of(this.getOrderTypes()).subscribe(orders => {
        this.orderTypes = orders[0];
        this.addOrdersCheckboxes(); this.dataService.showSpinner()
      });
    }

    this.orderTypes.map(single => {
      this.selectedOrderTypes.push(null);
    })
    this.currentDeal.orderTypes.map(singleType => {
      let index = _.findIndex(this.orderTypes, { id: singleType });
      this.selectedOrderTypes[index] = true;
    })

    if(this.currentDeal.categoryID && this.currentDeal.categoryName) {
      this.selectedCategory = {
        _id: this.currentDeal.categoryID,
        name: this.currentDeal.categoryName
      }
    }

    let expDate = this.modifyDate()
    this.dealsForm.patchValue({
      storeId: this.currentDeal.storeId,
      name: this.currentDeal.dealName,
      info: this.currentDeal.dealInfo,
      exp: expDate,
      price: this.currentDeal.dealPrice,
      picture: this.currentDeal.dealPic,
      orderTypes: this.selectedOrderTypes,
      categoryID: this.currentDeal.categoryID,
      items: [],
    })
    await this.getMenuForSelectedStore()
    await this.getDealCategories(this.currentDeal.storeId)
    this.currentDeal.item.map((singleItem, i) => {
      this.addItems(i)
    })
  }

  modifyDate() {
    this.currentDeal.expiryDate = new Date(this.currentDeal.expiryDate)
    let day = this.currentDeal.expiryDate.getDate();
    day = day.toString();
    if (day.length == 1) {
      day = 0 + day
    }
    let month = this.currentDeal.expiryDate.getMonth() + 1;
    month = month.toString();
    if (month.length == 1) {
      month = 0 + month
    }
    let year = this.currentDeal.expiryDate.getFullYear()
    let hours = this.currentDeal.expiryDate.getHours()
    hours = hours.toString();
    if (hours.length == 1) {
      hours = 0 + hours
    }
    let minutes = this.currentDeal.expiryDate.getMinutes()
    minutes = minutes.toString();
    if (minutes.length == 1) {
      minutes = 0 + minutes
    }
    return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes;
  }

  preDisplayDealItems(i) {
    let indexOfMenu = _.findIndex(this.menus, { _id: this.currentDeal.item[i].menuId });
    let indexOfSubMenu = _.findIndex(this.subMenus, { _id: this.currentDeal.item[i].subMenuId });
    let indexOfOptions = _.findIndex(this.options, { _id: this.currentDeal.item[i].optionId });
    if (indexOfMenu >= 0 && indexOfSubMenu >= 0 && indexOfOptions >= 0) {
      this.dealsForm.controls.items.push(this.formBuilder.group({
        menuID: [this.currentDeal.item[i].menuId, Validators.required],
        subMenuID: [this.currentDeal.item[i].subMenuId, Validators.required],
        optionsID: [this.currentDeal.item[i].optionId, Validators.required],
        quantity: [this.currentDeal.item[i].itemQuantity, [Validators.required, Validators.minLength(1)]],
        amount: [this.options[indexOfOptions].price, Validators.required],
        subMenu: [this.menus[indexOfMenu].subMenu],
        options: [this.subMenus[indexOfSubMenu].options],
        itemPrice: [this.currentDeal.item[i].totalPrice],
        flavors: [this.currentDeal.item[i].flavors]
      }))
      if (this.currentDeal.item[i].flavors) {
        this.flavors[i] = this.subMenus[indexOfSubMenu].flavors
        this.dealsForm.controls.items.controls[i].get('flavors').setValidators([Validators.required])
        this.selectedItems.push(this.currentDeal.item[i].flavors);
      }
      this.dealsForm.controls.items.controls[i].get('itemPrice').value = this.currentDeal.item[i].itemQuantity * this.options[indexOfOptions].price;
      // for(let j=0; j<this.dealsForm.get('items').controls.length;j++){
      //   this.totalPrice = this.totalPrice + this.dealsForm.get('items').controls[j].get('itemPrice').value;
      // } 
      this.totalPrice = this.currentDeal.dealPrice;
    }
  }

  async getMenuForSelectedStore() {
    this.menus = [];
    this.subMenus = [];
    this.options = [];
    let menu = await this.dealsService.getSingleMenu(this.dealsForm.value.storeId)
    menu.map(singleMenu => {
      this.menus.push(singleMenu);
      singleMenu.subMenu.map(singleSubMenu => {
        this.subMenus.push(singleSubMenu);
        singleSubMenu.options.map(singleOption => {
          this.options.push(singleOption);
        })
      })
    })
  }

  buildDealForm() {
    this.dealsForm = this.formBuilder.group({
      storeId: ['', Validators.required],
      picture: ['', Validators.required],
      name: ['', Validators.required],
      info: ['', Validators.required],
      exp: ['', Validators.required],
      items: new UntypedFormArray([]),
      orderTypes: new UntypedFormArray([]),
      categoryID: ['', Validators.required]
    })
  }

  categorySelected(event) {
    if(event.target.value) {
      this.selectedCategory = this.dealCategories[_.findIndex(this.dealCategories, {_id: event.target.value})];
    }
  }

  imageUploadConfig() {
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('upload_preset', this.CLOUDINARY_UPLOAD_PRESET);
      form.append('file', fileItem);
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let res: any = JSON.parse(response);
      this.dealsForm.patchValue({
        picture: res.url
      })
      return { item, response, status, headers };
    };

    this.uploader.onAfterAddingFile = (fileItem) => {
      this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
      this.dealsForm.patchValue({
        picture: ''
      })
    }
  }

  register() {
    if (this.totalPrice == 0) {
      document.getElementById('err-msg').classList.add('show');
    }
    else {
      const items = [];
      let subMenuName = '';
      let menuName = '';
      let optionName = '';
      this.dealsForm.value.items.map((single, i) => {
        let indexOfSubMenu = _.findIndex(this.subMenus, { _id: single.subMenuID });
        subMenuName = this.subMenus[indexOfSubMenu].name;
        let indexOfMenu = _.findIndex(this.menus, { _id: single.menuID });
        menuName = this.menus[indexOfMenu].name;
        let indexOfOption = _.findIndex(this.options, { _id: single.optionsID });
        optionName = this.options[indexOfOption].name;

        if (single.flavors && single.flavors.length > 0) {
          single.isFlavor = true;
          single.requiredFlavor = this.subMenus[indexOfSubMenu].requiredFlavor;
        } else {
          single.isFlavor = false;
        }

        items.push({
          subMenuName: subMenuName,
          subMenuId: single.subMenuID,
          totalPrice: this.dealsForm.get('items').controls[i].get('itemPrice').value,
          itemQuantity: single.quantity,
          optionName: optionName,
          optionId: single.optionsID,
          menuName: menuName,
          menuId: single.menuID,
          flavors: single.flavors ? single.flavors : [],
          requiredFlavor: single.requiredFlavor,
          isFlavor: single.isFlavor ? single.isFlavor : false
        })
      })
      let index = _.findIndex(this.stores, { _id: this.dealsForm.value.storeId });
      const storeName = this.stores[index].name;
      let selectedOrderTypes = []
      this.dealsForm.value.orderTypes.map((single, i) => {
        if (single == true) {
          selectedOrderTypes.push(this.orderTypes[i].id);
        }
      })
      const expdate = new Date(this.dealsForm.get('exp').value).valueOf()
      this.payload = {
        orderTypes: selectedOrderTypes,
        storeId: this.dealsForm.value.storeId,
        dealInfo: this.dealsForm.value.info,
        dealPrice: this.totalPrice,
        expiryDate: expdate,
        dealName: this.dealsForm.value.name,
        storeName: storeName,
        dealPic: this.dealsForm.value.picture,
        item: items,
        isFeatured: this.isDealFeatured,
        categoryID: this.selectedCategory._id,
        categoryName: this.selectedCategory.name,
      }

      if (this.urlID == 'add') {
        this.dealsService.createDeal(this.payload)
      }
      else {
        this.dealsService.updateDeal(this.payload, this.urlID)
      }
    }
  }

  async deleteItem(i, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = message;
    modalRef.result.then(async (result) => {
      if(result == 'yes'){
        that.dataService.showSpinner()
        that.totalPrice = that.totalPrice - that.dealsForm.get('items').controls[i].get('itemPrice').value;
        that.flavors[i] = [];
        if(that.dealsForm.value.items[i].isFlavor) {
          that.dealsForm.value.items[i].flavors.splice(0, that.dealsForm.value.items[i].flavors.length)
        }
        that.dealsForm.controls.items.removeAt(i);
        that.dataService.hideSpinner()
      }
    })
    // let res = await this.confirmationService.confirmation('Are you sure you want to delete this item?')
    // if(res){
    //   this.dataService.showSpinner()
    //   this.totalPrice = this.totalPrice - this.dealsForm.get('items').controls[i].get('itemPrice').value;
    //   this.dealsForm.controls.items.removeAt(i);
    //   this.dataService.hideSpinner()
    // }
  }

  removeImage() {
    this.uploader.clearQueue();
    this.dealsForm.patchValue({
      picture: ''
    })
  }

  close() {
    let datetime = new Date(this.dealsForm.value.exp)
    this.router.navigate(['deals'])
  }

  changeIsFeatured() {
    this.isDealFeatured = !this.isDealFeatured;
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

}
