import { Component, OnInit } from '@angular/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import { CustomerService } from 'app/pages/customer/customer.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.css']
})
export class CustomersListComponent implements OnInit {
  pageLimitOrdered = 10;
  pageStartOrdered = 0;
  pageLimit = 10;
  pageStart = 0;
  totalRecordsOrdered = 0;
  NoOfPagesOrdered = 0;
  pageLimitUnordered = 10;
  pageStartUnordered = 0;
  totalRecordsUnordered = 0;
  NoOfPagesUnordered = 0;
  search = false;
  searchValue = ''
  tab = 'ordered'
  block =false;
  orderedCustomers;
  unOrderedCustomers;
  payload:any = {
    "filters": {storeId: this.route.snapshot.params.id},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
  }
  payloadOrdered:any = {
    pagination: { skip: this.pageStartOrdered, limit: this.pageLimitOrdered },
    sort: {},
    fields: ""
  }
  payloadUnordered:any = {
    filters: {hasOrdered: false},
    pagination: { skip: this.pageStartUnordered, limit: this.pageLimitUnordered },
    sort: {},
    fields: ""
  }
  org;
  constructor(private dataService: DataServiceService, private route:ActivatedRoute, private permissionService: NgxPermissionsService,private translate: TranslateService, private customerService: CustomerService, private router: Router, private toastr: ToastrService) {

   }

  async ngOnInit(): Promise<void> {
    this.org = JSON.parse(localStorage.getItem('organizations')).find(o=>o.selected)
    this.getOrderedCustomers()
    this.getUnorderedCustomers()
    if(localStorage.getItem('lang') == 'ar'){
      document.getElementsByClassName('fa-times')[0]['style'].left = '145px';
    }
  }

  async getOrderedCustomers(){
    let response = await this.customerService.getOrderedCustomers(this.payloadOrdered, this.org.storeId);
this.orderedCustomers = response.customers;
    this.totalRecordsOrdered = response.totalRecord;
this.createPaginationOrdered()
    this.orderedCustomers.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })
  }
  async getUnorderedCustomers(){
    let response2 = await this.customerService.getUnconvertedCustomers(this.payloadUnordered, this.org.storeId);
    this.unOrderedCustomers = response2.customers;

    this.totalRecordsUnordered = response2.totalRecord;
    this.unOrderedCustomers.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })
  }

  // async sort(SortName: String) {
  //   if (SortName == 'customerName') {
  //     this.payload.sort = { "customerName": 1 }
  //   }
  //   if (SortName == 'email') {
  //     this.payload.sort = { "email": 1 }
  //   }
  //   if (SortName == 'createdAt') {
  //     this.payload.sort = { "createdAt": 1 }
  //   }
  //   this.payload.pagination = { skip: this.pageStart, limit: this.pageLimit }
  //   let response = await this.customerService.getOrderedCustomers(this.payload, this.org.storeId);
  //   this.orderedCustomers = response.customers;
  //   this.totalRecords = response.totalRecord;
  //   this.createPagination()
  //   this.orderedCustomers.map(single=>{
  //     single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
  //   })
  // }

  createPaginationOrdered() {
    this.NoOfPagesOrdered = Math.ceil(this.totalRecordsOrdered / this.pageLimitOrdered)
  }
  createPaginationUnordered() {
    this.NoOfPagesUnordered = Math.ceil(this.totalRecordsUnordered / this.pageLimitUnordered)
  }

  async goToPageOrdered(Index) {
    this.pageStartOrdered = (Index * this.pageLimitOrdered);
    this.payloadOrdered.pagination = { skip: this.pageStartOrdered, limit: this.pageLimitOrdered }
    let response = await this.customerService.getOrderedCustomers(this.payloadOrdered, this.org.storeId);
    this.orderedCustomers = response.customers;
    this.totalRecordsOrdered = response.totalRecord;
    this.createPaginationOrdered()
    this.orderedCustomers.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })
  }
  async goToPageUnordered(Index) {
    this.pageStartUnordered = (Index * this.pageLimitUnordered);
    this.payloadUnordered.pagination = { skip: this.pageStartUnordered, limit: this.pageLimitUnordered }
    let response = await this.customerService.getUnconvertedCustomers(this.payloadUnordered, this.org.storeId);
    this.unOrderedCustomers = response.customers;
    this.totalRecordsUnordered = response.totalRecord;
    this.createPaginationUnordered()
    this.unOrderedCustomers.map(single=>{
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })
  }

  // async reset() {
  //   this.payload.sort = {}
  //   this.payload.pagination = { skip: this.pageStart, limit: this.pageLimit }
  //   let response = await this.customerService.getOrderedCustomers(this.payload, this.org.storeId);
  //   this.orderedCustomers = response.customers;
  //   this.totalRecords = response.totalRecord;
  //   this.createPagination()
  //   this.orderedCustomers.map(single=>{
  //     single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
  //   })
  // }

  async toggleBlockOrdered(id, i){
    let index = _.findIndex(this.orderedCustomers, {_id: id});
    this.block = !this.orderedCustomers[index].block
    let payloadBlock = {
      block: this.block
    }
    let resp:any = await this.customerService.blockCustomer(id, payloadBlock)
    if(!resp){
      document.getElementById('toggle-ordered-'+i)['checked'] = !document.getElementById('toggle-ordered-'+i)['checked'];
    }

  }
  async toggleBlockUnordered(id, i){
    let index = _.findIndex(this.unOrderedCustomers, {_id: id});
    this.block = !this.unOrderedCustomers[index].block
    let payloadBlock = {
      block: this.block
    }
    let resp:any = await this.customerService.blockCustomerUnordered(id, payloadBlock)
    if(!resp){
      document.getElementById('toggle-unordered-'+i)['checked'] = !document.getElementById('toggle-unordered-'+i)['checked'];
    }
  }

  updateCustomer(id){
    this.router.navigate(['customer-profile/'+id])
  }

  showOrders(id){
    this.router.navigate(['orders/customer/'+id])
  }

  counterOrdered(i) {
    return new Array(i)
  }
  counterUnordered(i) {
    return new Array(i)
  }

  downloadPDF(){
    var innerContents = document.getElementById('pdfTable-'+this.tab).innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <style>
      #actionsHead, #actionsValue, #blockHead, #blockValue{
        display: none;
      }
      .sort, .fa{
        display: none !important
      }
      .paginationBtn{
        display: none
      }
    </style>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="content">${innerContents}</div>
</body>
</html>`);
  popupWinindow.document.close();
}
pageSizeChangedOrdered(e){
  this.pageLimitOrdered = parseInt(e.target.value);
  this.pageStartOrdered = 0;
  this.getOrderedCustomers()
}
pageSizeChangedUnordered(e){
  this.pageLimitUnordered = parseInt(e.target.value);
  this.pageStartUnordered = 0;
  this.getUnorderedCustomers()
}

searchCustomers(type = undefined){
  console.log(type)
  if(type == 'clear' || (type && !this.searchValue)){
    delete this.payload.filters.$or;
    this.searchValue = ''
    this.tab=='ordered'? this.getOrderedCustomers() : this.getUnorderedCustomers();
    this.search = type == 'clear' ? false : true;
    return;
  }
  if(!type || type.keyCode == 13){
    if(this.searchValue){
      if(this.tab=='ordered'){
        this.pageStartOrdered = 0;
        this.payloadOrdered.pagination.start = 0;
        this.payloadOrdered.filters = {...this.payloadOrdered.filters, $or: [{name:{$regex:".*"+this.searchValue+".*",$options:'i'}}]}
      }
      else{
        this.pageStartUnordered = 0;
        this.payloadUnordered.pagination.start = 0;
        this.payloadUnordered.filters = {...this.payloadUnordered.filters, $or: [{name:{$regex:".*"+this.searchValue+".*",$options:'i'}}]}
      }
      this.tab=='ordered'? this.getOrderedCustomers() : this.getUnorderedCustomers();
    }
  }
}
}
