import { Injectable } from '@angular/core';
import { DataServiceService } from './data-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
//import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token;
  language = new BehaviorSubject('en');
  redirectUrl;
  private orgs = new BehaviorSubject([])

  constructor(private dataService: DataServiceService, private translate: TranslateService, private router: Router, private toastr: ToastrService,
    //private permissionService: NgxPermissionsService
    ) { }

  setToken(token){
    return localStorage.setItem('access_token', token)
  }

  getToken(){
    let token =localStorage.getItem('access_token');
    return token;
  }
  setUser(user){
    return localStorage.setItem('user_info', JSON.stringify(user))
  }

  getUser(){
    let user =localStorage.getItem('user_info');
    user= user ? JSON.parse(user) : null;
    return<any> user;
  }

  setOrganizations(){
    this.orgs.next(JSON.parse(localStorage.getItem('organizations')));
  }

  getOrganizations(): Observable<any>{

    return this.orgs.asObservable();;
  }

  changePassword(payload){
    this.dataService.changePassword(payload).subscribe(val=>{
      this.toastr.success('Password changed!')
      this.router.navigate(['dashboard'])
    },err=>{
      this.toastr.error(err?.error?.error || 'Internal server error')
    })
  }
  async verifyEmail(payload){
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.verifyEmail(payload)
        .subscribe(val =>{
          this.dataService.hideSpinner()
          resolve(val)
          // this.toastr.success('Logged in!')
        },err=>{
          this.dataService.hideSpinner()
          reject(err)
          this.toastr.error(err?.error?.error || 'Failed')
        });
      })
        let result = await promise;
        return result;
  }
  async resetPassword(payload){
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.resetPassword(payload)
        .subscribe(val =>{
          this.dataService.hideSpinner()
          resolve(val)
          this.toastr.success('')
        },err=>{
          this.dataService.hideSpinner()
          reject(err)
          this.toastr.error(err?.error?.error || 'Failed')
        });
      })
        let result = await promise;
        return result;
  }
  async newPassword(payload){
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.newPassword(payload)
        .subscribe(val =>{
          this.dataService.hideSpinner()
          resolve(val)
          this.toastr.success('Password has been reset')
        },err=>{
          this.dataService.hideSpinner()
          reject(err)
          this.toastr.error(err?.error?.error || 'Failed')
        });
      })
        let result = await promise;
        return result;
  }

  async login(payload){
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.signIn(payload)
        .subscribe(val =>{
          this.dataService.hideSpinner()
          resolve(val)
          this.toastr.success('Logged in!')
        },err=>{
          this.dataService.hideSpinner()
          reject(err)
          this.toastr.error(err?.error?.error || 'Failed')
        });
      })
        let result = await promise;
        return result;
    }
  async register(payload){
    this.dataService.showSpinner()
    let promise = new Promise<any>((resolve, reject) => {
      this.dataService.signUp(payload)
        .subscribe(val =>{
          this.dataService.hideSpinner()
          resolve(val)
          this.toastr.success('User Registered Successfully!')
        },err=>{
          this.dataService.hideSpinner()
          console.log(err)
          reject(err)
          this.toastr.error(err?.error?.error || 'Failed')
        });
      })
        let result = await promise;
        return result;
    }


  logout() {
    let currentLang = localStorage.getItem('lang')
    localStorage.clear()
    localStorage.setItem('lang', currentLang)
    return true;
  }

    isAuthenticated() {
      this.token = localStorage.getItem('access_token');
      if(this.token){
       return true;

    } else {
      this.router.navigate(['login']);
      return false
    }

    }

    // async register(payload){
    //   this.dataService.showSpinner()
    //   let promise = new Promise<any>((resolve, reject) => {
    //     this.dataService.signUp(payload)
    //       .subscribe(val =>{
    //         this.dataService.hideSpinner()
    //         resolve(val)
    //         this.toastr.success('User successfully registered!')
    //       },err=>{
    //         this.dataService.hideSpinner()
    //         reject(err)
    //         this.toastr.error(err?.error?.message ?? 'Registration failed')
    //       });
    //     })
    //       let result = await promise;
    //       return result;
    //   }

      getSuperAdmin(){
        if(localStorage.getItem('superadmin')){
          return JSON.parse(localStorage.getItem('superadmin'))
        }
        else{
          return false;
        }
      }

      setLanguage(lang){
        localStorage.setItem('lang', lang)
        setTimeout(() => {
          this.formatLang(lang)
          console.log('hereee')
        }, 100);
        this.language.next(lang)
      }

      formatLang(lang){
        let htmlTag = document.getElementsByTagName('html')[0];
        htmlTag.dir = lang == 'ar' ? 'rtl' : 'ltr';
        htmlTag.lang = lang;
        this.translate.use(lang);
        let head = document.getElementsByTagName('head')[0]
        let link = document.createElement('link')
        link.rel = "stylesheet";
        link.type = 'text/css';
        link.id = 'lang/CSS';
        link.href = lang == 'ar' ? 'arabicCSS' : 'englishCSS';
        head.appendChild(link);
        const icons = document.getElementsByClassName('fa-times')
        const elems = Array.prototype.slice.call(icons);
        this.formatSidebarCss(lang)
      }

      formatSidebarCss(lang){
        if(lang == 'ar'){
          document.getElementsByClassName('main-panel')[0]['style'].marginRight = '260px';
          document.getElementsByClassName('switcher')[0]['style'].marginRight = '90px';
          if(document.getElementsByClassName('arrow') && document.getElementsByClassName('arrow')[0]){
            
            document.getElementsByClassName('arrow')[0]['style'].left = '20px';
            document.getElementsByClassName('arrow')[0]['style'].right = 'auto';
            document.getElementsByClassName('arrow')[1]['style'].left = '20px';
            document.getElementsByClassName('arrow')[1]['style'].right = 'auto';
          }
          if(document.getElementsByClassName('promo-icon') && document.getElementsByClassName('promo-icon')[0]){
            document.getElementsByClassName('promo-icon')[0]['style'].right = '14px';
          }
          document.getElementsByClassName('sidebar')[0]['style'].left = 'auto';
          for(let i=0;i<40;i++){
            if(document.getElementsByClassName('sidebar-lbl')[i]){
              if(document.getElementsByClassName('sidebar-icon')[i]){
                document.getElementsByClassName('sidebar-icon')[i].classList.add('ar-sidebar-icon');
              }
              document.getElementsByClassName('sidebar-lbl')[i].classList.add('ar-sidebar-lbl');
            }
            if(document.getElementsByClassName('acc-icon')[i]){
              document.getElementsByClassName('acc-icon')[i]['style'].left = '0px';
            }
            if(document.getElementsByClassName('fa-times')[i]){
              document.getElementsByClassName('fa-times')[i]['style'].right = 'auto';
              document.getElementsByClassName('fa-times')[i]['style'].left = '145px';
            }
          }
          document.getElementsByClassName('share')[0]['style'].borderRight = '1px solid grey';
          console.log('hereeeeeeeeeeeeee',document.getElementsByClassName('share'))
          document.getElementsByClassName('share')[0]['style'].borderTopLeftRadius = '25px';
          document.getElementsByClassName('share')[0]['style'].borderBottomLeftRadius = '25px';
          document.getElementsByClassName('share')[0]['style'].borderTopRightRadius = '0px';
          document.getElementsByClassName('share')[0]['style'].borderBottomRightRadius = '0px';

          document.getElementsByClassName('visit')[0]['style'].borderRight = 'none';
          document.getElementsByClassName('visit')[0]['style'].borderTopRightRadius = '25px';
          document.getElementsByClassName('visit')[0]['style'].borderBottomRightRadius = '25px';
          document.getElementsByClassName('visit')[0]['style'].borderTopLeftRadius = '0px';
          document.getElementsByClassName('visit')[0]['style'].borderBottomLeftRadius = '0px';

          // if(document.getElementsByClassName('popover') && document.getElementsByClassName('popover')[0]){
          //   document.getElementsByClassName('popover')[0]['style'].left = '40px !important'
          // }
        }
        else{
          document.getElementsByClassName('main-panel')[0]['style'].marginRight = '0px';
          document.getElementsByClassName('switcher')[0]['style'].marginRight = '0px';
          if(document.getElementsByClassName('arrow') && document.getElementsByClassName('arrow')[0]){
            console.log(document.getElementsByClassName('arrow'))
            document.getElementsByClassName('arrow')[0]['style'].right = '20px';
            document.getElementsByClassName('arrow')[0]['style'].left = 'auto';
            document.getElementsByClassName('arrow')[1]['style'].right = '20px';
            document.getElementsByClassName('arrow')[1]['style'].left = 'auto';
          }
          if(document.getElementsByClassName('promo-icon') && document.getElementsByClassName('promo-icon')[0]){

            document.getElementsByClassName('promo-icon')[0]['style'].right = 'auto';
          }
          document.getElementsByClassName('sidebar')[0]['style'].left = '0px';
          for(let i=0;i<40;i++){
            if(document.getElementsByClassName('sidebar-icon')[i]){
              if(document.getElementsByClassName('sidebar-lbl')[i]){

                document.getElementsByClassName('sidebar-lbl')[i].classList.remove('ar-sidebar-lbl');
              }
              document.getElementsByClassName('sidebar-icon')[i].classList.remove('ar-sidebar-icon');
            }
            if(document.getElementsByClassName('acc-icon')[i]){
              document.getElementsByClassName('acc-icon')[i]['style'].left = 'auto';
            }
            if(document.getElementsByClassName('fa-times')[i]){
              document.getElementsByClassName('fa-times')[i]['style'].left = 'auto';
              document.getElementsByClassName('fa-times')[i]['style'].right = '145px';
            }
          }
          document.getElementsByClassName('share')[0]['style'].borderRight = 'none';
          document.getElementsByClassName('share')[0]['style'].borderTopRightRadius = '25px';
          document.getElementsByClassName('share')[0]['style'].borderBottomRightRadius = '25px';
          document.getElementsByClassName('share')[0]['style'].borderTopLeftRadius = '0px';
          document.getElementsByClassName('share')[0]['style'].borderBottomLeftRadius = '0px';

          document.getElementsByClassName('visit')[0]['style'].borderRight = '1px solid grey';
          document.getElementsByClassName('visit')[0]['style'].borderTopLeftRadius = '25px';
          document.getElementsByClassName('visit')[0]['style'].borderBottomLeftRadius = '25px';
          document.getElementsByClassName('visit')[0]['style'].borderTopRightRadius = '0px';
          document.getElementsByClassName('visit')[0]['style'].borderBottomRightRadius = '0px';
          // if(document.getElementsByClassName('popover') && document.getElementsByClassName('popover')[0]){
          //   document.getElementsByClassName('popover')[0]['style'].left = '-40px !important'
          // }
        }
      }



}
