import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataServiceService } from 'app/utilities/data-service.service';
import * as _ from 'lodash';
import { AuthService } from 'app/utilities/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';
import { StoreService } from '../store.service';
import { DeleteModalComponent } from '../../../../delete-modal/delete-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.css']
})
export class StoreListComponent implements OnInit {
  pageLimit = 10;
  modalOptions:NgbModalOptions = {
    backdrop:'static',
    size:'lg'
  }
  pageStart = 0;
  totalRecords = 0;
  NoOfPages = 0;
  constructor(private router: Router, private translate: TranslateService,private ngModalService: NgbModal, private storeService: StoreService, private dataService: DataServiceService, private toastr: ToastrService, private authenticationService: AuthService) {

  }

  payload = {
    "filters": {},
    "pagination": { start: this.pageStart, limit: this.pageLimit },
    "sort": {},
    "fields": "name branch city phoneNumber block isServing isOpen"
  }

  stores;
  block = false;
  orderTypes = [
    { id: 0, name: 'Delivery' },
    { id: 1, name: 'Take away' },
    { id: 2, name: 'Reservation' },
    { id: 3, name: 'Digital menu' }
  ];
  selectedOrderTypes = []
  isSuperAdmin = false;
  urlid = 'all'
  async ngOnInit(): Promise<void> {
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.selectedOrderTypes = []
    let response = await this.storeService.getStores(this.payload)

    this.totalRecords = response.totalRecord
    this.stores = response.stores;
    this.stores.map(async single => {
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })

    let user = await this.authenticationService.getUser();

    if (await user.role == "superAdmin") {
      this.isSuperAdmin = true;
    }

    this.createPagination()
    /* for (let i = 0; i < this.stores.length; i++) {
      this.stores[i].orderType.map(single => {
        let index = _.findIndex(this.orderTypes, { id: single });
        this.selectedOrderTypes.push({ id: single, name: this.orderTypes[index].name, index: i })
      })
    } */
  }
  addStore() {
    this.router.navigate(['store-register/add'])
  }
  updateStore(id) {
    this.router.navigate(['store-register/' + id])
  }
  async sort(SortName: String) {
    if (SortName == 'name') {
      this.payload.sort = { "name": 1 }
    }
    if (SortName == 'city') {
      this.payload.sort = { "city": 1 }
    }
    if (SortName == 'country') {
      this.payload.sort = { "country": 1 }
    }
    if (SortName == 'order') {
      this.payload.sort = { "orderTypes": 1 }
    }
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.selectedOrderTypes = []
    let response = await this.storeService.getStores(this.payload)
    this.totalRecords = response.totalRecord
    this.stores = response.stores;
    this.stores.map(single => {
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })
    this.createPagination()
    for (let i = 0; i < this.stores.length; i++) {
      this.stores[i].orderType.map(single => {
        let index = _.findIndex(this.orderTypes, { id: single });
        this.selectedOrderTypes.push({ id: single, name: this.orderTypes[index].name, index: i })
      })
    }
  }

  async reset() {
    this.payload.sort = {}
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.selectedOrderTypes = []
    let response = await this.storeService.getStores(this.payload)
    this.totalRecords = response.totalRecord
    this.stores = response.stores;
    this.stores.map(single => {
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })

    this.createPagination()
    /* for (let i = 0; i < this.stores.length; i++) {
      this.stores[i].orderType.map(single => {
        let index = _.findIndex(this.orderTypes, { id: single });
        this.selectedOrderTypes.push({ id: single, name: this.orderTypes[index].name, index: i })
      })
    } */
  }

  menu(id) {
    this.router.navigate(['menu-list/' + id]);
  }

  async deleteStore(id, message) {
    let that = this;
    const modalRef = this.ngModalService.open(DeleteModalComponent,this.modalOptions);
    modalRef.componentInstance.message = 'Are you sure you want to delete this store?';
    modalRef.result.then(async (result) => {
      if (result == 'yes') {
          let response = await this.storeService.deleteStore(id)
            this.ngOnInit()
      }
    });
    // let res = await this.confirmationService.confirmation('Are you sure you want to delete this store?')
    // if(res){
    // }
  }
  createPagination() {
    this.NoOfPages = Math.ceil(this.totalRecords / this.pageLimit)
  }
  async goToPage(Index) {

    this.pageStart = (Index * this.pageLimit);
    this.payload.pagination = { start: this.pageStart, limit: this.pageLimit }
    this.selectedOrderTypes = []
    let response = await this.storeService.getStores(this.payload)
    this.totalRecords = response.totalRecord
    this.stores = response.stores;
    this.stores.map(single => {
      single.createdAt = this.dataService.formatDate(new Date(single.createdAt))
    })

    this.createPagination()
    /* for (let i = 0; i < this.stores.length; i++) {
      this.stores[i].orderType.map(single => {
        let index = _.findIndex(this.orderTypes, { id: single });
        this.selectedOrderTypes.push({ id: single, name: this.orderTypes[index].name, index: i })
      })
    } */

  }

  async toggleBlock(id) {
    let index = _.findIndex(this.stores, { _id: id });
    this.block = !this.stores[index].block
    let payloadBlock = {
      block: this.block
    }
    let response = await this.storeService.blockStore(id, payloadBlock)
    if (response.restUpdate.nModified == 1) {
      this.stores[index].block = !this.stores[index].block
    }
  }
  goToOrders(id) {
    this.router.navigate(['orders/store/' + id])
  }
  counter(i) {
    return new Array(i)
  }

  downloadPDF() {
    var innerContents = document.getElementById('pdfTable').innerHTML;
    var popupWinindow = window.open('', '_blank', 'top=0,left=0,height=0,width=0');
    popupWinindow.document.open();
    popupWinindow.document.write(`<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width">
      <style>
        #actionsHead{
          display: none
        }
        #blockHead{
          display: none
        }
        #actionsValue{
          display: none
        }
        #blockValue{
          display: none
        }
        .sort, .fa{
          display: none !important
        }
        .w-10{
          width: 15%
        }
        .w-13{
          width: 15%
        }
        .w-12{
          width: 20%
        }
        .w-6{
          width: 18%
        }
        .w-16{
          width: 20%
        }
        .paginationBtn{
          display: none
        }
      </style>
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
</head>
<body onload="window.print()" class="pad-15">

<div class="content">${innerContents}</div>
</body>
</html>`);
    popupWinindow.document.close();
  }

  pageSizeChanged(e) {
    this.pageLimit = parseInt(e.target.value);
    this.pageStart = 0;
    this.ngOnInit()
  }

  syncFbData() {
    let response = this.storeService.syncFbData()
  }

  resetBotMenus() {
    let response = this.storeService.resetBotMenus()
  }

  resetBotStores() {
    let response = this.storeService.resetBotStores()
  }

  async updateClosed(id) {
    let index = _.findIndex(this.stores, { _id: id });

    this.storeService.updateClosed({
      "filters": {
        "_id": this.stores[index]._id
      },
      "isOpen": this.stores[index].isOpen ? false : true
    })
      .then((res) => {
        if (res.openStatus.nModified == 1) {
          this.stores[index].isClosed = !this.stores[index].isClosed
        }
      })
  }

  async updateOnBoarded(id) {
    let index = _.findIndex(this.stores, { _id: id });

    let updateOnBoardedPayload = {
      "filters": {
        "_id": this.stores[index]._id
      },
      "isServing": this.stores[index].isOnboarded ? false : true
    }

    let updateServing = await this.storeService.updateOnBoarded(updateOnBoardedPayload).then(() => this.ngOnInit());
  }
}
