import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-method-modal',
  templateUrl: './method-modal.component.html',
  styleUrls: ['./method-modal.component.css']
})
export class MethodModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }
    methods = [
    {methodId: 1, name: 'Flat Rate', isActive: true, cost: 0},
    {methodId: 2, name: 'Free Shipping', isActive: true, minimumOrderAmount: 0}
  ]
  @Input() public addedMethods:any;
  @Input() public edit:any;
  selectedMethod;
  ngOnInit(): void {
    if(this.edit){
      this.methods[this.methods.findIndex(m=>m.methodId == this.edit.methodId)] = this.edit;
      this.selectedMethod = this.edit.methodId;
    }
  }

  close(type){
    if(type == 'submit'){
      this.activeModal.close(this.methods.find(m=>m.methodId == this.selectedMethod))
    }
    if(type=='cancel'){
      this.activeModal.close(type)
    }
  }

  selectMethod(e){
    this.selectedMethod = e.target.value;
  }

  formatMethods(){
    if(!this.edit){
      return this.methods.filter(m=>m.methodId!=(this.addedMethods.length ? this.addedMethods[0].methodId : null))
    }
    else{
      return [this.edit]
    }
  }

}
