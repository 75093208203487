import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataServiceService } from 'app/utilities/data-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-attach-property',
  templateUrl: './attach-property.component.html',
  styleUrls: ['./attach-property.component.scss']
})
export class AttachPropertyComponent implements OnInit, OnDestroy {
  @Input() public message;
  @Input() public attachTo: "category" | "sub-category" | "product";
  @Input() storeId: string = "";
  @Input() index: number = -1;

  searchControl: FormControl = new FormControl();

  destroyed$: Subject<string> = new Subject<string>();

  list: any[] = [];
  SHADOW_LIST: any[] = [];

  startListFrom: number = 0;
  itemsToGetOnScroll: number = 10;
  totalItems: number = 0;

  selected = null;
  selectedIndex: number | null = null;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private dataService: DataServiceService
  ) { }

  isAPIBeingCalled: boolean = false;

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((next: string) => {
      this.list = this.SHADOW_LIST.filter((item) => item.name.toLowerCase().includes(next));
    });

    this.fetchList();
  }

  async fetchList() {
    if(!this.storeId) return;

    try {
      this.isAPIBeingCalled = false;

      let payload = { filters: { storeId: this.storeId }, pagination: { start: this.startListFrom, limit: 10 }, sort: {}, fields: "" }
      let response;

      if (this.attachTo === "product") {
        payload.fields = "name displayPicture _id";
        response = await this.dataService.getProducts(payload).toPromise();
        this.list = [...this.list, ...response.product];
        this.SHADOW_LIST = [...this.list, ...response.product];
        this.totalItems = response.totalRecord;
      }

      if (this.attachTo === "sub-category") {
        payload.fields = "name picture _id";
        response = await this.dataService.getSubCategories(payload).toPromise();
        this.list = [...this.list, ...response.subCategory];
        this.SHADOW_LIST = [...this.list, ...response.subCategory];
        this.totalItems = response.totalRecord;
      }

      if (this.attachTo === "category") {
        payload.fields = "name picture _id";
        response = await this.dataService.getCategories(payload).toPromise();
        this.list = [...this.list, ...response.category];
        this.SHADOW_LIST = [...this.list, ...response.category];
        this.totalItems = response.totalRecord;
      }

    } catch (error) {
      this.isAPIBeingCalled = true;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next('');
    this.destroyed$.complete();
  }

  close(type: string) {
    let closeWith: string = type;

    if(closeWith === "attach") {
      const payload: { [key: string]: string | number } = { };

      payload['url'] = this.getParsedURL();
      payload['bannerIndex'] = this.index;
      payload['property'] = this.selected;
      payload['property']['type'] = this.attachTo;

      closeWith = `${closeWith}_-_${JSON.stringify(payload)}` 
    }

    this.activeModal.close(closeWith);
  }

  getParsedURL(): string {
    const param = {
      "category": `shop/product-list?categoryId=${this.selected._id}`,
      "sub-category": `shop/product-list?subCategoryId=${this.selected._id}`,
      "product": `shop/product/${this.selected._id}`
    }

    return param[this.attachTo];
  }

  changeSelectedItems(item: any) {
    this.selected = item;
  }

  onScroll() {
    if (this.list.length === this.totalItems) return;

    this.startListFrom = this.startListFrom + this.itemsToGetOnScroll;
    this.fetchList();
  }

  selectOption(event, index) {
    this.selected = event.target.checked ? this.list[index] : undefined;

    for(let i = 0; i < this.list.length; i++) {
      if(i !== index) {
        this.list[i].selected = false;
      }
    }
  }
}
